

// Calculate Maximum Heart Rate (MHR) using the formula: 220 - age
const calculateMHR = (age: any, userAccountAge: any) => {
    const ageNew = age || userAccountAge;
    console.log('AGE:', ageNew)
    const mhr = 220 - (ageNew || 0);
    console.log('MHR:', mhr)
    return mhr;
};

// Calculate Heart Rate Reserve (HRR)
const calculateHRR = (heartRateProfile: any) => {

    const { heartrateResting, heartrateMax, age, userAccountAge } = heartRateProfile

    const mhr = heartrateMax ? heartrateMax : calculateMHR(age, userAccountAge)
    console.log('mhr:', mhr)
    const hrr = mhr - (parseInt(heartrateResting) || 0);
    console.log('HRR:', hrr)
    return hrr;
};

// Calculate zones based on percentage ranges of HRR + Resting HR
const calculateZones = (heartRateProfile: any) => {

    const { heartrateResting } = heartRateProfile;

    console.log('!!! HEART RATE PROFILE:', heartRateProfile)

    const hrr = calculateHRR(heartRateProfile);
    const rhr = parseInt(heartrateResting) || 0;

    const initialZoneRanges = {
        'zone5': { min: Math.round(rhr + hrr * 0.9), max: Math.round(rhr + hrr * 1.0) },
        'zone4': { min: Math.round(rhr + hrr * 0.8), max: Math.round(rhr + hrr * 0.9) },
        'zone3': { min: Math.round(rhr + hrr * 0.7), max: Math.round(rhr + hrr * 0.8) },
        'zone2': { min: Math.round(rhr + hrr * 0.6), max: Math.round(rhr + hrr * 0.7) },
        'zone1': { min: Math.round(rhr + hrr * 0.5), max: Math.round(rhr + hrr * 0.6) }
    }

    console.log('forDB', initialZoneRanges)

    if (initialZoneRanges) return initialZoneRanges

    // just a better visualization of data returned
    return [
        {
            zone: 1,
            range: '50-60%',
            min: Math.round(rhr + hrr * 0.5),
            max: Math.round(rhr + hrr * 0.6),
        },
        {
            zone: 2,
            range: '60-70%',
            min: Math.round(rhr + hrr * 0.6),
            max: Math.round(rhr + hrr * 0.7),
        },
        {
            zone: 3,
            range: '70-80%',
            min: Math.round(rhr + hrr * 0.7),
            max: Math.round(rhr + hrr * 0.8),
        },
        {
            zone: 4,
            range: '80-90%',
            min: Math.round(rhr + hrr * 0.8),
            max: Math.round(rhr + hrr * 0.9),
        },
        {
            zone: 5,
            range: '90-100%',
            min: Math.round(rhr + hrr * 0.9),
            max: Math.round(rhr + hrr * 1.0),
        },
    ];
}


export { calculateZones, calculateHRR, calculateMHR }