/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { FC, useEffect, useState } from "react"
import HeartRateGraph from "./HeartRateGraph"
import HeartRateZonesInitial from "./HeartRateZonesInitial"
import HeartRateZonesView from "./HeartRateZonesView"
import { GetHeartRateProfile, GetHeartRateZones } from "../../modules/trainingPlanActions"
import Box from "@mui/material/Box"
import RSHeaderCont from "../layout/RSHeaderCont"
import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import RSDrawer from "../layout/RSDrawer"
import Loader from "../animation/Loader"
import moment from "moment"






const HeartRateBase: FC<any> = ({ showHeartRateTracker, setShowHeartRateTracker, userDataActivity, selectedActivity, accountProfile, userInfo, dayData }) => {

    const dayDetails = { planId: userInfo?.uid, planDayId: dayData?.marathon?.id, currentDate: dayData?.date, activityId: selectedActivity?.activityId, timeFrame: selectedActivity?.timeFrame }

    const dataSortedAscendingTime = userDataActivity.length && userDataActivity.sort((a: any, b: any) => (a.sts || a.startTimeInSeconds || a.ts) - (b.sts || b.startTimeInSeconds || b.ts))


    const isMobile = useIsMobileScreen();
    const userAccountAge = accountProfile?.dob ? moment().diff(accountProfile?.dob, 'years') : '';

    const [isLoading, setIsLoading] = useState(true)
    const [heartRateProfile, setHeartRateProfile] = useState({ heartrateResting: '', heartrateMax: '', age: userAccountAge, setting: false })
    const [showHeartRateZonesComputator, setShowHeartRateZonesComputator] = useState(false)
    const [zoneRangesData, setZoneRangesData] = useState<any>([])


    const handleGetHeartRateProfile = async () => {

        showHeartRateTracker && setIsLoading(true)

        await GetHeartRateProfile(accountProfile.id).then((res) => {

            const data = res?.data?.result[0]

            if (data) setHeartRateProfile({ heartrateResting: data.heartrate_resting, heartrateMax: data.heartrate_max, age: userAccountAge, setting: data?.setting_manual || false })
            else setShowHeartRateZonesComputator(true)

            setIsLoading(false)


        }).catch((err) => { console.log('handleGetHeartRateProfile', err) })
    }


    const handleGetZoneRanges = () => {


        const selectedCalendarDate = moment(dayDetails.currentDate).format('YYYY-MM-DD')

        showHeartRateTracker && setIsLoading(true)

        GetHeartRateZones(dayDetails.planId).then((res) => {

            // console.log('getHZR...')

            const fetchedData = res.data.result
            const latestZoneRanges: any = []

            // console.log('d:', fetchedData)

            // for each data recieved, if selectedCalendarDate is the same or above the fetched data's date_created, then push that data to the latestZoneRanges
            fetchedData.forEach((data: any) => {

                // remove timezone
                const dateCreated = data.date_created.split('T')[0]
                const updatedDate = data.updated_date

                if (moment(selectedCalendarDate).isSameOrAfter(moment(dateCreated).format('YYYY-MM-DD'))) {
                    latestZoneRanges.push(data)
                } else if (moment(selectedCalendarDate).isSameOrAfter(moment(updatedDate).format('YYYY-MM-DD'))) {
                    latestZoneRanges.push(data)
                }
            })

            if (latestZoneRanges.length === 0 && fetchedData.length) latestZoneRanges.push(fetchedData[0])

            // console.log('ZR:', latestZoneRanges)
            setIsLoading(false)

            const zoneRangesParsed = JSON.parse(latestZoneRanges[latestZoneRanges.length - 1]?.zone_ranges)
            // console.log('ZRParsed', zoneRangesParsed)


            setZoneRangesData(zoneRangesParsed)


        }).catch((err) => {

            console.log(err)
        })
    }


    useEffect(() => {
        handleGetZoneRanges()
        handleGetHeartRateProfile()
    }, [showHeartRateZonesComputator, showHeartRateTracker])



    const renderHeartRateZonesComputator = () => <Box sx={{ height: '100%' }}>
        <RSHeaderCont className='ellipsis-1l' noBanner isStatic={!isMobile} backClick={() => setShowHeartRateTracker(false)} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title={'Heart Rate Zones'} />
        <HeartRateZonesInitial userAccountDetails={accountProfile} dayDetails={dayDetails} setShowHeartRateZonesComputator={setShowHeartRateZonesComputator}></HeartRateZonesInitial>
    </Box>


    const renderHeartRateVisuals = () => <Box sx={{ height: '100%' }}>
        <RSHeaderCont className='ellipsis-1l' noBanner isStatic={!isMobile} backClick={() => setShowHeartRateTracker(false)} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title={'Heart Rate'} />
        {zoneRangesData ? <>
            <HeartRateGraph
                userDataActivity={dataSortedAscendingTime || userDataActivity}
                timeFrame={dayDetails?.timeFrame}
            />
            <HeartRateZonesView
                userAccountDetails={accountProfile}
                userDataActivity={dataSortedAscendingTime || userDataActivity}
                timeFrame={dayDetails?.timeFrame}
                dayDetails={dayDetails}
                heartRateProfile={heartRateProfile}
                zoneRangesData={zoneRangesData}
                handleGetZoneRanges={handleGetZoneRanges}
            ></HeartRateZonesView>
        </> : ''}

    </Box>


    return <>
        <RSDrawer fitContent popUpWidth={"auto"} bottom={!isMobile} open={showHeartRateTracker} onClose={() => {
            setShowHeartRateTracker(false)
        }}>
            {showHeartRateZonesComputator ? renderHeartRateZonesComputator() : renderHeartRateVisuals()}
        </RSDrawer>

        {showHeartRateTracker && <Loader active={isLoading || !zoneRangesData} />}
    </>

}


export default HeartRateBase