/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import { motion, AnimatePresence } from "framer-motion";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Download from '@mui/icons-material/Download'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import ProgramMidfoot from './ProgramMidfoot'
import ProgramReset from './ProgramReset'
import { DownloadModal } from '../../guides/Guide'
import featuredPrograms from '../../../modules/programs/featured-programs.json'
import { WEBINAR_DOWNLOADS, guideDocuments } from '../../../modules/values'
import allVideos from '../../../modules/videos/allVideos'
import { linkID } from '../../../modules/linkInfo'
import { strengthGuide, trainingGuide } from '../../../modules/videos/videoOrders'
import useProgramPage from '../../../hooks/useProgramPage'
import useVideoProgress from '../../../hooks/useVideoProgress'
import useAlert from '../../../hooks/useAlert'
import useFavorites from '../../favorites/useFavorites'
import VimeoPlayer from '../../../components/misc/VimeoPlayer'
import Heart from '../../../components/logos/Heart'
import GenericModal from '../../../components/misc/GenericModal'
import Loader from '../../../components/animation/Loader'
import RSNavCont from '../../../components/layout/RSNavCont'
import RSHeaderCont from '../../../components/layout/RSHeaderCont'
import { RSButton1 } from '../../../components/buttons'
import RSDrawer from '../../../components/layout/RSDrawer'
import { SH2, H2, P1 } from '../../../components/text/RSTypography'
import { IVimeoPlayerStore } from '../../../store/createVimeoPlayerStore';
import useStore from '../../../store/useStore';
import VimeoPlayerDefault from '../../../components/misc/VimeoPlayerDefault';
import RSVideoCard from '../../../components/cards/RSVideoCard'
import MidfootData from '../../midfoot-project/midfoot.db.json'
import midfootActions from '../../midfoot-project/midfootActions'
import resetActions from '../../reset/resetActions'
import GoogleCastButton from '../../../components/buttons/GoogleCastButton';
import usePlayCastVideo from '../../../hooks/usePlayCastVideo';
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import { IGoogleCastStore } from '../../../store/createGoogleCastStore';
import useRNBridge from '../../../hooks/useRNBridge';
import { Reset } from '../../../api/v2/reset/reset.types'
import { AdditionalContent, Program, ProgramVideo } from '../../../api/v2/programs/programs.types';
import useGetVideoProgress from '../hooks/useGetVideoProgress';
import { IProgramStore } from '../../../store/createProgramStore';
import useSWR from 'swr';
import { addActivePlan, getActivePlan, removeActivePlan } from '../../../modules/programActions';
import checkmarkWatched from './images/icons/video-card-check-mark_watched.svg';
import checkmarkUnwatched from './images/icons/video-card-check-mark_unwatched.svg';
import ActivePlanUpdatePanel from '../../../components/misc/ActivePlanUpdatePanel';
import { RingSpinner, RingSpinnerOverlay } from 'react-spinner-overlay';
import { Hidden } from '@mui/material';
import { navReq } from '../../../modules/apiConsume';
import { useLocation } from 'react-router-dom';
import { ITrainingPlanStore } from '../../../store/createTrainingPlanStore';
import { ICurrentUser } from '../../../store/createAccountStore'
import ProgramTraining from './ProgramTraining';
import useAppEvents from '../../../hooks/useAppEvents';
import { createInterest } from '../../../modules/interestsActions';

const variants = {
	enter: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		opacity: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "-100%" : "100%"
		};
	}
};

const ProgramMetadata = (props: any) => {
	const { onboarding, userInfo, programID, setProgramID, isRedirect, setIsRedirect, videoId, currentTab, setCurrentTab, backToHome, currentWeek } = props
	const { data: program, isLoading: isLoadingProgram, mutate } = useSWR(programID ? `/programs/${programID}?videos=all&texts=all&userId=${userInfo?.account_id}` : undefined)
	const url = useLocation();
	const { subscription } = useStore((state: ICurrentUser) => state)
	const locked = subscription?.accessDetails?.type?.toLowerCase() === 'basic'
	const [open, setOpen] = useState<any>(false)
	const [programData, setProgramData] = useState<any>('')
	const [initTab, setInitTab] = useState(0)
	const [activeTab, setActiveTab] = useState(0)
	// const [showVideo, setShowVideo] = useState<any>(false)
	const [videoData, setVideoData] = useState<any>(undefined)
	//const [isLoadingMark, setIsLoadingMark] = useState(false)
	//const [isFavorite, setIsFavorite] = useState(false)
	const [introVideoId, setIntroVideoId] = useState<any>(null)
	//const [currentVideoId, setCurrentVideoId] = useState<any>(null)
	const [introPlaying, setIntroPlaying] = useState(false)
	const [newWorkoutVideos, setNewWorkOutVideos] = useState<ProgramVideo[]>()
	const [newIntroVideos, setNewIntroVideos] = useState<ProgramVideo[]>()
	const [guideVideos, setGuideVideos] = useState<any>([])
	const [newGuideVideos, setNewGuideVideos] = useState<any>([])
	const [averageLength, setAverageLength] = useState(0)
	const [activeGroup, setActiveGroup] = useState('')
	const [modalOpen, setModalOpen] = useState(false)
	const [phaseVideos, setPhaseVideos] = useState<any>({})
	const [modalT, setModalT] = useState('')
	const [showPhases, setShowPhases] = useState(false)
	const [phase, setPhase] = useState(0)
	const [initPhase, setInitPhase] = useState(0)
	const [activeMidfoot, setActiveMidfoot] = useState<any | undefined>()
	const [isLoading, setIsLoading] = useState(false)
	const [hasActiveTraining, setHasActiveTraining] = useState(false)
	const [resetList, setResetList] = useState<any>([])
	const [resetData, setResetData] = useState<Reset | undefined>()
	const [midfootWeekVideosWithDuration, setMidfootWeekVideosWithDuration] = useState<any>({})
	const [resetWeekVideosWithDuration, setResetWeekVideosWithDuration] = useState<any>({})
	const [selectedProgram, setSelectedProgram] = useState<Program | undefined>()
	const [currentFile, setCurrentFile] = useState<string | undefined>()

	//// useStates for active plan
	const [showUpdatePlanPanel, setShowUpdatePlanPanel] = useState(false)
	const [isPlanActive, setIsPlanActive] = useState(false)
	const [activePlanId, setActivePlanId] = useState(0)
	const [activePlanProgress, setActivePlanProgress] = useState(0)
	const [confirmation, setConfirmation] = useState<boolean | string>(false)
	////

	//// useStates for onboarding view
	const [showOnboardingView, setShowOnboardingView] = useState(true)
	////

	const isMobile = useIsMobileScreen();


	// const [isPlaying, setIsPlaying] = useState(false)
	const prevActiveTab = useRef(0)
	const prevActivePhase = useRef(0)
	const [selectedVideoProgress, setSelectedVideoProgress] = useState(0)
	const { createEvent } = useAppEvents()
	const height = window.innerHeight
	const width = window.innerWidth
	const isLargeScreen = width > 1500
	const initVWidth = width - (isLargeScreen ? 48 : 0)
	const vWidth = initVWidth / (isLargeScreen ? 2 : 1)
	const videoPerRow = Math.floor(vWidth / 300)
	const colSpace = 12 / videoPerRow
	const vr = Math.floor(vWidth / 330)
	const colSpaceGuide = 12 / vr
	const { loading, getActiveMidfoot, createMidfootData, restartMidfoot } = midfootActions()
	const { getActiveReset, loading: resetLoading, createResetData, restartReset } = resetActions()
	const requestKey = localStorage.getItem('deviceRequestKey') || undefined
	const { playVideo } = usePlayCastVideo(requestKey)
	const { sendDataToReactNative } = useRNBridge()
	const [phaseList, setPhaseList] = useState<string[]>([])
	const [tabList, setTablist] = useState<string[]>([])
	const [contentCategory, setContentCategory] = useState<string[]>([])
	const { vimeoId, showVimeoPlayer, isPlaying, isFullScreen, setIsPlaying, setShowVimeoPlayer, setVimeoId, setIsFullScreen, setCurrentAction, setCurrentTitle, setCurrentList } = useStore((state: IVimeoPlayerStore) => state)
	const { programVideoProgress } = useStore((state: IProgramStore) => state)
	const { setSelectedVideo, setActiveVideo, activeVideo, setCastStatus, castStatus, selectedVideo } = useStore((state: IGoogleCastStore) => state)
	const { favorites, toggleFavorite, getFavorites, getIsFavorited } = useFavorites(userInfo?.account_id as number, undefined, 'workouts', true)
	const { onAlertOpen, setAlertMessage } = useAlert()
	const isMechanix = selectedProgram?.program_title?.toLowerCase().includes('mechanix')
	const isMidfoot = selectedProgram?.program_title?.toLowerCase().includes('midfoot')
	const isKneedForSpeed = selectedProgram?.program_title?.toLowerCase().includes('kneed for speed')
	const isYoga = selectedProgram?.program_title?.toLowerCase().includes('yoga')
	const isReset = selectedProgram?.program_title?.toLowerCase().includes('reset')
	const isFuel = selectedProgram?.program_title?.toLowerCase().includes('fuel')
	const isStrength = selectedProgram?.program_id === 6
	const hasPhases = phaseList.length > 0 ? true : false

	const hasMultipleFiles = selectedProgram?.additional_content && selectedProgram?.additional_content?.length > 1 ? true : false

	// const phasesList = ['Running Midfoot', 'Opening Your Stride', 'Finding Your Lean', 'Whole Body Running']
	document?.getElementsByClassName('selected-phase')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })

	const getPhases = () => {
		const phases: string[] = []
		selectedProgram?.videos?.forEach((video: ProgramVideo) => {
			if (!phases.includes(video?.phase as string) && video.phase !== "none")
				phases.push(video?.phase as string)
		});
		setPhaseList(phases)
	}

	const getNumberName = (num: number) => {
		switch (num) {
			case 1: return 'One'
			case 2: return 'Two'
			case 3: return 'Three'
			case 4: return 'Four'
			case 5: return 'Five'
			case 6: return 'Six'
			case 7: return 'Seven'
			case 8: return 'Eight'
			case 9: return 'Nine'
			case 10: return 'Ten'
		}
	}

	const loadResetData = async () => {
		setIsLoading(true)
		const data = await getActiveReset(userInfo?.account_id)
		if (data && data[0])
			setResetData(data[0] ? data[0] : undefined)
		const startD = data?.[0]?.date_started?.split('T')?.[0]?.replaceAll('-', '/')
		if (startD) {
			const allD = [...Array(30)].map((_, a: any) => moment(startD).add(a, 'days').format(`${window?.innerWidth < 340 ? 'ddd' : 'dddd'}, MMM DD, YYYY`))
			setResetList(allD)
			const daysDiff = moment().diff(moment(startD), 'days')
			if (daysDiff > 29) {
				setInitPhase(29)
			} else if (daysDiff < 0) {
				setInitPhase(0)
			} else
				setInitPhase(daysDiff)
		}
		setIsLoading(false)
	}

	const loadMidfootData = async () => {
		setIsLoading(true)
		const data = await getActiveMidfoot(userInfo?.account_id)
		let latestData = data?.[0]
		data?.forEach((d: any) => {
			const lm = latestData?.last_modified
			const dm = d?.last_modified
			if (lm && dm && moment(dm).isAfter(moment(lm)))
				latestData = d
		})
		setActiveMidfoot(latestData ? latestData : undefined)
		setIsLoading(false)
	}

	const handleContinueButton = async () => {
		if (resetData?.id && userInfo?.account_id) {
			await restartReset(userInfo.account_id, resetData.id)
			await loadResetData()
			setHasActiveTraining(false)
			selectedProgram?.intro_video_id && setIntroVideoId(selectedProgram?.intro_video_id)
			setIntroPlaying(true)
			setIsPlaying(false)
			setInitPhase(0)
		}
	}

	const handleStartTraining = async () => {
		if (resetData) {
			// setHasActiveTraining(true)
			await handleContinueButton()
		} else {
			await createResetData(userInfo?.account_id)
			await loadResetData()
			setInitPhase(0)
		}
	}

	const handleChangePhase = (val: number) => {
		prevActivePhase['current'] = phase
		setInitPhase(val);
	};

	const handleChangeActive = (event: React.SyntheticEvent, val: number) => {
		prevActiveTab['current'] = activeTab
		setInitTab(val);
	};

	const handleMarkAsFavorite = async (isChecked: boolean, id: any) => {
		if (id) {
			//setIsLoadingMark(true)
			await toggleFavorite(userInfo?.account_id as number, id as number, isChecked)
			await getFavorites()
			//setIsLoadingMark(false)
		}
	}

	const onDownloadClick = (title: string, filePath?: string) => {
		setModalT(title)
		setModalOpen(true)
		setCurrentFile(filePath)
	}

	const onLike = async (videoId: string) => {
		const isLiked = favorites && favorites.find((x: any) => x.id === videoId) ? true : false;
		await toggleFavorite(userInfo?.account_id as number, Number(videoId), !isLiked)
	}

	const getVideoTitle = (title: string) => {
		const program = selectedProgram?.program_title || 'RunSmart';
		// const value = program + ' | ' + title
		return program
	}

	const handlePlayVideo = async (videoId: string, title: string, type: string, progress?: number, videoTitle?: string) => {

		setSelectedVideo(videoId)
		setSelectedVideoProgress(Number(progress) | 0)
		window?.scroll?.({
			top: 0,
			behavior: "smooth"
		})
		document?.getElementsByClassName('MuiDrawer-paperAnchorRight')?.[0]?.scroll?.({
			top: 0,
			behavior: "smooth"
		})

		if ((castStatus == 'playing' || castStatus == 'active') && activeVideo != videoId) {
			setVimeoId(videoId)
			playVideo(videoId, progress)
		} else if ((castStatus == 'playing' || castStatus == 'active') && activeVideo == videoId) {
			setVimeoId(videoId)
			playVideo(videoId, progress, true)
		}
		else {
			setVimeoId(undefined)
			setIsPlaying(false)
			setTimeout(() => {
				setVimeoId(videoId)
				setIsPlaying(true)
				setIntroPlaying(false)
				setIntroVideoId(undefined)
			}, 200)
		}

		const isWorkout = selectedProgram?.program_title?.toLowerCase().includes('base') || selectedProgram?.program_title?.toLowerCase().includes('prime') || selectedProgram?.program_title?.toLowerCase().includes('yoga')

		const eventMetadata = {
			program_id: programID,
			program_type: selectedProgram?.program_title,
			video_id: videoId,
			video_title: isWorkout || !type ? (title || videoTitle) : type + " - " + title || videoTitle,
		}
		await createEvent(userInfo?.account_id, `${isWorkout ? "WORKOUT_LOGGED" : "PROGRAM_LOGGED"}`, JSON.stringify(eventMetadata))
		await createInterest({ user_id: userInfo?.account_id, interest_tag: selectedProgram?.category?.toLowerCase().replaceAll(" ", "_") || '' })
	}

	const getTitle = (title: any) => {
		const lowerTitle = title?.toLowerCase()
		if (lowerTitle?.includes('strength'))
			return ['Strength Training']
		else if (lowerTitle?.includes('training'))
			return ['5K/10K', 'Half-Marathon', 'Marathon']
		else if (lowerTitle?.includes('midfoot'))
			return [`Download`, ...MidfootData.map((md: any) => `Week ${md.week} Audio`)]
		else
			return [title]
	}

	const getModalTitle = (webinarType: string) => `${webinarType} Guide`

	const getFile = (title: any) => {
		const wd: any = { ...WEBINAR_DOWNLOADS, ...guideDocuments }
		if (isMidfoot) {
			const isAudio = title?.includes('Audio')
			const weekNum = title?.replaceAll(/\D*/g, '')
			return `/files/Midfoot${isAudio ? `_Week${weekNum}.mp3` : `_Project_Week.pdf`}`
		} else
			return wd[title]
	}

	const getEmailId = (title: any) => {
		const lowerTitle = title?.toLowerCase()
		if (lowerTitle?.includes('40'))
			return 84
		else if (lowerTitle?.includes('60'))
			return 82
		else if (lowerTitle?.includes('training'))
			return 76
		else if (lowerTitle?.includes('protocol'))
			return 77
		else if (lowerTitle?.includes('strength'))
			return 78
		else
			return 83
	}

	const getVideosId = () => programData?.Program?.includes('Training') ? trainingGuide : strengthGuide

	const getGuideType = (title: string) => {
		return title
	}

	const getAdditionContentCategories = (addContent: AdditionalContent[]) => {
		const categories: string[] = []

		addContent.forEach(element => {
			if (element.category && !categories.includes(element.category))
				categories.push(element.category)
		});

		setContentCategory(categories)
	}

	const getVideoProgress = (videoId: number) => {
		const progress = program?.data?.result[0]?.progress?.find((p: any) => p.video_id === videoId)?.progress
		return progress ? progress : 0
	}

	const getStartButtonLabel = () => {
		if (onboarding) {
			if (isPlanActive)
				return 'Plan Added'
			else
				return 'Add Plan'
		}
		else {
			if (isPlanActive)
				return 'Plan Started'
			else
				return 'Start Plan'
		}
	}

	useEffect(() => {
		isPlaying ? setCurrentAction("play") : undefined

		let intervalMutate: any

		if (isPlaying)
			intervalMutate = setInterval(() => { mutate() }, 5000)

		return () => {
			clearInterval(intervalMutate)
		}
	}, [isPlaying])

	useEffect(() => {
		!videoData ? setIsPlaying(false) : undefined
	}, [videoData])

	useEffect(() => {
		setShowPhases(false)
	}, [phase])

	useEffect(() => {
		setPhase(initPhase)
	}, [initPhase])

	useEffect(() => {
		setActiveTab(initTab)
	}, [initTab])

	useEffect(() => {
		if (currentTab && !isLoadingProgram && selectedProgram?.id) {
			// setInitTab(tabList.length + 1)
			if (selectedProgram?.program_tabs?.toLowerCase() === "about" || selectedProgram?.videos?.length === 1)
				setActiveTab(0)
			else {
				setActiveTab(currentTab)
				setInitTab(currentTab)
			}
		}
	}, [currentTab, isLoadingProgram, selectedProgram])

	useEffect(() => {
		if (showVimeoPlayer && open) {
			setIntroPlaying(true)
			setTimeout(() => {
				setIntroPlaying(false)
			}, 1)
		}
	}, [showVimeoPlayer])

	useEffect(() => {
		if (!isFullScreen && !showVimeoPlayer && !vimeoId) {
			setIntroPlaying(true)
			setIntroVideoId(introVideoId)
		}
	}, [isFullScreen, showVimeoPlayer, vimeoId])

	useEffect(() => {
		if (activeMidfoot && open && isMidfoot && !currentWeek) {
			const curWeek = activeMidfoot?.current_week ? activeMidfoot.current_week - 1 : 1
			setInitPhase(curWeek > 4 ? 4 : curWeek)
			setInitTab(1)
		}
		else if (currentWeek && open && isMidfoot) {
			setInitPhase(currentWeek - 1)
			setInitTab(1)
		}
	}, [activeMidfoot, open, currentWeek, isMidfoot])

	useEffect(() => {
		if (isReset && open) {
			setInitPhase(0)
			setInitTab(1)
		}
	}, [isReset, open])

	useEffect(() => {
		if (userInfo && open && isMidfoot) {
			loadMidfootData()
		}

		!open && setProgramID(null)
		!open && setSelectedProgram(undefined)
	}, [userInfo, open])

	useEffect(() => {
		if (userInfo && open && isReset)
			loadResetData()
	}, [userInfo, open, isReset])

	// useEffect(() => {
	// 	if (videoData?.cv && open) getIsFavorited(videoData.cv.id as unknown as number).then((res: any) => setIsFavorite(res))
	// }, [videoData, favorites])

	useEffect(() => {
		if (videoId) {
			setVimeoId(videoId)
			setIsPlaying(true)
		}
	}, [videoId])

	// useEffect(() => {
	// 	if (isPlaying && castStatus == 'active' && vimeoId) {
	// 		console.log('PLAYING VIDEO', vimeoId, selectedVideoProgress)
	// 		playVideo(vimeoId, selectedVideoProgress)
	// 		setIsPlaying(false)
	// 	}
	// }, [castStatus, isPlaying, vimeoId, activeVideo, selectedVideoProgress])

	useEffect(() => {
		const dataToSend = {
			requestType: 'cast-show-bottom-menu',
			payload: open ? false : true,
			timestamp: new Date().getTime(),
		}

		sendDataToReactNative(dataToSend);

	}, [open])

	useEffect(() => {

		return () => {
			const dataToSend = {
				requestType: 'cast-show-bottom-menu',
				payload: true,
				timestamp: new Date().getTime(),
			}

			sendDataToReactNative(dataToSend);
		}
	}, [])

	useEffect(() => {
		if (programID) {
			setOpen(true)

			if (isLoadingProgram && !program && !selectedProgram) {
				setIsLoading(true)
			}
			else if (!isLoadingProgram && !selectedProgram) {
				setIsLoading(false)
				setSelectedProgram(program?.data?.result[0] as Program)
			}
		}

	}, [program, isLoadingProgram, programID, selectedProgram])

	useEffect(() => {
		if (selectedProgram) {
			if (selectedProgram?.additional_content && selectedProgram?.additional_content.length > 0)
				getAdditionContentCategories(selectedProgram?.additional_content)
		}
	}, [selectedProgram])

	useEffect(() => {
		if (selectedProgram && selectedProgram?.videos && selectedProgram?.videos.length == 1 && isPlanActive) {
			handlePlayVideo(String(selectedProgram?.videos[0].video_id || ''), selectedProgram?.program_title || '', 'intro').then(() => {
				console.log('Playing video')
			})
		}
	}, [isPlanActive, selectedProgram])


	useEffect(() => {
		if (open) {
			setAverageLength(0)
			setGuideVideos([])
			setNewGuideVideos([])
			setNewIntroVideos([])
			setNewWorkOutVideos([])
			setPhaseVideos({})
			setInitPhase(currentWeek ? currentWeek - 1 : 0)
			setContentCategory([])
		}

		if (selectedProgram && open) {
			if (selectedProgram.intro_video_id && !videoId) {
				setIntroVideoId(selectedProgram.intro_video_id)
				setIntroPlaying(true)
				setIsPlaying(false)
			}

			if (videoId) {
				setVimeoId(videoId)
				setIsPlaying(true)
			}

			const workoutVideos = selectedProgram?.videos?.filter((v: ProgramVideo) => v.intro_status != 1)
			const introVideos = selectedProgram?.videos?.filter((v: ProgramVideo) => v.intro_status == 1)

			if (workoutVideos?.length) {
				let totalLength = 0
				setNewWorkOutVideos(workoutVideos)
				workoutVideos.forEach((v: ProgramVideo) => {
					totalLength = v.video_length ? totalLength + v.video_length : totalLength
				})
				setAverageLength(Number(Math.round((totalLength / workoutVideos.length) / 60).toFixed(0) || 0))
			}
			if (introVideos?.length)
				setNewIntroVideos(introVideos)

			if (selectedProgram?.videos && selectedProgram?.videos?.length > 0) {
				getPhases()
			}

			const isSingleVideo = selectedProgram?.videos?.length == 1 ? true : false

			const tabList: string[] = []
			selectedProgram?.program_tabs?.split(',').map((value) => {
				tabList.push(value.trim().toUpperCase())
			})
			// tabList = selectedProgram?.program_title.toLowerCase().includes('guide') ? [...tabList, 'GUIDES'] : [...tabList, introVideos?.length ? 'INTRO' : '', (isMechanix || isMidfoot) ? 'TRAINING' : isFuel ? 'VIDEOS' : isSingleVideo ? undefined : 'WORKOUTS']
			// tabList = isReset ? tabList.splice(2, 1) : tabList
			// tabList = isReset ? [...tabList, '', 'TRAINING'] : tabList
			// tabList = hasMultipleFiles ? [...tabList, 'FILES'] : tabList

			setTablist(tabList)
		}

		else {
			setIsPlaying(false)
			setIntroPlaying(false)
			setVimeoId(undefined)
			setIntroVideoId(undefined)
			// setProgramID(undefined)
			setProgramData('')
			setInitTab(0)
		}
	}, [selectedProgram, open, programID, currentWeek])

	const getActivePlanDetails = async (res?: any) => {

		if (res) setShowOnboardingView(false)

		const activePlanDetails = await getActivePlan(userInfo?.account_id, programID || selectedProgram?.program_id)

		if (activePlanDetails.data.result.length > 0) {
			setIsPlanActive(true);
			setConfirmation(false)
			setActivePlanId(activePlanDetails.data.result[0].id)
			setActivePlanProgress(activePlanDetails.data.result[0].progress_percent)

		} else {
			setIsPlanActive(false);
			setActivePlanId(0)
			setActivePlanProgress(0)
		}


	}

	const backToOnboarding = () => {

		if (onboarding || userInfo?.onboarding == 'true' || !userInfo?.plan_code) {
			setShowOnboardingView(true)
			navReq('/#/signup' + url.search)
		}
	}

	useEffect(() => {
		if (confirmation === false && !showOnboardingView) backToOnboarding()
	}, [confirmation])

	// for getting initial active plan data
	useEffect(() => {
		setShowUpdatePlanPanel(false)

		if (selectedProgram) {
			getActivePlanDetails()

			createInterest({ user_id: userInfo?.account_id, interest_tag: selectedProgram?.category?.toLowerCase().replaceAll(" ", "_") || '' })
		}
	}, [selectedProgram, userInfo])

	const renderEventDownload = () => {
		const event = selectedProgram?.additional_content?.find(x => x.type === "event")
		if (event && selectedProgram) {
			return (
				<>
					<Grid item xs={12} sx={{ mb: 1 }}>
						<Box sx={{ fontFamily: 'Poppins-Medium !important', fontSize: '18px' }}>{event.category}</Box>
						<Box className='blue-text' onClick={() => onDownloadClick(selectedProgram?.program_title, event?.file_path)} sx={{ fontFamily: 'Poppins-Bold !important', fontSize: '18px', cursor: "pointer" }}>Download Event Guide</Box>
					</Grid>

					<DownloadModal
						title={'Download'}
						show={modalOpen}
						onboarding={onboarding}
						setShow={setModalOpen}
						userInfo={userInfo}
						getEmailId={() => getEmailId(selectedProgram?.program_title)}
						getGuideType={() => getGuideType(selectedProgram?.program_title)}
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						getFile={() => currentFile ? currentFile : ''}
					/>
				</>
			)
		}
	}

	const renderDownloads = (isMultiple?: boolean) => {

		const getTitle = (category: string, title?: string, index?: number) => {
			if (isKneedForSpeed) {
				if (category.toLowerCase().includes('guide')) {
					if (index !== undefined) {
						return 'PDF Guide Week ' + (index + 1)
					}
				}
				else {
					if (index !== undefined) {
						return 'Audio Run Week ' + ((index - 4) + 1)
					}
				}
			}

			if (category.toLowerCase().includes('guide'))
				return 'Download Guide'
			else if (category.toLowerCase().includes('audio')) {
				if (index !== undefined) {
					return 'Week ' + index
				}
			}
			else if (title?.toLowerCase().includes('week')) {
				if (index !== undefined) {
					return 'Week ' + (index ? index + 1 : 0 + 1)
				}
			}
			else if (category.toLowerCase().includes('5k/10k') || category.toLowerCase().includes('marathon')) {
				if (index !== undefined) {
					return category
				}
			}
			else
				return 'Download Guide'
		}

		if (contentCategory.length > 0) {
			return <> {
				contentCategory.map(category => (
					<>
						<Grid key={category} container sx={{ pt: 2, pl: 2, fontFamily: 'Poppins-Medium', fontSize: '16px' }}>{category}</Grid>
						{selectedProgram?.additional_content?.filter(x => x.type !== "event").filter(x => x.category === category).map((file, i) => (<>

							<Grid item xs={isMultiple ? colSpace : 12}>
								<RSButton1 locked={locked} onClick={() => onDownloadClick(selectedProgram?.program_title, file?.file_path)} sx={{ mt: 1, maxWidth: '400px' }}>
									<Download sx={{ fontSize: '20px', mr: '5px' }} />
									{getTitle(file?.category || '', file?.file_path, i)}
								</RSButton1>
							</Grid>

							<DownloadModal
								title={'Download'}
								show={modalOpen && !locked}
								onboarding={onboarding}
								setShow={setModalOpen}
								userInfo={userInfo}
								getEmailId={() => getEmailId(selectedProgram?.program_title)}
								getGuideType={() => getGuideType(selectedProgram?.program_title)}
								onAlertOpen={onAlertOpen}
								setAlertMessage={setAlertMessage}
								getFile={() => currentFile ? currentFile : ''}
							/>

						</>))}
					</>
				))
			}
			</>
		}
		else {
			return <>
				{selectedProgram?.additional_content?.filter(x => x.type !== "event").map((file, i) => (<>
					<Grid item xs={isMultiple ? colSpace : 12}>
						<RSButton1 locked={locked} secondary={hasMultipleFiles ? false : true} onClick={() => onDownloadClick(selectedProgram?.program_title, file?.file_path)} sx={{ mt: 1, maxWidth: '400px' }}>
							<Download sx={{ fontSize: '20px', mr: '5px' }} />
							{getTitle(file?.category || '', file?.file_path, i)}
						</RSButton1>
					</Grid>
					<DownloadModal
						title={'Download'}
						show={modalOpen && !locked}
						onboarding={onboarding}
						setShow={setModalOpen}
						userInfo={userInfo}
						getEmailId={() => getEmailId(selectedProgram?.program_title)}
						getGuideType={() => getGuideType(selectedProgram?.program_title)}
						onAlertOpen={onAlertOpen}
						setAlertMessage={setAlertMessage}
						getFile={() => currentFile ? currentFile : ''}
					/>
				</>))}
			</>
		}
	}

	const renderGuides = () => {
		const getVideosIdList = getVideosId()
		return getVideosIdList.map((vId: any, vII: number) => {
			let finalVid: any = {}
			const gv = newGuideVideos?.length ? newGuideVideos : guideVideos
			if (gv?.length)
				gv?.map((vd: any) => finalVid[vd.id] = vd)
			else
				finalVid = allVideos
			const vidObj = finalVid?.[String(vId)] ? finalVid[String(vId)] : {}
			const { image, duration, headingTitle } = vidObj
			const durationConverted = Math.round(duration / 60)
			return <Grid key={'vl' + vII} item xs={colSpaceGuide} sx={{ pr: colSpaceGuide === 12 ? 3 : 0, pt: 2, position: 'relative' }}>
				<RSButton1 secondary onClick={() => {
					if (vId) {
						handlePlayVideo(vId, headingTitle || '', 'intro')
					}
				}} sx={{ p: '10px', alignItems: 'flex-start', mt: '2px' }}>
					<Grid container xs={5.5} sx={{ position: 'relative', overflow: 'hidden', height: '100px', boxShadow: '0px 0px 3px #80808024' }} justifyContent='center' alignItems='center'>
						<img
							style={{ scale: '2', margin: 'auto' }}
							src={image}
							alt=""
						/>
					</Grid>
					<Grid container xs={6.5} sx={{ height: '100%' }}>
						<Grid container xs={12} sx={{ pl: 2 }} justifyContent='space-between' alignItems='space-between'>
							<Grid container sx={{ flexDirection: 'column' }} justifyContent='flex-start' alignItems='flex-start'>
								<Box sx={{ fontFamily: 'Poppins-Bold !important', fontSize: '16px', textAlign: 'left', lineHeight: '1.2em', mt: '2px' }}>
									{headingTitle}
								</Box>
								{durationConverted ? <Box sx={{ fontFamily: 'Poppins-Light !important', fontSize: '16px' }}>
									{durationConverted}min
								</Box> : (duration !== 0 ? <Box sx={{ position: 'relative', width: '80px', height: '20px' }}>
									<Box className='loading-days toRight' sx={{ border: 'none !important' }} />
								</Box> : '')
								}
							</Grid>
							{/*<Grid container justifyContent='flex-start' alignItems='flex-end'>
								<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', textAlign: 'left', fontStyle: 'italic', lineHeight: '1.2em' }}>{headingTitle}</Box>
							</Grid>*/}
						</Grid>
					</Grid>
				</RSButton1>
			</Grid>
		})
	}

	const renderWorkouts = () => {
		const phaseValue = phaseList[phase]
		const pt = hasPhases ? selectedProgram?.videos?.filter(x => x.phase === phaseValue) : newWorkoutVideos
		// console.log('pt', pt)
		let workoutVideoDisplay = pt?.length ? pt : newWorkoutVideos
		workoutVideoDisplay = workoutVideoDisplay?.map((wv: ProgramVideo, wvi: number) => {
			const liked = favorites && favorites.find((x: any) => x.id == wv.video_id) ? true : false;
			return {
				headingTitle: wv?.video_title,
				duration: wv.video_length,
				id: wv.video_id,
				src: `${wv?.video_thumbnail ? wv.video_thumbnail : 'https://vumbnail.com/' + wv.video_id + '.jpg'}`,
				onClick: async () => await handlePlayVideo(wv.video_id ? String(wv.video_id) : '', wv?.video_title || 'a', 'workouts', getVideoProgress(wv.video_id as number)),
				liked,
				onLike: () => handleMarkAsFavorite(!liked, wv.video_id),
				phase: String(phase + 1)
			}
		})
		return workoutVideoDisplay?.length ? <>
			{hasPhases && renderPhaseSelect()}
			<AnimatePresence mode='wait' initial={false}>
				<motion.div
					key={phase}
					custom={initPhase - prevActivePhase?.current}
					variants={variants}
					initial="enter"
					animate="center"
					exit='exit'
					transition={{ duration: 0.2 }}
					style={{ paddingBottom: '20px', pointerEvents: userInfo?.plan_code ? 'auto' : 'none' }}
				>
					<Grid container sx={{ px: 3 }}>
						<RSVideoCard videoList={workoutVideoDisplay} sx={{ pt: 0 }} getVideoProgress={getVideoProgress} onboarding={onboarding} />
					</Grid>
				</motion.div>
			</AnimatePresence >
		</> : ''
	}

	const renderIntro = () => {
		// const introVideoDisplay = newIntroVideos?.length ? newIntroVideos : introVideos
		const introVideoDisplay = newIntroVideos?.map((iv: ProgramVideo, ivi: number) => {
			const urlId = iv?.video_id ? String(iv.video_id) : ''
			return {
				headingTitle: iv?.video_title,
				duration: iv?.video_length,
				id: urlId,
				src: `https://vumbnail.com/${urlId}.jpg`,
				onClick: async () => urlId && await handlePlayVideo(urlId, iv?.video_title || '', 'intro')
			}
		})

		return introVideoDisplay?.length ? <Grid container sx={{ px: 3 }}>
			<RSVideoCard videoList={introVideoDisplay} sx={{ pt: 0 }} onboarding={onboarding} />
		</Grid> : ''
	}

	const renderAbout = () => {
		const descriptionList = selectedProgram?.program_description && selectedProgram?.program_description?.length > 0 ? selectedProgram?.program_description : selectedProgram?.program_infos
		return <Grid container sx={{ px: 3, pt: 2 }}>
			{renderEventDownload()}
			{descriptionList?.map((p: any, pI: number) =>
				<Grid key={'about' + pI} container xs={12}>
					{p?.paragraphs?.map((pp: any, ppI: number) =>
						<P1 key={'about-paragraph' + ppI} desc sx={{ pb: 2 }}>
							{pp}
						</P1>
					)}
				</Grid>
			)}
		</Grid>
	}

	const allTabs = (tabs: any) => {
		if (tabList[tabs] === 'INTRO') {
			return renderIntro()
		}
		else if (tabList[tabs] === 'FILES') {
			return <Grid container xs={12} rowSpacing={1} columnSpacing={colSpace !== 12 ? 2 : 0} sx={{ pl: 3, pr: colSpace === 12 ? 3 : 0, pt: 1 }}>
				{renderDownloads(hasMultipleFiles)}
			</Grid>
		}
		else if (tabList[tabs] === 'TRAINING' && isMidfoot) {
			return <ProgramMidfoot phase={phase} initPhase={initPhase} prevActivePhase={prevActivePhase} favorites={favorites} renderPhaseSelect={renderPhaseSelect} handlePlayVideo={handlePlayVideo} handleMarkAsFavorite={handleMarkAsFavorite} userInfo={userInfo} activeMidfoot={activeMidfoot} loadMidfootData={loadMidfootData} setIsLoading={setIsLoading} setWeekVideosWithDuration={setMidfootWeekVideosWithDuration} weekVideosWithDuration={midfootWeekVideosWithDuration} onboarding={onboarding} />
		}
		else if (tabList[tabs] === 'TRAINING' && isReset) {
			return <ProgramReset phase={phase} initPhase={initPhase} prevActivePhase={prevActivePhase} renderPhaseSelect={renderPhaseSelect} renderResetTraining={renderResetTraining} handlePlayVideo={handlePlayVideo} handleMarkAsFavorite={handleMarkAsFavorite} userInfo={userInfo} activeMidfoot={activeMidfoot} loadMidfootData={loadMidfootData} setIsLoading={setIsLoading} setWeekVideosWithDuration={setResetWeekVideosWithDuration} weekVideosWithDuration={resetWeekVideosWithDuration} onboarding={onboarding} />
		}
		else if (tabList[tabs] === 'TRAINING' && isKneedForSpeed) {
			return <ProgramTraining phase={phase} initPhase={initPhase} prevActivePhase={prevActivePhase} favorites={favorites} renderPhaseSelect={renderPhaseSelect} handlePlayVideo={handlePlayVideo} handleMarkAsFavorite={handleMarkAsFavorite} userInfo={userInfo} setIsLoading={setIsLoading} programDetails={selectedProgram} onboarding={onboarding} />
		}
		else if ((tabList[tabs] === 'TRAINING' || tabList[tabs] === 'WORKOUTS' || tabList[tabs] === 'VIDEOS') && (!isMidfoot || !isReset || !isKneedForSpeed)) {
			return renderWorkouts()
		}
	}

	const renderActiveTab = () => {
		return <AnimatePresence mode='wait' initial={false}>
			<motion.div
				key={activeTab}
				custom={initTab - prevActiveTab?.current}
				variants={isMobile ? variants : undefined}
				initial="enter"
				animate="center"
				exit='exit'
				transition={{ duration: 0.2 }}
				style={{ paddingBottom: '20px', width: '100%' }}
			>
				{activeTab ? allTabs(activeTab) : renderAbout()}
			</motion.div>
		</AnimatePresence>
	}

	const renderSingleDownload = () => {
		if (!userInfo?.plan_code) return;
		else {
			return <Grid container sx={{ flexDirection: 'column' }}>
				{selectedProgram?.videos && selectedProgram?.videos.length == 1 &&
					<RSButton1 disabled={onboarding || !userInfo?.plan_code} onClick={() => {
						if (selectedProgram?.videos && selectedProgram?.videos[0].video_id) {
							handlePlayVideo(String(selectedProgram?.videos[0].video_id || ''), selectedProgram?.program_title || '', 'intro')
						}
					}}
						sx={{ mt: 1, maxWidth: '400px' }}>
						<PlayArrowIcon sx={{ fontSize: '20px', mr: '5px' }} />
						Play
					</RSButton1>
				}

				{((selectedProgram?.program_title && !hasMultipleFiles) && !(selectedProgram?.program_tabs?.toLowerCase().includes('files') && selectedProgram?.videos?.length == 1)) && <>{renderDownloads()}</>}
			</Grid>
		}
	}

	const renderResetTraining = () => {
		return <Grid container sx={{ pt: 1, pr: 3 }} justifyContent='flex-end' alignItems='center'>
			{/* <Box onClick={handleStartTraining} sx={{ fontFamily: 'Poppins-Medium', textDecoration: 'underline', color: '#0d6efd', cursor: 'pointer' }}>{resetData ? 'Re-' : ''}Start Training</Box> */}
		</Grid>
	}

	const renderPhaseSelect = () => {
		const weekOrPhase = isMidfoot || isKneedForSpeed ? 'Week' : (isReset ? 'Day' : 'Phase')
		const list = isMidfoot ? MidfootData.map((md: any) => md.title) : (isReset ? resetList : phaseList)
		return <Grid container sx={{ pt: 1, px: 3 }}>
			<Box sx={{ width: isReset ? '130px' : '140px' }}>
				<RSButton1 input onClick={() => setShowPhases(true)}>
					{weekOrPhase} {phase + 1}
				</RSButton1>
			</Box>
			<RSDrawer
				bottom
				open={showPhases}
				onClose={() => setShowPhases(false)}
				fitContent
				maxHeight='50%'
			>
				<Grid className='asdf' container sx={{ p: 3 }}>
					{list.map((p: any, pk: number) => {
						const isNum = list?.length > 10
						const n = pk + 1
						const isSelected = pk === phase
						return <Button key={'phase' + pk} className={isSelected ? 'selected-phase orange-bg' : ''} onClick={() => handleChangePhase(pk)} disabled={isSelected} sx={{ py: 1, px: 3, width: '100%', textTransform: 'none', color: '#010101 !important', justifyContent: 'flex-start', fontFamily: 'Poppins', fontSize: '14px' }}>
							{weekOrPhase} {isNum ? n : getNumberName(n)}{p ? `: ${p}` : ''}
						</Button>
					})}
				</Grid>
			</RSDrawer>
		</Grid>
	}

	const renderTotalAndAverage = () => {
		return <>
			{selectedProgram?.program_title.toLowerCase().includes('fuel') || selectedProgram?.program_title.toLowerCase().includes('protocol') ? undefined : (
				<>
					<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.4em' }} justifyContent="flex-start" alignItems="center">
						Total Workouts: {newWorkoutVideos?.length}
					</Grid>
					<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.4em' }} justifyContent="flex-start" alignItems="center">
						Average Length: {averageLength ? averageLength + 'm'
							: <Box sx={{ position: 'relative', width: '20px', height: '19px', pl: '5px' }}>
								<Box className='loading-days toRight' sx={{ border: 'none !important' }} />
							</Box>}
					</Grid>
				</>
			)}
		</>
	}

	const renderGroupDetails = () => {
		if (selectedProgram?.videos && selectedProgram?.videos?.length > 1 && !selectedProgram?.program_title?.toLowerCase().includes('guide'))
			return renderTotalAndAverage()
		// else
		// 	return renderSingleDownload()
	}

	const renderMain = () => {
		return <RSNavCont hideHeader hideFooter sx={{ backgroundColor: '#F6F5F5' }} contSX={isRedirect && !isMobile ? { minHeight: '60px' } : {}} addHeader={!isMobile ? 1 : 0}>
			<Loader active={isLoading} />
			<RSHeaderCont onboarding={onboarding} secondary={!isMobile} isStatic={!isMobile} noShadow={!isMobile} barSX={{ px: isMobile || isRedirect ? 2 : 0, backgroundColor: !isMobile ? 'transparent' : '' }}
				title={props?.currentProgram?.category || ''}
				backClick={() => {
					if (backToHome)
						navReq('/#/')
					else if (programID == 1) {
						navReq('/#/explore?page=0')
						setOpen(false)
						setCurrentTab(null)
					}
					else {
						backToOnboarding()
						setIsRedirect(false)
						setProgramID(null)
						setOpen(false)
						setCurrentTab(null)
					}
				}}
			>
				<div style={{ float: 'right' }}>
					<GoogleCastButton vimeoId={vimeoId} />
				</div>
			</RSHeaderCont>

			<Grid container className='bg-main' sx={{ flexDirection: 'row', mt: '0px', height: 'fit-content' }}>
				<Grid container xs={12} sx={{ pt: '0px', backgroundColor: 'transparent' }} alignItems='flex-start'>
					<Grid container sx={{ position: 'relative', mx: 'auto', overflow: 'hidden', maxHeight: 'fit-content', zIndex: '1' }} justifyContent="flex-start" alignItems="center">
						{(castStatus == 'playing') ? (
							<div className='casting'>
								<h2>Casting</h2>
								<img src={`https://vumbnail.com/${vimeoId || activeVideo}.jpg`} style={{ width: '100%' }} />
							</div>
						) : (
							<>
								<img src={`https://vumbnail.com/${introVideoId || vimeoId}.jpg`} style={{ width: '100%' }} />
								{introVideoId && introPlaying && !isLoading ? <VimeoPlayer id={introVideoId} isPlaying={introPlaying} isMuted={true} /> : ''}
								{castStatus != 'active' && isPlaying && vimeoId ? <VimeoPlayerDefault id={vimeoId} isPlaying={isPlaying} currentProgress={selectedVideoProgress} /> : ''}
								{/* {isPlaying && vimeoId ? <VimeoPlayerDefault id={vimeoId} isPlaying={isPlaying} /> : ''} */}
								{/* {isPlaying && vimeoId ? <VimeoPlayer id={vimeoId} isPlaying={isPlaying} isMuted={false} /> : ''} */}
							</>
						)}

					</Grid>
				</Grid>
				<Grid container xs={12} >
					<Grid container sx={{ px: 3, pt: 2, pb: activeGroup === 'programLinks' ? 2 : 1 }} justifyContent="flex-start" alignItems="center">
						<SH2 size={5} sx={{ width: '100%', py: '5px' }}>
							{selectedProgram?.program_title}
						</SH2>
						{renderGroupDetails()}
						{selectedProgram?.estimate && <Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '14px', lineHeight: '1.4em' }} justifyContent="flex-start" alignItems="center">
							Est. time: {selectedProgram?.estimate}
						</Grid>}
					</Grid>

					{userInfo?.account_id &&
						<Grid container sx={{ px: 3, py: 1, pb: activeGroup === 'programLinks' ? 2 : 1 }} justifyContent="flex-start" alignItems="center">

							<RSButton1 secondary={isPlanActive} sx={{
								display: 'flex',
								justifyContent: 'center',
								justifyItems: 'center',
								alignItems: 'center',
								p: '0x 10px',
								maxWidth: '400px',
							}}
								onClick={async () => {
									if (isPlanActive) setShowUpdatePlanPanel(true)
									else setConfirmation('add')
								}}>
								{getStartButtonLabel()}
							</RSButton1>

						</Grid>}


					<ActivePlanUpdatePanel
						show={showUpdatePlanPanel}
						confirmation={confirmation}
						setConfirmation={setConfirmation}
						setShow={setShowUpdatePlanPanel}
						setPlanState={setIsPlanActive}
						programTitle={selectedProgram?.program_title}
						programCategory={selectedProgram?.category}
						activePlanId={activePlanId}
						activePlanProgress={activePlanProgress}
						getActivePlanDetails={getActivePlanDetails}
						userId={userInfo?.account_id}
						programId={programID}
						// hideReset={(selectedProgram?.program_title.toLowerCase() === "running plans" || selectedProgram?.program_title.toLowerCase() === "runsmart recover") && onboarding}
						hideReset={onboarding}
						onboarding={onboarding}
						handleCloseModal={() => setOpen(false)}
						handleAdditionalAction={isReset ? handleStartTraining : undefined}
					/>

					<AnimatePresence mode='wait' initial={false}>
						<Grid container sx={{ pl: '9px', pt: 1 }}>
							{tabList.map((t, i) => {
								return t ? <Button sx={{ position: 'relative', textTransform: 'none', px: '15px', pb: '10px', color: 'black !important' }}
									key={'tabs' + i}
									className={i === activeTab ? "selected" : ""}
									onClick={(e) => handleChangeActive(e, i)}
								>
									<H2 size={1} sx={{ position: 'relative' }}>
										{t}
										{/*locked && t === 'FILES' && <img src='/images/icons/pro-icon.png' style={{ height: '15px', width: '15px', position: 'absolute', top: -8, right: -8 }} />*/}
										{i === activeTab ? (
											<motion.div className="underlineTab program-tabs" layoutId="underline" />
										) : null}
									</H2>
								</Button> : ''
							}
							)}
						</Grid>
					</AnimatePresence>

					{open && renderActiveTab()}
				</Grid>
			</Grid>

			<GenericModal
				show={hasActiveTraining}
				title="Are you sure?"
				text={'You want to restart your reset training?'}
				setShow={setHasActiveTraining}
				keyboard={false}
				backdrop="static"
				button1Action={handleContinueButton}
				button1Text={loading ? 'Loading...' : 'Yes, continue'}
				button2Action={() => setHasActiveTraining(false)}
				button2Text={'No, cancel'}
			/>
		</RSNavCont>
	}

	return isMobile || onboarding ? <RSDrawer
		popUpHeight={'90%'}
		noClose
		fullWidth
		bottom={!isMobile}
		open={open}
		onClose={() => {
			setProgramID(null)
			setOpen(false)
		}}
	>
		{renderMain()}
	</RSDrawer> : (open ? renderMain() : <></>)
}

export default ProgramMetadata;
