import create, { GetState, SetState } from "zustand";
import { devtools } from 'zustand/middleware'
import createAccountStore from "./createAccountStore";
import createAITrainingPlanStore from "./createAITrainingPlanStore";
import createAppleHealthKitStore from "./createAppleHealthKitStore";
import createComponentStore from "./createComponentStore";
import createExerciseStore from "./createExerciseStore";
import createGarminStore from "./createGarminStore";
import createGoogleCastStore from "./createGoogleCastStore";
import createProgramStore from "./createProgramStore";
import createSaleStore from "./createSaleStore";
import createSurveyStore from "./createSurveyStore";
import createTrainingPlanStore from "./createTrainingPlanStore";
import createTrainingStore from "./createTrainingStore";
import createVideoStore from "./createVideoStore";
import createVimeoPlayerStore from "./createVimeoPlayerStore";

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
    ...createAccountStore(set, get),
    ...createTrainingPlanStore(set, get),
    ...createComponentStore(set, get),
    ...createExerciseStore(set, get),
    ...createVideoStore(set, get),
    ...createTrainingStore(set, get),
    ...createSurveyStore(set, get),
    ...createGarminStore(set, get),
    ...createVimeoPlayerStore(set, get),
    ...createGoogleCastStore(set, get),
    ...createProgramStore(set, get),
    ...createAITrainingPlanStore(set, get),
    ...createAppleHealthKitStore(set, get),
    ...createSaleStore(set, get)
});

const useStore = create(devtools(createRootSlice));

export default useStore;