import { FC } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSCardCont from './RSCardCont'
import RSUnlock from '../misc/RSUnlock'
import { H3, H4, P1, P3 } from '../text/RSTypography'
import raceSelection from '../../pages/training-plan/build/RaceSelection.json'

export type RSTrainingCardProps = {
	trainingHeader?: string,
	trainingDesc?: string,
	trainingSubDesc?: string,
	icon?: string,
	color?: string,
	editable?: boolean,
	onClick?: any,
	userInfo?: any,
	trainingData?: any,
	children?: any,
	additionalIcon?: any,
	sx?: object,
	boxSx?: object,
	isResults?: boolean,
	raceDay?: boolean,
	afterRaceDay?: boolean,
	raceDayToday?: boolean,
	locked?: boolean,
	completePlan?: any
}

const RSTrainingCard: FC<RSTrainingCardProps> = ({ trainingHeader, trainingDesc, icon, color, editable, onClick, userInfo, children, trainingData, additionalIcon, sx = {}, isResults, trainingSubDesc, raceDay, afterRaceDay, raceDayToday, locked, completePlan }) => {
	const marathonType = userInfo?.type === 4 && userInfo?.raceResults ? 7 : userInfo?.type
	const event = raceSelection?.find((rs: any) => rs?.raceValue == marathonType)
	const eventName = event?.event_name || 'race'

	return <>
		{raceDay && additionalIcon}
		<RSCardCont editable={editable} onClick={editable ? onClick : undefined} additionalIcon={!raceDay && additionalIcon} sx={{p: 0, display: 'flex', flexDirection: 'column', ...sx}}>
			<Grid container xs={12}>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: '10px' }}>
					{trainingHeader && <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', px: 1, pt: 1, mb: trainingData?.length > 0 ? 0 : 2 }}>
						{raceDay && (afterRaceDay || raceDayToday) ?  <Grid container xs={12}>
								<H3 card sx={{ width: '100%' }} size={4}>{raceDayToday ? `It's Race Day!` : 'Enter Your Race Results'}</H3>
								<P1 size={2} card sx={{ width: '100%' }} >{raceDayToday ? `Good luck in your ${eventName} today!` : 'Tell us how it went.'}</P1>
							</Grid> : <>
							<i className={`fa-duotone fa-solid ${icon || 'fa-person-running'}`} style={{ fontSize: '22px', marginTop: '2px' }}></i>
							<Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px', display: 'flex', flexDirection: 'column', ml: '5px', width: '295px' }} justifyContent='flex-start'>
								<H3 className='ellipsis-1l' sx={{ width: '100%' }} size={4}>{trainingHeader}</H3>
								{trainingDesc && !isResults ? <Box sx={{ backgroundColor: color || '#DDD', width: 'fit-content', p: '5px', mt: '3px', borderRadius: '5px', display: 'flex', justifyContent: 'center' }} alignContent={'center'} alignItems={'center'}>
									<P1 size={-1.5} sx={{ textTransform: 'uppercase' }}>{trainingDesc?.toUpperCase() == 'RACE' ? 'RACE PACE' : trainingDesc}</P1>
								</Box> : ((trainingDesc || trainingSubDesc) ? <Box sx={{ width: 'fit-content', py: '5px', mt: '10px', ml: -3, borderRadius: '5px', justifyContent: 'center' }} alignContent={'center'} alignItems={'center'}>
									<P3 size={1.2}>{trainingDesc}</P3>
									<P1 size={-1} sx={{ color: "gray" }}>{trainingSubDesc}</P1>
								</Box> : <></>)}
							</Grid>
						</>}
					</Grid>}
					{trainingData?.length > 0 && <Box sx={{ width: '100%' }}><Grid container xs={12} sx={{ flexDirection: 'row', mt: 2, mb: 1 }}>
						{trainingData.map((tD: any, tDI: number) =>
							(tD?.values?.find((t: any) => t.value) ? <Grid item xs={6} key={tD.title + tDI} sx={{ display: 'flex', flexDirection: 'column', px: 2, pb: 1 }}>
								<P1 sx={{width: 'auto', textAlign: 'left'}}>{tD.title}</P1>
								<Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
									{tD?.values?.map((t: any) => {
										return t?.value ? <>
											<H4 sx={{ mr: '2px', textAlign: 'left' }} size={4}>{t.value}</H4>
											<P1 className='gray-disabled-text' sx={{ mb: '1px' }}>{t.label}</P1>
										</> : <></>
									})}
								</Box>
							</Grid> : <></>)
						)}
					</Grid></Box>}
					{completePlan ? <>
						<P3 size={-1} sx={{ mb: 1, mx: 1 }}>Achievement</P3>
						{completePlan}
					</> : <></>}
					{children}
				</Box>
				{userInfo && locked ? <RSUnlock userInfo={userInfo} /> : <></>}
			</Grid>
		</RSCardCont>
	</>
}

export default RSTrainingCard;
