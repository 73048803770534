import {FC, useState} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import {RSButton1, RSButton2} from '../../components/buttons'
import RSCardCont from '../../components/cards/RSCardCont'
import RSInputAdornment2 from '../../components/input/RSInputAdornment2'
import {H1, SH1, P1, P2, P3, P4, P5, A} from '../../components/text/RSTypography'
import RSToggleButton from '../../components/buttons/RSToggleButton';
import { getColorCode } from '../../modules/miscUtils'

const Container = ({children}: any) => {
	return <Grid container xs={12} className='gray-input-border' sx={{borderWidth: '1px', borderStyle: 'solid', borderRadius: '5px 0px 0px 5px', overflow: 'hidden'}}>
		{children}
	</Grid>
}

const CodeContainer: FC<{children: any, onClick?: any}> = ({children, onClick}) => {
	return <Grid container xs={12} className='dark-mode pointer' onClick={onClick}>
		{children}
	</Grid>
}

const SelectorContainer = ({children}: any) => {
	return <Grid container xs={12} sx={{backgroundColor: getColorCode('mixed'), height: '100%', borderRadius: '0px 5px 5px 0px'}}>
		{children}
	</Grid>
}

const BodyContainer: FC<{children: any, title: string, importStr: string, codeStr: string}> = ({children, title, importStr, codeStr}) => {
	const changeColor = (str: string) => {
		const strArr: any = []
		let str1 = ''
		str.split('').forEach((s: string, sI: number) => {
			if (s.match(/[<>={}/'"]|\s/)) {
				if (str1) {
					const strL = str1.length + 1
					if (str[sI - strL] === '>')
						strArr.push(<P1>{str1}</P1>)
					else if (str[sI - strL] === '<' || str[sI - strL] === '/' || str[sI - strL] === `'` || str[sI - strL] === `"`)
						strArr.push(<P1 className='zone-yellow'>{str1}</P1>)
					else
						strArr.push(<P1 className='zone-green'>{str[sI - strL] !== '{' && <>&nbsp;</>}{`${str[sI - strL] === ' ' ? ' ' : ''}${str1}`}</P1>)
				}
				str1 = ''
				strArr.push(<P1>{s}</P1>)
			} else
				str1 += s
		})
		return <Grid container sx={{mb: 1}}>
			{strArr}
		</Grid>
	}

	const handleCopy = async (str: string) => {
		await navigator.clipboard.writeText(str)
	}

	return <Container>
		<Grid container xs={12} sx={{height: 'calc(100% - 80px)', flexDirection: 'column'}}>
			<Box className='default-bs white-bg' sx={{width: '100%', height: '80px', p: 2}}>
				<H1 size={4} card>
					{title}
				</H1>
				<A sx={{fontStyle: 'italic'}} onClick={()=>handleCopy(importStr)}>
					{importStr}
					<i className='fa-light fa-copy' style={{ fontSize: '16px', marginLeft: '10px' }} />
				</A>
			</Box>
			<Box sx={{ width: '100%', height: 'calc(100% - 80px)', p: 4, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				{children}
			</Box>
		</Grid>
		<CodeContainer onClick={()=>handleCopy(codeStr)}>
			<Box sx={{height: '80px', width: '100%', p: 2, pb: 1, position: 'relative'}}>
				<Box sx={{width: 'calc(100% - 20px)'}}>
					{changeColor(codeStr)}
				</Box>
				<i className='fa-light fa-copy'
					style={{ fontSize: '18px', position: 'absolute', top: 16, right: 16 }} />
			</Box>
		</CodeContainer>
	</Container>

}

const AdminUI = () => {
	const renderTitle = (title: string) => <H1 size={4} sx={{textDecoration: 'underline'}}>{title}</H1>


	const renderColors = () => {
		return <Box sx={{width: '100%', pb: '20px'}}>
			<Grid container xs={12} sx={{pb: '10px'}}>
				{renderTitle('Colors')}
			</Grid>
			<Grid container xs={12} justifyContent='space-between' alignItems='center'>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='primary-bg admin-ui-colors' />
					{`#9fcd2b className='primary-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='primary3-bg admin-ui-colors' />
					{`#bfd38c className='primary3-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='analogous-bg admin-ui-colors' />
					{`#6bcd2b className='analogous-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='orange-bg admin-ui-colors' />
					{`#ff9a00 className='orange-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='gray-disabled-bg admin-ui-colors' />
					{`#cbcbcb className='gray-disabled-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='gray-mid-bg admin-ui-colors' />
					{`#e7e7e7 className='gray-mid-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='gray-dark-bg admin-ui-colors' />
					{`#4a4a4a className='gray-dark-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='accent-bg admin-ui-colors' />
					{`#6b2bcd className='accent-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='blue-bg admin-ui-colors' />
					{`#2b9fcd className='blue-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='pink-bg admin-ui-colors'/>
					{`#cd2b6e className='pink-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='danger-bg admin-ui-colors' />
					{`#dc3545 className='danger-bg'`}
				</Grid>

				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='zone-blue-bg admin-ui-colors' />
					{`#A7C7E7 className='zone-blue-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='zone-green-bg admin-ui-colors' />
					{`#78C679 className='zone-green-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='zone-yellow-bg admin-ui-colors' />
					{`#FCD34D className='zone-yellow-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='zone-orange-bg admin-ui-colors' />
					{`#FB923C className='zone-orange-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='zone-red-bg admin-ui-colors' />
					{`#DC2626 className='zone-red-bg'`}
				</Grid>

				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='alert-success-bg admin-ui-colors' />
					{`#D9EDA8 className='alert-success-bg'`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='alert-danger-bg admin-ui-colors' />
					{`#F1AEB5 className='alert-danger-bg'`}
				</Grid>

				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('XT')}} />
					{getColorCode('XT')} {`getColorCode('XT')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('EASY')}} />
					{getColorCode('EASY')} {`getColorCode('EASY')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('BASE')}} />
					{getColorCode('BASE')} {`getColorCode('BASE')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors'  sx={{backgroundColor: getColorCode('MIXED')}} />
					{getColorCode('MIXED')} {`getColorCode('MIXED')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('TEMPO')}} />
					{getColorCode('TEMPO')} {`getColorCode('TEMPO')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('INTERVAL')}} />
					{getColorCode('INTERVAL')} {`getColorCode('INTERVAL')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('TRACK')}} />
					{getColorCode('TRACK')} {`getColorCode('TRACK')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('PACE')}} />
					{getColorCode('PACE')} {`getColorCode('PACE')`}
				</Grid>
				<Grid container xs={12} sx={{mb: '5px'}} alignItems='center'>
					<Box className='admin-ui-colors' sx={{backgroundColor: getColorCode('RACE')}} />
					{getColorCode('RACE')} {`getColorCode('RACE')`}
				</Grid>
			</Grid>
		</Box>
	}

	const getPropsStr = (pArr: any) => {
		let pStr = ''
		pArr?.forEach((p: any) => {
			const [key, value] = p
			if (value)
				pStr += ` ${key}${typeof value === 'boolean' ? '' : `={${typeof value === 'number' ? value : '...doSomething'}}`}`
		})
		return pStr

	}

	const btn1Default = {
		secondary: false,
		tertiary: false,
		input: false,
		disabled: false
	}
	const [btn1Props, setBtn1Props] = useState<any>(btn1Default)
	const renderBtn1 = () => {
		const propsArr = Object.entries(btn1Props)
		const propsStr = getPropsStr(propsArr)
		const importStr = `import {RSButton1} from './components/buttons'`
		const codeStr = `<RSButton1${propsStr}>Button</RSButton1>`
		return <Grid container xs={12} sx={{mb: 3}}>
			<Grid container xs={8}>
				<BodyContainer title='Button 1' importStr={importStr} codeStr={codeStr}>
					<RSButton1 {...btn1Props}>
						Button
					</RSButton1>
				</BodyContainer>
			</Grid>
			<Grid container xs={4} >
				<SelectorContainer>
					<Grid container xs={12} sx={{p: 2}}>
						{propsArr.map((cP: any) => {
							const [key, value] = cP
							return <Grid container key={key} xs={12} sx={{mt: '10px'}}>
								<RSToggleButton label={key} active={value} setActive={() => setBtn1Props({...btn1Default, [key]: !value})}>{key}</RSToggleButton>
							</Grid>
						})}
					</Grid>
				</SelectorContainer>
			</Grid>
		</Grid>
	}

	const btn2Default = {
		secondary: false,
		tertiary: false,
		ghost: false,
		selected: false,
		disabled: false
	}
	const [btn2Props, setBtn2Props] = useState<any>(btn2Default)
	const renderBtn2 = () => {
		const propsArr = Object.entries(btn2Props)
		const propsStr = getPropsStr(propsArr)
		const importStr = `import {RSButton2} from './components/buttons'`
		const codeStr = `<RSButton2${propsStr}>Button</RSButton2>`
		return <Grid container xs={12} sx={{mb: 3}}>
			<Grid container xs={8}>
				<BodyContainer title='Button 2' importStr={importStr} codeStr={codeStr}>
					<RSButton2 {...btn2Props}>
						Button
					</RSButton2>
				</BodyContainer>
			</Grid>
			<Grid container xs={4} >
				<SelectorContainer>
					<Grid container xs={12} sx={{p: 2}}>
						{propsArr.map((cP: any) => {
							const [key, value] = cP
							const isSelected = key === 'selected' ? {disabled: btn2Props.disabled} : {}
							const isDisabled = key === 'disabled' ? {selected: btn2Props.selected} : {}
							return <Grid container key={key} xs={12} sx={{mt: 1}}>
								<RSToggleButton label={key} active={value} setActive={() => setBtn2Props({...btn2Default, [key]: !value, ...isSelected, ...isDisabled})}>{key}</RSToggleButton>
							</Grid>
						})}
					</Grid>
				</SelectorContainer>
			</Grid>
		</Grid>
	}

	const [cardsProps, setCardsProps] = useState<any>({
		onClick: false,
		editable: false,
		onClose: false,
		disabled: false,
		commonPads: false,
		isLoading: false
	})
	const heading = 'Lorem ipsum odor amet.'
	const paragraph = 'Lorem ipsum odor amet, consectetuer adipiscing elit.'

	const renderCards = () => {
		const propsArr = Object.entries(cardsProps)
		const newProps = {
			...cardsProps,
			onClick: cardsProps.onClick ? () => console.log('Clicked') : undefined,
			onClose: cardsProps.onClose ? () => console.log('Closed') : undefined,
		}
		const propsStr = getPropsStr(Object.entries(newProps))
		const importStr = `import RSCardCont from './components/cards/RSCardCont'`
		const codeStr = `<RSCardCont${propsStr}>Test</RSCardCont>`
		return <Grid container xs={12} sx={{mb: 3}}>
			<Grid container xs={8}>
				<BodyContainer title='Cards' importStr={importStr} codeStr={codeStr}>
					<RSCardCont {...newProps} sx={{width: '100%'}}>
						<Box sx={{width: '100%'}}>
							<SH1 card size={4} sx={{width: '100%', textAlign: 'left'}}>{heading}</SH1>
							<P1 card sx={{width: '100%', textAlign: 'left'}}>{paragraph}</P1>
						</Box>
					</RSCardCont>
				</BodyContainer>
			</Grid>
			<Grid container xs={4} >
				<SelectorContainer>
					<Grid container xs={12} sx={{p: 2}}>
						{propsArr.map((cP: any) => {
							const [key, value] = cP
							const isEditable = key === 'editable' && !value ? {onClick: true, onClose: false} : {}
							const isOnClick = key === 'onClick' ? (value ? {editable: false} : {onClose: false}) : {}
							const isOnClose = key === 'onClose'  && !value ? {editable: false, onClick: false} : {}
							return <Grid container key={key} xs={12} sx={{mt: 1}}>
								<RSToggleButton label={key} active={value} setActive={() => setCardsProps({...cardsProps, [key]: !value, ...isEditable, ...isOnClick, ...isOnClose})}>{key}</RSToggleButton>
							</Grid>
						})}
					</Grid>
				</SelectorContainer>
			</Grid>
		</Grid>
	}

	const typographyDefault = {
		onClick: false,
		card: false,
		italic: false
	}
	const [typographyProps, setTypographyProps] = useState<any>(typographyDefault)
	const [typographySize, setTypographySize] = useState<any>(14)
	const [typographyType, setTypographyType] = useState<any>('H1')
	const typeList = ['H1', 'SH1', 'P1', 'P2', 'P3', 'P4', 'P5', 'A']
	const getTypography = (typographyProps: any) => {
		const txt = 'Text Here'
		switch (typographyType) {
			case 'H1':
				return <H1 {...typographyProps}>{txt}</H1>
			case 'SH1':
				return <SH1 {...typographyProps}>{txt}</SH1>
			case 'P1':
				return <P1 {...typographyProps}>{txt}</P1>
			case 'P2':
				return <P2 {...typographyProps}>{txt}</P2>
			case 'P3':
				return <P3 {...typographyProps}>{txt}</P3>
			case 'P4':
				return <P4 {...typographyProps}>{txt}</P4>
			case 'P5':
				return <P5 {...typographyProps}>{txt}</P5>
			case 'A':
				return <A {...typographyProps}>{txt}</A>
		}
	}
	const renderTypographys = () => {
		const propsArr = Object.entries(typographyProps)
		const newProps = {
			...typographyProps,
			size: ((typographySize || 0) - 14)/2,
			onClick: typographyProps.onClick ? () => console.log('Clicked') : undefined,
		}
		const propsStr = getPropsStr(Object.entries(newProps))
		const importStr = `import {${typographyType}} from './components/text/RSTypography'`
		const codeStr = `<${typographyType}${propsStr}>Button</${typographyType}>`
		return <Grid container xs={12} sx={{mb: 3}}>
			<Grid container xs={8}>
				<BodyContainer title='Typography' importStr={importStr} codeStr={codeStr}>
					{getTypography(newProps)}
				</BodyContainer>
			</Grid>
			<Grid container xs={4} >
				<SelectorContainer>
					<Grid container xs={12} sx={{p: 2}}>
						<FormControl fullWidth sx={{my: 1}}>
							<InputLabel ><P4 size={2}>Type</P4></InputLabel>
							<Select
								className='white-bg'
								value={typographyType}
								label="Type"
								onChange={(e: any) => setTypographyType(e.target.value)}
							>
								{typeList.map((tl: string)=><MenuItem key={tl} value={tl}>{tl}</MenuItem>)}
							</Select>
						</FormControl>
						<Box sx={{my: 1}}><RSInputAdornment2 wholeNumber sx={{ width: '80px', height: '50px' }} value={typographySize} setValue={setTypographySize} label={'px'} placeholder={'0'} /></Box>
						{propsArr.map((cP: any) => {
							const [key, value] = cP
							return <Grid container key={key} xs={12} sx={{mt: 1, pb: -1}}>
								<RSToggleButton label={key} active={value} setActive={() => setTypographyProps({...typographyProps, [key]: !value})}>{key}</RSToggleButton>
							</Grid>
						})}
					</Grid>
				</SelectorContainer>
			</Grid>
		</Grid>
	}


	return <Grid container xs={12} justifyContent='center'>
		<Box className='default-mw' sx={{mb: 5, width: '100%'}}>
			{renderBtn1()}
			{renderBtn2()}
			{renderCards()}
			{renderTypographys()}
		</Box>
		{renderColors()}
	</Grid>

}

export default AdminUI;
