import React, { FC, useState } from "react";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Button
} from "@mui/material";
import { P1 } from "../text/RSTypography";
import RSDrawer from "../layout/RSDrawer";
import RSHeaderCont from "../layout/RSHeaderCont";
import useIsMobileScreen from '../../hooks/useIsMobileScreen'

export type CustomAccordionProps = {
	title: string,
	children: any,
	reverse?: boolean,
	sx?: object,
	style2?: boolean,
	card?: boolean,
	detailsSX?: object,
	normal?: boolean,
	titleIcon?: any,
	openInit?: boolean,
	drawer?: boolean,
}

const CustomAccordion: FC<CustomAccordionProps> = ({ title, children, reverse, sx = {}, detailsSX = {}, style2, card, normal, titleIcon, openInit = false, drawer }) => {

	const isMobile = useIsMobileScreen();
	const [openDrawer, setOpenDrawer] = useState(false);
	const titleHeight = 40;
	const cardClass = card ? 'white-bg' : (style2 ? 'bg-main' : 'gray-dark-bg')

	const renderTitle = () => {
		return <P1 desc size={style2 ? 0 : -1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={style2 ? 'black-text' : 'white-text'}>
			<Box sx={{ mr: 1, display: 'flex' }}>{titleIcon}</Box>
			{title}
		</P1>
	}


	return (normal ? <>{children}</> :
		<Box sx={{ width: '100%', minHeight: `${titleHeight}px`, maxHeight: '100%', overflow: 'hidden', ...sx }}>
			{drawer ? <>
				<Button className={cardClass} sx={{p: 0, px: 2,  height: '100%', width: '100%', justifyContent: 'space-between', color: '#010101'}} onClick={()=>setOpenDrawer(true)}>
					{renderTitle()}
					<i className="fa-regular fa-chevron-right" style={{width: '16px', fontSize: '16px'}}/>
				</Button>
				<RSDrawer
					bottom={!isMobile}
					popUpWidth={'auto'}
					open={openDrawer}
					onClose={() => setOpenDrawer(false)}
				>
					{isMobile && <RSHeaderCont className='ellipsis-1l' noBanner backClick={() => setOpenDrawer(false)} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title={title} />}
					{children}
				</RSDrawer>
			</> :
			<>
				<Accordion
					defaultExpanded={openInit}
					key={title}
					sx={{ pb: 0, borderRadius: `${style2 ? '5px' : 0} !important`, transform: reverse ? 'rotate(180deg)' : 'none', ...style2 && { boxShadow: 'none !important' } }}
					className={cardClass}
				>
					<AccordionSummary
						expandIcon={<i className={`fa-regular fa-chevron-down ${style2 ? 'black-text' : 'white-text'}`} style={{fontSize: '16px'}}/>}
						aria-controls={`title${title}-content`}
						id={`title${title}-header`}
						sx={{ height: `${titleHeight}px !important`, minHeight: `${titleHeight}px !important`, transform: reverse ? 'rotate(180deg)' : 'none', '& .MuiAccordionSummary-content': { m: '0 !important' } }}
						className={style2 ? '' : 'white-text'}
					>
						{renderTitle()}
					</AccordionSummary>

					<AccordionDetails className={card ? 'bg-main' : ''} sx={{ px: 2, mx: card ? '10px' : 0, py: style2 && !card ? 1 : 2, mb: card ? '10px' : 0, borderRadius: card ? '5px' : 0, transform: reverse ? 'rotate(180deg)' : 'none', ...detailsSX }}>
						{children}
					</AccordionDetails>
				</Accordion>
			</>}
		</Box>
	);
};

export default CustomAccordion;
