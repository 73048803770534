/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid } from "@mui/material"
import { FC } from "react"
import useTrainingPlanUtils from "../../hooks/useTrainingPlanUtils";
import {P1, P4} from "../text/RSTypography";

interface AppleWatchWorkoutLapsProps {
    data: any[],
    userInfo: any
}

const AppleWatchWorkoutLaps: FC<AppleWatchWorkoutLapsProps> = ({ data, userInfo }) => {

    const { ConvertMetersToMiles, ConvertS2M, getGarminPace } = useTrainingPlanUtils();
    const renderWorkoutLaps = () => {
        const running = []
        const otherActivities = []
        for (const act of data) {
            const isRunning = act.activityType === 'RUNNING'
            const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'
            const table = act.laps?.length > 0 && <>
							 <Grid container xs={12} justifyContent='flex-start' alignItems="center">
									<Grid container xs={12} sx={{pb: 1}} justifyContent='flex-start' alignItems="center">
										<Grid container xs={1} justifyContent='center' alignItems='center'><P4>Lap</P4></Grid>
										<Grid container xs={3} justifyContent='center' alignItems='center'><P4>Time</P4></Grid>
										<Grid container xs={3} justifyContent='flex-end' alignItems='center'>
											<P4 sx={{position: 'relative'}}>
												Distance
												<P1 size={-2} sx={{ position: 'absolute', right: 0, bottom: -10 }} >{paceUnit}</P1>
											</P4>
										</Grid>
										<Grid container xs={5} justifyContent='flex-end' alignItems='center'>
											<P4 sx={{position: 'relative'}}>
												Avg Pace
												<P1 size={-2} sx={{ position: 'absolute', right: 0, bottom: -10 }} >min/{paceUnit}</P1>
											</P4>
										</Grid>
									</Grid>

									{act.laps.map((lap: any, i: number) => {
											const lapDistance = lap.totalDistanceInMeters
											const lD = userInfo?.km == 'Y' ? (lapDistance / 1000)?.toFixed(2) : ConvertMetersToMiles(lapDistance)?.toFixed(2)

											const lapUnit = userInfo?.km == 'Y' ? 'km' : 'mile'
											return lap ? (
													<Grid key={i} container xs={12} sx={{ p: '5px' }}>
															<Grid container xs={1} justifyContent='center' alignItems='center'>
																<P1>{i+1}</P1>
															</Grid>
															<Grid container xs={3} justifyContent='center' alignItems='center'>
																<P1>{ConvertS2M(lap.timerDurationInSeconds)}</P1>
															</Grid>
															<Grid container xs={3} justifyContent='flex-end' alignItems='center'>
																<P1>{lD}</P1>
															</Grid>
															<Grid container xs={5} justifyContent='flex-end' alignItems='center'>
																<P1>{getGarminPace(userInfo?.km === 'Y' ? true : false, lap.totalDistanceInMeters / lap.timerDurationInSeconds).trim().split('/')[0]}</P1>
															</Grid>
													</Grid>
											) : ''
									})}
							</Grid>
            </>

            table && (isRunning ? running.push(table) : otherActivities.push(table))
        }

        return <>{[...running, ...otherActivities]}</>
    }

    return renderWorkoutLaps()
}

export default AppleWatchWorkoutLaps
