/* eslint-disable @typescript-eslint/no-unused-vars */

import { Box, Grid } from '@mui/material'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { RingSpinner } from 'react-spinner-overlay'
import Lottie from 'lottie-react'
import { CorosWorkout, Record } from '../../api/v2/coros/coros.types'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import SharePlan from '../../pages/training-plan/SharePlan'
import RSTrainingCard from '../cards/RSTrainingCard'
import { A, P1, P3 } from '../text/RSTypography'
import RunningLoader from '../animation/runsmart_loader.json'
import CustomAccordion from "../misc/CustomAccordion"
import CorosWatchWorkoutLaps from './CorosWatchWorkoutLaps'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import { Samples } from '../../api/v2/garmin-connect/garmin-connect.types'
import TrainingActivityMap from './TrainingActivityMap'

interface CorosWatchWorkoutProps {
    activities: CorosWorkout[],
    isLoading: boolean,
    userInfo: any
    planDates?: any,
    renderNotesArea?: any
    setSelectedActivity?: any
    setShowMap?: any
    setShowEffortFeedback?: any,
    setShowHeartRateTracker?: any,
    renderWatchComplete?: any,
    isRaceDay?: boolean,
    renderNoActivity?: any
}
const CorosWatchWorkout: FC<CorosWatchWorkoutProps> = ({ activities, isLoading, userInfo, planDates, renderNotesArea, setSelectedActivity, setShowMap, setShowEffortFeedback, setShowHeartRateTracker, renderWatchComplete, isRaceDay, renderNoActivity }) => {

    const { getGarminPace, ConvertS2TimeObject, ConvertMetersToMiles } = useTrainingPlanUtils()
    const requestKey = localStorage.getItem('deviceRequestKey')
    const newFeatures = localStorage.getItem('new-features')
    const isMobile = useIsMobileScreen();

    const timeToString = (props: any) => {
        const { hr, min, sec } = props
        return (hr || min || sec) ? `${hr ? `${hr.toString().padStart(2, '0')}:` : ''}${min || (!min && hr) ? `${min.toString().padStart(2, '0')}:` : ''}${(sec || !sec && (min || hr)) ? `${Math.floor(sec).toString().padStart(2, '0')}` : ''}` : ''
    }

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getWokoutIcon = (act: string) => {
        const activityName = act.toLowerCase()
        if (activityName.includes('running')) return 'fa-duotone fa-solid fa-person-running'
        else if (activityName.includes('track')) return 'fa-duotone fa-solid fa-person-running'
        else if (activityName.includes('walking')) return 'fa-duotone fa-solid fa-person-walking'
        else if (activityName.includes('cycling')) return 'fa-duotone fa-solid fa-person-biking'
        else if (activityName.includes('swimming')) return 'fa-duotone fa-solid fa-person-swimming'
        else if (activityName.includes('yoga')) return 'fa-duotone fa-solid fa-person'
        else if (activityName.includes('workout')) return 'fa-duotone fa-solid fa-dumbbell'
        else if (activityName.includes('training')) return 'fa-duotone fa-solid fa-dumbbell'
        else return 'fa-duotone fa-solid fa-dumbbell'
    }

    const getActivityName = (mode: number, subMode: number) => {
        if (mode == 8 && subMode == 1) return "Outdoor Running"
        if (mode == 8 && subMode == 2) return "Indoor Running"
        if (mode == 13 && subMode == 1) return "Triathlon"
        if (mode == 31 && subMode == 1) return "Walking"
        if (mode == 23 && subMode == 2) return "Strength Training"
        if (mode == 9 && subMode == 1) return "Cycling"
        if (mode == 9 && subMode == 2) return "Indoor Cycling"
        if (mode == 10) return "Swimming"
        else return 'Training'
    }

    const parsePace = (speed: number, unit: string) => {
        const paceDuration = getGarminPace(userInfo?.km == 'Y' ? true : false, speed)
        const paceValueArr = paceDuration ? paceDuration.split(' ') : []
        const paceMin = paceValueArr?.find((v: any) => v.includes('m'))?.replace('m', ':')
        const paceSec = paceValueArr?.find((v: any) => v.includes('s'))?.replace('s', '')?.padStart(2, '0')

        return `${paceMin ? paceMin : ''}` + `${paceSec ? paceSec : ''}` + "/" + `${unit}`
    }

    const parseRecordsToSample = (records: Record[]) => {
        console.log('records', records)
        const sample = records?.map((record: Record) => {
            if (!record.timestamp || !record.position_lat || !record.position_long) return
            return {
                startTimeInSeconds: moment(record.timestamp).unix(),
                latitudeInDegree: record.position_lat,
                longitudeInDegree: record.position_long,
                heartRate: record?.heart_rate,
            }
        })
        return sample.filter((s: any) => s)
    }

    const renderCorosActivity = (isFromDrawer?: boolean) => {

        const curDate = planDates?.date
        const aDate = planDates.weekDays[planDates.selectedWeek?.key - 1]
        const sWeek = planDates.weekDays[0]?.replaceAll('-', '/')
        const cDate = curDate ? moment(curDate) : (aDate ? moment(aDate.replaceAll('-', '/')).add(planDates.selectedDay, 'd') : moment(sWeek).add((planDates.selectedDay ? planDates.selectedDay : 0) - 7, 'd'))
        const selectedDisplay = cDate.format("YYYY/MM/DD")

        if (isLoading) {
            return <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 4 }}>
                <Box sx={{ height: '80px', width: '80px' }}>
                    <Lottie animationData={RunningLoader} />
                </Box>
                <P1 desc sx={{ mt: 1 }}>Loading data, please wait...</P1>
            </Box>
        }
        else
            if (activities.length) {
                const running = []
                const otherActivities = []
                let completeAdded = false
                let totalRaceDuration = 0
                for (const act of activities) {
                    // const activityLaps = workoutLaps.filter((w: any) => w.activityId === act.workout.uuid)
                    const samples = act?.fit?.records ? parseRecordsToSample(act.fit.records) : []
                    const hasPositionData = samples?.filter((x: any) => (x?.latitudeInDegree && x?.latitudeInDegree != 0)).length > 10
                    const hasHeartRateData = samples?.filter((x: any) => x?.heartRate).length > 10
                    const isRunning = act.mode == 8
                    const actDistance = act.distance ? (userInfo?.km == "N" ? ConvertMetersToMiles(act.distance as number)?.toFixed(1) : Number(act.distance as number / 1000)?.toFixed(1)) : ''
                    const actUnit = userInfo?.km == "N" ? 'Miles' : 'km'
                    const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'
                    const activityName = getActivityName(act.mode, act.subMode)
                    const trainingData: any = [...actDistance ? [{
                        title: 'Distance',
                        values: [{
                            value: actDistance,
                            label: actUnit
                        }]
                    }] : []]

                    if (act.avgSpeed) {
                        const speed = act.distance / act.duration
                        const paceDuration = getGarminPace(userInfo?.km == 'Y' ? true : false, speed)
                        const paceValueArr = paceDuration ? paceDuration.split(' ') : []
                        const paceMin = paceValueArr?.find((v: any) => v.includes('m'))?.replace('m', ':')
                        const paceSec = paceValueArr?.find((v: any) => v.includes('s'))?.replace('s', '')?.padStart(2, '0')

                        trainingData.push({
                            title: 'Pace',
                            values: [{
                                value: `${paceMin || ''}${paceSec || ''}`,
                                label: `/${paceUnit}`
                            }]
                        })
                    }

                    if (act.mode == 31 && act.subMode == 1 && act.step) {
                        trainingData.push({
                            title: 'Steps',
                            values: [{
                                value: act.step
                            }]
                        })
                    }

                    if (act.duration) {
                        const actDuration = timeToString(ConvertS2TimeObject(act.duration))
                        actDuration && trainingData.push({
                            title: 'Time',
                            values: [{
                                value: actDuration
                            }]
                        })
                    }

                    act.avgHeartRate && trainingData.push({
                        title: 'Avg Heart Rate',
                        values: [{
                            value: Number(act.avgHeartRate).toFixed()
                        }]
                    })

                    act.calorie && trainingData.push({
                        title: 'Total Calories',
                        values: [{
                            value: Number(act.calorie / 1000).toFixed()
                        }]
                    })

                    const isRaceData = isRunning && isRaceDay
                    totalRaceDuration += isRaceData ? act.duration : 0
                    const trainingCardProps = {
                        trainingHeader: activityName,
                        trainingDesc: act.deviceName,
                        icon: getWokoutIcon(activityName || ''),
                        trainingData,
                        raceDay: !completeAdded && isRaceData,
                        completePlan: !completeAdded && isRaceData && renderWatchComplete(totalRaceDuration),
                        additionalIcon: requestKey && isRunning ? <SharePlan
                            activityName={act?.deviceName || ''}
                            distance={actDistance || ''}
                            unit={actUnit || ''}
                            duration={timeToString(ConvertS2TimeObject(act.duration)) || ''}
                            pace={parsePace((act.distance / act.duration), paceUnit) || ''}
                            calories={Number((act.calorie / 1000).toFixed()) || 0}
                            heartRate={Number(Number(act.avgHeartRate).toFixed()) || 0}
                            date={moment(selectedDisplay).format('dddd, MMM DD, YYYY') || ''}
                            isRaceDay={isRaceDay}
                        /> : undefined,
                        isResults: true,
                    }
                    completeAdded = completeAdded || (isRaceData && renderWatchComplete ? true : false)
                    const elements = ((act?.fit?.laps?.length || 0) > 0) || trainingData?.length ? <RSTrainingCard {...trainingCardProps}>
                        {act?.fit?.laps?.length && (activityName?.toLowerCase().includes('walking') || activityName?.toLowerCase().includes('running')) ?
                            (<>
                                <P3 size={-1} sx={{ mb: 1, mx: 1 }}>Details</P3>
                                <CustomAccordion title="View laps" style2 titleIcon={<i className="fa-light fa-stopwatch" />}>
                                    <CorosWatchWorkoutLaps activityType={activityName as string} key={act.labelId} data={act.fit.laps} userInfo={userInfo} total_steps={act.step} />
                                </CustomAccordion>
                            </>) : <></>}

                        {hasPositionData && !isMobile ? (
                            <Box sx={{ my: 1 }}>
                                <CustomAccordion title="View map" style2 titleIcon={<i className="fa-light fa-map" />}>
                                    <TrainingActivityMap samples={samples && samples.length ? samples as Samples[] : []} />
                                </CustomAccordion>
                            </Box>
                        ) : hasPositionData && isMobile ? <div onClick={() => {
                            setSelectedActivity({ title: activityName, samples: samples })
                            setShowMap(true)
                        }}><Box sx={{
                            mt: 1, borderRadius: "5px", width: "100%", px: 2, py: 1.5, backgroundColor: "#f5f5f5", display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'
                        }}>
                                <Box sx={{ mr: 1, display: 'flex' }}>
                                    <i className="fa-light fa-map me-2" />
                                    <P1>View Map</P1>
                                </Box>
                                <i className="fa-regular fa-chevron-right me-1" />
                            </Box >
                        </div> : <></>
                        }
                        {isRunning ? (
                            <div
                                onClick={() => {
                                    setSelectedActivity({ title: activityName, samples: samples, activityId: act.startTime })
                                    setShowEffortFeedback(true)
                                }}>
                                <Box sx={{
                                    mt: 1, borderRadius: "5px", width: "100%", px: 2, py: 1.5, backgroundColor: "#f5f5f5", display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'
                                }}>
                                    <Box sx={{ mr: 1, display: 'flex' }}>
                                        <i className="fa-light fa-gauge-high me-2" />
                                        <P1>How did this run feel?</P1>
                                    </Box>
                                    <i className="fa-regular fa-chevron-right me-1" />
                                </Box>
                            </div>
                        ) : <></>}

                        {isRunning && hasHeartRateData ? (
                            <div
                                onClick={() => {
                                    setSelectedActivity({ title: activityName, samples: samples, activityId: act.startTime })
                                    setShowHeartRateTracker(true)
                                }}>
                                <Box sx={{
                                    mt: 1, borderRadius: "5px", width: "100%", px: 2, py: 1.5, backgroundColor: "#f5f5f5", display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'
                                }}>
                                    <Box sx={{ mr: 1, display: 'flex' }}>
                                        <i className="fa-light fa-heart me-2" />
                                        <P1>Heart Rate</P1>
                                    </Box>
                                    <i className="fa-regular fa-chevron-right me-1" />
                                </Box>
                            </div>
                        ) : <></>}


                    </RSTrainingCard> : <></>


                    isRunning ? running.push(elements) : otherActivities.push(elements)

                }
                const finalList = [...running, ...otherActivities]
                renderNotesArea && finalList.length && finalList.splice(1, 0, renderNotesArea())
                return <>{finalList}</>
            }
            else
                return renderNoActivity?.() || <Box>
                    <P1 desc>No activity for this date. Rest up <br />or complete your training and check back.</P1>
                </Box>
    }

    return renderCorosActivity()
}

export default CorosWatchWorkout
