/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useState, useRef } from 'react'
import { Modal, Toast, ToastContainer } from 'react-bootstrap'
import moment, { weekdays } from 'moment'
import 'moment-timezone';
import { strtotime } from 'locutus/php/datetime'
import is_numeric from 'locutus/php/var/is_numeric'

import { AnimatePresence, motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';

import useStore from '../../store/useStore'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore'
import { titleCase } from '../../modules/miscUtils'
import useCalendarBase from '../../pages/training-plan/hooks/useCalendarBase'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import { DAYS_OF_WEEK, DAYS_OF_WEEK_ALT } from '../../pages/training-plan/build/DaysOptions';
import { navReq } from '../../modules/apiConsume';
import { RaceResult } from '../../api/v2/types';
import RSDrawer from '../layout/RSDrawer';
import { RSButton1 } from '../buttons';
import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import useBuildTrainingPlan from '../../pages/training-plan/hooks/useBuildTrainingPlan';
import useGPTTrainingPlan from '../../pages/race/useGPTTrainingPlan';


const MotionTableBody = motion(TableBody)
const variants = {
	enter: (direction: number) => {
		return {
			x: direction === 0 ? 0 : direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		zIndex: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			zIndex: 0,
			x: direction < 0 ? "100%" : "-100%"
		};
	}
};

const Calendar = (props: any) => {
	const { userOverrides } = useStore((state: ITrainingPlanStore) => state)
	const { getWeekDays } = useTrainingPlanUtils()
	const { showViewCalendar, handleCloseViewCalendar, userInfo, getDailyActivityDisplay, selectedRace, currentWeek, handleClose,
		allUserNotes, selectedWeek, setSelectedWeek, setSelectedDay, getColorCode, selectedDay, setCalendarLoading, calendarLoading,
		weekDays, loading, raceResults, presetCalendarData, preview, handleAcceptPlan } = props

	const { getRaceDistance, getRaceDistancePace } = useBuildTrainingPlan(Number(userInfo?.training_level), Number(userInfo?.type), userInfo?.plan_type)

	const { buildLastWeekRuns } = useGPTTrainingPlan()

	const [dateObject, setDateObject] = useState<any>()
	const [currentAllNotes, setCurrentAllNotes] = useState<any>()
	const { buildCalendarMonth: buildCalendarMonthBase, loadingValues, setLoadingValues } = useCalendarBase(userOverrides);
	const [daysInMonth, setDaysInMonth] = useState<any>({})
	const [weekdayShort, setWeekdayShort] = useState<Array<any>>([])
	const [monthRange, setMonthRange] = useState<{ maxMonth: string, minMonth: string } | undefined>(undefined)
	const [direction, setDirection] = useState(0);
	const allData = useRef({})
	const abortController = useRef<any>({ current: '' })
	const [calendarData, setCalendarData] = useState<Array<any>>();
	const isMobile = useIsMobileScreen()

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		'border': '0',
		[`&.${tableCellClasses.head}`]: {
			fontSize: '1.3rem'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			position: 'relative'
		},
	}));

	const theme = createTheme({
		palette: {
			primary: {
				main: '#f9e479',
				light: '#fff',
				dark: '#fff',
				contrastText: '#010101',
			},
		},
	});

	const momentWrap = (d: any) => moment(new Date(d))

	const getDateString = (date: any) => date ? `${date.year()}-${date.month() + 1}` : ''

	const parseValidDateFormat = (date: string) => date?.replace('-', '/') + '/1'

	const handleSelectDate = (week: number, day: number) => {
		if (week && day) {
			const nextURL = `/#/training-plan/view?week=${week}&day=${day}`
			window.location.assign(nextURL)
			handleClose()
			handleCloseViewCalendar()
		}
	}

	const checkDate = (prev: boolean) => {
		const d = prev ? momentWrap(parseValidDateFormat(dateObject)).subtract(1, 'month') : momentWrap(parseValidDateFormat(dateObject)).add(1, 'month')
		const checkThis = getDateString(d)
		return daysInMonth?.[checkThis] ? false : true
	}

	const onPrev = () => {
		setDirection(-1)
		setDateObject(getDateString(momentWrap(parseValidDateFormat(dateObject)).subtract(1, 'month')))
	};

	const onNext = () => {
		setDirection(1)
		setDateObject(getDateString(momentWrap(parseValidDateFormat(dateObject)).add(1, 'month')))
	};

	const handleDateClick = (val: string, selected: any, data: any) => {
		// const weekDays = getWeekDays(new Date(), userInfo.start, userInfo.race_date, userInfo.monday_start ? DAYS_OF_WEEK_ALT : DAYS_OF_WEEK)
		let week
		let day = 0
		if (weekDays?.length && val) {
			for (let w = 0; w < weekDays.length; w++) {
				const curWeek = weekDays[w]
				const curWeekClone = curWeek?.replaceAll('-', '/')
				if (momentWrap(val?.replaceAll('-', '/')).isBetween(momentWrap(curWeekClone).subtract(1, 'd'), momentWrap(curWeekClone).add(7, 'd'))) {
					week = { week: curWeek, key: w + 1 }
					// day = momentWrap(val).diff(momentWrap(curWeek), 'days')
					day = momentWrap(val?.replaceAll('-', '/')).diff(momentWrap(curWeekClone), 'days')
					break;
				}
				if (w === weekDays.length - 1) {
					const startW = weekDays[0]?.replaceAll('-', '/')
					if (momentWrap(val?.replaceAll('-', '/')).isBefore(momentWrap(startW))) {
						const newDay = momentWrap(val)?.diff(momentWrap(startW)?.subtract(7, 'd'), 'days')
						week = { week: '', key: 0 }
						day = newDay ? newDay : day
					}
				}
			}
		}
		week && setSelectedWeek(week)
		setSelectedDay(day)
		buildCalendar(data, selected)
		setTimeout(() => handleCalendarClose(), 100)
	}

	const getVal = (data: any, date: any, isSelected: boolean) => {
		if (data && userInfo && Object.keys(userInfo).length) {
			const specificDate = date.format('YYYY-MM-D')
			const specificDateIOS = date.format('YYYY/MM/DD')
			// enable this if rearranging plan still has issues
			// >>>>
			// const dayNum = data[specificDate]?.activitiesValue?.marathon?.day
			// const weekNum = data[specificDate]?.activitiesValue?.marathon?.week || data[specificDate]?.week
			// const weekPlanLast = data[Object.keys(data)[Object.keys(data)?.length - 1]]?.week
			// const replacedDayId = userOverrides?.find((x: any) => x?.day+1 == dayNum && x?.week == weekNum)?.mid
			// const newDay = Object.keys(data).find(key => data[key].activitiesValue?.marathon?.id == replacedDayId)
			// // if removing GPTTrainingPlan dataWithOverride, then remove the last week checker conditon
			// const result = (replacedDayId && newDay && weekPlanLast == weekNum) ? data[newDay] : data[specificDate]
			// <<<<
			const result = data[specificDate]
			const { monday_start, km, start, race_date, weeks, type } = userInfo
			const recoveryDay = userInfo?.type !== 4 && momentWrap(race_date).add(1, 'days').format('YYYY-MM-D')
			const maintenanceDay = userInfo?.type === 4 && userInfo?.raceResults?.id && momentWrap(race_date).format('YYYY-MM-D')
			const skipMaintenance = maintenanceDay && userInfo?.raceResults?.recoveryPlan?.skip_maintenance_plan
			const raceDay = momentWrap(race_date).format('YYYY-MM-D')
			const isKm = km === "Y" ? "KMS" : "MILES";
			const isKm2 = km === "Y" ? "KM" : "MI";

			let assessmentVideosDays = false;
			let recoveryAssessmentsDays = false
			let rr: RaceResult | undefined = undefined;
			let maxWeeks: [] | undefined = []

			if (userInfo?.raceResults) {
				rr = userInfo?.raceResults
				maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
				assessmentVideosDays = moment(date).isBefore(moment(moment(Object.keys(data)[0]?.replaceAll('-', '/'))).add(rr?.recoveryPlan?.recoveryType.assessment_starts_in_days, 'days'))

				recoveryAssessmentsDays = moment(date).isSameOrAfter(moment(Object.keys(data)[0]?.replaceAll('-', '/')).add(rr?.recoveryPlan?.recoveryType.assessment_starts_in_days, 'days')) && moment(date).isBefore(moment(Object.keys(data)[0]?.replaceAll('-', '/')).add(rr?.recoveryPlan?.recoveryType.resume_running_on_day, 'days'))
			}

			if (result) {
				const dailyActivity = getDailyActivityDisplay(
					result?.activitiesValue,
					userInfo,
					result?.activitiesValue?.marathonPaces,
					selectedRace?.value as number,
					result?.activitiesValue?.marathon?.race_day,
					currentAllNotes
				)?.toUpperCase().split("|")


				if (dailyActivity) {
					if (assessmentVideosDays) {
						return <span className='daily-activity-calendar'>R</span>
					}
					else if (recoveryAssessmentsDays) {
						return <span className='daily-activity-calendar'>A</span>
					}

					if (dailyActivity[0] === "REST" && strtotime(specificDate) <= strtotime(start) || dailyActivity[0] === "REST" && strtotime(specificDate) >= strtotime(momentWrap(race_date).add(3, 'days')) || specificDate === maintenanceDay) {
						if (specificDate === recoveryDay)
							return 'Start Recovery'
						else if (specificDate === maintenanceDay)
							return <span className='daily-activity-calendar'>{skipMaintenance ? 'P' : 'M'}</span>
						else if (moment(specificDateIOS).isBefore(race_date))
							return <span className='daily-activity-calendar' style={{ marginTop: '0.5rem' }}>R</span>

					}
					else {
						if (dailyActivity[0] !== "REST") {
							let trainVal = dailyActivity[0]?.replace(isKm, "")?.replace(isKm2, "").trim().split(' ')
							const isNumber = is_numeric(trainVal[0])
							let desc = dailyActivity[1]?.trim()
							trainVal = trainVal.join(' ')
							const dayActivity = dailyActivity[0]?.toLowerCase()

							const crossTrainType = (dailyActivity.length > 2 && dailyActivity[2]) || ''

							// if (dailyActivity.length > 0) console.log('da: ', dailyActivity)

							const crossTrainActivities = Array.from(new Set(dailyActivity[2]?.toLowerCase()?.split(',').filter((item: string) => item != "").map((item: string) => item.trim().replace(/\s+/g, ''))))

							// if (crossTrainActivities?.length > 1) console.log('cta: ', crossTrainActivities)

							let currentWeek = 0;
							weekDays.forEach((e: any, i: number) => {
								if (moment(specificDateIOS).isSameOrAfter(moment(e)) && moment(specificDateIOS).isSameOrBefore(moment(e).add(6, 'days')))
									currentWeek = i
							});

							const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == result?.activitiesValue?.marathon?.id)?.distance ? true : false

							trainVal = !hasCustomDistance && isNumber && rr && maxWeeks ? (trainVal * maxWeeks[currentWeek]).toFixed(1) : trainVal

							if (trainVal === "RACE PACE") {
								if (dailyActivity.includes(' STRETCH')) {
									crossTrainActivities.push('stretch')
								}
							}

							const colorCode = dayActivity.includes('laps') || dayActivity.includes('race pace') ? getColorCode('RACE PACE') : getColorCode(desc)
							desc = desc ? desc?.toLowerCase()?.split(' ')?.map((dd: any) => is_numeric(dd.charAt(0)) ? dd.toUpperCase() : dd.charAt(0).toUpperCase() + dd.slice(1))?.join(' ') : ''
							desc = isMobile && desc === "Progression" ? "Prog." : desc === "Recovery" ? 'Recov.' : desc
							const isXT = trainVal === 'CROSS TRAIN'
							const isRP = (dayActivity && dayActivity.toLowerCase()?.includes('race') && !dayActivity.toLowerCase()?.includes('race day')) || trainVal === "RACE PACE" || desc == "Race" || (dailyActivity && dailyActivity?.find((x: any) => x?.toLowerCase()?.includes('race')) && !dailyActivity?.find((x: any) => x?.toLowerCase()?.includes('race day')))
							return !isRP && (isNumber || isXT) ? <Box className="daily-activity-cont" sx={{ backgroundColor: colorCode, width: '100%', height: '100%', borderRadius: 'inherit', border: `${isSelected ? '3px solid #3252D6' : ''}` }}>
								<span className={`daily-activity-calendar ${desc?.toUpperCase() === 'RACE DAY' ? 'rd' : ''}`} style={{ marginTop: `${crossTrainType != '' ? '2.2rem' : '0.5rem'}` }}>{isXT ? 'XT' : trainVal}</span>
								<span className={`daily-activity-desc ${desc?.toUpperCase() === 'RACE DAY' ? 'rd' : ''}`}>
									{desc?.toUpperCase() === 'RECOVERY' ? 'Recover' : (desc?.toUpperCase() === 'CROSS TRAIN' ? 'XT' : desc)}
									{/* {desc && <Box sx={{ mt: '3px', width: '80%', height: '3px', borderRadius: '10px', backgroundColor: colorCode }} />} */}
								</span>
								{crossTrainType != '' && <Box className='daily-activity-cross-train-icons' sx={{ marginLeft: `${crossTrainActivities.length > 1 ? '-0.5rem' : '-0.6rem'}` }}>
									{crossTrainActivities?.map((cta: any, i: number) => {
										const isStrength = cta?.toLowerCase()?.includes('strength')
										const isYoga = cta?.toLowerCase()?.includes('yoga')
										const isStretch = cta?.toLowerCase()?.includes('stretch')
										const isRoll = cta?.toLowerCase()?.includes('roll')

										return <Box key={i} sx={{ width: '0.9rem', height: '1.5rem' }}>
											<Box sx={{ position: 'absolute', width: '1.5rem', height: '1.5rem', borderRadius: '1.5rem', backgroundColor: `${isSelected ? '#CCC' : '#555'}`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
												<i style={{ marginTop: '0.1rem', fontSize: '0.8rem', color: '#ffffff' }}
													className={`fa-solid ${isRoll && 'fa-circle-dashed'} ${isStretch && 'fa-child-reaching'} ${isYoga && 'fa-spa'} ${isStrength && 'fa-dumbbell'}`} />
											</Box>
										</Box>
									})}

								</Box>}
							</Box> : <Box className="daily-activity-cont laps" sx={{ backgroundColor: colorCode, width: '100%', height: '100%', overflow: 'hidden', borderRadius: 'inherit', fontSize: '0.8em', lineHeight: '1em' }}>
								{isRP ? 'Race Pace' : titleCase(trainVal)}
								<Box className='daily-activity-cross-train-icons' sx={{ marginLeft: `${crossTrainActivities.length > 1 ? '-0.5rem' : '-0.6rem'}` }}>
									{crossTrainActivities?.map((cta: any, i: number) => {
										const isStretch = cta?.toLowerCase()?.includes('stretch')
										return <Box key={i} sx={{ width: '0.9rem', height: '1.5rem' }}>
											<Box sx={{ position: 'absolute', width: '1.5rem', height: '1.5rem', borderRadius: '1.5rem', backgroundColor: `${isSelected ? '#CCC' : '#555'}`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
												<i style={{ marginTop: '0.1rem', fontSize: '0.8rem', color: '#ffffff' }}
													className={`fa-solid ${isStretch && 'fa-child-reaching'}`} />
											</Box>
										</Box>
									})}
								</Box>
							</Box>
						} else {
							return raceDay === specificDate ?
								<Box sx={{ fontSize: '0.8em', lineHeight: '1em' }}>{type != 4 ? 'Race Day' : 'Training Ends'}</Box>
								: <span className='daily-activity-calendar'>R</span>
						}
					}
				}
			} else {
				if (specificDate === recoveryDay)
					return 'Start Recovery'
				else if (specificDate === maintenanceDay)
					return <span className='daily-activity-calendar'>{skipMaintenance ? 'P' : 'M'}</span>
				else if (start && race_date && strtotime(specificDate) > strtotime(moment(start)?.format('YYYY-MM-D')) && strtotime(specificDate) < strtotime(moment(race_date)?.format('YYYY-MM-D')))
					return <span className='daily-activity-calendar'>R</span>
			}
		}

		return ''
	}

	const loopDates = (min: number, max: number, data: any, year: number, month: number, selectedDate: any) => {
		const loopList = []
		if (min && max && data && year && month) {
			for (let d = min; d <= max; d++) {

				const thisDate = moment(`${year}/${month}/${d}`).format('YYYY-MM-DD')
				const isToday = thisDate === moment(new Date()).format('YYYY-MM-DD')
				const lastW = weekDays?.[0]?.replaceAll('-', '/')
				const aDate = weekDays[selectedWeek?.key - 1]
				const bDate = userInfo?.start && moment(userInfo.start)?.add(1, 'day')?.isBetween(moment(lastW)?.subtract(7, 'd'), moment(lastW)) ? moment(lastW)?.subtract(7 - selectedDay, 'd') : moment()
				const cDate = aDate ? moment(aDate.replaceAll('-', '/'))?.add(selectedDay, 'd') : bDate
				const selected = selectedDate ? selectedDate : cDate?.format('YYYY-MM-DD')
				const isSelected = thisDate === selected
				const dailyValue = getVal(data, momentWrap(`${year}/${month}/${d}`), isSelected)
				const dataDate = <>
					{/* contents inside a calendar day */}
					<Box className="calendar-date-value-cont" sx={{ p: dailyValue ? '0px' : '1px' }}>
						<Box className={`calendar-date-value ${isSelected && !(loadingValues) ? 'selected' : checkIsActiveDay(thisDate) ? 'active-days' : 'inactive-days'}`}
							sx={{ fontSize: `${dailyValue === 'Start Recovery' ? '0.7rem' : ''}`, color: '#010101', backgroundColor: 'white' }}>{dailyValue}</Box>
					</Box>
					{/* day # on top of the calendar day */}
					<Box className={`calendar-date ${isToday ? 'active' : ''}`}>{d}</Box>
				</>
				const dateMU = <>
					{dailyValue ?
						<ThemeProvider theme={theme}>
							{!preview ? <Button className='calendar-date-button' sx={{ textTransform: 'none', borderRadius: '10px', backgroundColor: 'white !important', border: '1px solid #F8F8FA' }} onClick={() => !preview && handleDateClick(moment(`${year}/${month}/${d}`).format('YYYY-MM-DD'), thisDate, data)}>
								{dataDate}
							</Button> :
								<Box className='calendar-date-button' sx={{ textTransform: 'none', borderRadius: '10px', backgroundColor: 'white !important', border: '1px solid #F8F8FA' }} onClick={() => !preview && handleDateClick(moment(`${year}/${month}/${d}`).format('YYYY-MM-DD'), thisDate, data)}>
									{dataDate}
								</Box>}
						</ThemeProvider> : dataDate}

				</>
				loopList.push(<StyledTableCell sx={{ '& *': { color: isSelected ? '#010101 !important' : '' } }} key={`${d}-${year}-${month}`} align="center">{dateMU}{(loadingValues) && <Box className='loading-days' sx={{ border: '1px solid #cdcdcd', borderRadius: '10px', boxSizing: 'border-box', height: 'calc(99% - 1px)', width: 'calc(99% - 1px)', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}</StyledTableCell>)
			}
		}
		return loopList;
	}

	const checkIsActiveDay = (dateToCheck: string) => {
		const currentMonth = momentWrap(parseValidDateFormat(dateObject)).month()
		const dateMonth = moment(dateToCheck).month()
		if (currentMonth == dateMonth) return true
		else return false
	}

	const buildCalendar = (data: any, selectedDate: any) => {
		// setCalendarLoading(true)
		//BUILD DAYS OF THE WEEK
		const { monday_start = 0, km = '', start = new Date(), race_date = new Date(), weeks = 0, type = 0 } = userInfo ? userInfo : {}
		const momentOptions = { week: { dow: 1 } }
		const newLocal: any = monday_start ? true : false;

		const weekdayshort: any = moment.updateLocale('en', momentOptions).weekdaysMin(newLocal);
		setWeekdayShort(weekdayshort)

		//BUILD DAYS
		const startDate = momentWrap(start)
		const monthsAfterRace = moment(moment().format('YYYY/MM/01'))?.diff(moment(momentWrap(race_date)?.add(1, 'days').format('YYYY/MM/01')), 'month')
		const addMonth = monthsAfterRace > 0 ? monthsAfterRace : 0
		const endDate = momentWrap(momentWrap(race_date).add(1, 'days')).add(1 + addMonth, "month")
		const newDate = momentWrap(startDate);

		let allMonths = {};

		while (newDate < endDate) {
			let blanksStartDay = parseInt(newDate.startOf("month").format("d")) - (newLocal ? 1 : 0)
			blanksStartDay = blanksStartDay < 0 ? 7 + blanksStartDay : blanksStartDay
			const blanksEndDay = 7 - (parseInt(newDate.endOf("month").format("d")) + (newLocal ? 0 : 1))

			let blankStart: any = [];
			let blankEnd: any = [];
			let cal: any = [];

			const year = newDate.year()
			const month = newDate.month() + 1

			if (blanksStartDay > 0) {
				const prevMonth = momentWrap(newDate).subtract(1, "month")
				const prevDaysInMonth = prevMonth.daysInMonth();
				blankStart = loopDates(prevDaysInMonth - blanksStartDay + 1, prevDaysInMonth, data, prevMonth.year(), prevMonth.month() + 1, selectedDate)
			}

			cal = loopDates(1, newDate.daysInMonth(), data, year, month, selectedDate)

			if (blanksEndDay > 0) {
				const nextMonth = momentWrap(newDate).add(1, "month")
				blankEnd = loopDates(1, blanksEndDay, data, nextMonth.year(), nextMonth.month() + 1, selectedDate)
			}

			const totalSlots = [...blankStart, ...cal, ...blankEnd]
			const rows: Array<any> = [];
			let cells: Array<any> = [];
			totalSlots.forEach((row, i) => {
				if (i % 7 !== 0) {
					cells.push(row); // if index not equal 7 that means not go to next week
				} else {
					cells.length && rows.push(cells); // when reach next week we contain all td in last week to rows
					cells = []; // empty container
					cells.push(row); // in current loop we still push current row to new container
				}
				if (i === totalSlots.length - 1) { // when end loop we add remain date
					rows.push(cells);
				}
			});

			allMonths = { ...allMonths, [getDateString(newDate)]: rows.map((d, i) => <TableRow sx={{ backgroundColor: '#F6F5F5' }} key={i}>{d}</TableRow>) }

			newDate.add(1, "month");
		}
		setDaysInMonth(allMonths)

	}

	const handleCalendarClose = () => {
		setDirection(0);
		handleCloseViewCalendar(false)
	}

	useEffect(() => {
		setCalendarLoading(loadingValues)
	}, [loadingValues])

	useEffect(() => {
		Object.keys(daysInMonth)?.length > 0 && setCalendarLoading(false)
	}, [daysInMonth])

	useEffect(() => {
		if (userInfo && selectedWeek) {
			const activeDate = selectedWeek?.week ? selectedWeek.week.replaceAll('-', '/') : new Date()
			const initDate = momentWrap(currentWeek.key <= 0 ? userInfo.start : activeDate)
			setDateObject(getDateString(initDate))
		}
	}, [selectedWeek])

	useEffect(() => {
		const aDate = weekDays[selectedWeek?.key - 1]
		let cDate = aDate ? moment(aDate.replaceAll('-', '/'))?.add(selectedDay, 'd') : moment()
		const lastW = weekDays[0]?.replaceAll('-', '/')
		if (!aDate && userInfo?.start && moment(userInfo.start)?.add(1, 'day')?.isBetween(moment(lastW)?.subtract(7, 'd'), moment(lastW))) {
			cDate = moment(userInfo.start)
		}
		showViewCalendar && setDateObject(getDateString(cDate))
	}, [showViewCalendar])

	useEffect(() => {
		setCalendarLoading(true)
		const d = allData['current']
		if (d && userInfo && Object.keys(d)?.length) {
			const lastW = weekDays[0]?.replaceAll('-', '/')
			const cDate = userInfo?.start && moment(userInfo.start)?.add(1, 'day')?.isBetween(moment(lastW)?.subtract(7, 'd'), moment(lastW)) ? moment(lastW)?.subtract(7 - selectedDay, 'd') : ''
			const sDate = !weekDays[selectedWeek?.key - 1] && cDate ? cDate?.format('YYYY-MM-DD') : ''
			buildCalendar(d, sDate)
		}
	}, [selectedDay, selectedWeek])

	useEffect(() => {
		if (allUserNotes && (JSON.stringify(currentAllNotes) !== JSON.stringify(allUserNotes))) {
			setCurrentAllNotes(allUserNotes)
		}
	}, [allUserNotes])

	useEffect(() => {
		setCalendarLoading(presetCalendarData ? false : true)
		const gptUsage = false
		abortController?.['current']?.abort?.();
		abortController['current'] = new AbortController();
		if (userInfo && currentAllNotes && (raceResults || presetCalendarData)) {
			setMonthRange({
				maxMonth: getDateString(momentWrap(userInfo.start)),
				minMonth: getDateString(momentWrap(userInfo.race_date))
			})
			moment.tz("America/New_York")
			const activeDate = selectedWeek?.week ? selectedWeek.week.replaceAll('-', '/') : new Date()
			const initDate = momentWrap(currentWeek.key <= 0 ? userInfo?.start : activeDate)
			setDateObject(getDateString(initDate))
			if (presetCalendarData) {
				let result = presetCalendarData;
				if (userInfo?.raceResults) {
					const rr: RaceResult = userInfo?.raceResults
					const isRecovery = userInfo?.uid == rr?.maintenance_plan_id;
					const recoveryWeeks = rr?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0
					result = rr && isRecovery && recoveryWeeks > 0 ? result.filter((x: any) => x?.monthData?.week <= recoveryWeeks) : result
				}

				try {
					const raceDay = presetCalendarData.find((x: any) => x.monthData?.dateVal == moment(userInfo?.race_date).format('YYYY-MM-D') || x.monthData?.dateVal == moment(userInfo?.race_date).format('YYYY-MM-DD'))

					if (raceDay) {
						if (userInfo?.type != 4) {
							raceDay.activitiesValue.marathon.distance = getRaceDistance(userInfo?.type as number)
							raceDay.activitiesValue.marathon.race_day = 1
							raceDay.activitiesValue.marathonPaces[0].pace = "Race Day"
							raceDay.activitiesValue.marathonPaces[0].notes = `${getRaceDistancePace(userInfo)} ${userInfo?.km === "Y" ? "kms" : "miles"}`
						}
					}

					const afterRaceDay = userInfo?.type != 4 ? presetCalendarData?.filter((x: any) => x.activitiesValue.marathon.id > raceDay?.activitiesValue.marathon.id) : []
					const raceDayName = moment(userInfo.race_date).format('dddd').toLowerCase();
					const isTaperPreviousWeek = raceDayName === 'monday' && (userInfo?.type == 0 || userInfo?.type == 1 || userInfo?.type == 2 || userInfo?.type == 3 || userInfo?.type == 5 || userInfo?.type == 8) ? true : false

					let raceWeekRuns = []
					if (userInfo?.type == 4 || !userInfo?.type) {
						raceWeekRuns = []
					} else
						raceWeekRuns = presetCalendarData?.filter((x: any) => x.activitiesValue.marathon.week == (userInfo?.weeks - (isTaperPreviousWeek ? 1 : 0)) && x.activitiesValue.marathon.race_day != 1 && x.activitiesValue.marathon.distance > 0)

					if (raceWeekRuns?.length)
						raceWeekRuns.forEach((x: any) => {
							const distace = x.activitiesValue.marathon.distance
							const runs = buildLastWeekRuns(distace, x.activitiesValue.marathonPaces, userInfo?.type, userInfo?.km)
							x.activitiesValue.marathonPaces = undefined
							x.activitiesValue.marathonPaces = runs
						})

					if (afterRaceDay.length)
						afterRaceDay.forEach((x: any) => {
							if (x.activitiesValue.marathonPaces.length) {
								x.activitiesValue.marathonPaces[0].pace = ""
								x.activitiesValue.marathonPaces[0].notes = ""
							}

							if (x.activitiesValue.workouts.length)
								x.activitiesValue.workouts = []

							x.activitiesValue.marathon.distance = 0
						})

					let dateValCont: any = {}
					for (const x of result) {
						const dateVal = x?.monthData?.dateVal
						if (dateVal)
							dateValCont = { ...dateValCont, [`${dateVal}`]: x }
					}

					setCalendarData(result)
					allData['current'] = dateValCont
					buildCalendar(dateValCont, '')
					setLoadingValues(false)
				} catch (e) {
					console.log('error: ', e)
					setLoadingValues(false)
				}
			}
			else
				buildCalendarMonthBase(0, userInfo, abortController['current']).then((res) => {
					let result = res;

					if (userInfo?.raceResults) {
						const rr: RaceResult = userInfo?.raceResults
						const isRecovery = userInfo?.uid == rr?.maintenance_plan_id;
						const recoveryWeeks = rr?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0
						result = rr && isRecovery && recoveryWeeks > 0 ? result.filter((x: any) => x?.monthData?.week <= recoveryWeeks) : result
					}

					setCalendarData(result)

					let dateValCont = {}
					for (const x of result) {
						const dateVal = x?.monthData?.dateVal
						if (dateVal)
							dateValCont = { ...dateValCont, [`${dateVal}`]: x }
					}
					// dateValCont = dateValCont.filter((x: any) => x <= userInfo?.race_date)
					allData['current'] = dateValCont
					// console.log(dateValCont)
					buildCalendar(dateValCont, '')
				})
		} else {
			buildCalendar({}, '')
		}

		return () => {
			abortController?.['current']?.abort?.();
		}

	}, [userInfo, userOverrides, currentAllNotes, raceResults, presetCalendarData, showViewCalendar])

	useEffect(() => {
		setCalendarLoading(true)
		let dateValCont = {}
		let dateVal: any = undefined;
		if (calendarData) {
			for (const x of calendarData) {
				dateVal = x?.monthData?.dateVal
				if (dateVal)
					dateValCont = { ...dateValCont, [`${dateVal}`]: x }
			}
		}

		setTimeout(() => {
			buildCalendar(dateValCont, '')
		}, 1)

	}, [dateObject, calendarData])

	return (
		<RSDrawer
			bottom
			noClose={preview}
			preview={preview}
			popUpHeight='90%'
			open={showViewCalendar}
			onClose={handleCalendarClose}

		>
			<Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>

				{preview && <Grid sx={{ width: 'auto', height: '24px', borderRadius: '8px', my: '12px', px: '20px', textWrap: 'nowrap', display: 'flex', backgroundColor: '#3f4a99', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
					<h2 style={{ fontSize: '20px', color: 'white', margin: '0' }}>Plan Preview</h2>
				</Grid>}


				<Table sx={{ height: `calc(100% - ${preview ? 128 : 0}px) !important` }}>
					<TableHead>
						<TableRow>
							<StyledTableCell align="center" colSpan={7} sx={{ backgroundColor: 'white', p: '8px 16px', position: 'relative' }}>

								<Grid container>
									<Grid container xs={3} justifyContent="flex-end" alignItems="center">
										<IconButton
											disabled={loadingValues || loading ? true : checkDate(true)}
											sx={{ color: '#000000', height: '30px', width: '30px', minWidth: '0px', minHeight: '0px', m: '2px 5px 4px 5px' }}
											onClick={onPrev}
											className="month-button"
											type="button"
											aria-label="Previous Month"
											size="small"
										>
											<ArrowBack fontSize="medium" sx={{ stroke: 'white', strokeWidth: '1px' }} />
										</IconButton>
									</Grid>
									<Grid container xs={6} justifyContent="center" alignItems="center" sx={{ fontSize: '1.2em' }}>
										{momentWrap(dateObject?.replace('-', '/') + '/1').format('MMMM YYYY')}
									</Grid>
									<Grid container xs={3} justifyContent="flex-start" alignItems="center">
										<IconButton
											disabled={loadingValues || loading ? true : checkDate(false)}
											sx={{ color: '#000000', height: '30px', width: '30px', minWidth: '0px', minHeight: '0px', m: '2px 5px 4px 5px' }}
											onClick={onNext}
											className="month-button"
											type="button"
											aria-label="Next Month"
											size="small"
										>
											<ArrowForward fontSize="medium" sx={{ stroke: 'white', strokeWidth: '1px' }} />
										</IconButton>
									</Grid>
								</Grid>
							</StyledTableCell>
						</TableRow>
						<TableRow sx={{ backgroundColor: '#F8F8FA' }}>
							{weekdayShort.map((wd, i) => <StyledTableCell align="center" key={i + wd} sx={{ position: 'relative', p: 0, height: '50px' }}>
								<div className='calendar-week-cont'><span className='calendar-week-char'>{wd.charAt(0)}</span></div>
							</StyledTableCell>)}
						</TableRow>
					</TableHead>
					<AnimatePresence mode='wait'>
						<MotionTableBody
							className='calendar-table-body'
							key={dateObject}
							custom={direction}
							variants={variants}
							initial="enter"
							animate="center"
							transition={{ duration: 0.5 }}
						>
							{daysInMonth[dateObject]}
						</MotionTableBody>
					</AnimatePresence>
				</Table>
				{preview && <Grid container sx={{ width: '100%', height: '50px', my: '15px', justifyContent: 'space-evenly' }}>
					<RSButton1 secondary sx={{ width: '130px' }} onClick={() => { handleCloseViewCalendar(false) }}>Cancel</RSButton1>
					<RSButton1 sx={{ width: '130px' }} onClick={() => { handleAcceptPlan() }}>Accept Plan</RSButton1>
				</Grid>}
			</Box>
		</RSDrawer>
	)
}

export default Calendar
