/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { RSButton1 } from '../buttons'
import RSCloseBtn from '../buttons/RSCloseBtn'
import RSDrawer from './RSDrawer'
import { H1, SH3, P1 } from '../text/RSTypography'
import useQueryParams from '../../hooks/useQueryParams';
import { isMobile, isSafari } from 'react-device-detect'
import DownloadAppButtons from '../../pages/home/DownloadAppButtons';
import { useLocation } from 'react-router-dom';
import useScrollDirection from '../../hooks/useScrollDirection';
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import IntegrationsMainPage from '../../pages/integrations/IntegrationsMainPage'
import { HOME_LOGO, RUNSMART_ICON } from '../../modules/miscImages'
import { Logout } from '@mui/icons-material';

const theme = createTheme({
	palette: {
		primary: {
			main: '#fff',
			contrastText: '#010101'
		},
	},
});

export type RSHeaderContProps = {
	className?: string,
	show?: boolean,
	children?: any,
	sx?: object,
	barSX?: object,
	contSX?: object,
	childrenSX?: object,
	secondary?: boolean,
	isInside?: boolean,
	drawer?: boolean,
	noShadow?: boolean,
	scrollHide?: boolean,
	bottom?: boolean,
	title?: string,
	backClick?: any,
	backClickDisabled?: boolean,
	closeClick?: any,
	isStatic?: boolean,
	onboarding?: boolean,
	header?: boolean,
	noBanner?: boolean,
	childFullWidth?: boolean,
	displayCardNoWatch?: boolean,
	userInfo?: any,
	hasLogo?: boolean,
	centerChild?: boolean
	hasCenteredLogo?: boolean
	logout?: any
}

const HideOnScroll: FC<RSHeaderContProps> = ({ children, drawer, scrollHide }) => {
	const drawerElement = document?.getElementsByClassName('MuiDrawer-paperAnchorRight')?.[0]
	const trigger = useScrollTrigger({
		target: drawer ? drawerElement : window
	});

	return (scrollHide ? <Slide appear={false} direction="down" in={!trigger}>
		{children}
	</Slide> : <>{children}</>);
}

const RSHeaderCont: FC<RSHeaderContProps> = ({ className = '', show = true, children, drawer, sx = {}, barSX = {}, contSX = {}, childrenSX = {}, secondary, isInside, noShadow, scrollHide, bottom, title, backClick, isStatic, onboarding, header, closeClick, noBanner, childFullWidth, displayCardNoWatch = false, userInfo, hasLogo, centerChild, backClickDisabled, hasCenteredLogo, logout }) => {
	const { requestKey: deviceKey } = useQueryParams()
	const navigate = useNavigate()
	const requestKey = localStorage.getItem('deviceRequestKey') || deviceKey
	const location = useLocation()
	const isShow = location.pathname === '/renew' || location.pathname === '/suggested-programs' || location.pathname === '/signup' || location.pathname === '/login' || location.pathname === '/explore' ? false : true
	const [showDownloadBanner, setShowDownloadBanner] = useState(!requestKey && isMobile && !isSafari && isShow)
	const [displayPopupConnectWatch, setDisplayPopupConnectWatch] = useState<boolean>(false)
	const isMobileSC = useIsMobileScreen();
	const scrollDirection = useScrollDirection()
	const style = {
		height: displayCardNoWatch ? '100px' : '60px',
		position: 'relative',
		width: '100%',
		zIndex: 1001,
		mt: showDownloadBanner && !onboarding && !bottom && !noBanner ? '50px' : 0,
		...sx
	}

	const barStyle = {
		px: displayCardNoWatch ? 0 : 2,
		height: 'inherit',
		width: '100%',
		top: bottom ? 'auto' : (secondary ? 60 : 0),
		bottom: bottom ? 0 : 'auto',
		right: isInside ? 'auto' : 0,
		mt: showDownloadBanner && !onboarding && !noBanner ? '50px' : 0,
		transition: 'margin-top 0.2s',
		...barSX
	}

	if (!onboarding && scrollDirection && scrollDirection === "down") {
		barStyle.mt = 0
	}
	const hasBlank = ((backClick && title && !children) || centerChild) && !closeClick
	return show ? <ThemeProvider theme={theme}>
		<Box className={className} sx={style} >
			{showDownloadBanner && !onboarding && !bottom ? <DownloadAppButtons sx={{ display: noBanner ? 'none' : 'flex' }} /> : undefined}
			<HideOnScroll drawer={drawer} scrollHide={scrollHide}>
				<AppBar position={isStatic ? 'static' : 'fixed'} className={`${noShadow ? 'no-bs' : 'default-bs'} ${drawer ? 'sm-mw ' : (isInside ? 'default-mw' : '')}`} sx={barStyle}>
					<Grid container xs={12} sx={{ px: displayCardNoWatch ? 2 : 0 }} justifyContent="center" alignItems="center">
						<Box className='default-mw' sx={{ position: 'relative', display: 'flex', justifyContent: backClick || children || closeClick ? 'space-between' : 'center', alignItems: 'center', width: '100%', height: displayCardNoWatch ? '60px' : '100%', ...contSX }}>
							{hasLogo && <img onClick={() => navigate('/', { replace: true })} className='home_mobile_logo' src={RUNSMART_ICON} style={{ position: 'absolute', left: 0 }} alt="RunSmart Home Logo" />}
							{hasLogo && children && <Box sx={{ width: '22px' }} />}
							{hasCenteredLogo && <img onClick={() => navigate('/', { replace: true })} className='home_mobile_logo' src={HOME_LOGO} style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }} alt="RunSmart Home Logo" />}
							{backClick ? <Button disabled={backClickDisabled} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '42px', height: '100%', ml: -2, color: '#010101', p: 0, minWidth: 0 }} onClick={() => backClick?.()} >
								<ArrowBack />
							</Button> : (centerChild ? <Box sx={{ width: '26px' }} /> : <></>)}
							{title && !closeClick && <Box className='transition-al' sx={{width: hasBlank ? 'calc(100% - 52px)' : 'auto'}}>
								{header ? <SH3 size={1} sx={{ pt: '7px' }}>{title}</SH3> : <H1 className='ellipsis-1l' size={2} sx={{textAlign: 'center'}}>{title}</H1>}
							</Box>}
							{children && <Box sx={{ minWidth: backClick ? '26px' : 0, width: childFullWidth && !title ? 'calc(100% - 26px)' : (backClick || hasLogo || centerChild ? 'auto' : '100%'), ...childrenSX }}>{children}</Box>}
							{hasBlank ? <Box sx={{ width: '26px' }} /> : <></>}
							{closeClick && <Box className='pointer' onClick={() => closeClick?.()} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
								<RSCloseBtn />
								{title && !isMobileSC && <Box sx={{ ml: '10px', fontFamily: 'Poppins', fontSize: '18px', letterSpacing: 'normal' }}>
									{header ? <h4 style={{ fontWeight: 'bold', paddingTop: '7px' }}>{title}</h4> : title}
								</Box>}
							</Box>}
							{logout && <Box className='pointer' onClick={() => logout?.()} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
								<Logout />
								{title && !isMobileSC && <Box sx={{ ml: '10px', fontFamily: 'Poppins', fontSize: '18px', letterSpacing: 'normal' }}>
									{header ? <h4 style={{ fontWeight: 'bold', paddingTop: '7px' }}>{title}</h4> : title}
								</Box>}
							</Box>}
						</Box>
					</Grid>
					{displayCardNoWatch && userInfo ? <Grid container className='default-bs blue-bg' xs={12} sx={{ px: 1, py: '2px' }} alignItems='center' justifyContent='center'>
						<Grid container className='default-mw' alignItems='center' justifyContent='space-between'>
							<i className='fa-kit fa-light-watch-smart-circle-question' style={{ fontSize: '20px' }} />
							<P1 size={-1} sx={{ width: 'calc(100% - 105px)' }}>Connect your wearable for enhanced tracking and analysis</P1>
							<RSButton1 onClick={() => setDisplayPopupConnectWatch(true)} sx={{ width: '70px', p: '5px' }}>
								<P1 size={-1}>Connect</P1>
							</RSButton1>
						</Grid>
						<RSDrawer bottom fitContent open={displayPopupConnectWatch} onClose={() => setDisplayPopupConnectWatch(false)}>
							<Box sx={{ py: 2, px: 3 }}>
								<IntegrationsMainPage userInfo={userInfo} />
							</Box>
						</RSDrawer>
					</Grid> : <></>}
				</AppBar>
			</HideOnScroll>
		</Box>
	</ThemeProvider> : <></>
}

export default RSHeaderCont;
