/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap'

import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import BackButton from '../../components/buttons/BackButton'
import SelectPill from '../../components/input/SelectPill'
import AppMenus, { BodyComponent } from '../../components/layout/AppMenus'
import ScrollRow from '../../components/layout/ScrollRow'
import PageTitle from '../../components/text/PageTitle'
import { H1, SH2, P3 } from '../../components/text/RSTypography'
import RSIcon from '../../components/icons/RSIcon'
import useScrollRow from '../../hooks/useScrollRow'
import DatePicker from "react-datepicker";
import useSale, { ISale } from '../../hooks/useSale'
import moment from 'moment'
import WithAuth from '../../components/WithAuth'
import { AuthPageProps } from '../../modules/types'
import { navReq } from '../../modules/apiConsume'
import useAdminPage from './useAdminPage'
import { BrowserUpdated, DownloadDoneTwoTone } from '@mui/icons-material'
import useMidfoot from '../midfoot-project/useMidfoot'
import ExcelExporter from '../../components/misc/ExcelExporter'
import AdminUI from './AdminUI'
import Embeddings from './Embeddings'
import ChatCompletion from './ChatCompletion'
import ChatCompletionStep from './ChatCompletionStep'
import ChatCompletionSingle from './ChatCompletionSingle'
import TrainingPlanManagerV2 from './TrainingPlanManagerV2'
import { HOME_LOGO, HOME_LOGO_WHITE, RUNSMART_ICON } from '../../modules/miscImages'

import ENV_VARS from './adminDashVars.json'
import ReferralProgram from './ReferralProgramV2'
import ReferralSettings from './ReferralSettings'
import EmailingSystem from './WeeklyOutlook'
import WeeklyOutlook from './WeeklyOutlook'
import WeeklyOutlookPrompts from './WeeklyOutlookPrompts'
import WeeklyOutlookSettings from './WeeklyOutlookSettings'
import WeeklyOutlookHistory from './WeeklyOutlookHistory'
import RSButton from '../../components/buttons/RSButton'
import ManageAdminUsers from './ManageAdminUsers'
import useSWR from 'swr'
import { AdminUser } from '../../api/v2/admin-users/admin-users.types'

const PILL_OPTIONS_OLD = ["OpenAI Embeddings", "OpenAI Chat - Single",
    "Training Plans",
    // "OpenAI Chat - Steps",
    "Manage Sale",
    // "Midfoot Project",
    'Styles']

const PILL_OPTIONS = [
    "Training Plans",
    "OpenAI Embeddings",
    // "Sandbox",
    // "OpenAI Chat - Steps",
    "Manage Sale",
    "Referral Program",
    "Weekly Outlooks",
    "Admin Users",
    'Styles']


// const ALLOWED_USER = [1, 64, 16123]
const AdminPage: FC<AuthPageProps> = ({ userInfo }) => {

    const navigate = useNavigate()

    const { isScrolling, setIsScrolling } = useScrollRow()

    const { allSale: activeSale, isLoadingSale, updateSale } = useSale()

    const [pillKey, setPillKey] = useState(0)
    const [dayIntervalValue, setDayIntervalValue] = useState(0)
    const [selectedPill, setSelectedPill] = useState('Training Plans')
    const [midfootUsers, setMidfootUsers] = useState<any[] | undefined>()
    const [endDate, setEndDate] = useState(new Date());
    const [saleDetails, setSaleDetails] = useState<ISale | undefined>(activeSale)
    const [selectedTP, setSelectedTP] = useState('plans')
    const [selectedRP, setSelectedRP] = useState('referrers')
    const [selectedOL, setSelectedOL] = useState('sandbox')
    const [sidePanelMinimized, setSidePanelMinimized] = useState(false)

    // const { updateDaysInterval, surveyReport = {}, IsLoading } = useAdminPage(selectedPill)
    const { data: adminUsers, mutate } = useSWR<{ data: { result: AdminUser[] } }>(userInfo?.account_id ? `/admin-users?page${1}&limit=${100}` : null, { refreshInterval: 0 })

    const env = ENV_VARS.ADMIN_ENV.toString().toUpperCase()

    const { getActiveUsers, isLoading: loadingMidfootUsers } = useMidfoot()

    const onPillChange = (value: any) => {
        setSelectedPill(value)
        // setPillKey(value === "Introduction" ? 0 : 1)
    }

    const handleUpdateSale = (type: string) => {
        if (type === "headline" && saleDetails?.headline !== activeSale?.headline)
            updateSale(saleDetails as ISale)

        else if (type === "subtext" && saleDetails?.subtext !== activeSale?.subtext)
            updateSale(saleDetails as ISale)

        else if (type === "banner" && saleDetails?.banner !== activeSale?.banner)
            updateSale(saleDetails as ISale)
    }

    const handleButtonMinimizeSidePanel = () => setSidePanelMinimized(!sidePanelMinimized)



    const renderSale = () => {
    	return <Form>
				<Form.Group>
					<div className='half-md-card card mx-auto p-3 mt-0'>
						{/* <h4>Midfoot Project:<a href='/#/programs/midfoot-project'>Click here...</a></h4> */}
						{/* <br /> */}
						<Form.Label className='download-label'>
							Toggle Sale
						</Form.Label>
						<div className='d-flex-row-center'>
							<h5 className='mt-3'>OFF</h5>
							<Form.Check
								type="switch"
								id="custom-switch"
								checked={saleDetails?.active == 1 ? true : false}
								onChange={(e) => setSaleDetails({ ...saleDetails as ISale, active: e.target.checked ? 1 : 0 })}
								disabled={isLoadingSale}
							/>
							<h5 className='mt-3'>ON</h5>
						</div>

						<Form.Label className='mt-3'>
							Headline
						</Form.Label>
						<Form.Control
							value={saleDetails?.headline}
							type="text"
							placeholder="Type headline here"
							onChange={(e) => setSaleDetails({ ...saleDetails as ISale, headline: e.target.value })}
							onBlur={() => handleUpdateSale("headline")}
							disabled={isLoadingSale}
						/>

						<Form.Label className='mt-3'>
							Subtext
						</Form.Label>
						<Form.Control
							value={saleDetails?.subtext}
							type="text"
							as="textarea"
							rows={3}
							placeholder="Type subtext here"
							onChange={(e) => setSaleDetails({ ...saleDetails as ISale, subtext: e.target.value })}
							onBlur={() => handleUpdateSale("subtext")}
							disabled={isLoadingSale}
						/>

						<Form.Label className='mt-3'>
							Banner
						</Form.Label>
						<Form.Control
							value={saleDetails?.banner}
							type="text"
							placeholder="Type banner here"
							onChange={(e) => setSaleDetails({ ...saleDetails as ISale, banner: e.target.value })}
							onBlur={() => handleUpdateSale("banner")}
							disabled={isLoadingSale}
						/>

						<Form.Label className='mt-3'>
							Sale Ends
						</Form.Label>
						<DatePicker
							className='p-1 small-date-picker'
							selected={moment(saleDetails?.end).toDate()}
							onChange={(date: Date) => setSaleDetails({ ...saleDetails as ISale, end: date })}
							disabled={isLoadingSale}
						/>


						{isLoadingSale ? (
							<div className='center-content'>
								<Spinner animation='border' />
							</div>
							) : undefined}
					</div>
				</Form.Group>
			</Form>
    }

    const PILL_OPTIONS_V2: any = [{
    	item: 'Training Plans',
    	body: <TrainingPlanManagerV2 selectedTP={selectedTP} userInfo={userInfo}/>,
			onClick: setSelectedTP,
			subActive: selectedTP,
    	icon: <RSIcon type='trainingLogo' />,
    	subList: [{
    		item: 'Plan History',
    		key: 'plans',
    		icon: <i className="fa-light fa-rectangle-history-circle-user" />
    	}, {
    		item: 'Build Toggles',
    		key: 'builds',
    		icon: <i className="fa-light fa-toggle-on" />
    	}, {
    		item: 'Prompts',
    		key: 'prompts',
    		icon: <i className="fa-light fa-square-terminal" />
    	}, {
    		item: 'Sandbox',
    		key: 'sandbox',
    		icon: <i className="fa-brands fa-codepen" />
    	}]
    }, {
    	item: 'OpenAI Embeddings',
    	body: <Embeddings />,
    	icon: <i className="fa-light fa-microchip-ai"/>
    }, {
    	item: 'Manage Sale',
    	body: renderSale(),
    	onClick: undefined,
    	icon: <i className="fa-light fa-badge-percent" />
    }, {
    	item: 'Referral Program',
    	body: selectedRP === 'referrers' ? <ReferralProgram /> : <ReferralSettings />,
    	onClick: setSelectedRP,
    	subActive: selectedRP,
    	icon: <i className="fa-sharp fa-light fa-share-all" />,
    	subList: [{
				item: 'Referrers',
				key: 'referrers',
				icon: <i className="fa-light fa-user-friends" />
			}, {
				item: 'Settings',
				key: 'settings',
				icon: <i className="fa-light fa-cog" />
			}]
    }, {
    	item: 'Weekly Outlooks',
    	body: selectedOL === 'history' ? <WeeklyOutlookHistory /> : selectedOL === 'settings' ? <WeeklyOutlookSettings /> : selectedOL === 'prompts' ? <WeeklyOutlookPrompts /> : <WeeklyOutlook />,
			onClick: setSelectedOL,
			subActive: selectedOL,
    	icon: <RSIcon type='weekly-outlook' viewBox='-35 0 300 306.000000'/>,
    	subList: [{
				item: 'History',
				key: 'history',
				icon: <i className="fa-light fa-rectangle-history-circle-user" />
			}, {
				item: 'Settings',
				key: 'settings',
				icon: <i className="fa-light fa-cog" />
			}, {
				item: 'Prompts',
				key: 'prompts',
				icon: <i className="fa-light fa-square-terminal" />
			}, {
				item: 'Sandbox',
				key: 'sandbox',
				icon: <i className="fa-brands fa-codepen" />
			}]
    }, {
    	item: 'Admin Users',
    	body: <ManageAdminUsers />,
    	icon: <i className="fa-light fa-user-tie" />
    }, {
    	item: 'Styles',
    	body: <AdminUI />,
    	icon: <i className="fa-light fa-pen-nib" />
    }]


    useEffect(() => {
        setSaleDetails(activeSale)
    }, [activeSale])

    useEffect(() => {
        if (!isLoadingSale && saleDetails?.end !== activeSale?.end)
            updateSale(saleDetails as ISale)
    }, [saleDetails?.end])

    useEffect(() => {
        updateSale(saleDetails as ISale)
    }, [saleDetails?.active])

    useEffect(() => {
        //check if user is allowed to access the page
        const admins = adminUsers?.data.result
        if (userInfo?.account_id && admins && admins.length > 0) {
            const allowed = admins.find((admin: AdminUser) => admin.user_id === userInfo.account_id)
            if (!allowed) {
                alert('You are not allowed to access this page')
                navigate('/')
            }
        }

    }, [userInfo, adminUsers])

    const [openDrawer, setOpenDrawer] = useState<boolean>(false)
		const drawerWidth = openDrawer ? '220px'
			: (selectedPill && PILL_OPTIONS_V2?.find((p: any)=>p.subList && p.item === selectedPill) ? '50px' : '44px')
		const drawerPadding = openDrawer ? '5px' : 0

    useEffect(() => {
        if (selectedPill === "Midfoot Project")
            getActiveUsers().then(res => {
                setMidfootUsers(res)
            })
    }, [selectedPill])

    const selectedOption = PILL_OPTIONS_V2.find((x: any)=>x.item === selectedPill)

    return <Box sx={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
    	<AppBar className='transition-all' position='fixed' sx={{width: `calc(100% - ${openDrawer ? drawerWidth : '0px'})`, height: '40px', zIndex: 2000 , backgroundColor: env.includes('DEV') ? '#D63A53' : env.includes('STAG') ? '#0EB50D' : '#2350B0', justifyContent: 'center'}}>
    		<IconButton className='transition-all' onClick={() => setOpenDrawer(!openDrawer)} sx={{position: 'absolute', left: 0, px: drawerPadding, width: openDrawer ? 0 : drawerWidth, opacity: openDrawer ? 0 : 1}}>
    			<RSIcon type='hamburger' height='24px' width='24px' sx={{fill: 'white'}} />
				</IconButton>
				{!openDrawer && <img style={{position: 'absolute', left: 60, height: '80%', width: 'auto'}} onClick={() => setOpenDrawer(!openDrawer)} className='header-logo' src={HOME_LOGO_WHITE} alt="RunSmart Home Logo" />}
    		<H1 size={2} sx={{width: '100%', textAlign: 'center'}}>YOU ARE IN {env}</H1>
    	</AppBar>
    	<Drawer variant="permanent" anchor="left" open={openDrawer ? true : false} PaperProps={{ className: 'transition-all', sx: {width: drawerWidth, whiteSpace: 'nowrap', overflow: 'hidden'} }}>
    		<Box sx={{display: 'flex', justifyContent: 'flex-end', height: '40px'}}>
					<Button className='transition-all' onClick={() => setOpenDrawer(!openDrawer)} sx={{width: '100%', height: '100%', px: '13px', py: 0, display: 'flex', justifyContent: 'flex-end', opacity: openDrawer ? 1 : 0}}>
						<img style={{width: 'auto', height: '80%'}} onClick={() => setOpenDrawer(!openDrawer)} className='header-logo' src={RUNSMART_ICON} alt="Back" />
					</Button>
				</Box>
				<Divider />
    		<List disablePadding>
    			{PILL_OPTIONS_V2.map((value: any, index: number) => {
    				const isActive = selectedPill === value.item
						return <>
							<ListItemButton className={`transition-all ${isActive ? (value.subList ? 'gray-disabled-bg' : 'orange-bg') : ''}`} sx={{px: openDrawer ? 2 : '6px'}} key={value.item+index} onClick={() => onPillChange(value.item)}>
								<ListItemIcon sx={{width: '31px', minWidth: 0, fontSize: '24px', color: '#010101', display: 'flex', justifyContent: 'center'}}>{value.icon}</ListItemIcon>
								<ListItemText className='transition-all' sx={{pl: drawerPadding, opacity: openDrawer ? 1 : 0}}>
									<P3>{value.item}</P3>
								</ListItemText>
							</ListItemButton>
							{value.subList?.length > 0 && <Collapse className={isActive ? 'gray-disabled-light-bg' : ''} in={isActive} key={index}>
								<List component='div' disablePadding>
									{value.subList.map((subValue: any, subIndex: number) => {
										const subActive = value.subActive === subValue.key
										return <ListItemButton className={`transition-all ${subActive ? 'orange-bg' : ''}`} sx={{ pl: openDrawer ? 4 : 1.5 }} key={subValue.item+subIndex} onClick={() => value.onClick?.(subValue.key)}>
											<ListItemIcon sx={{width: '31px', minWidth: 0, fontSize: '24px', color: '#010101', display: 'flex', justifyContent: 'center'}}>{subValue.icon}</ListItemIcon>
											<ListItemText className='transition-all' sx={{pl: drawerPadding, opacity: openDrawer ? 1 : 0}}>
												<P3>{subValue.item}</P3>
											</ListItemText>
										</ListItemButton>
									})}
								</List>
							</Collapse>}
						</>
    			})}
    		</List>
    	</Drawer>
    	<Box className='transition-all' sx={{ width: `calc(100% - ${drawerWidth})`, px: 3, pt: '60px'}}>
				<SH2 size={5} sx={{width: '100%', textAlign: 'center', pb: 2}}>{selectedOption?.subList?.find((x: any)=>x.key === selectedOption.subActive)?.item || selectedPill}</SH2>
    		{selectedOption?.body}
    	</Box>
    </Box>

//    return (
//        <div style={{ backgroundColor: '#EEE', height: '100vh' }}>
//
//            <Box sx={{ position: 'absolute', left: '2rem', top: '2.7rem' }}>
//                {/* <RSButton onClick={() => handleButtonMinimizeSidePanel()}>show</RSButton> */}
//                <i className='fa-duotone fa-solid fa-bars' style={{ fontSize: '2.2rem', color: '#777', cursor: 'pointer' }} onClick={() => handleButtonMinimizeSidePanel()}></i>
//            </Box>
//
//            <Drawer
//                sx={{ minWidth: '10vw', zIndex: '-1' }}
//                variant="permanent"
//                anchor="left"
//            >
//
//                <Box sx={{ width: sidePanelMinimized ? '1rem' : '18rem', display: 'flex', flexDirection: 'column', alignItems: 'start', mt: '33%', transitionDuration: '300ms' }}>
//
//                    <Box sx={{ position: 'absolute', left: '2rem', top: '2.7rem' }}>
//                        {/* <RSButton onClick={() => handleButtonMinimizeSidePanel()}>show</RSButton> */}
//                        <i className='fa-duotone fa-solid fa-bars' style={{ fontSize: '2.2rem', color: '#777', cursor: 'pointer' }} onClick={() => handleButtonMinimizeSidePanel()}></i>
//                    </Box>
//
//                    {PILL_OPTIONS.map((value, index) => (
//                        <>
//                            <Button key={index}
//                                style={{
//                                    marginTop: `${selectedPill == value ? '1.8rem' : '1.8rem'}`, marginBottom: '1.6rem', marginLeft: '-4rem', color: `${selectedPill == value ? '#FFF' : '#777'}`, border: 'none', textAlign: 'start',
//                                    display: 'flex', alignItems: 'center', zIndex: 2,
//                                    backgroundColor: `${selectedPill == value ? '#999' : '#FFF'}`, height: '4.5rem', width: '110%', borderRadius: '2rem', transitionDuration: '350ms'
//                                }}
//                                onClick={() => onPillChange(value)}>
//                                <div style={{ fontFamily: 'Poppins-SemiBold', marginLeft: '5rem', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', fontSize: '0.9rem' }}>{value}</div>
//                            </Button>
//
//                            <div style={{
//                                marginTop: '-4rem', marginBottom: `${(selectedPill == value && (value == 'Training Plans' || value == 'Referral Program' || value == 'Weekly Outlooks')) ? '0rem' : '0.8rem'}`, marginLeft: '-4rem', color: `${selectedPill == value ? '#BBB' : '#777'}`, border: 'none', textAlign: 'start',
//                                zIndex: 1,
//                                backgroundColor: `${selectedPill == value ? '#B2B2B2' : '#FFF'}`, height: `${(selectedPill == value && value == 'Training Plans') ? '18rem' : selectedPill == value && value == 'Referral Program' ? '11rem' : selectedPill == value && value == 'Weekly Outlooks' ? '18rem' : '0rem'}`, width: '110%', borderRadius: '0 0 2rem 0', transitionDuration: '700ms'
//                            }}>
//                                {selectedPill == value && value == 'Training Plans' && <div style={{ marginLeft: '6rem ', color: 'white', fontFamily: 'Poppins-SemiBold', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '3.3rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedTP('plans')}>
//                                        {selectedTP == 'plans' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Plan History
//                                    </Button>
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '1rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedTP('builds')}>
//                                        {selectedTP == 'builds' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Build Toggles</Button>
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '1rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedTP('prompts')}>
//                                        {selectedTP == 'prompts' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Prompts</Button>
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '1rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedTP('sandbox')}>
//                                        {selectedTP == 'sandbox' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Sandbox</Button>
//                                </div>
//                                }
//                                {selectedPill == value && value == 'Referral Program' && <div style={{ marginLeft: '6rem ', color: 'white', fontFamily: 'Poppins-SemiBold', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '3.3rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedRP('referrers')}>
//                                        {selectedRP == 'referrers' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Referrers
//                                    </Button>
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '1rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedRP('settings')}>
//                                        {selectedRP == 'settings' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Settings</Button>
//                                </div>
//                                }
//                                {selectedPill == value && value == 'Weekly Outlooks' && <div style={{ marginLeft: '6rem ', color: 'white', fontFamily: 'Poppins-SemiBold', display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
//
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '3.3rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedOL('history')}>
//                                        {selectedOL == 'history' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        History
//                                    </Button>
//
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '1rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedOL('settings')}>
//                                        {selectedOL == 'settings' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Settings
//                                    </Button>
//
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '1rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedOL('prompts')}>
//                                        {selectedOL == 'prompts' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Prompts</Button>
//
//                                    <Button style={{ fontSize: '0.9rem', marginTop: '1rem', backgroundColor: '#CCCCCC00', border: 'none' }}
//                                        onClick={() => setSelectedOL('sandbox')}>
//                                        {selectedOL == 'sandbox' ? <i className='fa-solid fa-circle' style={{ marginRight: '1rem' }} /> : <i className='fa-regular fa-circle' style={{ marginRight: '1rem' }} />}
//                                        Sandbox
//                                    </Button>
//
//                                </div>
//                                }
//                            </div>
//
//                        </>
//                    ))
//                    }
//
//
//                </Box>
//
//                {/* #245AA1 */}
//                {!sidePanelMinimized && <Box sx={{ width: '100%', position: 'absolute', bottom: '0', display: 'flex', flexDirection: 'column', alignItems: 'center', pb: '2.5rem', pr: '0.5rem' }}>
//                    <img onClick={() => navigate('/', { replace: true })} className='header-logo' src={HOME_LOGO} alt="RunSmart Home Logo" />
//                </Box>}
//            </Drawer>
//
//
//            <div style={{ backgroundColor: '#EEE' }}>
//                <div style={{ width: '84%', height: 'auto', marginLeft: '18rem', padding: '3.3rem 4rem 0' }}>
//                    {selectedPill === "Manage Sale" ? (
//                        <Form>
//                            <Form.Group>
//                                <div className='half-md-card card mx-auto p-3 mt-0'>
//                                    {/* <h4>Midfoot Project:<a href='/#/programs/midfoot-project'>Click here...</a></h4> */}
//                                    {/* <br /> */}
//                                    <Form.Label className='download-label'>
//                                        Toggle Sale
//                                    </Form.Label>
//                                    <div className='d-flex-row-center'>
//                                        <h5 className='mt-3'>OFF</h5>
//                                        <Form.Check
//                                            type="switch"
//                                            id="custom-switch"
//                                            checked={saleDetails?.active == 1 ? true : false}
//                                            onChange={(e) => setSaleDetails({ ...saleDetails as ISale, active: e.target.checked ? 1 : 0 })}
//                                            disabled={isLoadingSale}
//                                        />
//                                        <h5 className='mt-3'>ON</h5>
//                                    </div>
//
//                                    <Form.Label className='mt-3'>
//                                        Headline
//                                    </Form.Label>
//                                    <Form.Control
//                                        value={saleDetails?.headline}
//                                        type="text"
//                                        placeholder="Type headline here"
//                                        onChange={(e) => setSaleDetails({ ...saleDetails as ISale, headline: e.target.value })}
//                                        onBlur={() => handleUpdateSale("headline")}
//                                        disabled={isLoadingSale}
//                                    />
//
//                                    <Form.Label className='mt-3'>
//                                        Subtext
//                                    </Form.Label>
//                                    <Form.Control
//                                        value={saleDetails?.subtext}
//                                        type="text"
//                                        as="textarea"
//                                        rows={3}
//                                        placeholder="Type subtext here"
//                                        onChange={(e) => setSaleDetails({ ...saleDetails as ISale, subtext: e.target.value })}
//                                        onBlur={() => handleUpdateSale("subtext")}
//                                        disabled={isLoadingSale}
//                                    />
//
//                                    <Form.Label className='mt-3'>
//                                        Banner
//                                    </Form.Label>
//                                    <Form.Control
//                                        value={saleDetails?.banner}
//                                        type="text"
//                                        placeholder="Type banner here"
//                                        onChange={(e) => setSaleDetails({ ...saleDetails as ISale, banner: e.target.value })}
//                                        onBlur={() => handleUpdateSale("banner")}
//                                        disabled={isLoadingSale}
//                                    />
//
//                                    <Form.Label className='mt-3'>
//                                        Sale Ends
//                                    </Form.Label>
//                                    <DatePicker
//                                        className='p-1 small-date-picker'
//                                        selected={moment(saleDetails?.end).toDate()}
//                                        onChange={(date: Date) => setSaleDetails({ ...saleDetails as ISale, end: date })}
//                                        disabled={isLoadingSale}
//                                    />
//
//
//                                    {isLoadingSale ? (
//                                        <div className='center-content'>
//                                            <Spinner animation='border' />
//                                        </div>
//                                    ) : undefined}
//                                </div>
//                            </Form.Group>
//                        </Form>
//                    ) : selectedPill === "Onboarding Survey" ? (
//                        <div>
//                            {/* <Form>
//                                <Form.Group>
//                                    <div className='half-md-card card mx-auto p-3 mt-0'>
//                                        <h4>New Accounts: <b>{surveyReport.totalUsers}</b></h4>
//                                        <h4>New Subscriptions: <b>{surveyReport.allSubscriptions}</b></h4>
//                                        <h4>Percentage: <b>{(surveyReport.allSubscriptions / surveyReport.totalUsers * 100).toPrecision(3)}%</b></h4>
//                                        <hr />
//
//                                        <h4>Started Survey: <b>{surveyReport.completedSurvey}</b></h4>
//                                        <h4>Percentage: <b>{surveyReport.percentage}</b></h4>
//                                        <p><b>{surveyReport.allSubsWithCompletedSurvey}</b> out of <b>{surveyReport.completedSurvey}</b> users who started the survey started a plan, with a total percentage of <b>{(surveyReport.allSubsWithCompletedSurvey / surveyReport.completedSurvey * 100).toPrecision(3)}%</b>.</p>
//                                        <hr />
//                                        <h4>Skipped Survey: <b>{surveyReport.notCompleted}</b></h4>
//                                        <h4>Percentage: <b>{(surveyReport.notCompleted / surveyReport.totalUsers * 100).toPrecision(3)}%</b></h4>
//                                        <p><b>{surveyReport.allSubsNoSurvey}</b> out of <b>{surveyReport.notCompleted}</b> users who did not complete the survey started a plan, with a total percentage of <b>{(surveyReport.allSubsNoSurvey / surveyReport.notCompleted * 100).toPrecision(3)}%</b>.</p>
//                                        <hr />
//                                        <h4>Incomplete surveys: <b>{surveyReport.userWithEmptySurvey + surveyReport.usersStoppedAtQ2 + surveyReport.usersStoppedAtQ3 + surveyReport.usersStoppedAtQ4}</b></h4>
//                                        <h4>Percentage <b>{(surveyReport.userWithEmptySurvey + surveyReport.usersStoppedAtQ2 + surveyReport.usersStoppedAtQ3 + surveyReport.usersStoppedAtQ4 / surveyReport.completedSurvey * 100).toPrecision(3)}%</b></h4>
//                                        <h4>Empty Surveys: <b>{surveyReport.userWithEmptySurvey}</b></h4>
//                                        <h4>Users who stopped at:</h4>
//                                        <h4>Q2 (Weekly Average): <b>{surveyReport.usersStoppedAtQ2
//                                        }</b></h4>
//                                        <h4>Q3 (Running Goal): <b>{surveyReport.usersStoppedAtQ3
//                                        }</b></h4>
//                                        <h4>Q4 (Struggle Areas): <b>{surveyReport.usersStoppedAtQ4
//                                        }</b></h4>
//                                        <hr />
//                                        <Form.Label className='mt-n1'>
//                                            <h5>Change Suggested Programs Days Interval</h5>
//                                        </Form.Label>
//                                        <Form.Control
//                                            value={dayIntervalValue}
//                                            type="number"
//                                            placeholder="Type Days Interval here..."
//                                            disabled={isLoadingSale}
//                                            onChange={(e) => setDayIntervalValue(e.target.value as unknown as number)}
//                                        />
//                                        <Button disabled={IsLoading} type='button' className='bg-primary border-0 mt-3' onClick={() => updateDaysInterval(dayIntervalValue)}>Save</Button>
//                                        {IsLoading ? (
//                                            <div className='center-content text-center'>
//                                                <h4>Loading, please wait...</h4>
//                                                <Spinner animation='border' />
//                                            </div>
//                                        ) : undefined}
//                                    </div>
//                                </Form.Group>
//                            </Form> */}
//                        </div>
//                    ) :
//                        selectedPill === "Midfoot Project" ? (
//                            <div style={{ maxHeight: '100vh' }} className='md-card card mx-auto p-3 mt-0'>
//                                <Row>
//                                    <Col>
//                                        Total active users: {midfootUsers?.length}
//                                    </Col>
//                                    <Col>
//                                        {midfootUsers && midfootUsers.length > 0 ? (<ExcelExporter apiData={midfootUsers} filename={`midfoot-active-users_${moment(new Date()).toISOString()}`} />) : undefined}
//                                    </Col>
//                                </Row>
//                                <Table responsive striped bordered hover size="sm">
//                                    <thead>
//                                        <tr>
//                                            <html>#</html>
//                                            <th>User ID</th>
//                                            <th>First Name</th>
//                                            <th>Last Name</th>
//                                            <th>Email</th>
//                                            <th>Date Started</th>
//                                        </tr>
//                                    </thead>
//                                    <tbody>
//                                        {midfootUsers?.map((data, index) => (
//                                            <tr key={index}>
//                                                <td>{index + 1}</td>
//                                                <td>{data.user_id}</td>
//                                                <td style={{ minWidth: '160px' }}>{data.first_name}</td>
//                                                <td style={{ minWidth: '160px' }}>{data.last_name}</td>
//                                                <td style={{ minWidth: '200px' }}>{data.email}</td>
//                                                <td style={{ minWidth: '130px' }}>{moment(data.date_started.split('T')[0]).format('MMMM D, YYYY')}</td>
//                                            </tr>
//                                        ))}
//
//                                    </tbody>
//                                </Table>
//                            </div>
//                        ) :
//                            selectedPill === "Styles" ?
//                                <AdminUI />
//                                : selectedPill === "OpenAI Embeddings" ? <Embeddings />
//                                    : selectedPill === "Sandbox" ? <ChatCompletionSingle userInfo={userInfo} />
//                                        : selectedPill === "OpenAI Chat - Steps" ? <ChatCompletionStep userInfo={userInfo} />
//                                            : selectedPill === "Training Plans" ? <TrainingPlanManagerV2 selectedTP={selectedTP} userInfo={userInfo} />
//                                                : selectedPill === "Referral Program" && selectedRP == 'referrers' ? <ReferralProgram />
//                                                    : selectedPill === "Referral Program" && selectedRP == 'settings' ? <ReferralSettings />
//                                                        : selectedPill === "Weekly Outlooks" && selectedOL === "sandbox" ? <WeeklyOutlook userInfo={userInfo} />
//                                                            : selectedPill === "Weekly Outlooks" && selectedOL === "prompts" ? <WeeklyOutlookPrompts />
//                                                                : selectedPill === "Weekly Outlooks" && selectedOL === "settings" ? <WeeklyOutlookSettings />
//                                                                    : selectedPill === "Weekly Outlooks" && selectedOL === "history" ? <WeeklyOutlookHistory />
//                                                                        : selectedPill === "Admin Users" ? <ManageAdminUsers />
//                                                                            : undefined
//                    }
//                </div>
//            </div>
//
//            <Box sx={{
//                zIndex: '1', position: 'absolute', top: 0, left: 0, width: '100%', height: '1.5rem', mb: '0', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center',
//                backgroundColor: env.includes('DEV') ? '#D63A53' : env.includes('STAG') ? '#0EB50D' : '#2350B0'
//            }}>
//                YOU ARE IN {env}
//            </Box>
//
//        </div>
//    )
}

export default WithAuth(AdminPage)
