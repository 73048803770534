/* eslint-disable @typescript-eslint/no-unused-vars */

import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'

import { Form } from 'react-bootstrap'
import useSWR from 'swr'
import { IBuildPlanAvailability } from '../../api/v2/training-plan/training-plan.types'
import { putReq } from '../../modules/apiConsume'

const BuildPlanAvailability = () => {

    const { data, isLoading, mutate } = useSWR('/build-plan-availability', { refreshInterval: 0 })

    const [planBuilds, setPlanBuild] = useState<Array<IBuildPlanAvailability>>([])

    const hanldeChangeStatus = async (planBuild: IBuildPlanAvailability) => {
        await putReq('/v2/build-plan-availability', { id: planBuild.id, status: planBuild.status == 1 ? 0 : 1 })
        mutate()
    }

    useEffect(() => {
        if (data && data.data.result.length > 0)
            setPlanBuild(data.data.result)
    }, [data])

    return (
        <Grid container>
            {planBuilds.map((raceType) => {
                return (
                    <Grid key={raceType.id} container xs={12} justifyContent='center'>
                        <Grid sx={{width: '200px'}}>
                            <Form.Label className='download-label mt-2'>
                                {raceType.race_type.toUpperCase()}
                            </Form.Label>
                            <hr />
                        </Grid>
                        <Grid sx={{width: '200px'}}>
                            <div className='d-flex-row-center'>
                                <h5 className='mt-3'>OFF</h5>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={raceType?.status == 1 ? true : false}
                                    onChange={(e) => hanldeChangeStatus(raceType)}
                                    disabled={isLoading}
                                />
                                <h5 className='mt-3'>ON</h5>
                            </div>
                            <hr />
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default BuildPlanAvailability
