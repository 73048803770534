import { FC } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {RSButton2} from '../buttons'
import {P1} from '../text/RSTypography'

export type RSCardContProps = {
	className?: string,
	children?: any,
	onClick?: any,
	onClose?: any,
	sx?: object,
	disabled?: boolean
	isLoading?: boolean,
	editable?: boolean,
	ghost?: boolean,
	additionalIcon?: any,
	commonPads?: boolean,
	child14px?: boolean
}

const RSCardCont: FC<RSCardContProps> = ({ className = '', children, onClick, onClose, sx, disabled, isLoading, editable, ghost, additionalIcon, commonPads, child14px }) => {
	const isChild14px = child14px ? 'btn-14-child' : ''

	const renderChildren = () => {
		return editable ? <Grid xs={12} sx={{display: 'flex', width: '100%'}}>
			{children}
		</Grid> : children
	}

	const renderButtonChildren = () => {
		const hasIcon = ((editable && onClick)|| onClose)
		return <>
			{renderChildren()}
			{additionalIcon && <Box sx={{position: 'absolute', right: hasIcon ? 45 : 10, top: 10, fontSize: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
				{additionalIcon}
			</Box>}
			{hasIcon &&  <Box className={onClose ? 'pointer' : ''} onClick={onClose || onClick ? (e) => {
				e.stopPropagation()
				onClick?.() || onClose?.()
			} : undefined} sx={{position: 'absolute', right: onClose ? 5 : 10, top: onClose ? 5 : 10, fontSize: '16px', width: onClose ? 'auto' :'21px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
				<i className={`${ editable ? 'fa-light fa-pen' : 'fa-regular fa-xmark'}`} style={{fontSize: '20px'}}/>
				{editable && <P1 size={-3} sx={{mt: '3px'}}>EDIT</P1>}
			</Box>}
		</>
	}


	const renderBody = () => {
		return !disabled && (onClick && !editable) ? <RSButton2 secondary={!ghost} ghost={ghost} className={`${className} ${isChild14px}`} onClick={() => onClick?.()} sx={{ p: commonPads ? '13px 16px' : '10px', alignItems: 'flex-start', overflow: 'hidden', letterSpacing: 'normal !important', ...sx }}>
			{renderButtonChildren()}
		</RSButton2> : <Box className={`${className} rsButtonStyle white-bg ${isChild14px}`} sx={{ p: commonPads ? '13px 16px' : '10px', display: 'flex', position: 'relative', overflow: 'hidden', letterSpacing: 'normal !important', ...sx}}>
			{renderButtonChildren()}
		</Box>
	}


	return isLoading ? <span style={{position: 'relative', display: 'block', width: '100%'}}>
		{renderBody()}
		<Box className='loading-days toRight' sx={{border: 'none !important', position: 'absolute', width: '100%', height: '100%'}} />
	</span> : renderBody()
}

export default RSCardCont;
