/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, { FC, useState } from 'react';
import { Container, TextField, Typography, Box, Paper, Grid, OutlinedInput, InputAdornment } from '@mui/material';
import { H4, P1, P3, P4 } from '../text/RSTypography';
import RSButton from '../buttons/RSButton';
import { SaveHeartRateProfile, SaveHeartRateZones } from '../../modules/trainingPlanActions';
import moment from 'moment';
import { calculateMHR, calculateZones } from '../../hooks/useHeartRateZonesComputator'
import RSDateInput from '../input/RSDateInput';
import Loader from '../animation/Loader';
import { UpdateAccountProfile } from '../../modules/accountActions';
import RSDrawer from '../layout/RSDrawer';
import RSHeaderCont from '../layout/RSHeaderCont';
import HeartRateZonesEditor from './HeartRateZonesEditor';


const HeartRateZones: FC<any> = ({ userAccountDetails, dayDetails, setShowHeartRateZonesComputator }) => {

    const userAccountAge = userAccountDetails.dob ? moment().diff(userAccountDetails.dob, 'years') : ''

    const [age, setAge] = useState<any>('');
    const [heartrateResting, setHeartrateResting] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [birthDate, setBirthDate] = useState(userAccountDetails.dob || false)
    const [showTooltip, setShowTooltip] = useState(false)
    const [showHeartRateZonesEditor, setShowHeartRateZonesEditor] = useState(false)

    const heartRateProfile = {
        heartrateResting: heartrateResting,
        age: age,
        userAccountAge: userAccountAge
    }


    // waits for both saving functions to complete before closing the modal
    const handleClickProceed = async () => {

        setIsLoading(true)

        Promise.all([handleSaveZoneRanges(), handleSaveHeartRateProfile(), handleUpdateBirthDate()]).then(() => {
            setShowHeartRateZonesComputator(false)
            setIsLoading(false)
        })

    }


    const handleSaveZoneRanges = async () => {

        const zoneRangesInitial = calculateZones(heartRateProfile)

        // setZoneRanges(zoneRangesInitial)

        console.log('!!! SAVING zone ranges:', zoneRangesInitial)

        // Save the updated zone ranges
        await SaveHeartRateZones({ plan_id: dayDetails.planId, plan_day_id: dayDetails.planDayId, activity_id: dayDetails.activityId, zone_ranges: JSON.stringify(zoneRangesInitial), updated_date: dayDetails.currentDate }).then((res) => {

            console.log('saved heart rate zones', res)
            return true

        }).catch((err) => {

            console.log(err)
            return true
        })
    }



    const handleSaveHeartRateProfile = async () => {

        await SaveHeartRateProfile({ user_id: userAccountDetails.id, username: userAccountDetails.username, heartrate_resting: parseInt(heartrateResting), heartrate_max: calculateMHR(heartRateProfile.age, heartRateProfile.userAccountAge), setting_manual: false }).then((res) => {

            console.log('saved heart rate profile', res)
            return true

        }).catch((err) => {

            console.log(err)
            return true
        })

    }


    const handleSetAge = (date: any) => {

        console.log('uesrAccountDetails', userAccountDetails)

        const age = moment().diff(date, 'years') || moment().diff(userAccountDetails.dob, 'years')
        console.log('d', date)
        setAge(age)
        moment(date) && setBirthDate(date)
    }


    const handleUpdateBirthDate = async () => {

        await UpdateAccountProfile({ user_id: userAccountDetails.user_id, dob: moment(birthDate).format('YYYY-MM-DD') }).then((res) => {

            console.log('response', res)
            return true
        })
    }


    const renderHeartRateZonesEditor = () => <Box sx={{ height: '100%' }}>
        <RSHeaderCont className='ellipsis-1l' noBanner isStatic={true} backClick={() => setShowHeartRateZonesEditor(false)} barSX={{ borderRadius: 0 }} title={'Heart Rate Settings'} />
        <HeartRateZonesEditor
            userAccountDetails={userAccountDetails}
            heartRateProfile={heartRateProfile}
            zoneRanges={[]}
            setZoneRanges={[]}
            dayDetails={dayDetails}
            setShowHeartRateZonesEditor={setShowHeartRateZonesEditor}
            inputValues={[]}
            setInputValues={null}
            setHeartRateProfileUpdated={null}
        />
    </Box>


    return (
        <Box sx={{ p: 3 }}>

            <Box sx={{ backgroundColor: 'white', p: '1.4rem 1.6rem', mb: '1rem', borderRadius: '5px' }}>

                <H4 size={2} sx={{ mb: '0.6rem' }}>Set Your Zones</H4>
                <P1> Heart rate zones are set using the scientifically validated heart rate zone reserve (HRR) formula.  </P1>

                <Box sx={{ mt: '2.6rem' }}>

                    <Grid sx={{ mt: '1.4rem', mb: '3rem' }}>
                        <P1 sx={{ mb: '0.5rem' }}>Age</P1>

                        <Grid container sx={{ width: '8.2rem' }}>
                            <RSDateInput inputSX={{ height: '3rem' }} date={userAccountDetails.dob || birthDate || ''} setDate={handleSetAge} referenceDate={moment().subtract(30, 'y').format('YYYY/MM/DD')} maxDate={moment().subtract(1, 'year').format('YYYY/MM/DD')} />
                        </Grid>
                        {/* <Grid sx={{ maxHeight: '3rem', overflow: 'hidden' }}>
                            <OutlinedInput
                                value={userAccountAge}
                                onChange={(e) => setAge(e.target.value)}
                                sx={{
                                    mt: '-6px',
                                    overflow: 'hidden',
                                    backgroundColor: '#F5F5F5',
                                    width: '7.7rem',
                                    height: '3rem',
                                    borderRadius: '5px',
                                }}
                            />
                        </Grid> */}
                    </Grid>

                    <Grid sx={{ mt: '1.4rem', mb: '1rem' }}>
                        <Grid sx={{ display: 'flex' }}>
                            <P1 sx={{ mb: '0.5rem', mr: '0.3rem' }}>Resting Heart Rate</P1>
                            <i className="fa-light fa-circle-info" style={{ marginTop: '0.1rem', fontSize: '16px' }}
                                onClick={() => setShowTooltip(true)}></i>
                        </Grid>
                        <Grid sx={{ maxHeight: '3rem', overflow: 'hidden' }}>
                            <OutlinedInput
                                endAdornment={<InputAdornment position="end">bpm</InputAdornment>}
                                onChange={(e) => setHeartrateResting(e.target.value)}
                                sx={{
                                    // mt: '-6px',
                                    // overflow: 'hidden',
                                    // backgroundColor: '#F5F5F5',
                                    width: '8.2rem',
                                    height: '3rem',
                                    borderRadius: '5px',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                {/* <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                    <P3 sx={{ mt: '2rem', mb: '3rem', color: '#485596', mr: '1.6rem' }}
                        onClick={() => {
                            setShowHeartRateZonesEditor(true)
                        }}>
                        {'View HR Settings'}</P3>
                </Box> */}


                <RSButton
                    disabled={heartrateResting == '' || (userAccountAge == '' && birthDate == '')}
                    sx={{ mt: '3rem' }}
                    onClick={() => {
                        handleClickProceed()
                    }}  >
                    Proceed
                </RSButton>

            </Box>

            {/* <RSDrawer fitContent popUpWidth={"auto"} bottom={!isMobile} open={showHeartRateZonesEditor} onClose={() => {
                setShowHeartRateZonesEditor(true)
            }}>
                {renderHeartRateZonesEditor()}
            </RSDrawer> */}



            <RSDrawer
                bottom
                fitContent
                popUpWidth='400px'
                open={showTooltip}

                onClose={() => {
                    setShowTooltip(false)
                }}
            >
                <Grid container xs={12} sx={{ p: 3 }}>
                    <H4 size={2} sx={{ width: '100%', my: '1rem' }}>Resting Heart Rate</H4>
                    <P1 sx={{ width: '100%', mb: '4rem' }}>
                        {`Resting Heart Rate (RHR) is the number of times your heart beats per minute while at complete rest. It’s a key indicator of fitness and recovery. We use it, along with the Heart Rate Reserve (HRR) formula, to personalize your heart rate zones for smarter training`}
                    </P1>
                </Grid>
            </RSDrawer>

            <Loader active={isLoading} />

        </Box>
    );
}

export default HeartRateZones;