/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ShareInvite from './ShareInvite'
import { RSButton1 } from '../../components/buttons'
import { H3, SH3, P1, P2 } from '../../components/text/RSTypography'
import { ICurrentUser } from '../../store/createAccountStore'
import useStore from '../../store/useStore'
import { RedeemCredits } from '../../modules/shareActions'
import { RouletteSpinner } from 'react-spinner-overlay'
import useSWR from 'swr'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import Loader from '../../components/animation/Loader'
import RSDrawer from '../../components/layout/RSDrawer'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useRNBridge from '../../hooks/useRNBridge'
import { Snackbar } from '@mui/material'

type MyCreditProps = {}

const MyCredit: FC<MyCreditProps> = () => {

	const { currentUser } = useStore((state: ICurrentUser) => state)
	const [loading, setLoading] = useState(false)

	const [openRedeemSuccess, setOpenRedeemSuccess] = useState(false)
	const [selectedCode, setSelectedCode] = useState('')
	const [showConfirmCopy, setShowConfirmCopy] = useState(false);
	const [transactions, setTransactions] = useState<any>();
	const [data, setData] = useState<any>();

	const { data: tranxResults, mutate: mutateTransactions, isLoading: isLoadingTransactions } = useSWR(currentUser?.account_id ? `/share/transactions/user/${currentUser?.account_id}` : null, { refreshInterval: 0, revalidateOnFocus: true })

	const { data: redeemedCredits, isLoading: isLoadingCreditHistory, mutate: mutateHistory } = useSWR(currentUser?.account_id ? `/share/credit-history?user_id=${currentUser?.account_id}` : null, { refreshInterval: 0, revalidateOnFocus: true })

	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false
	const { mobileType } = useDeviceDetect()
	const mobile = useIsMobileScreen()

	const { sendDataToReactNative } = useRNBridge()

	const handleCopyCode = async () => {
		await navigator.clipboard.writeText(selectedCode)
		// setShowConfirmCopy(true)
		setLoading(true)
		setTimeout(() => {
			setLoading(false)
			// handleOpenUrl('https://shop.runsmartonline.com')
			// handleOpenUrl("https://jmkitty.myshopify.com")
			handleConfirmCopyCode(selectedCode)
		}, 500)
	}

	const handleOpenShop = () => handleOpenUrl("https://shop.runsmartonline.com")

	const handleConfirmCopyCode = (selectedCode: string) => {
		setSelectedCode(selectedCode)
		setOpenRedeemSuccess(true)
	}

	const handleClose = () => {
		setShowConfirmCopy(false);
	};

	const handleOpenUrl = (url: string) => {
		if (isNativeApp && mobileType.toLowerCase() === "ios") {
			const dataToSend = {
				requestType: 'open-rs-shop',
				payload: { url: url },
				timestamp: new Date().getTime(),
			}
			sendDataToReactNative(dataToSend);
		} else
			window.open(url, '_blank')
	}

	const handleRedeem = () => {
		if (loading) return
		setLoading(true)
		if (currentUser?.account_id)
			RedeemCredits(currentUser?.account_id).then(async (res) => {
				// setOpenRedeemSuccess(true)
				await mutateHistory()
				await mutateTransactions()
				setLoading(false)
			}).catch((err) => {
				setLoading(false)
				console.log(err)
			})
	}

	useEffect(() => {
		if (currentUser?.account_id) {
			handleRedeem()
		}
	}, [currentUser])

	useEffect(() => {
		if (data && data?.length > 0) {
			const code = data.filter((x: any) => x.code !== "")[0]?.code
			if (code)
				setSelectedCode(code ? code : "")
		}
	}, [data])

	useEffect(() => {
		if (tranxResults?.data?.result) {
			setTransactions(tranxResults?.data?.result)
		}
	}, [tranxResults])

	useEffect(() => {
		if (redeemedCredits?.data?.result && redeemedCredits?.data?.result?.length > 0) {
			setData(redeemedCredits?.data?.result)
		}
	}, [redeemedCredits])

	const RenderRedeemSuccess = () => {
		return <>
			<RSDrawer bottom maxHeight={mobile && selectedCode ? "32%" : "70%"} maxWidth="70%" open={openRedeemSuccess} onClose={() => setOpenRedeemSuccess(false)}>
				<div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
					{/* <div style={{ marginTop: '2rem', marginBottom: '1rem', fontSize: '1.2rem', width: 'auto', fontWeight: 'bold', textAlign: 'center' }}>Share and Earn Store Cred</div> */}
					<div style={{ maxWidth: "80%" }}><h3 style={{ marginTop: '1.4rem', marginBottom: '1rem', fontSize: "1.6rem" }}>Get ready for some <br /> sweet merch!</h3></div>
					{selectedCode !== "" && <div style={{ width: '70%' }}><p>Code <b>{selectedCode}</b> has been copied to your clipboard. Enter it at checkout to apply your credit.</p></div>}

					<div style={{ position: mobile ? "absolute" : "unset", bottom: 10, display: 'flex', flexDirection: mobile ? 'column' : 'row', justifyContent: 'space-evenly', margin: '2rem 0 1rem' }}>
						<RSButton1 sx={{ width: mobile ? "90vw" : "200px" }} onClick={handleOpenShop}>
							Shop Merch
						</RSButton1>
					</div>
				</div>
			</RSDrawer>
		</>
	}

	return (
		<>
			<Grid xs={12} sx={{ pt: 3 }}>
				<Grid container xs={12} sx={{ pb: 3 }}>
					{/* <Grid container xs={6} sx={{ flexDirection: 'column' }} justifyContent='flex-start' alignItems='center'>
						<P1>Available Credit</P1>
						<SH3 size={6} sx={{ py: 1 }}>${transactions?.activeAmount ? Number(transactions?.activeAmount).toFixed(2) : 0.00}</SH3>
					</Grid> */}
					<Grid container xs={12} sx={{ flexDirection: 'column' }} justifyContent='flex-start' alignItems='center'>
						<P1>Store Credit</P1>
						<SH3 size={6} sx={{ py: 1 }}>${transactions?.availableStoreCredits ? Number(transactions?.availableStoreCredits).toFixed(2) : 0.00}</SH3>
					</Grid>
				</Grid>


				<Grid container xs={12} justifyContent="center" alignItems="center">
					<RSButton1 disabled={transactions?.availableStoreCredits == 0 || !transactions} onClick={handleCopyCode}>
						{loading ? (
							<RouletteSpinner size={25} color='#010101' />
						) : `COPY CODE ${selectedCode ? selectedCode : ''}`}
					</RSButton1>
				</Grid>
				{data?.length ? <></> : <Grid container xs={12} justifyContent='space-between'>
					<ShareInvite location='share_drawer' newPage />
				</Grid>
				}

				<Grid container xs={12} sx={{ pt: 3 }}>
					<Grid container sx={{ width: 'calc(100% - 120px)' }} justifyContent='flex-start' alignItems='center'>
						<H3>Credit History</H3>
					</Grid>
					<Grid container sx={{ width: '120px' }} justifyContent='center'>
						<H3>Amount</H3>
					</Grid>
				</Grid>

				{isLoadingCreditHistory ? <Loader />
					: (data?.length ? data?.map((d: any, i: number) => {
						const { action, date, email, amount } = d
						return <Grid className='pointer' container xs={12} sx={{ pt: 2 }} key={'credit-history-' + i} onClick={() => d.order_status_url && handleOpenUrl(d.order_status_url)}>
							<Grid container sx={{ width: 'calc(100% - 120px)' }} justifyContent='flex-start' alignItems='flex-start'>
								<Box className='accent-bg' sx={{ height: '15px', width: '15px', borderRadius: '50%', mr: 1 }} />
								<Box sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 23px)' }}>
									<SH3>{action}</SH3>
									<P1 className='ellipsis-1l'>Date: {moment(date).format('MMM DD YYYY h:mm A')}</P1>
									{d.transactionId && <P2>Order ID: <b>{d.transactionId}</b></P2>}
									{/* {d.redeemStatus && <SH3 className={d.redeemStatus.toLowerCase().includes('available') ? 'primary-dark-text' : ''}>{d.redeemStatus}</SH3>} */}
									{email && <P2 className='ellipsis-1l' sx={{ pb: '2px' }}>Email: {email}</P2>}
								</Box>
							</Grid>
							<Grid container sx={{ width: '120px' }} justifyContent='center'>
								{amount ? <SH3 className={amount.includes('+') ? 'primary-dark-text' : ''}>{amount}</SH3>
									: <Box className='gray-dark-bg' sx={{ height: '1px', width: '10px' }} />}
							</Grid>
						</Grid>
					}) : <Grid xs={12}>
						<P1 size={1} sx={{ pt: 4, textAlign: 'center', textTransform: 'italic' }}>No credit history</P1>
					</Grid>)
				}
			</Grid>
			<Snackbar
				open={showConfirmCopy}
				autoHideDuration={2000}
				onClose={handleClose}
				message={`Code ${selectedCode} copied to clipboard`}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			// action={action}
			/>
			{RenderRedeemSuccess()}
		</>
	)
}
export default MyCredit;
