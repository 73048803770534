/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid } from "@mui/material"
import { FC } from "react"
import { Lap } from "../../api/v2/coros/coros.types";
import useTrainingPlanUtils from "../../hooks/useTrainingPlanUtils";
import {P1, P4} from "../text/RSTypography";

interface CorosWatchWorkoutLapsProps {
    data: Lap[],
    userInfo: any,
    activityType: string
    total_steps?: number
}

const CorosWatchWorkoutLaps: FC<CorosWatchWorkoutLapsProps> = ({ data, userInfo, activityType, total_steps }) => {

    const { ConvertMetersToMiles, ConvertS2M, getGarminPace } = useTrainingPlanUtils();

    const renderWorkoutLaps = () => {
        const running = []
        const otherActivities = []
        // for (const act of data) {
        const isRunning = activityType?.toLowerCase()?.includes('running')
        const isWalking = activityType?.toLowerCase()?.includes('walking')
        const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'
        const table = data?.length > 0 && <>
            <Grid container xs={12} justifyContent='flex-start' alignItems="center">
                <Grid container xs={12} sx={{pb: 1}} justifyContent='flex-start' alignItems="center">
                    <Grid container xs={1} justifyContent='center' alignItems='center'><P4>Lap</P4></Grid>
										<Grid container xs={3} justifyContent='center' alignItems='center'><P4>Time</P4></Grid>
										<Grid container xs={3} justifyContent='flex-end' alignItems='center'>
											<P4 sx={{position: 'relative'}}>
												Distance
												<P1 size={-2} sx={{ position: 'absolute', right: 0, bottom: -10 }} >{paceUnit}</P1>
											</P4>
										</Grid>
                    {
                        isWalking ? (<Grid container xs={5} justifyContent='flex-end' alignItems='center'>
                            <P4 sx={{position: 'relative'}}>
                            	Steps
														</P4>
                        </Grid>) : (
                            <Grid container xs={5} justifyContent='flex-end' alignItems='center'>
															<P4 sx={{position: 'relative'}}>
																Avg Pace
																<P1 size={-2} sx={{ position: 'absolute', right: 0, bottom: -10 }} >min/{paceUnit}</P1>
															</P4>
														</Grid>
                        )
                    }
                </Grid>

                {data.map((lap: Lap, i: number) => {
                    const lapDistance = lap.total_distance
                    const lD = userInfo?.km == 'Y' ? (lapDistance / 1000)?.toFixed(2) : ConvertMetersToMiles(lapDistance)?.toFixed(2)

                    const lapUnit = userInfo?.km == 'Y' ? 'km' : 'mile'
                    return lap ? (
                        <Grid container xs={12} sx={{my: 1}}>
                            <Grid container xs={1} justifyContent='center' alignItems='center'>
															<P1>{i+1}</P1>
														</Grid>
                            <Grid container xs={3} justifyContent='center' alignItems='center'>
															<P1>{ConvertS2M(lap.total_elapsed_time)}</P1>
                            </Grid>
                            <Grid container xs={3} justifyContent='flex-end' alignItems='center'>
															<P1>{lD}</P1>
														</Grid>
                            {
                                isWalking ? (
                                    <Grid container xs={5} justifyContent='flex-end' alignItems='center'>
																			<P1>{i === data.length - 1 ? (total_steps ? total_steps - ((data.length - 1) * 1000) : 0) : 1000}</P1>
                                    </Grid>
                                ) : (
                                    <Grid container xs={5} justifyContent='flex-end' alignItems='center'>
																			<P1>{getGarminPace(userInfo?.km === 'Y' ? true : false, lap.total_distance / lap.total_elapsed_time).trim().split('/')[0]}</P1>
                                    </Grid>
                                )
                            }
                        </Grid>
                    ) : ''
                })}
            </Grid>
        </>

        table && (isRunning ? running.push(table) : otherActivities.push(table))

        return <>{[...running, ...otherActivities]}</>
    }

    return renderWorkoutLaps()
}

export default CorosWatchWorkoutLaps
