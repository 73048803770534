import { FC, isValidElement, useState } from 'react'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import RSFlexCard from './RSFlexCard'
import RSCardCont from './RSCardCont'
import { SH3, P2 } from '../text/RSTypography'
import Heart from '../logos/Heart'
import useStore from '../../store/useStore';
import { ICurrentUser } from '../../store/createAccountStore';
import RSDrawer from '../layout/RSDrawer';
import RSUpgrade from '../misc/RSUpgrade';

export type RSVideoCardProps = {
	videoList: Array<any>,
	sx?: object,
	getVideoProgress?: Function,
	onboarding?: boolean,
	thmb_scale?: number
}

export type VideoProps = {
	id?: string | number,
	duration?: number,
	src?: string,
	headingTitle?: string,
	onClick?: Function,
	liked?: boolean,
	onLike?: Function,
	type?: any
	phase?: string
	program?: string
}

const PREMIUM_CONTENTS = ['BASE', 'YOGA', 'STRENGTH', 'PRIME', 'FUEL', 'GUIDE', 'PRIME', 'MECHANIX', 'RESET', 'MIDFOOT', 'TRAINING', 'KNEED']


const RSVideoCard: FC<RSVideoCardProps> = ({ videoList, sx = {}, getVideoProgress, onboarding, thmb_scale }) => {

	const [showProAd, setShowProAd] = useState(false)
	const { subscription } = useStore((state: ICurrentUser) => state)
	const isSubscribed = !subscription?.accessDetails?.type?.toUpperCase()?.includes('BASIC')
	const limit = subscription?.accessDetails?.access?.programs?.limit_videos || 0
	const getFitAccess = subscription?.accessDetails?.access?.programs?.get_fit
	const trainings = ['TRAINING', 'MIDFOOT', 'RESET']
	const renderBody = () => {

		return videoList?.map((v: VideoProps, i) => {

			const id = v?.id ? v.id : ''
			const isLiked = v?.liked
			const progress = getVideoProgress ? getVideoProgress(v.id) : 0
			const duration = v?.duration ? Math.round(v?.duration / 60) : ''
			const videoSrc = v?.src ? v?.src : (id ? `https://vumbnail.com/${id}.jpg` : '')

			const isPremiumContent = PREMIUM_CONTENTS?.some((x) => v?.src?.toUpperCase().includes(x) || v?.program?.toUpperCase().includes(x))
			let isLocked = false

			if (!isSubscribed && isPremiumContent) {
				if (v?.program && trainings.includes(v?.program?.toUpperCase())) {
					if (v?.program?.toLowerCase().includes("reset") && !getFitAccess) {
						isLocked = true
					} else if (v?.program?.toLowerCase().includes("midfoot") || v?.program?.toLowerCase().includes("training")) {
						isLocked = true
					}
				}
				else if (v?.phase && Number(v?.phase) > 1) {
					isLocked = true
				}
				else if (limit && limit <= i) {
					isLocked = true
				}
			}

			return isValidElement(v) ? v : <>
				{id && v?.onLike && !onboarding && <Box sx={{ position: 'absolute', top: 20, right: 8, zIndex: 1 }}>
					<Heart onLike={() => v?.onLike?.()} isLiked={isLiked} isNotAbsolute={true} isFromMetadata={true} />
				</Box>}

				{onboarding && <Box sx={{ position: 'absolute', top: 20, right: 8, zIndex: 1 }}>
					<i className="fa-regular fa-lock-keyhole" style={{ fontSize: '20px' }}></i>
				</Box>}

				{isLocked && <Grid container xs={12} sx={{ m: 1, position: 'absolute', left: 22, bottom: 6, zIndex: 1 }}>
					<img src='/images/icons/pro-icon.png' style={{ height: '25px', width: '25px' }} />
				</Grid>}

				<RSCardCont onClick={() => isLocked ? setShowProAd(true) : v?.onClick?.()} sx={{ pointerEvents: !onboarding ? 'auto' : 'none' }}>
					<Grid container xs={5.5} sx={{ position: 'relative', overflow: 'hidden', height: '100px', boxShadow: '0px 0px 3px #80808024' }} justifyContent='center' alignItems='center'>
						{videoSrc ? <img style={{ scale: `${thmb_scale ? thmb_scale : '2'}`, margin: 'auto' }} src={videoSrc} alt="" />
							: <Box className='loading-days toRight' />}

						{progress ? <Box sx={{ width: '100%', height: '5px', position: 'absolute', bottom: 0, backgroundColor: 'transparent', textTransform: 'capitalize !important' }}>
							<Box sx={{ width: `${progress}%`, height: '100%', backgroundColor: '#2b9fcd' }} />
						</Box> : ''}
					</Grid>
					<Grid container xs={6.5} sx={{ height: '100%' }}>
						<Grid container xs={12} sx={{ px: 2 }} justifyContent='space-between' alignItems='space-between'>
							<Grid container sx={{ flexDirection: 'column' }} justifyContent='flex-start' alignItems='flex-start'>
								<SH3 title>
									{v?.headingTitle}
								</SH3>
								{duration ? <P2 subtitle>
									{duration}min
								</P2> : (duration !== 0 ? <Box sx={{ position: 'relative', width: '80px', height: '20px' }}>
									<Box className='loading-days toRight' sx={{ border: 'none !important' }} />
								</Box> : '')
								}
							</Grid>
						</Grid>
					</Grid>
				</RSCardCont>
			</>
		})
	}

	return videoList?.length ? <>
		<RSFlexCard videoList={renderBody()} sx={sx} />

		<RSDrawer bottom popUpWidth={'500px'} open={showProAd} fitContent onClose={() => setShowProAd(false)}>
			{/* *TO PLACE PRO AD HERE* */}
			{/* to add user info */}
			<RSUpgrade userInfo={''} title={'Unlock More with RunSmart Pro'} description={'Access expert-designed strength, yoga, run form, and mobility programs—everything you need to run stronger, faster, and smarter, all inside the RunSmart app.'} />
		</RSDrawer>

	</> : <></>
}

export default RSVideoCard;
