import { useState, FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Input from '@mui/material/InputBase';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import RSHeaderCont from './RSHeaderCont'
import RSSideMenu from './RSSideMenu'
import RSTheme from '../styles/RSTheme'
import RSIcon from '../icons/RSIcon'
import {SH1, P1} from '../text/RSTypography'
import RSAnnouncement from '../custom-popover/RSAnnouncement'
import GoogleCastButton from '../buttons/GoogleCastButton'
import { HOME_LOGO } from '../../modules/miscImages'
import Loader from '../animation/Loader'
import { LoaderProps } from '../../modules/types'
import { signOut } from '../../modules/accountActions'
import { getColorCode } from '../../modules/miscUtils'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useStore from '../../store/useStore'
import useSale from '../../hooks/useSale'
import { IComponentStore } from '../../store/createComponentStore'
import { ICurrentUser } from '../../store/createAccountStore'

export type RSNavContProps = {
	planInactive?: any,
	children?: any,
	plain?: boolean,
	hideHeader?: boolean,
	hideFooter?: boolean,
	sx?: object,
	contSX?: object,
	contClass?: string,
	onClick?: any,
	backClick?: any,
	backClickDisabled?: boolean,
	loaderProps?: LoaderProps
	showLogOut?: boolean,
	additionalChildren?: any,
	disableHome?: boolean,
	addHeader?: number,
	displayCardNoWatch?: boolean,
	userInfo?: any
	centerHeader?: boolean
}

export type MenuItemProps = {
	name?: string,
	link?: string,
	subLinks?: Array<string>,
	logo: Function
}


const RSNavCont: FC<RSNavContProps> = ({ planInactive, children, plain, hideHeader, contSX = {}, sx = {}, loaderProps = {}, onClick, hideFooter, showLogOut, disableHome, addHeader = 0, contClass = '', displayCardNoWatch = false, userInfo, additionalChildren, centerHeader, backClick, backClickDisabled }) => {
	const [open, setOpen] = useState(false)
	const location = useLocation()
	const navigate = useNavigate()
	const isMobile = useIsMobileScreen();
	const { isMobile: showCast, showAnnouncementUpdates, setShowAnnouncementUpdates, toast, setToast } = useStore((state: IComponentStore) => state)
	const { currentUser, subscription } = useStore((state: ICurrentUser) => state)
	const menuItems = [
		{
			name: 'Training',
			link: '/',
			logo: (isActive?: boolean, bottom?: boolean) => <RSIcon type={`trainingLogo${isActive ? '-active' : ''}`} height='24px' width='auto' className={bottom ? '' : 'header-icons'} />
		},
		{
			name: 'Explore',
			link: '/explore',
			logo: (isActive?: boolean, bottom?: boolean) => <RSIcon type={`menu${isActive ? '-active' : ''}`} className={bottom ? 'footer-icons' : 'header-icons'} />
		},
		{
			name: 'Profile',
			link: '/profile',
			logo: (isActive?: boolean, bottom?: boolean) => <RSIcon type={`profile${isActive ? '-active' : ''}`} className={bottom ? 'footer-icons' : 'header-icons'} />
		}
	]

	const header = hideHeader ? 0 : 1
	const footer = hideFooter ? 0 : 1
	const constStyles = {
		width: '100%',
		height: '100%',
		minHeight: window.innerHeight - ((footer + header + addHeader) * 60),
		overflowX: 'hidden',
		...contSX
	}

	const loaderPropsDefault: LoaderProps = {
		isBehindHeader: true,
		...loaderProps
	}
	const { activeSale, isLoadingSale, saleEnd } = useSale()
	const showAnnouncement = subscription?.accessDetails?.type === "basic" && activeSale?.active && saleEnd && !isLoadingSale

	const renderFooter = () => {
		return isMobile && !hideFooter ? <RSHeaderCont bottom barSX={{ px: '10px' }} sx={{ zIndex: 1002 }}>
			<Grid container xs={12} sx={{ height: '100%' }} justifyContent='space-between' alignItems='center'>
				{menuItems.map((item: MenuItemProps, i: number) => {
					const linkList = item.subLinks ? [...item.subLinks, item.link] : [item.link]
					const isActive = item.link ?
						(i ? linkList?.find((ll: string | undefined) => ll && location?.pathname?.includes(ll))
							: location?.pathname === item.link)
						: false
					return <Grid container xs={12 / menuItems.length} className='pointer'
						key={'menu-' + i}
						onClick={onClick ? onClick : () => item.link && navigate(item.link, { replace: true })}
						sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}
					>
						<Box sx={{ position: 'absolute', top: '0', width: '100%', height: '3px', backgroundColor: isActive ? '#9fcd2b' : 'white' }} />
						<Box sx={{ height: '37px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.logo(isActive, true)}</Box>
						<Box sx={{ fontSize: '12px', fontFamily: 'Poppins-Medium' }}>{item.name}</Box>
					</Grid>
				})}
			</Grid>
		</RSHeaderCont> : <></>
	}

	const renderHeader = () => {
		return !hideHeader ? <RSHeaderCont childrenSX={{height: '100%'}} sx={{ zIndex: 1002 }} displayCardNoWatch={displayCardNoWatch} userInfo={userInfo} backClick={backClick} backClickDisabled={backClickDisabled} centerChild={centerHeader}>
			<Grid container xs={12} sx={{ height: '100%' }} justifyContent='space-between' alignItems='center'>
				<Box className={disableHome ? '' : 'pointer'} sx={{ display: 'flex' }}>
					<img onClick={() => !disableHome && navigate('/', { replace: true })} className='header-logo' src={HOME_LOGO} alt="RunSmart Home Logo" />
					{!isMobile && false && <Box sx={{ borderRadius: '5px', backgroundColor: 'rgba(159,205,43,0.2)', ml: '30px', width: '250px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<i style={{ padding: '0px 10px' }} className="fa-regular fa-magnifying-glass"></i>
						<Input placeholder="Search.." />
					</Box>}
				</Box>
				<Box sx={{ height: '100%', display: 'flex' }}>
					{
						!plain && !planInactive && <>
							{
								!isMobile && menuItems.map((item: MenuItemProps, i: number) => {
									const linkList = item.subLinks ? [...item.subLinks, item.link] : [item.link]
									const isActive = item.link ?
										(i ? linkList?.find((ll: string | undefined) => ll && location?.pathname?.includes(ll))
											: location?.pathname === item.link)
										: false
									return <Box className='pointer'
										key={'menu-' + i}
										onClick={onClick ? onClick : () => item.link && navigate(item.link, { replace: true })}
										sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 1, mx: 1 }}
									>
										<Box>{item.logo(isActive)}</Box>
										{item.name && <Box sx={{ fontSize: '14px' }}>{item.name}</Box>}
										{item.name && <Box sx={{ position: 'absolute', bottom: '0', width: '100%', height: '3px', backgroundColor: isActive ? '#9fcd2b' : 'white' }} />}
									</Box>
								})
							}
							{showCast && isMobile && <Grid container sx={{ px: '20px', mt: '-3px' }} justifyContent='center' alignItems='center'>
								<GoogleCastButton vimeoId={location.pathname.split('/')[3]} />
							</Grid>}
							{/* <Grid container className='pointer' onClick={() => setOpen(true)} sx={{ pl: isMobile ? 0 : '20px' }} justifyContent='center' alignItems='center'>
								<i style={{ fontSize: '22px' }} className="fa-light fa-bars"></i>
							</Grid> */}
						</>
					}

					{(showLogOut || additionalChildren) && <Box sx={{ height: '100%', display: 'flex', justifyContent: 'flex-end' }}>
						{additionalChildren}
						{showLogOut && <Box className='pointer' onClick={signOut}
							sx={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: '5px' }}
						>
							<Box><i style={{ fontSize: '22px' }} className="fa-regular fa-arrow-right-from-bracket"></i></Box>
							{!isMobile && <Box sx={{ fontSize: '0.8em' }}>LogOut</Box>}
						</Box>}
					</Box>}
				</Box>
			</Grid>
		</RSHeaderCont> : <></>
	}

	const handleSnackClose = () => {
		toast?.onClose?.()
		setToast({show: false})
	}

	const isToastSuccess = toast?.variant === 'success' ? 'success' : undefined
	const isToastDanger = toast?.variant === 'danger' ? 'error' : undefined

	return <RSTheme>
		<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', ...sx }} justifyContent='flex-start' alignItems='center'>
			{loaderPropsDefault.active && <Box sx={{ position: 'absolute', height: '100%', top: 0, width: '100%' }}>
				<Loader {...loaderPropsDefault} />
			</Box>}
			{renderHeader()}
			<Box className={`default-mw ${contClass}`} sx={constStyles}>
				{children}
			</Box>
			{renderFooter()}
			<RSSideMenu open={open} onClose={() => setOpen(false)} />
			{showAnnouncement && <RSAnnouncement userInfo={userInfo} saleEnd={saleEnd} isSaleAnnouncement showAnnouncementUpdates={location?.pathname?.includes('signup') || location?.pathname?.includes('renew') || !currentUser?.account_id ? false : showAnnouncementUpdates} setShowAnnouncementUpdates={setShowAnnouncementUpdates} />}

			<Snackbar onClose={handleSnackClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={toast?.show} autoHideDuration={toast?.duration || 5000}>
				<Alert onClose={handleSnackClose}
					className={`${isToastSuccess ? 'alert-success-bg' : (isToastDanger ? 'alert-danger-bg' : '')}`}
					severity={isToastSuccess || isToastDanger || 'info' }
					sx={{...!isToastSuccess && !isToastDanger && {backgroundColor: `${getColorCode('BASE')} !important`}}}>
					{toast?.title && <SH1 card size={1} className='black-text' sx={{width: '100%'}}>{toast?.title}</SH1>}
					{toast?.message && <P1 card className='black-text' sx={{width: '100%'}}>{toast?.message}</P1>}
				</Alert>
			</Snackbar>
		</Box>
	</RSTheme>
}

export default RSNavCont;
