/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@mui/material"
import { useRecurly } from "@recurly/react-recurly";
import { useEffect, useState } from "react";
import { RSButton1 } from "../../components/buttons";
import RSDrawer from "../../components/layout/RSDrawer";
import { SH2, SH3, P1, P2, P3, A, H1 } from "../../components/text/RSTypography"
import useAlert from "../../hooks/useAlert";
import useDeviceDetect from "../../hooks/useDeviceDetect";
import { createSubscription, getPlanCosts, getSubscriptionInfo, updateBillingInfo } from "../../modules/accountActions";
import { CreateShareTransaction } from "../../modules/shareActions";
import { DEFAULT_COSTS } from "../../modules/values";
import { IComponentStore } from "../../store/createComponentStore";
import useStore from "../../store/useStore";
import PaymentDetails from "./checkout/PaymentDetails";
import ReactPixel from 'react-facebook-pixel';
import useSale from "../../hooks/useSale";
import useQueryParams from "../../hooks/useQueryParams";
import useRNBridge from "../../hooks/useRNBridge";
import RSHeaderCont from "../../components/layout/RSHeaderCont";
import useIsMobileScreen from "../../hooks/useIsMobileScreen";
import ApplePayButton from "../../components/buttons/ApplePayButton";
import GooglePayButton from "../../components/buttons/GooglePayButton";
import moment from "moment";
import { ICurrentUser } from "../../store/createAccountStore";
import useAppEvents from "../../hooks/useAppEvents";
import RunSmartPro from "./RunSmartPro";


const UpgradePlan = (props: any) => {
    const { userInfo, openPaymentDetails, setOpenPaymentDetails, setOpenUpgradePro, openUpgradePro, isUpdatePaymentInfo, userSubscriptionBasic } = props
    const [openChoosePlan, setOpenChoosePlan] = useState(false)
    // const [openPaymentDetails, setOpenPaymentDetails] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState('yearly')
    const [isValidCard, setIsValidCard] = useState(false)
    const [costs, setCosts] = useState({ ...DEFAULT_COSTS })
    const [monthly, setMonthly] = useState(false)
    const [isNativeMobile, setIsNativeMobile] = useState(false)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [postalCode, setPostalCode] = useState("")

    const [hasSale, setHasSale] = useState(false)

    const { requestKey } = useQueryParams()

    const localStorageRequestKey = localStorage.getItem('deviceRequestKey') || requestKey || undefined
    const { mobileType } = useDeviceDetect()

    const { activeSale, isLoadingSale } = useSale()
    const [googlePayIsReady, setGooglePayIsReady] = useState(false)

    const [loadingGooglePay, setLoadingGooglePay] = useState(false)
    const [isApplePaySupport, setIsApplePaySupport] = useState(false)
    const { sendDataToReactNative } = useRNBridge()

    const [openUpgradeComplete, setOpenUpgradeComplete] = useState(false)

    const isMobile = useIsMobileScreen()

    const { createEvent } = useAppEvents()

    const openUrlIOS = localStorageRequestKey && mobileType.toLowerCase() === "ios"
    const termsLink = 'https://runsmartonline.com/terms-of-use/'
    const privacyLink = 'https://runsmartonline.com/privacy-policy/'
    const waiverLink = 'https://runsmartonline.com/waiver-of-liability/'

    const { setIsLoading, setIsSuccess, isLoading, lastRNBridgeMessage, setLastRNBridgeMessage } = useStore((state: IComponentStore) => state)
    const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();
    const { pastDueBalance } = useStore((state: ICurrentUser) => state)

    const {
        setAlertVariant,
        setAlertMessage,
        onAlertOpen
    } = useAlert()

    const recurly = useRecurly()


    const { subscription } = useStore((state: ICurrentUser) => state)
    const isUserBasic = subscription?.accessDetails?.type === "basic" ? true : false



    const gtagTrack = () => {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-10847885916/DW1UCO6PkssZENy01rQo',
            'value': monthly ? 67 : 147,
            'currency': 'USD'
        });
    }

    const createShareTransaction = async (subscriptionId: string) => {
        const referralCode = localStorage.getItem('referral_code')
        if (referralCode && userInfo?.account_id && subscriptionId) {
            await CreateShareTransaction(userInfo?.account_id, referralCode, subscriptionId, "pro", 1)
        }
    }

    // After Billing Change
    const afterBillingChange = async () => {
        const signupMethod = (isNativeMobile && mobileType === "Ios") ? 'Ios' : (isNativeMobile && mobileType === "Android") ? 'Android' : 'Web'
        // ReactPixel.trackCustom('AddPaymentInfo', { k: new Date().getTime() });
        const planCode = monthly ? costs.monthlyProCode : hasSale ? costs.annualFlashCode : costs.annualProCode
        await createSubscription(planCode, signupMethod)
    }

    const afterBillingProcess = async () => {
        // ReactPixel.trackCustom('AddPaymentInfo', null);
        ReactPixel.trackCustom('ProPurchase', { k: new Date().getTime() });
        const eventMetadata = {
            plan_name: monthly ? costs.monthlyProCode : hasSale ? (isNativeMobile ? "annual_99" : costs.annualFlashCode) : costs.annualProCode,
            amount: monthly ? costs.monthlyPro : hasSale ? costs.annualFlash : costs.annualPro,
        }
        userInfo?.account_id ? await createEvent(userInfo?.account_id as number, "UPGRADE_TO_PRO", JSON.stringify(eventMetadata)) : undefined
        setOpenUpgradePro(false)
        setOpenPaymentDetails(false)
        setOpenUpgradeComplete(true)
        //window.location.reload()
    }

    // On Change
    const onChange = (event: any) => {
        if (event.valid != null) {
            setIsValidCard(event.valid)
        }
    }

    // In app purchase

    const handleInitPurchaseSubscription = async () => {

        if (localStorageRequestKey && userInfo?.account_id) {
            sendDataToReactNative({
                requestType: 'google-choose-plan-webapp',
                requestKey: requestKey || localStorageRequestKey,
                payload: monthly ?
                    { amount: costs.monthlyPro, planCode: costs.monthlyProCode, type: 'trial', account_id: userInfo.account_id, isSale: hasSale }
                    : { amount: hasSale ? costs.annualFlash : costs.annualPro, planCode: hasSale ? "annual_99" : costs.annualProCode, type: 'trial', account_id: userInfo.account_id, isSale: hasSale },
                timestamp: new Date().getTime()
            })
        }
    }

    // On Submit
    const onSubmit = () => {
        // ReactPixel.trackCustom('InitiateCheckout', { k: new Date().getTime() });
        setIsLoading(true)
        const errorMsg = () => {
            setAlertVariant('danger')
            setAlertMessage(
                `Could not create subscription.`
            )
            onAlertOpen()
            return false
        }
        try {
            const form = document.querySelector('#billing-form') as HTMLFormElement
            recurly.token(form, async (err, token) => {
                if (err) {
                    setIsLoading(false)
                    return errorMsg()
                } else {
                    const billingResult = await updateBillingInfo(token.id)
                    if (billingResult?.data?.id) {
                        if (isUpdatePaymentInfo) {
                            setIsLoading(false)
                            setIsSuccess(true)
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        }
                        else {
                            // After Billing Update
                            await afterBillingChange()
                            const { isActive, subscription } = await getSubscriptionInfo()
                            if (isActive && subscription?.id) {
                                setIsLoading(false)
                                setIsSuccess(true)
                                setTimeout(() => {
                                    setIsSuccess(false)
                                    setTimeout(async () => {
                                        gtagTrack()
                                        // await createShareTransaction(subscription.id)
                                        await afterBillingProcess()
                                    }, 500)
                                }, 3000)


                                return true
                            }
                        }
                    }
                    setIsLoading(false)
                    return errorMsg()
                }
            })
        } catch (err) {
            setIsLoading(false)
            return errorMsg()
        }
    }

    const handleSaveExternalSubscription = async (data: any) => {
        setIsLoading(true)
        setTimeout(async () => {
            setIsLoading(false)
            gtagTrack()
            await afterBillingProcess()
        }, 5000)
    }

    const getData = async () => {
        const newCosts = await getPlanCosts({
            hasOffer: false,
            isRenew: false,
            isYearlyRenewOnly: false,
            hasSale: hasSale,
        })

        setCosts(newCosts)
    }

    const handleOpenUrl = (url: string) => {
        if (localStorageRequestKey && mobileType.toLowerCase() === "ios") {
            const dataToSend = {
                requestType: 'open-rs-shop',
                payload: { url: url },
                timestamp: new Date().getTime(),
            }
            sendDataToReactNative(dataToSend);
        } else
            window.open(url, '_blank')
    }
    

    useEffect(() => {
        console.log('///////////////////////////')
        console.log(activeSale)
        console.log(localStorageRequestKey)
        console.log(isUserBasic)
        console.log(userSubscriptionBasic)
        console.log('///////////////////////////')
        if (activeSale && !localStorageRequestKey || activeSale && localStorageRequestKey) {
            if (activeSale.active == 1 && (isUserBasic || userSubscriptionBasic))
                setHasSale(true)

            else if (activeSale.active == 0)
                setHasSale(false)
        }
        else
            setHasSale(false)
    }, [activeSale, localStorageRequestKey, mobileType, userSubscriptionBasic])

    useEffect(() => {
        setIsNativeMobile((requestKey || localStorage.getItem("deviceRequestKey")) ? true : false)
    }, [localStorageRequestKey, requestKey])

    useEffect(() => {
        // handleGetExternalAccount()
        const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
        if (lastRNBridgeMessage && userInfo) {
            const RNBridgeData = JSON.parse(lastRNBridgeMessage)

            if (RNBridgeData.requestKey === requestKey || RNBridgeData.requestKey === localStorageRequestKey) {
                setMessageData(RNBridgeData)
                setLastRNBridgeMessage(undefined)
            }
        }

    }, [lastRNBridgeMessage, userInfo]);

    useEffect(() => {
        // alert(JSON.stringify(messageData))
        if (messageData) {

            const localStorageRequestKey = localStorage.getItem('deviceRequestKey')

            const handleSucessSub = (messageData.requestKey === requestKey || messageData.requestKey === localStorageRequestKey) && (messageData.requestType === "google-success-sub-mobileapp") ? true : false

            const handleSucessSubIos = (messageData.requestKey === requestKey || messageData.requestKey === localStorageRequestKey) && (messageData.requestType === "apple-success-sub-mobileapp") ? true : false

            // alert(handleSucessSub)
            // alert(handleSucessSubIos)

            if (userInfo?.account_id && handleSucessSub) {
                const externalData = { user_id: userInfo?.account_id, type: 'google', purchase_token: messageData.payload, is_expired: 0, app_account_token: requestKey || localStorageRequestKey }
                handleSaveExternalSubscription(externalData)
            }
            else if (userInfo?.account_id && handleSucessSubIos) {
                const externalData = { user_id: userInfo?.account_id, type: 'apple', purchase_token: messageData.payload, is_expired: 0, app_account_token: requestKey || localStorageRequestKey }
                handleSaveExternalSubscription(externalData)
            }
        }

    }, [messageData]);

    useEffect(() => {
        if (userInfo?.account_id) {
            getData()
        }
    }, [userInfo])

    const renderOtherPaymentMethods = () => {
        const supportedPaymentMethods = isApplePaySupport || googlePayIsReady
        return <>
            {supportedPaymentMethods ? <P2 sx={{ my: 0 }} card>{isUpdatePaymentInfo ? "Update billing with" : `Choose a payment method`}:</P2> : undefined}
            <Box sx={{ width: '100%', my: 1, display: 'flex', flexDirection: 'row', }}>
                <ApplePayButton
                    costs={monthly ? costs.monthlyPro : (hasSale && !monthly) ? costs.annualFlash : costs.annualPro}
                    planCode={monthly ? costs.monthlyProCode : hasSale ? costs.annualFlashCode : costs.annualProCode}
                    afterBillingChange={afterBillingChange}
                    afterBillingProcess={afterBillingProcess}
                    tranxType={isUpdatePaymentInfo ? "update" : "create-signup"}
                    setIsApplePaySupport={setIsApplePaySupport}
                />

                <GooglePayButton
                    costs={monthly ? costs.monthlyPro : (hasSale && !monthly) ? costs.annualFlash : costs.annualPro}
                    planCode={monthly ? costs.monthlyProCode : hasSale ? costs.annualFlashCode : costs.annualProCode}
                    afterBillingChange={afterBillingChange}
                    afterBillingProcess={afterBillingProcess}
                    tranxType={isUpdatePaymentInfo ? "update" : "create-signup"}
                    setLoadingGooglePay={setLoadingGooglePay}
                    loadingGooglePay={loadingGooglePay}
                    buttonStyle={{
                        buttonRadius: 5,
                        buttonColor: 'white',
                    }}
                    // className="checkout-google-pay"
                    setGooglePayIsReady={setGooglePayIsReady}
                />

            </Box>
        </>
    }

    const renderPaymentDetails = () => {
        return <Box sx={{ height: '100%' }}>
            <RSHeaderCont noBanner isStatic={!isMobile} backClick={isMobile ? () => setOpenPaymentDetails(false) : undefined} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title={isUpdatePaymentInfo ? 'Update Billing' : "Upgrade to RunSmart Pro"} />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "start", width: "100%", height: 'calc(100% - 60px)' }}>
                <Box>
                    {!isUpdatePaymentInfo ? renderChoosePlan() : undefined}
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', px: 3 }}>
                        {!isUpdatePaymentInfo ? <P3 card size={3} sx={{ mt: isUpdatePaymentInfo ? 4 : 0.5, mb: 2, width: "100%", textAlign: "left", }}>Total Due Today: ${hasSale && !monthly ? costs.annualFlash : !hasSale && !monthly ? costs.annualPro : costs.monthlyPro} USD</P3> : <P3 card size={3} sx={{ mt: isUpdatePaymentInfo ? 4 : 0.5, mb: 2, width: "100%", textAlign: "left", }}>Total Due Today:${pastDueBalance} USD</P3>}

                        {!localStorageRequestKey ? renderOtherPaymentMethods() : undefined}
                        {!localStorageRequestKey ? (
                            <>                <P3 size={0.9} sx={{ my: 0.7 }} card>Credit Card</P3>
                                <PaymentDetails
                                    firstName={firstName}
                                    lastName={lastName}
                                    postalCode={postalCode}
                                    setFirstName={(e) => setFirstName(e.target.value)}
                                    setLastName={(e) => setLastName(e.target.value)}
                                    setPostalCode={(e) => setPostalCode(e.target.value)}
                                    onChangeCard={onChange}
                                />
                            </>
                        ) : undefined}

                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', mt: localStorageRequestKey ? 2 : 3 }}>
                            <RSButton1 disabled={!localStorageRequestKey && !isValidCard} onClick={localStorageRequestKey ? handleInitPurchaseSubscription : onSubmit} impose sx={{ width: "100%" }}>
                                {isUpdatePaymentInfo ? `Update Billing` : "Upgrade to Pro"}
                            </RSButton1>
                        </Box>

                        {isUpdatePaymentInfo ? <P2 card size={0.5} sx={{ mt: localStorageRequestKey ? 4 : 3 }}>
                            {`Your account will be charged $${pastDueBalance.toFixed(2)} USD. Cancel your plan any time from your profile page.`}
                        </P2> : <P2 card size={0.5} sx={{ mt: localStorageRequestKey ? 4 : 3 }}>
                            {`Your account will be charged every ${monthly ? 'month' : '12 months'} from ${moment().format("MMMM DD, YYYY")}. Cancel your plan any time from your profile page.`}
                        </P2>}

                    </Box>
                </Box>

                <Box sx={{ display: 'flex', pb: 2, flexDirection: 'column', justifyContent: 'center', my: 3, alignItems: 'center', textAlign: 'center', px: 2, }}>
                    <P2 card size={0.5} sx={{ mt: 1 }}>
                        Read our {' '}
                        <A link={termsLink} onClick={openUrlIOS ? () => handleOpenUrl(termsLink) : undefined} newWindow p1 underline>
                            Terms of Service
                        </A>
                        ,{' '}
                        <A link={privacyLink} onClick={openUrlIOS ? () => handleOpenUrl(privacyLink) : undefined} newWindow p1 underline>
                            Privacy Policy
                        </A>
                        ,{' '}
                        <A link={waiverLink} onClick={openUrlIOS ? () => handleOpenUrl(waiverLink) : undefined} newWindow p1 underline>
                            Waiver of Liability
                        </A>
                    </P2>
                </Box>
            </Box>
        </Box>
    }


    const renderChoosePlan = () => {
        return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', mt: localStorageRequestKey ? 3 : 2, px: 3 }}>
            <P3 card size={2}>Choose a plan to get started.</P3>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "start", justifyContent: 'star', mt: 1 }}>
                <div onClick={() => setMonthly(false)} style={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: !monthly ? '#fcc26a' : 'white', borderRadius: 2, px: 3, py: 2, mr: 3 }}>

                        {hasSale && <Box sx={{ position: 'relative' }}>
                            <Box className='pink-bg' sx={{ position: 'absolute', top: -6, right: -9, p: '2px 3px', borderRadius: '5px' }}>
                                <P1 size={-2} className='white-text'>
                                    SALE
                                </P1>
                            </Box>
                        </Box>}
                        <P2 card size={1}>Yearly</P2>
                        <SH3 card size={2}>${hasSale ? costs.annualFlash : costs.annualPro}/yr USD</SH3>
                        <P3 size={-1} >{`($${Number((hasSale ? costs.annualFlash : costs.annualPro) / 12).toFixed(2)}/mo)`}</P3>
                    </Box>
                </div>
                <div onClick={() => setMonthly(true)} style={{ cursor: 'pointer' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: monthly ? '#fcc26a' : 'white', borderRadius: 2, px: 3, py: 3 }}>
                        <P2 card size={1} >Monthly</P2>
                        <SH3 card size={2} >${costs.monthlyPro}/mo USD</SH3>
                    </Box>
                </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 3, alignItems: 'center', textAlign: 'center', px: 3 }}>
            </Box>
        </Box >
    }

    const renderUpgradeComplete = () => {
        return <Box className='background-bottom' sx={{ height: '100%', backgroundImage: 'url(/img-new/misc/pro_upgrade_complete.jpg)' }}>
            <RSHeaderCont noBanner isStatic={!isMobile} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title='Upgrade to RunSmart Pro' />
            <Box sx={{ px: 3, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'center', width: "100%", pt: 5, pb: '100px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) calc(100% - 80px), transparent calc(100% - 40px))' }}>

                <Box>
                    <img src='/images/icons/pro-icon.png' style={{ height: '60px', width: '60px' }} />
                </Box>
                <SH2 size={5} sx={{ pt: 2 }}>{`Boom! You're Pro Now.`}</SH2>
                <P1 card sx={{ px: 1, textAlign: 'center', pt: 2 }}>You just leveled up your training-and joined
                    thousands of runners training smarter, running
                    stronger, and getting results with RunSmart.
                </P1>
                <RSButton1 sx={{ mt: 3 }} onClick={() => window.location.reload()}>
                    Finish
                </RSButton1>
            </Box>
        </Box>
    }


    const renderSaleNotice = () => {

        const headline = activeSale?.headline
        const subtext = activeSale?.subtext
        const banner = activeSale?.banner
        const saleEnd = activeSale?.end

        return <>
            <Box sx={{
                px: 3, py: 3, display: 'flex', flexDirection: 'column'
            }}>
                <SH3 card size={5}>{banner}</SH3>
                <H1 card size={5}>{headline}</H1>
                <P1 >{subtext}</P1>
                <RSButton1 onClick={() => {
                    setOpenUpgradePro(false)
                    setOpenPaymentDetails(true)
                }
                } impose>
                    Upgrade to Pro
                </RSButton1>
                <P1>{saleEnd}</P1>

            </Box>
        </>
    }

    return (
        <>
            <RSDrawer bottom={!isMobile} popUp={!isMobile} fitContent={isMobile ? false : true} popUpWidth={'500px'} popUpHeight={!isMobile ? '80%' : 'auto'} open={openUpgradePro} onClose={() => setOpenUpgradePro(false)}>
                <RunSmartPro setOpen={setOpenUpgradePro} userInfo={userInfo} />
            </RSDrawer >

            <RSDrawer bottom fitContent popUpWidth={"700px"} open={openChoosePlan} onClose={() => setOpenChoosePlan(false)}>
                {renderChoosePlan()}
            </RSDrawer>

            <RSDrawer fitContent popUpWidth={"auto"} bottom={!isMobile} open={openPaymentDetails} onClose={() => {
                setOpenPaymentDetails(false)
            }}>
                {renderPaymentDetails()}
            </RSDrawer>

            <RSDrawer popUpWidth={"auto"} popUpHeight={"800px"} bottom={!isMobile} open={openUpgradeComplete} onClose={() => {
                setOpenUpgradeComplete(false)
            }}>
                {renderUpgradeComplete()}
            </RSDrawer>
        </>
    )
}

export default UpgradePlan
