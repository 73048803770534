import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { IRaceType } from '../training-plan/build/RaceType'
import { RaceOptions } from '../training-plan/build/RaceOptions'
import raceSelection from '../training-plan/build/RaceSelection.json'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore'
import useStore from '../../store/useStore'
import { ICurrentUser } from '../../store/createAccountStore'
import GenericModal from '../../components/misc/GenericModal'
import { guideText } from '../../modules/values'
import moment from 'moment'
import { navReq } from '../../modules/apiConsume'
import RSFlexCard from '../../components/cards/RSFlexCard';
import RSChurrosButton from '../../components/buttons/RSChurrosButton';
import RSDrawer from '../../components/layout/RSDrawer';
import { H1, H2 } from '../../components/text/RSTypography'
import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import useQueryParams from '../../hooks/useQueryParams';
import useSWR from 'swr';
import { IBuildPlanAvailability } from '../../api/v2/training-plan/training-plan.types';
import RaceBuild from '../race/RaceBuild';
import RaceSearch from '../search/RaceSearch';
import StepRecover from '../training-plan/build/StepRecover';
import useAppEvents from '../../hooks/useAppEvents';


const CONFIRM_BUTTONS = ['Yes, continue', 'No, cancel']

export type RunningTabProps = {
    openTrain?: boolean
    focusTrain?: boolean
    bodyData?: any,
    setHideButtons?: any
    onboarding?: boolean,
    setIsLoading?: any
}

const RunningTab: FC<RunningTabProps> = ({ openTrain, focusTrain, bodyData, setHideButtons, onboarding, setIsLoading }) => {

    const navigate = useNavigate()
    const isMobile = useIsMobileScreen()
    const { race_type, end } = useQueryParams()
    const { createEvent } = useAppEvents()

    const { setSelectedRace, selectedRace, agreedCreateNewPlan, setAgreedCreateNewPlan, currentPath, setCurrentPath, hasActivePlan, setHasActivePlan, setSelectedRaceDate } = useStore((state: ITrainingPlanStore) => state)
    const { currentUser } = useStore((state: ICurrentUser) => state)
    const [loadingCheck, setLoadingCheck] = useState(true)
    const [showTrainForRaceModal, setShowTrainForRaceModal] = useState(false)
    //const [showRaceSearchModal, setShowRaceSearchModal] = useState(false)
    const [selectedRaceName, setSelectedRaceName] = useState<any>(undefined)
    const [planBuilds, setPlanBuild] = useState<Array<IBuildPlanAvailability>>([])

    const [openNotAvailable, setOpenNotAvailable] = useState(false)
    const [openMaintenance, setOpenMaintenance] = useState(false)
    const [maintenanceDetails, setMaintenanceDetails] = useState<any>(undefined)

    const [showRecoverTraining, setShowRecoverTraining] = useState(false)

    const { data } = useSWR('/build-plan-availability', { refreshInterval: 0, revalidateOnFocus: true })
    const { data: accountProfile, isLoading: accountProfileLoading } = useSWR(currentUser?.account_id ? `/account-profile?account_id=${currentUser?.account_id}` : null, { refreshInterval: 0 })


    function handleClick(selectedRaceOption: IRaceType) {
        if (!loadingCheck || currentPath === "/training-plan/build/step-2") {
            setSelectedRace(selectedRaceOption)
            if (selectedRaceOption?.value === 4)
                setOpenMaintenance(true)
            else
                onboarding ? setShowRecoverTraining(true) : navigate(selectedRaceOption?.value === 7 ? '/training-plan/build/step-recover' : '/training-plan/build/step-2', { replace: true })
        }
    }

    const handleCancelButton = () => {
        setAgreedCreateNewPlan(false)
        navigate('/', { replace: true })
    }

    const isPlanBuildAvailable = (raceType: string) => {
        return planBuilds.filter(x => x.race_type === raceType && x.status === 1).length > 0
    }

    const handleContinueButton = () => {
        setAgreedCreateNewPlan(true)
        setHasActivePlan(false)
    }

    useEffect(() => {
        if (selectedRaceName !== undefined) {
            setHideButtons?.(true)
            setShowTrainForRaceModal(false)
        } else
            setHideButtons?.(false)
    }, [selectedRaceName])

    useEffect(() => {
        if (race_type === '4' && end) {
            setOpenMaintenance(true)
        }
    }, [race_type, end])

    useEffect(() => {
        setLoadingCheck(false)
        setHasActivePlan(false)

    }, [hasActivePlan, currentUser, agreedCreateNewPlan]);

    useEffect(() => {
        openTrain && setShowTrainForRaceModal(true)
    }, [openTrain]);

    useEffect(() => {
        return () => {
            setAgreedCreateNewPlan(false);
            setHasActivePlan(false)
            setCurrentPath('')
        };
    }, []);

    useEffect(() => {
        setSelectedRaceDate(moment(new Date()).format('MM/DD/YYYY'))
    }, []);

    useEffect(() => {
        if (data && data.data.result.length > 0)
            setPlanBuild(data.data.result)
    }, [data])

    useEffect(() => {
        setMaintenanceDetails({
            "name": "Maintenance Plan",
            "date": moment(new Date()).format('MM/DD/YYYY'),
            "dateEnd": moment(new Date()).format('MM/DD/YYYY'),
            "place": "",
            "type": ["MAINTENANCE"],
            "desc": "",
            "homepage": "",
            "logo": "",
            "raceTitle": "Maintenance",
            "selectedRaceDistance": "MAINTENANCE"
        })
    }, [])

    const renderRaceSelection = () => raceSelection.filter(x => x.type == "explore-page").map((rs: any, rsI: number) =>

        <RSChurrosButton
            key={rsI}
            symbol={rs.logo}
            title={rs.subtitle}
            subtitle={rs.details}
            distance={rs.distance}
            dipColor={rs.color}
            disabled={accountProfileLoading}
            onClick={() => {
                if (currentUser?.account_id)
                    createEvent(currentUser?.account_id, "BROWSED_RACES")
                rs.id == 1 ? navReq('/#/explore?p_id=1') : rs.id == 8 ? setShowTrainForRaceModal(true) : handleClick(RaceOptions.find(x => x.value == rs.raceValue) as IRaceType)
            }}
        />
    )

    const renderDistanceSelection = () => raceSelection.filter(x => x.type === "selection").map((rs: any, rsI: number) =>
        <Box key={rsI} sx={{ my: bodyData && !isMobile ? 0 : 2 }}>
            <RSChurrosButton
                key={rsI}
                disabled={rs.disable_explore}
                symbol={rs.logo}
                title={rs.subtitle}
                dipColor={rs.color}
                onClick={() => {
                    setSelectedRace({ ...rs, subTitle: rs.subtitle })
                    const canBuild = isPlanBuildAvailable(rs.title.toLowerCase())
                    if (canBuild) {
                        bodyData ? setSelectedRaceName(rs.title) : navigate(`/search-race?raceName=${rs.title}`, { replace: true })
                    }
                    else {
                        setOpenNotAvailable(true)
                    }
                }}
            />
        </Box>
    )

    const renderTrainForARace = () => {

        return (
            <>
                <H1 size={5} sx={{ mb: 2, textAlign: 'left' }} >Train For a Race</H1>
                {raceSelection.filter(x => x.type === "search").map((rs: any, rsI: number) => (
                    <RSChurrosButton
                        key={rsI}
                        symbol={rs.logo}
                        title={rs.subtitle}
                        subtitle={rs.details}
                        distance={rs.distance}
                        dipColor={rs.color}
                        onClick={() => bodyData ? setSelectedRaceName('') : navigate('/search-race', { replace: true })}
                    />
                ))}
                <H2 size={1} sx={{ mt: 3 }} >Choose a Race Distance</H2>
                {bodyData && !isMobile ? <RSFlexCard videoList={renderDistanceSelection()} sx={{ pt: 0, my: 0, overflow: 'hidden' }} /> : renderDistanceSelection()}
            </>
        )
    }


    // Render
    return (<>
        <GenericModal
            show={hasActivePlan}
            title="Are you sure?"
            text={guideText.CreateNewWarning}
            setShow={setHasActivePlan}
            keyboard={false}
            backdrop="static"
            button1Action={handleContinueButton}
            button1Text={CONFIRM_BUTTONS[0]}
            button2Action={handleCancelButton}
            button2Text={CONFIRM_BUTTONS[1]}
        />

        {showRecoverTraining ? <StepRecover onboarding={true} />

            :

            selectedRaceName === undefined && (focusTrain ? <Box sx={{ px: 3, pt: bodyData ? 0 : 2, pb: bodyData ? 0 : 3 }}>

                {renderTrainForARace()}
            </Box>
                :
                <Box sx={{ pb: 2, px: 3 }}>
                    {onboarding && <Grid>
                        <H1 size={5} sx={{ mb: 2, textAlign: 'left' }} >Start a Running Routine</H1>
                        {`Choose an option to get started.`}
                    </Grid>}
                    <Grid container xs={12} sx={{ lineHeight: '1.2em', fontFamily: 'Poppins-Light !important', fontSize: '18px' }} justifyContent="flex-start" alignItems="center">
                        <RSFlexCard videoList={renderRaceSelection()} sx={{ pt: 0, my: 0, overflow: 'hidden' }} />
                    </Grid>
                </Box>)

        }

        <RSDrawer bottom fitContent={window.innerHeight < 800} popUpWidth={!isMobile ? '450px' : '400px'} popUpHeight={'auto'} open={showTrainForRaceModal} onClose={() => setShowTrainForRaceModal(false)}>
            <Box sx={{ p: 3, pt: 2 }}>
                {renderTrainForARace()}
            </Box>
        </RSDrawer>


        <RSDrawer bottom maxHeight={isMobile ? '90%' : '100%'} popUpWidth='450px' popUpHeight='600px' open={openNotAvailable} onClose={() => setOpenNotAvailable(false)}>

            <Grid container xs={12} sx={{ flexDirection: 'column', height: isMobile ? '100%' : '275px' }} alignItems='center'>
                <Box>
                    <Box sx={{ width: '100%', fontSize: '26px', fontFamily: 'Poppins-Bold', p: 3, display: 'flex', justifyContent: 'center', marginTop: '40px', textAlign: "center", lineHeight: 1.3 }}>
                        {(selectedRace?.title?.toLowerCase() == "half" || selectedRace?.title?.toLowerCase() == "full") ? selectedRace?.subTitle : selectedRace?.title.toUpperCase()} Plans are currently being updated
                    </Box>
                    <Box sx={{ fontSize: '18px', fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', px: 4, lineHeight: '1.4em', textAlign: 'center' }}>
                        Apologies for the inconvenience. <br /> Please check back soon.
                    </Box>
                    <br />
                    <div onClick={() => navigate(`/training-plan/build/step-1?race=${selectedRace?.title.toLowerCase()}`, { replace: true })}>
                        <Box sx={{ fontSize: '18px', fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', px: 2, lineHeight: '1.4em', textAlign: 'center', color: 'blue', textDecoration: 'underline', cursor: "pointer" }}>
                            Go to legacy training plans
                        </Box>
                    </div>

                    <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", bottom: 0, position: 'absolute' }}>
                        <img src='/img-new/misc/runsmart_construction.png' style={{ width: "70%" }} />
                    </Box>
                </Box>
            </Grid>
        </RSDrawer>

        {bodyData && selectedRaceName !== undefined && <RaceSearch onBack={() => setSelectedRaceName(undefined)} raceName={selectedRaceName} setIsLoading={setIsLoading} />}

        <RSDrawer bottom fullWidth popUp popUpHeight='600px' open={openMaintenance} onClose={() => {
            window.location.assign('/#/')
            setOpenMaintenance(false)
        }}>
            <RaceBuild popUpHeight={'600px'} accountProfile={accountProfile?.data?.result.length > 0 ? accountProfile?.data?.result[0] : { user_id: currentUser?.account_id }} raceDetails={{ ...maintenanceDetails }}
                onClose={() => {
                    window.location.assign('/#/')
                    setOpenMaintenance(false)
                }} maintenanceEnd={end} />
        </RSDrawer>
    </>
    )
}

export default RunningTab
