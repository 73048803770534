/* eslint-disable @typescript-eslint/no-unused-vars */

import { RefreshRounded, Notifications } from '@mui/icons-material'
import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Table } from 'react-bootstrap'
import { RouletteSpinner } from 'react-spinner-overlay'
import useWeeklyOutlook from '../../api/v2/weekly-outlook/useWeeklyOutlook'
import { WeeklyOutlook } from '../../api/v2/weekly-outlook/weekly-outlook.types'
import { RSButton1 } from '../../components/buttons'
import RSInput from '../../components/input/RSInput'
import RSInputAdornment from '../../components/input/RSInputAdornment'
import RSPopUpModal from '../../components/layout/RSPopUpModal'
import { P1 } from '../../components/text/RSTypography'
import { GetAllActiveTrainingPlan, GetTrainingPlanInfo } from '../../modules/aiTrainingPlanActions'
import { GetWeeklyOutlookDetailsAll, PushNotification, SearchWeeklyOutlookDetailsByParam } from '../../modules/weeklyOutlookActions'
import useGPTTrainingPlan from '../race/useGPTTrainingPlan'
import WeeklyOutlookPreview from './WeeklyOutlookPreview'
import Loader from '../../components/animation/Loader'


const filtersHelpful = ["Yes", "No", "Any"]
const filtersFeedback = ["Yes", "Any"]

const WeeklyOutlookHistory = () => {

    const [weeklyOutlookHistory, setWeeklyOutlookHistory] = useState<WeeklyOutlook[]>([])
    const [page, setPage] = useState<number>(0)
    const [limit, setLimit] = useState<number>(10)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [isGenerating, setIsGenerating] = useState<boolean>(false)
    const [isSendingNotif, setIsSendingNotif] = useState<boolean>(false)
    const [selectedHistory, setSelectedHistory] = useState<WeeklyOutlook | null>(null)
    const [open, setOpen] = useState(false)
    const [allActivePlans, setAllActivePlans] = useState<any>([])
    const [generateLimit, setGenerateLimit] = useState<number>(5)
    const [counter, setCounter] = useState<number>(0)
    const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0)

    const { convertToLegacyTrainingInfo } = useGPTTrainingPlan()

    const { getWeeklyOutlookDetails, getWeeklyOutlook } = useWeeklyOutlook()
    const [searchParam, setSearchParam] = useState<string>("")

    const [filterHistoryHelpful, setFilterHistoryHelpful] = useState<string>("")
    const [filterHistoryFeedback, setFilterHistoryFeedback] = useState<string>("")

    const hasFetched = useRef(false);
    const listSection = useRef(null) as any;

    const getAllWeeklyOutlookHistory = async (page: number, newLimit?: number) => {
        setIsLoadingData(true)
        const response = await GetWeeklyOutlookDetailsAll(newLimit ? newLimit : limit, page)
        if (response && response.data) {
            setIsLoadingData(false)
            if (page == 0) {
                setTotalHistoryCount(response.data?.result?.count)
                setWeeklyOutlookHistory(response.data.result.results)
                return
            }
            setTotalHistoryCount(response.data?.result?.count)
            setWeeklyOutlookHistory((prev) => [...prev, ...response.data.result.results])
            
        } else setIsLoadingData(false)
    }

    const handlePushNotification = async (external_id: string, data: any) => {
        const weeklyOutlook = JSON.parse(data)
        const headings = "RunSmart Weekly Outlook"
        const subtitle = weeklyOutlook?.headline || ""
        const contents = weeklyOutlook?.recap || ""
        const type = 1;
        setIsSendingNotif(true)
        await PushNotification(contents, headings, subtitle, external_id, type).catch((err) => {
            console.log('>>> PushNotification Error', err)
            setIsSendingNotif(false)
        })
        setIsSendingNotif(false)
    }

    const sanitizedText = (text: string) => {
        return text.replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/\t/g, "\\t")
    }


    const parseHistoryData = (data: any) => {
        if (!data) return { current: {}, upcoming: {} }
        return {
            current: data?.week_outlook && data?.week_outlook !== "undefined" ? JSON.parse(sanitizedText(data?.week_outlook) || "{}") : "",
            upcoming: data?.upcoming_outlook && data?.upcoming_outlook !== "undefined" ? JSON.parse(sanitizedText(data?.upcoming_outlook) || "{}") : "",
            raw_current: data?.raw_current_week && data?.raw_current_week !== "undefined" ? JSON.parse(sanitizedText(data?.raw_current_week) || "{}") : [],
            raw_upcoming: data?.raw_upcoming_week && data?.raw_upcoming_week !== "undefined" ? JSON.parse(sanitizedText(data?.raw_upcoming_week) || "{}") : [],
        }
    }

    const handleSearchHistory = async (paramText: string) => {
        if (paramText == "") {
            getAllWeeklyOutlookHistory(0)
            return
        }

        if (paramText.length < 3) {
            return
        }

        const response = await SearchWeeklyOutlookDetailsByParam(paramText.trim().toLowerCase())
        if (response && response.data) {
            setTotalHistoryCount(response.data?.result.length)
            setWeeklyOutlookHistory(response.data.result)
            setPage(0)
        }
    }

    const getAllActivePlans = async () => {
        setIsLoading(true)
        const response = await GetAllActiveTrainingPlan()
        console.log('>>> GetAllActiveTrainingPlan', response)
        const allActivePlans = response?.data?.result
        console.log('>>> allActivePlans', allActivePlans)
        const specificPlan = allActivePlans && allActivePlans.find((x: any) => x?.plan_id?.toString()?.includes('1435')) || []
        console.log('>>> specificPlan', specificPlan)

        setAllActivePlans(response.data.result)
        setIsLoading(false)
    }

    const handleGenerateOutlook = async (planId?: number) => {
        const LEGACY_OFFSET = 50000
        const abortController = new AbortController()
        // get random plans from allActivePlans with limit of generateLimit
        let randomPlans = []
        if (planId) {
            setGenerateLimit(1)
            randomPlans = allActivePlans.filter((plan: any) => plan.plan_id == (planId - LEGACY_OFFSET))
        }
        else
            randomPlans = allActivePlans.sort(() => Math.random() - Math.random()).slice(0, generateLimit)

        let counter = 0;
        setIsGenerating(true)
        for (const plan of randomPlans) {
            await GetTrainingPlanInfo((plan.plan_id + LEGACY_OFFSET), abortController.signal).then(async (res: any) => {
                const allTrainingData = res?.data?.result[0]
                const convertedInfo = convertToLegacyTrainingInfo(allTrainingData)
                const response = {
                    convertedInfo: convertedInfo,
                    allTrainingData: allTrainingData,
                    currentWeek: plan.current_week,
                }

                await getWeeklyOutlookDetails(response?.allTrainingData, response?.currentWeek).then(async (res: any) => {
                    if (!(res && res?.user_id && response?.currentWeek))
                        return
                    await getWeeklyOutlook(res?.user_id, response?.currentWeek, res?.is_km, res, response?.convertedInfo, planId ? true : false).then(async (res: any) => {
                        console.log('>>> getWeeklyOutlook', res)
                    })
                })
            })
            counter++
            setCounter((prev) => prev + 1)
        }

        if (Number(counter) == Number(generateLimit) || planId) {
            setTimeout(async () => {
                setPage(0)
                await getAllWeeklyOutlookHistory(0)
                setIsGenerating(false)
                setCounter(0)
                if (planId)
                    setGenerateLimit(5)
            }, 2000)
        }
    }

    useEffect(() => {
        if (page > 0) {
            setIsLoading(true)
            getAllWeeklyOutlookHistory(page)
            setIsLoading(false)
        }
    }, [page])

    useEffect(() => {

        let newLimit = limit

        // console.log('>>> filterHistoryHelpful', filterHistoryHelpful)
        // console.log('>>> filterHistoryFeedback', filterHistoryFeedback)

        if(filterHistoryHelpful == 'Yes' || filterHistoryHelpful == 'No')
            newLimit = 1000
        else newLimit = 10

        if(filterHistoryFeedback == 'Yes') newLimit = 1000

        setLimit(newLimit)
        getAllWeeklyOutlookHistory(0, newLimit)

    }, [filterHistoryHelpful, filterHistoryFeedback])

    useEffect(() => {
        if (!hasFetched.current) {
            getAllActivePlans()
            getAllWeeklyOutlookHistory(0)
            hasFetched.current = true;
        }
    }, [])

    return (
        <Grid container spacing={1} ref={listSection}>
            <Grid xs={12} md={12}>
                <div className='half-md-card w-100 card mx-auto p-3 mt-0'>
                    <Grid className='mb-1' container spacing={1}>
                        <Grid item xs={6}>
                            <h3>Weekly Outlook History</h3>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div style={{ width: "500px", float: "right" }}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Grid item xs={12} md={7} sx={{ mr: 1 }}>
                                        <RSInput value={String(searchParam)} setValue={setSearchParam} title='Search by User or PlanId'/>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <RSButton1 sx={{ my: 1, height: '50px' }} onClick={async () => await handleSearchHistory(searchParam)}>Search</RSButton1>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <h4>Total Active: {allActivePlans?.length || 0}</h4>
                        </Grid>

                        <Grid item xs={12} md={1}>
                            <RSInputAdornment max={allActivePlans?.length || 10} value={String(generateLimit)} setValue={setGenerateLimit} label={'Limit'} arial={''} />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <RSButton1 secondary disabled={isGenerating} sx={{ my: 1, mx: 1 }} onClick={async () => await handleGenerateOutlook()}>{isGenerating ? (<><RouletteSpinner size={20} color='#888' />  <span style={{ marginLeft: "10px" }}>{`Generating... (Done: ${counter}/${generateLimit})`}</span></>) : "Generate"}</RSButton1>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <div style={{ width: "500px", display: 'flex', float: "right", justifyContent: 'end', alignItems: 'end' }}>
                                <FormControl sx={{ m: 1, minWidth: 200 }}>
                                    <InputLabel id="demo-simple-select-helper-label">Helpful</InputLabel>
                                    <Select
                                        value={filterHistoryHelpful}
                                        // label="Helpful"
                                        onChange={(e) => setFilterHistoryHelpful(e.target.value as string)}
                                        sx={{ width: '200px', height: '50px', backgroundColor: 'white', marginRight: '0.4rem' }}
                                    >
                                        {filtersHelpful?.map((option, index) => (<MenuItem key={index} value={option} style={{ border: 'none' }}>{option}</MenuItem>))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ m: 1, minWidth: 200 }}>
                                    <InputLabel id="demo-simple-select-helper-label">With Feedback</InputLabel>
                                    <Select
                                        value={filterHistoryFeedback}
                                        // label="Feedback"
                                        onChange={(e) => setFilterHistoryFeedback(e.target.value as string)}
                                        sx={{ width: '200px', height: '50px', backgroundColor: 'white' }}
                                    >
                                        {filtersFeedback?.map((option, index) => (<MenuItem key={index} value={option} style={{ border: 'none' }}>{option}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>

                    <Table responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>PlanID</th>
                                <th style={{ width: '80px' }}>User</th>
                                <th>Week</th>
                                <th style={{ width: '200px' }}>Plan Name</th>
                                <th>Plan Type</th>
                                <th>Watch</th>
                                <th>Helpful</th>
                                <th>User Feedback</th>
                                <th>Created</th>
                                <th colSpan={1}></th>
                            </tr>
                        </thead >
                        <tbody>
                            {weeklyOutlookHistory?.map((history, index: number) => {

                                if (filterHistoryHelpful == 'No' && (history?.feedback_rate == 1 || history?.feedback_rate == 3) )
                                    return null

                                if (filterHistoryHelpful == 'Yes' && (history?.feedback_rate == 0 || history?.feedback_rate == 3) )
                                    return null

                                if(filterHistoryFeedback == 'Yes' && history?.feedback_message == "")
                                    return null

                                return (
                                <tr onClick={() => {
                                    setSelectedHistory(history)
                                    setOpen(true)
                                }} style={{ cursor: 'pointer' }} key={index}>
                                    <td>{index + 1}</td>
                                    <td>{history?.plan_id || 0}</td>
                                    <td>{history?.user?.username || ""}</td>
                                    <td>{history?.week_number || 0}</td>
                                    <td>{history?.plan?.event_name || ""}</td>
                                    <td>{history?.plan?.race_type || ""}</td>
                                    <td>{history?.device?.toUpperCase() || ""}</td>
                                    {/* <td>{Array.from({ length: history?.feedback_rate }, (_, index) => (<span>★</span>))}</td> */}
                                    <td>{history?.feedback_rate == 1 ? "Yes" : history?.feedback_rate == 0 ? "No" : ''}</td>
                                    <td>{history?.feedback_message || ""}</td>
                                    <td>{moment(history.date_created).format('MM/DD/YY h:mm A')}</td>
                                    {/* <td>
                                        <RSButton1 onClick={async (e: any) => {
                                            e.stopPropagation();
                                            await handleGenerateOutlook(history?.plan_id)
                                        }}><RefreshRounded /></RSButton1>
                                    </td> */}
                                    <td>
                                        <RSButton1 disabled={!history.external_id || (history.external_id && history.notification_status == 0 ? true : false)} onClick={async (e: any) => {
                                            e.stopPropagation();
                                            await handlePushNotification(history.external_id as string, history?.week_outlook)
                                        }}>{isSendingNotif ? <RouletteSpinner size={20} color='#888' /> : <Notifications />}</RSButton1>
                                    </td>
                                </tr>
                            )}
                            )}
                        </tbody>
                    </Table >
                    <div className='w-100 text-center mx-auto'>
                        {weeklyOutlookHistory.length >= limit && weeklyOutlookHistory.length < totalHistoryCount ?
                            <RSButton1 disabled={isLoading} sx={{ width: '200px', my: 1, mx: 1 }} onClick={() => setPage((prev) => prev + 1)}>{isLoading ? "Loading..." : "Load More..."}</RSButton1>
                            : (weeklyOutlookHistory.length == 0 ? <P1>No Data</P1> : <P1> - End of List - <br /> Total Records: {totalHistoryCount}</P1>)
                        }
                    </div>
                </div>
            </Grid>

            <RSPopUpModal
                open={open}
                onClose={() => setOpen(false)}
                popUpWidth='120%'
                popUpHeight='auto'
                popUpMaxWidth='auto'
            >
                <WeeklyOutlookPreview weeklyOutlook={parseHistoryData(selectedHistory)} setOpen={setOpen} />
            </RSPopUpModal>

            <Loader active={isLoadingData} />
        </Grid >
    )
}

export default WeeklyOutlookHistory