/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useState, useEffect, FC, useRef } from 'react';
import { Box, Typography, LinearProgress, Paper, Grid, TextField, Divider, OutlinedInput, InputAdornment, Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { H1, H4, P1, P2, P3, P4, SH1, SH2, SH3, SH4 } from '../text/RSTypography';
import moment from 'moment';
import RSButton from '../buttons/RSButton';
import { SaveHeartRateZones, GetHeartRateZones, GetHeartRateProfile, SaveHeartRateProfile } from '../../modules/trainingPlanActions';
import { Form } from 'react-bootstrap';
import Loader from '../animation/Loader';
import HeartRateZonesComputator from './HeartRateZonesInitial';
import { calculateZones } from '../../hooks/useHeartRateZonesComputator';
import RSDrawer from '../layout/RSDrawer';
import { RSButton1 } from '../buttons';
import RSCardCont from '../cards/RSCardCont';
import RSInputAdornment from '../input/RSInputAdornment';
import RSInputAdornment2 from '../input/RSInputAdornment2';
import RSToggleButton from '../buttons/RSToggleButton';
import { getZoneColors } from '../../modules/miscUtils'



interface ZoneRange {
    min: number;
    max: number;
}


interface ZoneRanges {
    [key: string]: ZoneRange;
}


interface InputValues {
    [key: string]: {
        min: string;
        max: string;
    }
}

//local test data
//const zr = JSON.parse('{"zone5":{"min":168,"max":181},"zone4":{"min":155,"max":168},"zone3":{"min":142,"max":155},"zone2":{"min":129,"max":142},"zone1":{"min":117,"max":129}}')
// @@@ Heart Rate Settings
const HeartRateZonesEditor: FC<any> = ({ userAccountDetails, heartRateProfile, zoneRanges, setZoneRanges, modeManual, setModeManual, dayDetails, setShowHeartRateZonesEditor, inputValues, setInputValues, setHeartRateProfileUpdated, showSaveDialogue, setHasChanges }) => {

    const hasMounted = useRef(false);
    let initialSetting = modeManual

    const [isLoading, setIsLoading] = useState(false)

    const [inputHeartRateProfile, setInputHeartRateProfile] = useState<any>(heartRateProfile)

    const [showTooltipZones, setShowTooltipZones] = useState(false)
    const [showTooltipHRresting, setShowTooltipHRresting] = useState(false)
    const [showTooltipHRmax, setShowTooltipHRmax] = useState(false)


    // Handle input change without immediately updating other zones
    const handleInputChange = (zone: string, type: 'min' | 'max', value: string): void => {
        setInputValues((prev: any) => ({
            ...prev,
            [zone]: {
                ...prev[zone],
                [type]: value
            }
        }))
        setHasChanges(true)
    }


    const handleInputChangeHRAuto = (key: any, value: any) => {
        setInputHeartRateProfile((prev: any) => ({
            ...prev,
            [key]: value
        }))
        setHasChanges(true)
    }


    const handleInputBlurHRAuto = () => {

        if (inputHeartRateProfile.heartrateResting == '' || inputHeartRateProfile.heartrateMax == '') {
            setInputHeartRateProfile(heartRateProfile)
            return
        }

        const zoneRangesInitial = calculateZones(inputHeartRateProfile)
        setInputValues(zoneRangesInitial)
    }


    // Handle blur event - only update zones when user leaves the input field
    const handleInputBlur = (zone: string, type: 'min' | 'max'): void => {
        const value = inputValues[zone][type];
        const numValue = parseInt(value, 10);

        if (isNaN(numValue)) {
            // Reset to original value if invalid input
            setInputValues((prev: any) => ({
                ...prev,
                [zone]: {
                    ...prev[zone],
                    [type]: zoneRanges[zone][type].toString()
                }
            }));
            return;
        }

        const updatedZoneRanges: ZoneRanges = { ...zoneRanges };

        // Update the specified zone's min or max
        updatedZoneRanges[zone][type] = numValue;

        // Create an array of zones in order
        const zoneKeys: string[] = Object.keys(zoneRanges).sort();
        const zoneIndex: number = zoneKeys.indexOf(zone);

        // Update adjacent zones to prevent overlaps and gaps
        if (type === 'min') {
            // If changing min, update previous zone's max to be exactly one less
            if (zoneIndex > 0) {
                const prevZone: string = zoneKeys[zoneIndex - 1];
                const newPrevMax = numValue - 1;
                updatedZoneRanges[prevZone].max = newPrevMax;

                // Also update the input value for the adjacent zone
                setInputValues((prev: any) => ({
                    ...prev,
                    [prevZone]: {
                        ...prev[prevZone],
                        max: newPrevMax.toString()
                    }
                }));
            }
        } else if (type === 'max') {
            // If changing max, update next zone's min to be exactly one more
            if (zoneIndex < zoneKeys.length - 1) {
                const nextZone: string = zoneKeys[zoneIndex + 1];
                const newNextMin = numValue + 1;
                updatedZoneRanges[nextZone].min = newNextMin;

                // Also update the input value for the adjacent zone
                setInputValues((prev: any) => ({
                    ...prev,
                    [nextZone]: {
                        ...prev[nextZone],
                        min: newNextMin.toString()
                    }
                }));
            }
        }

        // Ensure min < max within the same zone
        if (updatedZoneRanges[zone].min > updatedZoneRanges[zone].max) {
            if (type === 'min') {
                updatedZoneRanges[zone].max = updatedZoneRanges[zone].min;
                setInputValues((prev: any) => ({
                    ...prev,
                    [zone]: {
                        ...prev[zone],
                        max: prev[zone].min
                    }
                }));

                // Update next zone's min if needed to prevent gaps
                if (zoneIndex < zoneKeys.length - 1) {
                    const nextZone: string = zoneKeys[zoneIndex + 1];
                    const newNextMin = updatedZoneRanges[zone].max + 1;
                    updatedZoneRanges[nextZone].min = newNextMin;

                    setInputValues((prev: any) => ({
                        ...prev,
                        [nextZone]: {
                            ...prev[nextZone],
                            min: newNextMin.toString()
                        }
                    }));
                }
            } else {
                updatedZoneRanges[zone].min = updatedZoneRanges[zone].max;
                setInputValues((prev: any) => ({
                    ...prev,
                    [zone]: {
                        ...prev[zone],
                        min: prev[zone].max
                    }
                }));

                // Update previous zone's max if needed to prevent gaps
                if (zoneIndex > 0) {
                    const prevZone: string = zoneKeys[zoneIndex - 1];
                    const newPrevMax = updatedZoneRanges[zone].min - 1;
                    updatedZoneRanges[prevZone].max = newPrevMax;

                    setInputValues((prev: any) => ({
                        ...prev,
                        [prevZone]: {
                            ...prev[prevZone],
                            max: newPrevMax.toString()
                        }
                    }));
                }
            }
        }

        setZoneRanges(updatedZoneRanges)
    }


    const handleSaveZoneRanges = () => {

        console.log('Saving zone ranges:', zoneRanges)
        setIsLoading(true)

        // Save the updated zone ranges
        SaveHeartRateZones({ plan_id: dayDetails.planId, plan_day_id: dayDetails.planDayId, activity_id: dayDetails.activityId, zone_ranges: JSON.stringify(inputValues), updated_date: dayDetails.currentDate }).then((res) => {

            console.log('saved heart rate zones', res)
            setIsLoading(false)
            setShowHeartRateZonesEditor(false)

        }).catch((err) => { console.log(err) })
    }


    const handleSaveHeartRateProfile = async () => {

        await SaveHeartRateProfile({ user_id: userAccountDetails.id, username: userAccountDetails.username, heartrate_resting: inputHeartRateProfile.heartrateResting, heartrate_max: inputHeartRateProfile.heartrateMax, setting_manual: modeManual }).then((res) => {

            setHeartRateProfileUpdated(inputHeartRateProfile)
            console.log('saved heart rate profile', res)
            return true

        }).catch((err) => {

            console.log(err)
            return true
        })
    }


    useEffect(() => {
        if (!hasMounted.current) {
            initialSetting = modeManual
            hasMounted.current = true;
            return;
        }
        !modeManual && handleInputBlurHRAuto()

        setHasChanges(true)

    }, [modeManual])



    const renderHeartRateZoneHeaderInfos = () => <RSCardCont commonPads sx={{ mb: 2 }}>
        <Grid container xs={12}>
            <Grid container sx={{ mb: 1 }} alignItems='center'>
                <SH2 size={1} sx={{ mr: '3px' }}>Heart Rate Zones</SH2>
                <i className="fa-light fa-circle-info" style={{ fontSize: '16px' }}
                    onClick={() => setShowTooltipZones(true)}></i>
            </Grid>

            <P2>Heart rate zones are set using the scientifically validated heart rate zone reserve (HRR) formula.</P2>

            <Grid container xs={12} sx={{ mt: 3, mb: 1 }}>
                <Grid container xs={6} alignItems='center'>
                    <P4 sx={{ mr: '3px' }}>Resting HR</P4>
                    <i className="fa-light fa-circle-info" style={{ fontSize: '16px' }}
                        onClick={() => setShowTooltipHRresting(true)}></i>
                </Grid>
                <Grid container xs={6} alignItems='center'>
                    <P4 sx={{ mr: '3px' }}>Max HR</P4>
                    <i className="fa-light fa-circle-info" style={{ fontSize: '16px' }}
                        onClick={() => setShowTooltipHRmax(true)}></i>
                </Grid>
            </Grid>

            <Grid container xs={12} sx={{ mb: 1 }}>

                {/* map the contents of heartRateProfile here */}
                {Object.entries(heartRateProfile).map(([key, value]) => {
                    if (key === 'heartrateResting' || key === 'heartrateMax')
                        return (
                            <Grid key={key} item xs={6} >
                                <RSInputAdornment2 wholeNumber disabled={modeManual} sx={{ maxWidth: '80px' }} value={inputHeartRateProfile[key]} setValue={(e: any) => handleInputChangeHRAuto(key, e)} onBlur={() => handleInputBlurHRAuto()} label='bpm' labelSX={{ fontSize: '14px' }} />
                            </Grid>
                        )
                })}
            </Grid>
        </Grid>
    </RSCardCont>



    const renderZoneRangeEditorV2 = () => zoneRanges && <Grid container>
        {/* Use a reverse sorted array to put zone5 on top */}
        {Object.entries(zoneRanges)
            .sort(([zoneA], [zoneB]) => {
                // Extract the number from the zone name and compare in reverse
                const zoneNumA = parseInt(zoneA.replace('zone', ''), 10);
                const zoneNumB = parseInt(zoneB.replace('zone', ''), 10);
                return zoneNumB - zoneNumA; // Descending order (zone5 first)
            })
            .map(([zone, range]: [string, any]) => {
                // Extract zone number for display
                const zoneNum = parseInt(zone.replace('zone', ''), 10);

                return (
                    <Grid container xs={12} key={zone} sx={{ my: 1 }} justifyContent='start'>
                        <Grid container>
                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                                <Box className={getZoneColors(zone, true)} sx={{ opacity: 0.5, borderRadius: '5px', width: '15px', height: '40px' }}></Box>
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', height: '40px' }}>
                                <P1>Zone {zoneNum}</P1>
                            </Grid>

                            {/* map the contents of inputValues here */}
                            {Object.entries(inputValues[zone]).map(([key, value]) => <Grid key={key} item xs={4} sx={{ display: 'flex', width: '100%', justifyContent: 'center', overflow: 'hidden' }}>
                                <RSInputAdornment2 wholeNumber column disabled={!modeManual} sx={{ width: `calc(100% - 10px)`, maxWidth: '80px' }} value={value || ''} setValue={(e: any) => handleInputChange(zone, key as 'min' | 'max', e)} onBlur={() => handleInputBlur(zone, key as 'min' | 'max')} label='bpm' labelSX={{ fontSize: '14px' }} />

                            </Grid>)}

                        </Grid>
                    </Grid>
                );
            })}
    </Grid >


    const renderTooltipZones = () => <RSDrawer
        bottom
        fitContent
        popUpWidth='400px'
        open={showTooltipZones}

        onClose={() => {
            setShowTooltipZones(false)
        }}
    >
        <Grid container xs={12} sx={{ p: 3 }}>
            <H4 size={2} sx={{ width: '100%', mb: '1rem' }}>Heart Rate Zones</H4>
            <P1 sx={{ width: '100%', mb: '3rem' }}>
                {`Heart rate zones help measure effort, not speed—they represent how hard your body is working. While your zones don’t change much over time, as you get fitter, you’ll be able to run faster at the same effort level. Tracking zones helps you train smarter, balance intensity, and improve endurance.`}
            </P1>
        </Grid>
    </RSDrawer>


    const renderTooltipHRresting = () => <RSDrawer
        bottom
        fitContent
        popUpWidth='400px'
        open={showTooltipHRresting}

        onClose={() => {
            setShowTooltipHRresting(false)
        }}
    >
        <Grid container xs={12} sx={{ p: 3 }}>
            <SH1 size={1} sx={{ width: '100%', mb: '1rem' }}>Resting Heart Rate</SH1>
            <P1 sx={{ width: '100%', mb: '3rem' }}>
                {`Resting Heart Rate (RHR) is the number of times your heart beats per minute while at complete rest. It’s a key indicator of fitness and recovery. We use it, along with the Heart Rate Reserve (HRR) formula, to personalize your heart rate zones for smarter training`}
            </P1>
        </Grid>
    </RSDrawer>


    const renderTooltipHRmax = () => <RSDrawer
        bottom
        fitContent
        popUpWidth='400px'
        open={showTooltipHRmax}

        onClose={() => {
            setShowTooltipHRmax(false)
        }}
    >
        <Grid container xs={12} sx={{ p: 3 }}>
            <H4 size={2} sx={{ width: '100%', mb: '1rem' }}>Max Heart Rate</H4>
            <P1 sx={{ width: '100%', mb: '3rem' }}>
                {`Maximum Heart Rate (MHR) is the highest number of beats per minute your heart can reach during intense effort. While formulas like 220 - age provide a rough estimate, they’re often inaccurate. We start by setting your MHR based on your age, but as you train, we’ll refine it using your workout data to ensure your heart rate zones are as accurate and personalized as possible.`}
            </P1>
        </Grid>
    </RSDrawer>


    const renderSaveDialogue = () => <RSDrawer
        bottom
        fitContent
        popUpWidth='400px'
        open={showSaveDialogue}
        sx={{ p: 3 }}
        onClose={() => {
            setShowHeartRateZonesEditor(false)
        }}
    >
        <Grid container xs={12} justifyContent='space-between' >
            <SH1 size={1} sx={{ pb: 2 }}>
                Would you like to save your changes?
            </SH1>
            <RSButton secondary sx={{ mt: 3 }}
                onClick={() => {
                    setModeManual((prev: any)=>!prev)
                    setShowHeartRateZonesEditor(false)
                }}
            >Close</RSButton>
            <RSButton1 sx={{ mt: 1 }}
                onClick={() => {
                    handleSaveHeartRateProfile()
                    handleSaveZoneRanges()
                }}>Save</RSButton1>
        </Grid>
    </RSDrawer>


    return <Box sx={{ mx: 3, my: 2 }}>

        {renderHeartRateZoneHeaderInfos()}

        <RSCardCont commonPads>
            <Grid container xs={12}>
                <Grid container sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }}>
                    <SH2 size={1}>Manual Heart Rate Zones</SH2>
                    <RSToggleButton active={modeManual} setActive={setModeManual} />
                </Grid>

                <P2>Toggle to manual entry to set your resting heart rate and adjust your training zones based on your own measurements.</P2>

                <Grid container xs={12} sx={{ mb: 1, mt: 3 }}>

                    <Grid item xs={4} sx={{ display: 'flex', textAlign: 'center', width: '100%', justifyContent: 'center' }}>
                        <P4>Zone</P4>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', textAlign: 'center', width: '100%', justifyContent: 'center' }}>
                        <P4>Zone Min</P4>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', textAlign: 'center', width: '100%', justifyContent: 'center' }}>
                        <P4>Zone Max</P4>
                    </Grid>
                </Grid>

                {renderZoneRangeEditorV2()}
            </Grid>
        </RSCardCont>

        <RSButton
            sx={{ mt: 2, mb: 3 }}
            onClick={() => {
                handleSaveHeartRateProfile()
                handleSaveZoneRanges()
            }}  >
            Save
        </RSButton>


        {renderTooltipZones()}
        {renderTooltipHRresting()}
        {renderTooltipHRmax()}
        {renderSaveDialogue()}

        <Loader active={isLoading} />
    </Box>
}

export default HeartRateZonesEditor


///////

const IOSSwitch = styled((props: any) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#65C466',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}))


