import { Box } from '@mui/material'
import { FC, useState } from 'react'
import { RSButton1 } from '../../../components/buttons'
import RSHeaderCont from '../../../components/layout/RSHeaderCont'
import { P2, P3 } from '../../../components/text/RSTypography'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'
import FinalStep from './FinalStep'

const FEATURES = [
    "Fully unlocked run plans that include pacing, editing, and more",
    "Send to watch making it easy to stay on track and pace.",
    "Hundreds of runner-specific workouts and exercises designed by Physical Therapists.",
    "Access to run form and nutrition programs to power your training",
    "Introduction to RunSmart Fuel to fuel your running and life"
];

interface DowngradePlanProps {
    setOpenDowngradePlan: (boolean: false) => void,
    user_id?: number
}

const DowngradePlan: FC<DowngradePlanProps> = ({ setOpenDowngradePlan, user_id }) => {

    const isMobile = useIsMobileScreen()

    const [showFinalStep, setShowFinalStep] = useState(false)

    return (
        <>
            <RSHeaderCont isStatic={!isMobile} backClick={isMobile ? () => setOpenDowngradePlan(false) : undefined} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px' }} title='Downgrade Subscription' />
            <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {showFinalStep ? (<FinalStep user_id={user_id} closeModal={() => setOpenDowngradePlan(false)} />) :
                    <>
                        <P3 size={1}>
                            By downgrading your subscription, you lose:
                        </P3>
                        {
                            FEATURES.map((feature, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                    <i className="fa-duotone fa-solid fa-circle-check duo-tone-GW" style={{ fontSize: '15px', marginRight: 10 }} />
                                    <P2 size={1} key={index}>{feature}</P2>
                                </Box>
                            ))
                        }
                        <RSButton1 onClick={() => { setShowFinalStep(true) }} impose sx={{ maxWidth: 'auto', mt: 5 }}>
                            Continue
                        </RSButton1>

                        <RSButton1 tertiary onClick={() => setOpenDowngradePlan(false)} impose sx={{ maxWidth: "100%", mt: 2 }}>
                            {`Nevermind, I don't want to downgrade.`}
                        </RSButton1>
                    </>
                }
            </Box>
        </>
    )
}

export default DowngradePlan