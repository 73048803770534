import moment from 'moment';
import useSWR from 'swr';
import Box from '@mui/material/Box';

import { SkipMaintenancePlan } from '../../api/v2/types'
import { SkipMaintenance } from '../../modules/trainingPlanActions'
import { RSButton1 } from '../buttons'
import RSPopUpModal from '../layout/RSPopUpModal'
import RSDrawer from '../layout/RSDrawer'
import {SH1} from '../text/RSTypography'
import RaceBuild from '../../pages/race/RaceBuild';

const AddMaintenancePlan = (props: any) => {
	const {addMaintenance, setAddMaintenance, doNotAddMaintenance, setDoNotAddMaintenance, userInfo, getRaceResult, setLoadingValues, handleGetJourney} = props
	const { data: accountProfile } = useSWR(userInfo?.internal_id ? `/account-profile?account_id=${userInfo?.internal_id}` : null, { refreshInterval: 0 })
	const rd = userInfo?.race_date?.slice(0, userInfo?.race_date?.indexOf('T'))?.replaceAll('-', '/')
	const newRd = moment(rd).isBefore(moment()) ? moment() : rd
	const maintenanceDetails = {
		"name": "Maintenance Plan",
		"date": moment(newRd).format('MM/DD/YYYY'),
		"dateEnd": moment(newRd).format('MM/DD/YYYY'),
		"place": "",
		"type": ["MAINTENANCE"],
		"desc": "",
		"homepage": "",
		"logo": "",
		"raceTitle": "Maintenance",
		"selectedRaceDistance": "MAINTENANCE"
	}

	const handleAcceptMaintenance = async () => {
		await SkipMaintenance({recovery_id: userInfo?.raceResults?.recoveryPlan?.id} as SkipMaintenancePlan)
	}

	const handleSkipMaintenance = async () => {
  	const recovery_id = userInfo?.raceResults?.recoveryPlan?.id
  	if (recovery_id) {
			setLoadingValues?.(true)
  		await SkipMaintenance({recovery_id} as SkipMaintenancePlan)
  		getRaceResult && await getRaceResult()
  		handleGetJourney?.()
  	}
  	setDoNotAddMaintenance(false)
  }

	return <>
		<RSDrawer bottom fullWidth popUp popUpHeight='600px' open={addMaintenance} onClose={() => {
				setAddMaintenance(false)
		}}>
			<RaceBuild popUpHeight={'600px'} accountProfile={accountProfile?.data?.result.length > 0 ? accountProfile?.data?.result[0] : { user_id: userInfo?.internal_id }} raceDetails={{ ...maintenanceDetails }} onClose={() => setAddMaintenance(false)} toDoOnAccept={handleAcceptMaintenance}/>
		</RSDrawer>

		<RSPopUpModal
			open={doNotAddMaintenance}
			onClose={() => setDoNotAddMaintenance(false)}
			popUpWidth='calc(100% - 50px)'
			popUpMaxWidth='400px'
		>
			<Box sx={{p: 3}}>
				<SH1 size={2} sx={{width: '100%', textAlign: 'center'}}>
					{`Don't Add Maintenance Plan?`}
				</SH1>
				<RSButton1 sx={{mt: 4}} onClick={handleSkipMaintenance}>
					Confirm
				</RSButton1>
				<RSButton1 sx={{mt: 1}} tertiary onClick={() => setDoNotAddMaintenance(false)}>
					Cancel
				</RSButton1>
			</Box>
		</RSPopUpModal>
	</>

}

export default AddMaintenancePlan;
