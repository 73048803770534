import { FC, useState } from 'react'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import useIsMobileScreen from "../../hooks/useIsMobileScreen";
import useSale from '../../hooks/useSale'
import { SH3, P2, P3, H1, H2, P1 } from "../../components/text/RSTypography"
import { Box } from "@mui/material"
import RSCardCont from "../../components/cards/RSCardCont";
import RSFlagButton from '../../components/buttons/RSFlagButton';
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import Grid from "@mui/material/Grid";
import { RSButton1 } from '../../components/buttons';
import UpgradePlan from '../../pages/account/UpgradePlan'
import { RECURLY_PUBLIC_KEY } from '../../modules/cliEnvValues';

const PRO_FEATURES_LIST = [
    {
        title: "Flexible Plans",
        subtitle: "Fit Training into Your Life",
        description: "Move runs around anytime - Pro plans flex with your schedule.",
        icon: "fa-regular fa-calendar-days color-primary",
        iconSize: 40,
    },
    {
        title: "Personalized Pacing",
        subtitle: "Run the Right Pace, Every Time",
        description: "Get paces tailored to you with adjustments for trails, treadmill, and road.",
        icon: "fa-regular fa-gauge-high color-primary",
        iconSize: 40,
    },
    {
        title: "Stay Injury-Free",
        subtitle: "Strength & Mobility Made for Runners",
        description: "Physical Therapist-designed routines to keep you strong and pain-free.",
        icon: "fa-regular fa-kit-medical color-primary",
        iconSize: 40,
    },
    {
        title: "Watch Sync",
        subtitle: "Send Your Plan to Your Watch",
        description: "Your runs (with pacing) sync directly to your watch - no more checking your phone mid-run.",
        icon: "fa-kit fa-regular-watch-smart-circle-arrow-left color-primary",
        iconSize: 40,
    },
]

const RUNNERS_TESTIMONIALS = [{
	name: 'James Atkinson',
	img: '/img-new/sale/james_atkinson.jpg',
	quote: `“RunSmart has kept me injury free for almost six years now.”`,
}, {
	name: 'Sarah Baker',
	img: '/img-new/sale/sarah_baker.jpg',
	quote: `“Got my BQ and PR'd by over 4 minutes!”`,
}, {
	name: 'Clarisa Fonseca',
	img: '/img-new/sale/clarisa_fonseca.jpg',
	quote: `“After a year of being injured I recovered.”`,
}]

interface RunSmartProProps {
    setOpen?: (open: boolean) => void
    userInfo: any,
    isSaleAnnouncement?: boolean
}

const RunSmartPro: FC<RunSmartProProps> = ({ setOpen, userInfo, isSaleAnnouncement }) => {
		const { activeSale, saleEnd } = useSale()
		const { headline, subtext, banner } = activeSale || {}
    const isMobile = useIsMobileScreen()
    const [openPaymentDetails, setOpenPaymentDetails] = useState<boolean>(false)
    const [openUpgradePro, setOpenUpgradePro] = useState<boolean>(false)

    const proTitle = isSaleAnnouncement && headline ? headline
    	: 'Train Smarter with Pro - Flexible, Personalized, Injury-Free'

		const proDesc = isSaleAnnouncement && subtext ? subtext
			: 'Upgrade to Pro for flexible plans, injury-free training, and pacing sent straight to your watch.'

		const proBanner = isSaleAnnouncement && banner ? banner : ''

		const listHeader = isSaleAnnouncement ? 'Join thousands of runner who trust their running to RunSmart!'
			: 'What you unlock:'

		const saleDate = `Sale Ends ${saleEnd}`

    return (
        <Grid container xs={12} sx={{ height: '100%' }} justifyContent='start'>
            <Box className='background-bottom' sx={{ backgroundImage: `url('/img-new/accounts/runsmart_track_bground.png')`, width: '100%'}}>
                {isMobile && setOpen && <RSHeaderCont noBanner isStatic={!isMobile} backClick={() => setOpen(false)} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title='RunSmart Pro' />}
								<Box sx={{mb: 2, px: 3, pt: 3}}>
										<Box sx={{ width: '100%' }}>
												<img src='/images/icons/pro-icon-word.png' style={{ height: '20px', width: 'auto' }} />
										</Box>
										{isSaleAnnouncement && <H1 card className='pink-text' size={5}>{`SALE - ${proBanner}`}</H1>}
										{isSaleAnnouncement ? <H2 card size={3} sx={{ py: 1 }}>{proTitle}</H2> : <H1 card size={5} sx={{ pb: 1 }}>{proTitle}</H1>}
										<P1 card>{proDesc}</P1>
										<RSButton1 onClick={() => setOpenPaymentDetails(true)} sx={{ mt: 2, width: "50%", maxHeight: "45px" }}>{"Upgrade to Pro"}</RSButton1>
										{isSaleAnnouncement && <P3 sx={{pt: 1}}>{saleDate}</P3>}
										<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
												<Elements>
														<UpgradePlan userInfo={userInfo} setOpenPaymentDetails={setOpenPaymentDetails} openPaymentDetails={openPaymentDetails} setOpenUpgradePro={setOpenUpgradePro} openUpgradePro={openUpgradePro} />
												</Elements>
										</RecurlyProvider>
								</Box>


                <Box sx={{p: 3}}>
                    <SH3 card size={1} sx={{mb: 1}}>{listHeader}</SH3>
                    { isSaleAnnouncement ?
                    	RUNNERS_TESTIMONIALS.map((tData: any, index: number) => (
                    		<RSFlagButton key={index} fullTitle imgCircle title={tData.quote} symbol={tData.img} sx={{ mb: 2 }}>
													<P3 size={-1} italic sx={{width: '100%', textAlign: 'right'}}>{`- ${tData.name}`}</P3>
                    		</RSFlagButton>
                    	))
                    	: PRO_FEATURES_LIST.map((feature, index) => (
                        <RSCardCont key={index} sx={{ display: 'flex', flexDirection: 'row', mb: 2, p: 0, overflow: 'hidden' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', mx: 2, minWidth: 45 }}>
                                <i className={feature.icon} style={{ fontSize: feature.iconSize }} />
                            </Box>
                            <Box sx={{ my: 2, pr: 1.5 }}>
                                <SH3 desc size={1}>{feature.title}</SH3>
                                <P3 card size={-0.1} sx={{ mt: 0.2, color: "#444444" }}>{feature.subtitle}</P3>
                                <P2 card size={-0.1} sx={{ mt: 0.7, color: "#444444" }}>{feature.description}</P2>
                            </Box>
                        </RSCardCont>
                    	))
										}
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', my: 2 }} />
                </Box>
            </Box >
        </Grid >
    )
}

export default RunSmartPro
