/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import Grid from '@mui/material/Grid'

import RSCardCont from '../../components/cards/RSCardCont'
import { RSButton1 } from '../../components/buttons'
import { H3, SH2, SH3, P1, P2 } from '../../components/text/RSTypography'
import { Table } from 'react-bootstrap'

interface WeeklyOutlookPreviewProps {
    weeklyOutlook: any
    setOpen: any
}
const WeeklyOutlookPreview: FC<WeeklyOutlookPreviewProps> = ({ weeklyOutlook, setOpen }) => {

    const renderBody = () => {
        const summaryHeadLine = weeklyOutlook?.current?.headline
        const recap = weeklyOutlook?.current?.recap
        const raw_current = weeklyOutlook?.raw_current
        const raw_upcoming = weeklyOutlook?.raw_upcoming
        const upcomingHeadline = weeklyOutlook?.upcoming?.headline

        try {
            //const upcomingHeadline = weeklyOutlook?.upcoming?.headline
            if (weeklyOutlook?.upcoming?.recap && typeof weeklyOutlook?.upcoming?.recap === "object") {
                const hasBullets = weeklyOutlook?.upcoming?.recap?.bullets?.length > 0
                if (hasBullets)
                    weeklyOutlook.upcoming.recap = weeklyOutlook?.upcoming?.recap?.bullets?.join("")
                else
                    weeklyOutlook.upcoming.recap = weeklyOutlook?.upcoming?.recap?.join("")
            }
        } catch (e) {
            weeklyOutlook.upcoming.recap = ''
        }

        const upcomingSplit = weeklyOutlook?.upcoming?.recap && typeof weeklyOutlook?.upcoming?.recap === "string" ? weeklyOutlook?.upcoming?.recap?.replaceAll('**', '')?.split("*")?.filter((x: string) => x) : []
        // const upcomingSplit = weeklyOutlook?.upcoming?.recap?.replaceAll('**', '')?.split("*")?.filter((x: string) => x)
        const upcomingRecap = upcomingSplit?.[0]
        const upcomingBulletPoints = upcomingSplit?.splice(1)

        // console.log('!!! weeklyOutlook', weeklyOutlook)

        return <>
            <Grid xs={12} sx={{ p: 3 }}>
                <Grid container>
                    <SH2 size={1} sx={{ mr: '5px' }}>Date Created:</SH2>
                    <P1 size={1}>{moment(weeklyOutlook?.details?.date_created?.split('T')?.[0]).format('MMMM DD, YYYY')}</P1>
                </Grid>
                <H3 sx={{ width: '100%', mt: 2 }}>SUMMARY</H3>
                <RSCardCont sx={{ mt: 1, px: 2, py: '13px' }}>
                    <Grid container>
                        {summaryHeadLine && <SH3 card>{summaryHeadLine}</SH3>}
                        {recap && <P2 card>{recap}</P2>}
                    </Grid>
                </RSCardCont>

                <Table responsive striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Day</th>
                            <th>Distance</th>
                            <th>Completed</th>
                            <th>Pace</th>
                            <th>Completed</th>
                            <th>Run Type</th>
                            {/* <th>Cross Training</th> */}
                            <th>Heart Rate</th>
                        </tr>
                    </thead >
                    <tbody>
                        {raw_current?.map((data: any, index: number) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.day_name}</td>
                                <td>{data.planned_distance}</td>

                                <td>{data.running_activity && data.running_activity.length > 0 ? data.running_activity.map((userDetails: any, j: number) => <>
                                    <tr>{userDetails.completed_distance}</tr>
                                </>)
                                    :
                                    data.completed_distance
                                }</td>
                                {/* <td>{data.completed_distance}</td> */}

                                <td>{data.planned_pace}</td>

                                <td>{data.running_activity && data.running_activity.length > 0 ? data.running_activity.map((userDetails: any, j: number) => <>
                                    <tr>{userDetails.completed_pace}</tr>
                                </>)
                                    :
                                    data.completed_pace
                                }</td>
                                {/* <td>{data.completed_pace}</td> */}

                                <td>{data.run_type}</td>
                                {/* <td>{data.cross_training}</td> */}


                                <td>{data.running_activity && data.running_activity.length > 0 ? data.running_activity.map((userDetails: any, j: number) => <>
                                    <tr>{userDetails.completed_heart_rate || userDetails.heartRate}</tr>
                                </>)
                                    :
                                    data.completed_heart_rate || data.heartRate
                                }</td>
                                {/* <td>{data.heartRate}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table >
                <H3 sx={{ width: '100%', mt: 2 }}>UPCOMING</H3>
                <RSCardCont sx={{ mt: 1, px: 2, py: '13px' }}>
                    <Grid container>
                        {upcomingHeadline && <SH3 card>{upcomingHeadline}</SH3>}
                        <P2 card>{upcomingRecap}</P2>
                        {upcomingBulletPoints?.map((ubp: any, ubpI: number) => <Grid container key={`ubp-${ubpI}`} xs={12} sx={{ mt: 1 }}>
                            <i className="fa-regular fa-circle-arrow-right" style={{ width: '20px', fontSize: '20px', marginRight: '5px', marginTop: '3px', height: '100%' }} />
                            <P2 card sx={{ width: 'calc(100% - 25px)' }}>{ubp}</P2>
                        </Grid>)}
                    </Grid>
                </RSCardCont>


                <Table responsive striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Day</th>
                            <th>Run Type</th>
                            <th>Distance</th>
                            {/* <th>Cross Training</th> */}
                        </tr>
                    </thead >
                    <tbody>
                        {raw_upcoming?.map((data: any, index: number) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.day_name}</td>
                                <td>{data.run_type}</td>
                                <td>{data.run_distance}</td>
                                {/* <td>{data.cross_training}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table >
                <Grid container xs={12} sx={{ pt: 10 }} justifyContent='center' alignItems='center'>
                    <Grid container xs={12} justifyContent='center' alignItems='center'>
                        <RSButton1 sx={{ width: '100px', my: 2, mx: 1 }} onClick={() => setOpen(false)}>Close</RSButton1>
                    </Grid>
                </Grid>
            </Grid></>
    }

    return renderBody()
}

export default WeeklyOutlookPreview
