/* eslint-disable @typescript-eslint/no-unused-vars */
import allCategories from '../modules/static-images/allCategories'
import phpUrls from '../modules/videos/php-url-mapping.json'
import aliasUrls from '../modules/videos/aliases.json'
// import { getHomeData, getHomeVideoContinue } from '../modules/accountActions'
import { CardRowProps, Card } from '../components/page-specific/home/InfoRow'
import { AuthPageProps } from '../modules/types'
import allVideos, { Video } from '../modules/videos/allVideos'
import {
	continueTrainingCard,
	facebookCard,
	JOIN_REASONS,
} from '../modules/values'
import { useState, useEffect, useRef } from 'react'
// import response from '../home-data.json'
import useHomeTrainingPlan, { TrainingInfo } from './useHomeTrainingPlan'
import { useLocation } from 'react-router-dom'
import useStore from '../store/useStore'
import { IVideoStore } from '../store/createVideoStore'
import useSuggestedPrograms from '../pages/onboarding/useSuggestedPrograms'
import { ISurveyDetails } from '../api/v2/onboarding-survey/types'
import useSurveyActions from '../pages/onboarding/useSurveyActions'
import { ITrainingStore } from '../store/createTrainingStore'
import { TrainingDetails } from '../store/types'
import { getReq } from '../modules/apiConsume'
import useSWR from 'swr'
import moment from 'moment'
import { ITrainingPlanStore } from '../store/createTrainingPlanStore'
import useGPTTrainingPlan from '../pages/race/useGPTTrainingPlan'
import { UpdatePlanUnit } from '../modules/trainingPlanActions'
import { IAiTrainingPlanStore } from '../store/createAITrainingPlanStore'

// Video
export type VideoWithProgress = Video & {
	isComplete: boolean
	isLiked: boolean
	duration: string
	progress: number
	type?: string
}

// Video Rows
type VideoRows = {
	continue: VideoWithProgress[]
	mustTry: VideoWithProgress[]
}

// Video Types
const videoTypes = {
	continue: 'continue',
	guides: 'guides',
	must_try: 'mustTry',
	programs: 'programs',
	seminars: 'seminars',
}

// Default Video Rows
const defaultVideoRows: VideoRows = {
	continue: [],
	mustTry: [],
}

// Video Row Keys
const videoRowKeys = Object.keys(defaultVideoRows)

// Default Header
const defaultHeader = {
	image: '',
	mainText: '',
	mainLink: '',
	subText: '',
	subLink: '',
	titleText: '',
	subtitleText: '',
	progress: 0,
	isComplete: false,
	hasPlayButton: false,
}

/**
 * Use Home Page Hook
 */

const useHomePage = ({
	isIos,
	userInfo,
	isMobile,
	accountProfile
}: {
	isIos: boolean
	userInfo: any
	isMobile?: boolean
	accountProfile?: any
}) => {

	const [infoRow, setInfoRow] = useState<CardRowProps['cards']>([])
	const [isInfoLoading, setIsInfoLoading] = useState<boolean>(true)
	const [continueRow, setContinueRow] = useState<VideoWithProgress[]>([])
	const [mustTryRow, setMustTryRow] = useState<VideoWithProgress[]>([])
	const [header, setHeader] = useState(defaultHeader)
	const [joinReason, setJoinReason] = useState<any>()
	const [isLoadingTraining, setIsLoadingTraining] = useState(true)
	const { aiTrainingPlans: userTrainingPlans } = useStore((state: IAiTrainingPlanStore) => state)

	const { setHomeVideos, homeVideos: currentHomeVideos } = useStore((state: IVideoStore) => state)
	const { trainingDetails, setTrainingDetails, setCurrentRecover } = useStore((state: ITrainingStore) => state)
	const { setUserOverrides, userOverrides: userOverridesData } = useStore((state: ITrainingPlanStore) => state)
	const { getSurveyDetails } = useSurveyActions(userInfo?.account_id)
	const { constructSurveyAnswers } = useSuggestedPrograms(userInfo?.account_id as number)

	const RECOVERY = localStorage.getItem('recoverStatus') === "recovered" ? true : false
	const { convertToLegacyTrainingInfo } = useGPTTrainingPlan()

	// const [trainingDetails, setTrainingDetails] = useState<TrainingInfo | undefined>()
	const hasFetched = useRef(false);

	const { data: userPlans } = useSWR(userInfo?.account_id ? `/training-plan/userinfo?uid=${userInfo?.account_id}` : null)
	// const { data: userTrainingPlans } = useSWR(userInfo?.account_id ? `/gpt-training-plan-info/all?userId=${userInfo?.account_id}` : null)

	const activePlans = userPlans?.data?.result?.filter((x: any) => x.status == 1 && x.is_active_plan == true)

	if (activePlans) {
		const gptActivePlans = userTrainingPlans?.filter((x: any) => x.status == 1 && x.isActivePlan == true)
		gptActivePlans?.forEach((x: any) => {
			activePlans?.push(convertToLegacyTrainingInfo(x))
		})
	}

	let currentPlan = activePlans ? activePlans?.sort((a: any, b: any) => {
		if (moment(a.start) > moment(b.start))
			return -1
		else if (moment(a.start) < moment(b.start))
			return 1
		else {
			if (moment(a.race_date) > moment(b.race_date))
				return -1
			else if (moment(a.race_date) < moment(b.race_date))
				return 1
			else
				return 0
		}

	})[activePlans.length - 1] : undefined
	currentPlan = currentPlan?.km ? { ...currentPlan, km: userInfo?.km || currentPlan.km } : undefined
	const { data: userNotes } = useSWR(currentPlan?.uid ? `/training-plan/all-user-notes?userId=${currentPlan?.uid}` : null)
	const { data: userOverrides } = useSWR(currentPlan?.uid ? `/training-plan/user-override?userId=${currentPlan?.uid}` : null)
	const { data: currentRecover } = useSWR(userInfo?.account_id ? `/recover/user?user_id=${userInfo?.account_id}` : null)
	// const { data: homeVideos, isLoading } = useSWR(userInfo?.account_id && joinReason ? `/continue-videos?user_id=${userInfo?.account_id}&searchKey=${joinReason}` : null)
	const { data: raceResult } = useSWR(currentPlan ? `/race-results?user_id=${currentPlan?.internal_id}&training_plan_id=${currentPlan?.uid}&type=${currentPlan?.type}` : null)

	const { getTrainingDetails } = useHomeTrainingPlan(userNotes, userOverridesData || [], currentPlan, raceResult, userInfo)


	const url = useLocation()

	const initData = async () => {
		const accountId = userInfo?.account_id || 0
		const details = await getSurveyDetails(accountId)
		const joinReason = details ? constructSurveyAnswers(details as ISurveyDetails) : userInfo?.join_reason as string || 'strength'
		setJoinReason(joinReason)
	}
	// Set Home Data
	const setHomeData = async (trainingDetails: TrainingInfo, homeVideos: any) => {
		const newVideoRows: VideoRows = {
			continue: [],
			mustTry: [],
		}
		const headerBgUrl = ""
		const headerMainLink = ""
		const image = (phpUrls as any)[headerBgUrl] || headerBgUrl || ''
		let mainLink = (phpUrls as any)[headerMainLink] || headerMainLink || ''
		mainLink = aliasUrls[mainLink as keyof typeof aliasUrls] || mainLink
		let subLink = ''
		subLink = aliasUrls[subLink as keyof typeof aliasUrls] || subLink
		const newHeader: typeof header = {
			image,
			mainLink,
			subLink,
			mainText: '',
			subText: '',
			titleText: '',
			subtitleText: '',
			hasPlayButton: false,
			isComplete: false,
			progress: 0,
		}
		if (newHeader.mainText && newHeader.mainText == newHeader.subText) {
			newHeader.subText = newHeader.subLink = ''
		}
		const hasContinueCard = true
		const continueCard = {
			...continueTrainingCard,
			showProgressBar: trainingDetails?.in_progress || trainingDetails?.percent_completed == 100 ? true : false,
			showDaysUntilRace: !trainingDetails?.type.includes('Main'),
			progressPercent: trainingDetails?.percent_completed || 0,
			daysUntilRace: trainingDetails?.days_before_race || 0,
			scheduleText: trainingDetails?.workout_text || '',
			trainingType: trainingDetails?.type,
			otherInfo: trainingDetails?.race_date,
			id: trainingDetails?.plan_id,
			isLoading: isLoadingTraining,
			weeks: trainingDetails?.weeks,
			pace: trainingDetails?.pace,
			paceDetails: trainingDetails?.paceDetails,
			weekPaceOriginal: trainingDetails?.weekPaceOriginal,
			weekPace: trainingDetails?.weekPace,
			currentWeek: trainingDetails?.currentWeek,
			currentDay: trainingDetails?.currentDay,
			logo: trainingDetails?.logo,
			typeIndex: trainingDetails?.typeIndex,
			planType: trainingDetails?.plan_type,
			trainingStartDate: trainingDetails?.training_start,
			event_name: trainingDetails?.event_name,
			race_logo: trainingDetails?.race_logo,
			curr_date: trainingDetails?.curr_date || '',
			all_training_text: trainingDetails?.all_training_text || [],
			all_training_text_original: trainingDetails?.all_training_text_original || [],
			daysUntilStart: trainingDetails?.days_before_start,
			monday_start: trainingDetails?.monday_start,
		} as Card

		if (trainingDetails?.create_new)
			continueCard.text = continueCard.text || ''
		else if (!trainingDetails?.in_progress)
			continueCard.text = (trainingDetails?.type.includes('Maintenance') && (moment(trainingDetails?.race_date).diff(moment(), 'd') == 0 ? true : false)) ? 'Maintenance Plan' : `Your plan begins in ${trainingDetails?.days_before_start == 0 ? 1 : trainingDetails?.days_before_start} ${(trainingDetails?.days_before_start == 0 ? 1 : trainingDetails?.days_before_start) == 1 ? 'day' : 'days'}`
		const getInfoCards = () => {
			return [continueCard as Card]
		}

		// Info Cards
		const infoCards: CardRowProps['cards'] = getInfoCards()
		setInfoRow(infoCards)
		// Iterate Video Types
		for (const typeKey in videoTypes) {
			const rowInfo = homeVideos?.continue
			// (response.data.result as any)[typeKey]
			const rowKey = videoTypes[
				typeKey as keyof typeof videoTypes
			] as keyof VideoRows
			if (!videoRowKeys.includes(rowKey)) continue

			// Iterate Videos
			for (const videoKey in rowInfo) {
				const videoInfo = rowInfo[videoKey]
				const resultType = homeVideos.type || ''
				const videoProgress: number[] = videoInfo.progress ? JSON.parse(videoInfo.progress) : [0];
				const urlId = resultType === "suggested" ? videoInfo.video_id : videoInfo.url || videoInfo.video_id
				const videoMetadata = allVideos[urlId]
				if (videoMetadata && videoMetadata?.url) {
					const url = videoMetadata.url + `?type=${isMobile ? 'mobile' : 'full'}`
					const videoDetails: VideoWithProgress = {
						...videoMetadata,
						url: url,
						isComplete: videoInfo.complete_time ? true : false,
						duration: videoInfo.durationText || '',
						isLiked: false,
						progress: Number(videoInfo.progress ? videoProgress[videoProgress.length - 1] : 0) || 0,
						type: resultType
					}
					newVideoRows[rowKey].push(videoDetails)
				}
			}
		}

		const continueVideos = newVideoRows.continue.filter((v: any, i, a) => a.findIndex((v2: any) => ['id'].every((k: any) => v2[k] === v[k])) === i).filter(x => x.progress <= 80).splice(0, 6);
		setContinueRow(continueVideos)
		setMustTryRow(newVideoRows.mustTry)
		setHeader(newHeader)
	}

	// Mount Effect
	useEffect(() => {
		const abortController = new AbortController();
		// initData()
		if (userInfo?.account_id && userPlans) {
			setIsLoadingTraining(true)
			getTrainingDetails(abortController).then(async (res) => {
				setTrainingDetails(res as TrainingDetails)
				setIsLoadingTraining(false)
			})
		}
		return () => {
			abortController.abort();
		}
	}, [userInfo, userPlans])

	useEffect(() => {
		setCurrentRecover(currentRecover?.data?.result[0])
	}, [currentRecover])

	useEffect(() => {
		const abortController = new AbortController();
		if (userOverridesData && userOverridesData?.length > 0) {
			setIsLoadingTraining(true)
			getTrainingDetails(abortController).then(async (res) => {
				setTrainingDetails(res as TrainingDetails)
				setIsLoadingTraining(false)
			})
		}

		return () => {
			abortController.abort();
		}
	}, [userOverridesData])

	useEffect(() => {
		if (userOverrides && userOverrides?.data?.result?.length > 0) {
			setUserOverrides(userOverrides.data.result)
		}
	}, [userOverrides])

	useEffect(() => {
		const abortController = new AbortController();
		if (userNotes && userNotes?.data?.result?.length > 0) {
			setIsLoadingTraining(true)
			getTrainingDetails(abortController).then(async (res) => {
				setTrainingDetails(res as TrainingDetails)
				setIsLoadingTraining(false)
			})
		}
	}, [userNotes])


	useEffect(() => {
		if (currentPlan && currentPlan?.uid && accountProfile && accountProfile?.measurement_system && !hasFetched.current) {
			const unit = currentPlan?.unit
			if (!unit) {
				UpdatePlanUnit(currentPlan?.uid - 50000, accountProfile?.measurement_system === 'imperial' ? 'mi' : 'km')
				hasFetched.current = true;
			}
		}
	}, [currentPlan, accountProfile, hasFetched])

	// useEffect(() => {
	// 	if (!isLoading) {
	// 		homeVideos?.data?.result?.continue.length > 0 ? setHomeVideos(homeVideos?.data?.result) : undefined
	// 	}
	// }, [homeVideos, isLoading])

	useEffect(() => {
		setIsInfoLoading(true)
		const cachedRequest = async (data: TrainingDetails, homeVideos: any) => {
			await setHomeData(data, homeVideos)
		}
		if (trainingDetails && currentHomeVideos && !isLoadingTraining)
			cachedRequest(trainingDetails, currentHomeVideos)

		setIsInfoLoading(false)
	}, [trainingDetails, isLoadingTraining, currentHomeVideos])

	return {
		infoRow,
		continueRow,
		mustTryRow,
		programsRow: allCategories.programs,
		webinarsRow: allCategories.webinars,
		guidesRow: allCategories.guides,
		header,
		isInfoLoading,
	}
}

export default useHomePage
