/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prefer-const */

import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, Paper, Grid, TextField, Divider, OutlinedInput, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { H1, H2, H3, H4, SH3, P1, P2, P3, P4, A, SH2, SH1 } from '../text/RSTypography';
import moment from 'moment';
import HeartRateZonesEditor from './HeartRateZonesEditor';
import RSDrawer from '../layout/RSDrawer';
import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import RSHeaderCont from '../layout/RSHeaderCont';
import RSCardCont from '../cards/RSCardCont';
import { GetHeartRateZones, GetHeartRateProfile, SaveHeartRateProfile } from '../../modules/trainingPlanActions';
import { RSButton1 } from '../buttons';
import RSButton from '../buttons/RSButton';
import { getZoneColors } from '../../modules/miscUtils'

// Styled components
const ZoneBar = styled(LinearProgress)<{ fillColor: string }>(({ theme, fillColor }) => ({
  height: '15px',
  borderRadius: 5,
  backgroundColor: theme.palette.grey[200],
  '& .MuiLinearProgress-bar': {
    backgroundColor: fillColor,
  },
}));

// Types
// sts: start time in seconds
// hr: heart rate
interface HeartRateData {
  ts: number;
  startTimeInSeconds: number;
  heartRate: number;
  sts: number;
  hr: number;
  heart_rate: number;
}

interface ZoneRange {
  min: number;
  max: number;
}

interface ZoneRanges {
  [key: string]: ZoneRange;
}

interface ZoneCounts {
  [key: string]: number;
}

interface InputValues {
  [key: string]: {
    min: string;
    max: string;
  };
}


const HeartRateZonesView = (userData: any) => {

  const { userAccountDetails, userDataActivity, heartRateProfile, timeFrame, dayDetails, zoneRangesData, handleGetZoneRanges } = userData

  const [hasChanges, setHasChanges] = useState(false)
  const [showSaveDialogue, setShowSaveDialogue] = useState(false)
  const [modeManual, setModeManual] = useState<any>(heartRateProfile?.setting === "true" || false)


  const isMobile = useIsMobileScreen()


  // Parse initial zone ranges from the provided format
  const parseInitialZoneRanges = (): ZoneRanges => {
    const initialZoneRanges: ZoneRanges = {
      zone5: { min: 161, max: 180 },
      zone4: { min: 151, max: 160 },
      zone3: { min: 141, max: 150 },
      zone2: { min: 131, max: 140 },
      zone1: { min: 100, max: 130 }
    };
    return initialZoneRanges;
  };


  // Initialize input values state
  const initializeInputValues = (ranges: ZoneRanges): InputValues => {
    const initialInputValues: InputValues = {};
    Object.entries(ranges).forEach(([zone, range]) => {
      initialInputValues[zone] = {
        min: range.min.toString(),
        max: range.max.toString()
      };
    })
    return initialInputValues;
  };


  // State to store zone ranges
  const [zoneRanges, setZoneRanges] = useState<ZoneRanges>(parseInitialZoneRanges());
  const [showHeartRateZonesEditor, setShowHeartRateZonesEditor] = useState(false)
  const [inputValues, setInputValues] = useState<InputValues>(initializeInputValues(parseInitialZoneRanges()));
  const [heartRateProfileUpdated, setHeartRateProfileUpdated] = useState(null)


  // State to store zone counts
  const [zoneCounts, setZoneCounts] = useState<ZoneCounts>({
    zone5: 0,
    zone4: 0,
    zone3: 0,
    zone2: 0,
    zone1: 0,
  });

  // Calculate zone counts whenever data or zone ranges change
  useEffect(() => {
    calculateZoneCounts();
  }, [zoneRanges]);

  // Calculate zone counts based on heart rate data and zone ranges
  const calculateZoneCounts = (): void => {
    const counts: ZoneCounts = {
      zone5: 0,
      zone4: 0,
      zone3: 0,
      zone2: 0,
      zone1: 0
    }

    let prevTime = userDataActivity[0]?.sts || userDataActivity[0]?.startTimeInSeconds || userDataActivity[0]?.ts
    let prevHR = userDataActivity[0]?.hr || userDataActivity[0]?.heartRate || userDataActivity[0]?.heart_rate

    // for loop userDataActivity
    for (const data of userDataActivity) {
      const hr = data?.hr || data?.heartRate || data?.heart_rate;
      const sts = data?.sts || data?.startTimeInSeconds || data?.ts

      if (timeFrame?.start && (sts < timeFrame.start)) continue
      if (timeFrame?.end && (sts > timeFrame.end)) break


      if (Math.abs(sts - prevTime) > 1) {

        const timeFill = Math.abs(sts - prevTime)
        const hrFillAverage = (hr + prevHR) / 2

        for (let i = 0; i < timeFill; i++) {
          let assignedZone = false
          Object.entries(zoneRanges).forEach(([zone, range]: [string, ZoneRange]) => {
            if (assignedZone) return
            if (hrFillAverage >= range.min && hrFillAverage <= range.max) {
              counts[zone]++
              assignedZone = true
            }
          })
        }
      }

      Object.entries(zoneRanges).forEach(([zone, range]: [string, ZoneRange]) => {

        if (hr >= range.min && hr <= range.max) {
          counts[zone]++;
        }
      })

      prevHR = hr
      prevTime = sts
    }

    setZoneCounts(counts);
  };

  // Calculate maximum count for normalization
  // const maxCount: number = Math.max(...Object.values(zoneCounts), 1)

  // Calculate sum of all counts
  const maxCount: number = Object.values(zoneCounts).reduce((acc, count) => acc + count, 0);


  useEffect(() => {
    setZoneRanges(zoneRangesData)
    setInputValues(initializeInputValues(zoneRangesData))
  }, [zoneRangesData])


  useEffect(() => {
    setHasChanges(false)
    handleGetZoneRanges()
    !showHeartRateZonesEditor && setShowSaveDialogue(false)
  }, [showHeartRateZonesEditor])



  const renderHeartRateZonesEditor = () => <Box sx={{ height: '100%' }}>
    <RSHeaderCont className='ellipsis-1l' noBanner isStatic={!isMobile} backClick={() => hasChanges ? setShowSaveDialogue(true) : setShowHeartRateZonesEditor(false)} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title={'Heart Rate Settings'} />
    <HeartRateZonesEditor
      userAccountDetails={userAccountDetails}
      heartRateProfile={heartRateProfileUpdated || heartRateProfile}
      zoneRanges={zoneRanges}
      setZoneRanges={setZoneRanges}
      modeManual={modeManual}
      setModeManual={setModeManual}
      dayDetails={dayDetails}
      setShowHeartRateZonesEditor={setShowHeartRateZonesEditor}
      inputValues={inputValues}
      setInputValues={setInputValues}
      setHeartRateProfileUpdated={setHeartRateProfileUpdated}
      showSaveDialogue={showSaveDialogue}
      setHasChanges={setHasChanges}
    />
  </Box>





  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Box>
        <H2 sx={{ mb: 1 }} >HR Zones</H2>
        <RSCardCont sx={{ display: 'flex', flexDirection: 'column', px: 2, py: 1 }}>
          {/* Sort the zone entries to display zone5 first, followed by zone4, etc. */}
          {Object.entries(zoneCounts)
            .sort(([zoneA], [zoneB]) => {
              // Extract the number from the zone name and compare in reverse
              const zoneNumA = parseInt(zoneA.replace('zone', ''), 10);
              const zoneNumB = parseInt(zoneB.replace('zone', ''), 10);
              return zoneNumB - zoneNumA; // Descending order (zone5 first)
            })
            .map(([zone, count]: [string, number]) => {
              // Extract zone number for display
              const zoneNum = parseInt(zone.replace('zone', ''), 10);

              return (
                <Box key={zone} sx={{ my: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Grid sx={{ display: 'flex' }}>
                      <SH3 sx={{ mr: '5px' }}>Zone {zoneNum} </SH3> <P2>({zoneRanges?.[zone]?.min}-{zoneRanges?.[zone]?.max} bpm)</P2>
                    </Grid>
                    <P1>
                      {moment.utc(count * 1000).format("HH:mm:ss")}
                    </P1>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={(count / maxCount) * 100}
                    className={`zone-color ${getZoneColors(zone)}`}
                    sx={{ height: '15px', borderRadius: 5 }}
                    color='inherit'
                  />
                </Box>);
            })}
        </RSCardCont>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <A sx={{ my: 3 }}
            onClick={() => {
              setShowHeartRateZonesEditor(true)
            }}>
            {'View HR Settings'}</A>
        </Box>
      </Box>


      <RSDrawer fitContent popUpWidth={"auto"} bottom={!isMobile} open={showHeartRateZonesEditor} onClose={() => {
        setShowHeartRateZonesEditor(true)
      }}>
        {renderHeartRateZonesEditor()}
      </RSDrawer>


    </Box>
  );
};

export default HeartRateZonesView


/////////////////////////////////////////////////////


// Heart rate data
const sampleDataGarmin: any = [
  { "startTimeInSeconds": 1740527121, "heartRate": 105 },
  { "startTimeInSeconds": 1740527122, "heartRate": 105 },
  { "startTimeInSeconds": 1740527123, "heartRate": 104 },
  { "startTimeInSeconds": 1740527126, "heartRate": 103 },
  { "startTimeInSeconds": 1740527127, "heartRate": 103 },
  { "startTimeInSeconds": 1740527128, "heartRate": 103 },
  { "startTimeInSeconds": 1740527150, "heartRate": 104 },
  { "startTimeInSeconds": 1740527151, "heartRate": 104 },
  { "startTimeInSeconds": 1740527152, "heartRate": 105 },
  { "startTimeInSeconds": 1740527153, "heartRate": 106 },
  { "startTimeInSeconds": 1740527154, "heartRate": 106 },
  { "startTimeInSeconds": 1740527155, "heartRate": 106 },
  { "startTimeInSeconds": 1740527156, "heartRate": 107 },
  { "startTimeInSeconds": 1740527157, "heartRate": 107 },
  { "startTimeInSeconds": 1740527162, "heartRate": 107 },
  { "startTimeInSeconds": 1740527163, "heartRate": 107 },
  { "startTimeInSeconds": 1740527164, "heartRate": 108 },
  { "startTimeInSeconds": 1740527165, "heartRate": 107 },
  { "startTimeInSeconds": 1740527166, "heartRate": 107 },
  { "startTimeInSeconds": 1740527167, "heartRate": 108 },
  { "startTimeInSeconds": 1740527168, "heartRate": 108 },
  { "startTimeInSeconds": 1740527169, "heartRate": 108 },
  { "startTimeInSeconds": 1740527170, "heartRate": 108 },
  { "startTimeInSeconds": 1740527171, "heartRate": 109 },
  { "startTimeInSeconds": 1740527172, "heartRate": 109 },
  { "startTimeInSeconds": 1740527173, "heartRate": 110 },
  { "startTimeInSeconds": 1740527178, "heartRate": 110 },
  { "startTimeInSeconds": 1740527179, "heartRate": 111 },
  { "startTimeInSeconds": 1740527180, "heartRate": 110 },
  { "startTimeInSeconds": 1740527181, "heartRate": 110 },
  { "startTimeInSeconds": 1740527182, "heartRate": 109 }
];
