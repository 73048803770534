/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import jwt_decode from "jwt-decode";
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import MailIcon from '@mui/icons-material/Mail';
import { appleAuthHelpers } from 'react-apple-signin-auth';
import useStore from '../../../../store/useStore'
import { ICurrentUser } from '../../../../store/createAccountStore'
import { RSButton1 } from '../../../../components/buttons'
import useDeviceDetect from '../../../../hooks/useDeviceDetect'
import useProgressIndicator from './../useProgressIndicator'
import useQueryParams from '../../../../hooks/useQueryParams';
import { WebSocketContext } from '../../../../WebsocketProvider';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI, GOOGLE_CLIENT_AUTH, RECAPTCHA_SITE_KEY } from '../../../../modules/cliEnvValues';
import { IAppleTokenDetails, IAppleUser, IGoogleUser } from '../../../../store/types';
import { createImpactClickId, logIn, signUp, updateAccountInfo } from '../../../../modules/accountActions';
import { IComponentStore } from '../../../../store/createComponentStore';
import { urlWithOffer } from '../../../account/SignIn';
import startCase from 'lodash.startcase';
import { sha1 } from 'locutus/php/strings';
import useAlert from '../../../../hooks/useAlert';
import secureLocalStorage from 'react-secure-storage';
import EmailSignup from '../../../account/EmailSignup';
import GenericAlert from '../../../../components/misc/GenericAlert';
import useStoreWSMessages from '../../../../hooks/useStoreWSMessages';
import useRNBridge from '../../../../hooks/useRNBridge';
import { RouletteSpinnerOverlay } from 'react-spinner-overlay'
import { CreateShareTransaction } from '../../../../modules/shareActions';
import { H1, P1, P3, A } from '../../../../components/text/RSTypography'
import { isMobile } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useAppEvents from '../../../../hooks/useAppEvents';

export type CreateAccountProps = {
	done?: any,
	bodyData?: any,
	setBodyData?: any,
	handleOpenSignIn?: any,
}

const CreateAccount: FC<CreateAccountProps> = ({ done, bodyData, setBodyData, handleOpenSignIn }) => {
	const [signedUp, setSignedUp] = useState(false)
	const { currentUser, setCurrentUser } = useStore((state: ICurrentUser) => state)
	const { mobileType } = useDeviceDetect()
	const navigate = useNavigate();
	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
	const { requestKey, irclickid, offer } = useQueryParams()
	const { sendJsonMessage, lastJsonMessage } = useContext(WebSocketContext);
	const { setIsLoading: setIsLoadingMain, setLoadingMsg, setIsSuccess, lastRNBridgeMessage, setLastRNBridgeMessage } = useStore((state: IComponentStore) => state)
	const [isLoading, setIsLoading] = useState(false)
	const offerType = Number(offer) || undefined
	const [redirect, setRedirect] = useState('')
	const [signupTypedirect, setSignupType] = useState('')
	const [showEmailSignup, setShowEmailSignup] = useState(false)

	const CLICK_ID = localStorage.getItem("irclickid") || irclickid || undefined

	const { storeWebSocketMessages, webSocketMessages, removeMessageByType } = useStoreWSMessages()
	const { sendDataToReactNative } = useRNBridge()
	const { createEvent } = useAppEvents()

	const openUrlIOS = localStorageRequestKey && mobileType.toLowerCase() === "ios"
	const termsLink = 'https://runsmartonline.com/terms-of-use/'
	const privacyLink = 'https://runsmartonline.com/privacy-policy/'
	const waiverLink = 'https://runsmartonline.com/waiver-of-liability/'

	const [messageData, setMessageData] = useState<{ requestKey: string, payload: any, requestType: string, timestamp: number } | undefined>();

	const {
		showAlert,
		onAlertOpen,
		onAlertClose,
		alertTitle,
		alertMessage,
		setAlertMessage,
		alertVariant,
		setAlertVariant,
	} = useAlert()

	const renderBTN = (icon: any, text: string, action: any, className?: string) => {
		return <RSButton1 sx={{ mb: '10px' }} onClick={action} className={className || ''}>
			{icon}
			<Box sx={{ pl: '10px' }}>{text}</Box>
		</RSButton1>
	}

	async function handleRedirect() { }
	const signUpSuccess = () => {
		setSignedUp(true)
	}

	const initImpactConversion = async (orderId: string, account_id: number, email: string) => {
		if (CLICK_ID) {
			// window?.callConversion?.(IMPACT_TRIAL_ID, orderId, String(account_id), sha1(email))
			await createImpactClickId(account_id as number, irclickid, orderId, 'created')
		}
	}

	const createShareTransaction = async (account_id: number) => {
		const referralCode = localStorage.getItem('referral_code')
		if (referralCode && account_id) {
			await CreateShareTransaction(account_id, referralCode, '', 'create_account', 0)
		}
	}

	const onSubmit = async (type?: string, googleUser?: IGoogleUser, appleUser?: IAppleUser) => {
		setIsLoadingMain(true)
		const referralCode = localStorage.getItem('referral_code')
		const signupMethod = mobileType === "Android" ? "android" : mobileType === "Ios" ? "ios" : "web"

		if (googleUser && type === "google") {
			const response = await signUp({
				username: googleUser?.email as string,
				email: googleUser?.email as string,
				password: googleUser?.sub as string,
				signup_method: signupMethod,
				referral_code: referralCode as string || undefined
			})
			const account_id = response?.data?.account_id
			// Login and get token
			if (account_id != null) {
				ReactPixel.trackCustom('BasicAccountCreated', { k: new Date().getTime() });
				// await createShareTransaction(account_id)
				const response = await logIn({
					username: googleUser?.email as string,
					password: googleUser?.sub as string,
				})
				// Go to User Info Page
				if (response?.data?.account_id != null) {
					//disable impact implementation
					// await initImpactConversion(sha1(response?.data?.account_id), response?.data?.account_id, sha1(googleUser?.email as string))
					await createEvent(account_id, 'SIGNED_UP', typeof window.navigator === "undefined" ? "" : navigator.userAgent)

					const result = await updateAccountInfo({
						firstName: startCase(googleUser?.given_name.toLowerCase()),
						lastName: startCase(googleUser?.family_name.toLowerCase()),
						gender: "",
						// favorite: ""
					})
					if (result?.data?.id) {

						setBodyData({
							...bodyData,
							firstName: startCase(googleUser?.given_name.toLowerCase()),
							lastName: startCase(googleUser?.family_name.toLowerCase())
						})

						setCurrentUser(response?.data)
						setIsLoadingMain(false)
						setIsLoading(false)
						setSignedUp(true)
					}
				}
			}

			// User or email exists
			else if (response?.error?.message?.includes('exist')) {
				setIsLoading(false)
				setIsLoadingMain(false)
				setAlertVariant('danger')
				setAlertMessage(response.error.message)
				onAlertOpen()
			}

			else if (JSON.stringify(response)?.toLowerCase()?.includes('many')) {
				setIsLoadingMain(false)
				setIsLoading(false)
				setAlertVariant('danger')
				setAlertMessage(response)
				onAlertOpen()
			}

			// Uncaught error
			else {
				setIsLoadingMain(false)
				setIsLoading(false)
				setAlertVariant('danger')
				setAlertMessage('Signup failed')
				onAlertOpen()
			}
		}

		// Sign Up Apple
		if (appleUser && type === "apple") {
			await signUp({
				username: appleUser?.email as string,
				email: appleUser?.email as string,
				password: appleUser?.password as string,
				signup_method: signupMethod,
				referral_code: referralCode as string || undefined
			}).then(async (response) => {
				const account_id = response?.data?.account_id
				// Login and get token
				if (account_id != null) {
					ReactPixel.trackCustom('BasicAccountCreated', { k: new Date().getTime() });
					// await createShareTransaction(account_id)
					await logIn({
						username: appleUser?.username as string,
						password: appleUser?.password as string,
					}).then(async (response) => {
						if (response?.data?.account_id != null) {
							await createEvent(account_id, 'SIGNED_UP', typeof window.navigator === "undefined" ? "" : navigator.userAgent)

							const data = {
								username: appleUser?.username as string,
								password: appleUser?.password as string,
							}
							secureLocalStorage.setItem("workerKeys", data)
							secureLocalStorage.setItem("isAppleSignup", true)
							//disable impact implementation
							// await initImpactConversion(sha1(response?.data?.account_id), response?.data?.account_id, sha1(appleUser?.email as string))

							await updateAccountInfo({
								firstName: startCase(appleUser?.firstName?.toLowerCase()) || '',
								lastName: startCase(appleUser?.lastName?.toLowerCase()) || '',
								gender: "",
								// favorite: ""
							}).then((result) => {
								if (result?.data?.id) {

									setBodyData({
										...bodyData,
										firstName: startCase(appleUser?.firstName?.toLowerCase()) || '',
										lastName: startCase(appleUser?.lastName?.toLowerCase()) || ''
									})

									setCurrentUser(response?.data)
									setIsLoadingMain(false)
									setIsLoading(false)
									setSignedUp(true)
								}
								else {
									setIsLoading(false)
									setIsLoadingMain(false)
									setAlertVariant('danger')
									setAlertMessage("Error updating account info")
									onAlertOpen()
								}
							})
						}
					})
					// Go to User Info Page
				}
				// User or email exists
				else if (response?.error?.message?.includes('exist')) {
					setIsLoading(false)
					setIsLoadingMain(false)
					setAlertVariant('danger')
					setAlertMessage(response.error.message)
					onAlertOpen()
				}

				else if (JSON.stringify(response)?.toLowerCase()?.includes('many')) {
					setIsLoading(false)
					setIsLoadingMain(false)
					setAlertVariant('danger')
					setAlertMessage(response)
					onAlertOpen()
				}

				// Uncaught error
				else {
					setIsLoading(false)
					setIsLoadingMain(false)
					setAlertVariant('danger')
					setAlertMessage('Signup failed')
					onAlertOpen()
				}
			})
		}
	}

	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			const userInfo = await axios.get(GOOGLE_CLIENT_AUTH, { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } });

			if (userInfo) {
				onSubmit("google", userInfo?.data)
				// setGoogleUser(userInfo?.data as IGoogleUser)
			}
			else {
				alert('Sign up failed')
			}
		},
		onError: () => {
			alert('Sign up failed')
		},
	});

	const signupApple = async () => await appleAuthHelpers.signIn({
		authOptions: {
			clientId: APPLE_CLIENT_ID,
			scope: 'email name',
			redirectURI: APPLE_REDIRECT_URI,
			state: 'state',
			nonce: 'nonce',
			usePopup: true
		},

		onSuccess: async (response: any) => {
			const tokenDetails: IAppleTokenDetails = await jwt_decode(response?.authorization?.id_token)
			if (response && tokenDetails) {
				const data: IAppleUser = {
					username: tokenDetails?.email,
					password: tokenDetails?.sub,
					email: tokenDetails?.email,
					firstName: response?.user?.name?.firstName,
					lastName: response?.user?.name?.lastName,
				}
				onSubmit("apple", undefined, data)
			}
			else {
				setAlertVariant('danger')
				setAlertMessage('Signup failed')
				onAlertOpen()
			}
		},
		onError: (error: any) => {
			if (error.error !== "popup_closed_by_user") {
				setAlertVariant('danger')
				setAlertMessage(startCase(error.error))
				onAlertOpen()
			}
		},
	});

	const handleGoogleLogin = () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if (requestKey || localStorageRequestKey) {
			// sendJsonMessage && sendJsonMessage({ requestType: 'google-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
			sendDataToReactNative({ requestType: 'google-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
		}
		else
			return googleLogin()
	}

	const handleAppleLogin = () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if ((requestKey || localStorageRequestKey)) {
			// sendJsonMessage && sendJsonMessage({ requestType: 'apple-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
			sendDataToReactNative({ requestType: 'apple-signin-webapp', requestKey: requestKey || localStorageRequestKey, payload: {}, timestamp: new Date().getTime() })
		}
		else
			return signupApple()
	}

	const handleSuccessEmailSignup = (accountId: number) => {
		setShowEmailSignup(false)
		setBodyData({
			...bodyData,
			id: accountId
		})

		localStorage.setItem('user_id', String(accountId))
	}

	const handleOpenUrl = (url: string) => {
		const dataToSend = {
			requestType: 'open-rs-shop',
			payload: { url: url },
			timestamp: new Date().getTime(),
		}
		sendDataToReactNative(dataToSend);
	}


	useEffect(() => {
		if (bodyData?.id) {
			done?.()
		}
	}, [bodyData])

	useEffect(() => {
		if (currentUser?.account_id && !bodyData?.id) {
			setBodyData({
				...bodyData,
				id: currentUser.account_id
			})
		}
	}, [signedUp, currentUser])

	// useEffect(() => {
	// 	const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
	// 	const googleSignData = webSocketMessages.find(x => (x?.requestKey === requestKey || x?.requestKey === localStorageRequestKey) && (x?.requestType === "google-signin-mobileapp"))
	// 	if (googleSignData) {
	// 		onSubmit("google", googleSignData.payload)
	// 	}
	// }, [webSocketMessages]);

	useEffect(() => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		if (lastRNBridgeMessage) {
			const messageData = JSON.parse(lastRNBridgeMessage)
			if (messageData?.requestKey === localStorageRequestKey) {
				setMessageData(messageData)
				setLastRNBridgeMessage(undefined)
			}
		}
	}, [lastRNBridgeMessage]);

	useEffect(() => {
		if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData?.requestType === 'apple-success-signin' && messageData.payload) {
			const tokenDetails: IAppleTokenDetails = jwt_decode(messageData?.payload?.identityToken)
			const fullName = messageData?.payload?.fullName
			const appleUser: IAppleUser = {
				username: tokenDetails.email,
				password: tokenDetails.sub,
				email: tokenDetails.email,
				firstName: fullName?.givenName || '',
				lastName: fullName?.familyName || ''
			}

			onSubmit("apple", undefined, appleUser)
		}
		else if ((messageData?.requestKey === requestKey || messageData?.requestKey === localStorageRequestKey) && messageData?.requestType === 'google-signin-mobileapp' && messageData.payload) {
			onSubmit("google", messageData.payload)
		}
	}, [messageData]);

	return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: !isMobile ? 'center' : 'flex-start' }}>
		{/* Alert */}
		<GenericAlert
			show={showAlert}
			title={alertTitle}
			message={alertMessage}
			variant={alertVariant}
			onClose={onAlertClose}
		/>

		<H1 size={5} sx={{ mb: 2, px: 2, textAlign: !isMobile ? 'center' : 'center' }}>
			Create Your Account
		</H1>
		<P1 sx={{ pb: 2, width: '100%', textAlign: 'center' }}>
			Already have an account?{' '}
			<A onClick={handleOpenSignIn} p1 underline>
				Log in
			</A>
		</P1>
		<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
			<EmailSignup isSignUp={true} handleSuccessEmailSignup={handleSuccessEmailSignup} />
		</GoogleReCaptchaProvider>
		{!showEmailSignup && <Grid container sx={{ pt: '10px', px: 2 }} alignItems='center'>
			<P1 sx={{ pb: 2, width: '100%', textAlign: 'center' }}>
				By signing up, you agree to our{' '}
				<A link={termsLink} onClick={openUrlIOS ? () => handleOpenUrl(termsLink) : undefined} newWindow p1 underline>
					Terms of Service
				</A>
				,{' '}
				<A link={privacyLink} onClick={openUrlIOS ? () => handleOpenUrl(privacyLink) : undefined} newWindow p1 underline>
					Privacy Policy
				</A>
				,{' '}
				<A link={waiverLink} onClick={openUrlIOS ? () => handleOpenUrl(waiverLink) : undefined} newWindow p1 underline>
					Waiver of Liability
				</A>
				,{' '}
				and receive our email updates.
			</P1>

		</Grid>}
		<Grid container xs={12} justifyContent='center' alignItems='center'>
			<Box className='gray-input-border' sx={{ width: 'calc(50% - 20px)', borderTop: '1px solid gray' }} />
			<P3 size={1} sx={{ width: '40px', textAlign: 'center' }}>or</P3>
			<Box className='gray-input-border' sx={{ width: 'calc(50% - 20px)', borderTop: '1px solid gray' }} />
		</Grid>
		<Grid container sx={{ pt: 2, pb: '5px', px: 2 }} alignItems='center' justifyContent='center' >
			<Box sx={{ width: '230px' }}>
				{mobileType !== "Android" && renderBTN(<AppleIcon />, 'Sign up with Apple', handleAppleLogin, 'create-account-btn-apple')}
				{renderBTN(<GoogleIcon />, 'Sign up with Google', handleGoogleLogin, 'create-account-btn-google')}
			</Box>
		</Grid>
	</Box>
}

export default CreateAccount
