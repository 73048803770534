import { FC, useEffect, useState } from "react"
import { navReq } from "../../modules/apiConsume"
import { addActivePlan, removeActivePlan } from "../../modules/programActions"
import { ITrainingPlanStore } from "../../store/createTrainingPlanStore"
import useStore from "../../store/useStore"
import { RSButton1 } from '../buttons';
import { H1, P1 } from '../text/RSTypography'
import RSDrawer from "../layout/RSDrawer"
import RSPopUpModal from "../layout/RSPopUpModal"
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useAppEvents from "../../hooks/useAppEvents"
import { createInterest } from "../../modules/interestsActions"


export type ActivePlanUpdatePanelProps = {
    show: boolean
    setShow: (x: boolean) => any
    setPlanState?: (x: boolean) => any
    programTitle?: string
    programCategory?: string
    activePlanId?: number
    activePlanProgress?: number
    userId?: number
    programId?: number
    confirmation?: boolean | string
    hideReset?: boolean
    setConfirmation?: (x: boolean | string) => any
    getActivePlanDetails?: (arg0: any) => any
    onboarding?: boolean
    handleCloseModal?: () => void
    handleAdditionalAction?: () => Promise<void>
}


const ActivePlanUpdatePanel: FC<ActivePlanUpdatePanelProps> = ({
    programTitle,
    programCategory,
    activePlanProgress,
    userId,
    programId,
    activePlanId,
    show,
    confirmation = false,
    hideReset,
    setConfirmation,
    setPlanState,
    setShow,
    getActivePlanDetails,
    onboarding,
    handleCloseModal,
    handleAdditionalAction
}) => {

    const [showConfirmation, setShowConfirmation] = useState<boolean | string>(confirmation)

    const { createEvent } = useAppEvents()
    const { setIsOnboardingSelected } = useStore((state: ITrainingPlanStore) => state)
    const isMobile = useIsMobileScreen()
    const performActivePlanUpdate = async (func: Promise<any>) => {
        const updatePerformed = await func
        if (updatePerformed) {
            setShow(false)
            setShowConfirmation(false)
            handleCloseModal && handleCloseModal()
        }
    }

    const closeConfirmation = () => {
        if (setConfirmation) setConfirmation(false)
        setShowConfirmation(false)
    }

    useEffect(() => {
        setShowConfirmation(confirmation)
    }, [confirmation])

    return <>

        <RSDrawer bottom fitContent light sx={{ visibility: showConfirmation ? 'hidden' : 'visible' }} popUpWidth={'500px'} popUpHeight={'200px'} open={show} onClose={() => setShow(false)}>
            <div className="plan-panel">

                <H1 size={3} sx={{ pb: 1 }}>{programTitle}</H1>

                <P1 size={1}>{activePlanProgress ? activePlanProgress : '0'}% Complete</P1>

                <div className='plan-panel--buttons'>

                    {programId && userId && !hideReset &&
                        <RSButton1 sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center',
                            maxWidth: '400px'
                        }}
                            onClick={() => {
                                setShowConfirmation('reset')
                            }}
                        >
                            Reset Progress
                        </RSButton1>}

                    {activePlanId &&
                        <RSButton1 secondary sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            textAlign: 'right',
                            maxWidth: '400px'
                        }}
                            onClick={() => {
                                setShowConfirmation('stop')
                            }}
                        >
                            {`${onboarding ? 'Remove' : 'Stop'} Plan`}
                        </RSButton1>}

                </div>
            </div>
        </RSDrawer>

        <RSPopUpModal popUpWidth={isMobile ? '90%' : '500px'} popUpHeight={'auto'} open={showConfirmation != false} onClose={() => closeConfirmation()}>
            <div className="plan-panel">
                <div className='center-align--col'>
                    <h4>{showConfirmation === 'add' ? programTitle : 'Are you sure?'}</h4>
                    {showConfirmation === 'reset' &&
                        <p>Resetting your progress will clear all progress for {programTitle}</p>}

                    {showConfirmation === 'stop' ? (onboarding ? <p>Are you sure you want to remove this plan to your homepage?</p> : <p>Stopping your plan will clear your progress and remove it from home</p>) : undefined}

                    {showConfirmation === 'add' ? (onboarding ? <p>Are you sure you want to add this plan to your homepage?</p> : <p>Starting this program will add it to your homepage and track your progress</p>) : undefined}

                </div>

                <div className='plan-panel--buttons'>

                    {programId && userId && <>

                        {showConfirmation != 'add' && activePlanId ?
                            <RSButton1 sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                justifyItems: 'center',
                                alignItems: 'center',
                                maxWidth: '400px'
                            }}
                                onClick={async () => {

                                    const eventMetadata = {
                                        program_id: programId,
                                        program_title: programTitle,
                                        current_progress: activePlanProgress
                                    }

                                    if (showConfirmation === 'reset') {
                                        handleAdditionalAction && await handleAdditionalAction()

                                        await performActivePlanUpdate(addActivePlan({ user_id: userId, program_id: programId }))
                                        await createEvent(userId, "PROGRAM_RESET_PROGRESS", JSON.stringify(eventMetadata))
                                        programCategory && await createInterest({ user_id: userId, interest_tag: programCategory?.toLowerCase().replaceAll(' ', '_') || '' })
                                        programTitle && programTitle.toLowerCase() === "running plans" ? navReq('/#/training-plan/build/step-1') : null

                                        programTitle && programTitle.toLowerCase() === "runsmart recover" ? navReq('/#/training-plan/build/step-recover') : null
                                    }
                                    else if (showConfirmation === 'stop') {
                                        programTitle && programTitle.toLowerCase() === "running plans" ? setIsOnboardingSelected(false) : null
                                        await performActivePlanUpdate(removeActivePlan(activePlanId))
                                        await createEvent(userId, "PROGRAM_REMOVED", JSON.stringify(eventMetadata))
                                        setPlanState && setPlanState(false)
                                    }
                                }}
                            >
                                Yes, {showConfirmation === 'reset' ? 'reset my progress' : `${onboarding ? 'remove' : 'stop'} this plan`}
                            </RSButton1> : null}

                        {showConfirmation === 'add' && getActivePlanDetails &&
                            <RSButton1 sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                justifyItems: 'center',
                                alignItems: 'center',
                                maxWidth: '400px'
                            }}
                                onClick={
                                    async () => {
                                        const eventMetadata = {
                                            program_id: programId,
                                            program_title: programTitle,
                                            current_progress: activePlanProgress
                                        }
                                        handleAdditionalAction && await handleAdditionalAction()
                                        programTitle && programTitle.toLowerCase() === "running plans" ? setIsOnboardingSelected(true) : null

                                        !hideReset && programTitle && programTitle.toLowerCase() === "running plans" ? navReq('/#/training-plan/build/step-1') : null

                                        !hideReset && programTitle && programTitle.toLowerCase() === "runsmart recover" ? navReq('/#/training-plan/build/step-recover') : null

                                        getActivePlanDetails(await addActivePlan({ user_id: userId, program_id: programId }))
                                        await createEvent(userId, "PROGRAM_ADDED", JSON.stringify(eventMetadata))
                                        programCategory && await createInterest({ user_id: userId, interest_tag: programCategory?.toLowerCase().replaceAll(' ', '_') || '' })
                                        onboarding && handleCloseModal && handleCloseModal()
                                    }

                                }>
                                {`${onboarding ? 'Add' : 'Start'} Plan`}
                            </RSButton1>}

                    </>}

                    <RSButton1 secondary sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        textAlign: 'right',
                        maxWidth: '400px'
                    }}
                        onClick={() => closeConfirmation()}
                    >
                        Cancel
                    </RSButton1>

                </div>
            </div>
        </RSPopUpModal>
    </>
}

export default ActivePlanUpdatePanel;
