
import { StoreSlice } from "./types"

export interface ISale {
    id: number;
    headline: string;
    subtext: string;
    banner: string;
    start: Date;
    end: Date;
    active: number;
}

export interface ISaleStore {
    activeSale: ISale | undefined,
    setActiveSale: (sale: ISale) => void,
    allSale: ISale | undefined,
    setAllSale: (sale: ISale) => void,
    isSaleChecked: boolean,
    setIsSaleChecked: (isSaleChecked: boolean) => void
}

const createSaleStore: StoreSlice<ISaleStore> = (set) => ({
    activeSale: undefined,
    setActiveSale: (activeSale) => set({ activeSale }),
    allSale: undefined,
    setAllSale: (allSale) => set({ allSale }),
    isSaleChecked: false,
    setIsSaleChecked: (isSaleChecked) => set({ isSaleChecked })
})

export default createSaleStore