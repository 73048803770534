/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-loss-of-precision */


import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import is_numeric from 'locutus/php/var/is_numeric'

import { motion, AnimatePresence } from "framer-motion";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import WeatherIcon from '@mui/icons-material/WbSunny';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'
import DArrowForward from '@mui/icons-material/KeyboardDoubleArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import StarFilled from '@mui/icons-material/StarOutlined';
import StarEmpty from '@mui/icons-material/StarOutlineOutlined';
import StarHalf from '@mui/icons-material/StarHalfOutlined';
import CheckIcon from '@mui/icons-material/Check';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { AccessTimeFilledSharp, CheckCircleOutlineRounded, HighlightOffOutlined } from '@mui/icons-material';
import recoverVideos from '../../pages/recover/DailyRecoveryVideos.json'

import AddMaintenancePlan from './AddMaintenancePlan';
import AppleWatchWorkout from './AppleWatchWorkout';
import AppleWatchWorkoutLaps from './AppleWatchWorkoutLaps';
import CompletePlan from './CompletePlan'
import RecoveryVideos from './RecoveryVideos';
import RecoveryAssessments from './RecoveryAssessments';
import useStore from '../../store/useStore'
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore'
import { ICurrentUser } from '../../store/createAccountStore'
import { RaceResult, UserNotesRecord, UserRaceResults } from '../../api/v2/types'
import { titleCase, getGuidance } from '../../modules/miscUtils'
import { CreateUserCustomPace, UpdateUserCustomPace, CreateUserNotes, UpdateUserNotes, CreateRaceResult, UpdateRaceResult, GetUserInfo, getGarminSyncData, CreateChangeLog } from '../../modules/trainingPlanActions'
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils'
import useQueryParams from '../../hooks/useQueryParams';
import useBuildTrainingPlan from '../../pages/training-plan/hooks/useBuildTrainingPlan'
import raceSelection from '../../pages/training-plan/build/RaceSelection.json'
import SharePlan from '../../pages/training-plan/SharePlan'
import IntegrationsMainPage from '../../pages/integrations/IntegrationsMainPage';
import { navReq, putReq } from '../../modules/apiConsume';
import { Video } from '../../modules/videos/allVideos';
import defaultExercises from './default-exercise.json'
import { RSButton1, RSButton2 } from '../../components/buttons'
import RSCardCont from '../../components/cards/RSCardCont'
import RSTrainingCard from '../../components/cards/RSTrainingCard'
import RSFlexCard from '../../components/cards/RSFlexCard'
import RSVideoCard from '../../components/cards/RSVideoCard'
import RSDrawer from '../../components/layout/RSDrawer'
import RSUnlock from '../../components/misc/RSUnlock'
import RSUpgrade from '../../components/misc/RSUpgrade'
import CustomAccordion from "../../components/misc/CustomAccordion"
import { H2, H3, H4, SH1, SH2, SH3, SH4, SH5, P1, P2, P3, P4, A } from '../../components/text/RSTypography'
import WorkoutVideoContainer from '../../pages/training-plan/components/WorkoutVideoContainer';
import numberHelperUtil from '../../utils/numberHelperUtil';
import textHelperUtils from '../../utils/textHelperUtils';
import { IAppleHealthKitStore } from '../../store/createAppleHealthKitStore';
import { AppleHealthActivity } from '../../api/v2/apple-health-kit/apple-health-kit.types';
import { CreateAppleHealtActivity, GetAppleHealtActivity, UpdateAppleHealtActivity } from '../../modules/appleHealthKitActions';
import useHealthActivity, { WorkoutData } from '../../hooks/useHealthActivity';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import SurfaceTypes from './SurfaceTypes';
import RSIcon from '../../components/icons/RSIcon'
import { changeDaySurfaceType, getSurfaceTypeByPlanId } from '../../modules/trainingPlanActions'
import Spinner from 'react-bootstrap/Spinner'
import Loader from '../animation/Loader';
import { getCorosScheduledWorkouts, getCorosUserByUserId, getUserWorkouts } from '../../modules/corosActions';
import { CorosWorkout } from '../../api/v2/coros/coros.types';
import CorosWatchWorkout from './CorosWatchWorkout';
import { CorosScheduledWorkout } from '../../api/v2/coros/coros.scheduled.types';
import TrainingActivityMap from './TrainingActivityMap';
import Slider from '@mui/material/Slider';
import TrainingDayFeedback from './TrainingDayFeedback';
import { Samples } from '../../api/v2/garmin-connect/garmin-connect.types';
import RSHeaderCont from '../layout/RSHeaderCont';
import Lottie from 'lottie-react';
import RunningLoader from '../animation/runsmart_loader.json'
import { LineChart } from '@mui/x-charts/LineChart';
import HeartRateGraph from './HeartRateGraph';
// import HeartRateGraphZones from './HeartRateGraphZones';
import HeartRateZonesView from './HeartRateZonesView';
import HeartRateBase from './HeartRateBase';

const variants = {
    enter: (direction: number) => {
        return {
            opacity: 0,
            x: direction > 0 ? "100%" : "-100%"
        };
    },
    center: {
        opacity: 1,
        x: 0
    },
    exit: (direction: number) => {
        return {
            opacity: 0,
            x: direction > 0 ? "-100%" : "100%"
        };
    }
};

const enabledTestFeatures = true
const defaultSurfaceType = 'road' || 'tread'

const DailyActivity = (props: any) => {
    const { fr } = useQueryParams()
    const navigate = useNavigate()
    const [initTab, setInitTab] = useState(0)
    const [activeTab, setActiveTab] = useState(0)
    const prevActiveTab = useRef(0)
    const [isEditing, setIsEditing] = useState(false)
    const [miles, setMiles] = useState('')
    const [paces, setPaces] = useState<any>({})
    const [isMarkComplete, setIsMarkComplete] = useState(false)
    const [isTakingNotes, setIsTakingNotes] = useState(false)
    const [comment, setComment] = useState('')
    const [newComment, setNewComment] = useState('')
    const [currentId, setCurrentId] = useState(undefined)
    const [isDayComplete, setIsDayComplete] = useState(0)
    const [showWeatherAlert, setShowWeatherAlert] = useState(false)
    const [weatherAlertAdjustList, setWeatherAlertAdjustList] = useState<any>([])
    const [checkedAdjustPlan, setCheckedAdjustPlan] = useState<any>({})
    const [checkAll, setCheckAll] = useState(true)
    const [isValid, setIsValid] = useState(false)
    const [hasRecoveryAssessment, setHasRecoveryAssessment] = useState(false)
    const [workoutVideos, setWorkoutVideos] = useState<any>({ videos1: [], videos2: [] })
    const { currentDayActivity, currentLocation } = useStore((state: ITrainingPlanStore) => state)
    const { computeKmPace, ConvertMetersToMiles, ConvertS2M, getGarminPace, ConvertS2TimeObject } = useTrainingPlanUtils();
    const [isAfterCurrentDate, setIsAfterCurrentDate] = useState(false)
    const [rate, setRate] = useState(0)
    const [lapsData, setLapsData] = useState<any>([])
    const [updateRaceResult, setUpdateRaceResult] = useState(false)
    const [showAssessment, setShowAssessment] = useState(false)
    const { getWTAValue, weatherAdjust, getWeatherData, getAdjustmentValue } = useBuildTrainingPlan();
    const [invalidGarminPermission, setInvalidGarminPermission] = useState(false)
    const [hasSyncData, setHasSyncData] = useState(false)
    const [addMaintenance, setAddMaintenance] = useState(false)
    const [doNotAddMaintenance, setDoNotAddMaintenance] = useState(false)
    const [workOutDuration, setWorkOutDuration] = useState<Array<any> | null>(null)
    const [durationList1, setDurationList1] = useState<Array<any> | null>(null)
    const [durationList2, setDurationList2] = useState<Array<any> | null>(null)
    const isMobile = useIsMobileScreen();
    const { findNumberWord } = numberHelperUtil()
    const { capitalizeEachWord } = textHelperUtils()
    const { setPermission, permission: appleHealthPermission, dataGenerated, setDataGenerated } = useStore((state: IAppleHealthKitStore) => state)
    const [appleHealthActivity, setAppleHealthActivity] = useState<AppleHealthActivity | undefined>()
    const [appleHealthActivityLaps, setAppleHealthActivityLaps] = useState<any | undefined>()
    const [isLoadingAppleHealth, setIsLoadingAppleHealth] = useState<boolean>(false)
    const [displayCrossTrainingModal, setDisplayCrossTrainingModal] = useState(false)
    const [hasAppleHealthData, setHasAppleHealthData] = useState<boolean>(false)
    const { getWorkoutLaps } = useHealthActivity()
    const [corosUserData, setCorosUserData] = useState<any>()
    const [corosUserActivity, setCorosUserActivity] = useState<CorosWorkout[]>([])
    const [isLoadingCoros, setIsLoadingCoros] = useState(false)


    // >> Surface Related
    const [activeSurface, setActiveSurface] = useState('road')
    const [prevSurfaceType, setPrevSurfaceType] = useState('')
    const [prevSurfaceModifier, setPrevSurfaceModifier] = useState(1)
    const [surfaceModifier, setSurfaceModifier] = useState(1)
    const [surfaceUnit, setSurfaceUnit] = useState('TIME')
    const [openSurface, setOpenSurface] = useState(false)
    const [updatePaceValues, setUpdatePaceValues] = useState(false)
    const [currentTotalDistance, setCurrentTotalDistance] = useState(0)
    const [intervalPaces, setIntervalPaces] = useState<any>([])
    const [allDaySurfaceType, setAllDaySurfaceType] = useState<any>([])
    const [activeSurfaceIcon, setActiveSurfaceIcon] = useState()
    const [updateCurrentSurfaceType, setUpdateCurrentSurfaceType] = useState(false)
    const [defaultPaces, setDefaultPaces] = useState<any>([])
    const [displayTread, setDisplayTread] = useState(false)
    const [isSurfaceAdjustedPaceValues, setIsSurfaceAdjustedPaceValues] = useState(false)
    const [loadingPaces, setLoadingPaces] = useState(false)
    const [loadedPaces, setLoadedPaces] = useState<any>([])
    const [loadingSurface, setLoadingSurface] = useState(false)
    const [loadingSurfaceChange, setLoadingSurfaceChange] = useState(false)
    const [loadingInitialLanding, setLoadingInitialLanding] = useState(true)
    const [showMap, setShowMap] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState<{ title: string, samples: Samples[], activityId?: number } | undefined>()
    const [showEffortFeedback, setShowEffortFeedback] = useState(false)
    let currentPaces = {}
    // <<

    const [showHeartRateTracker, setShowHeartRateTracker] = useState(false)

    const requestKey = localStorage.getItem('deviceRequestKey')
    const newFeatures = localStorage.getItem('new-features')

    const { mobileType } = useDeviceDetect()

    const isNativeIOS = requestKey && mobileType.toLowerCase() === 'ios'



    const { userNotes, allUserNotes, userInfo, dayData, selectedRace, getDailyActivityDisplay, getColorCode, getWorkoutVideoDetails, handleOpenVideo, distanceInterpret, convertPaceBase, getConvertedPace, userCustomPaces, getOverridePaceData, getAllUserNotes, getUserNotes, setLoadingValues, currentDayActivities, userGarminDetails, weekDays, createGarminWorkout, groupPaces, setSelectedDay, setSelectedWeek, selectedDay, selectedWeek, isForward, setChangeLoc, finishedGarminSync, hasGarminSyncError, setFinishedGarminSync, getRaceResult, raceResults, loadingValues, accountInfo, createCorosWorkout, loadingGarminActivity, accountProfile, loadingNewResult, setLoadingNewResult, setLoadingWatch, gptPlanId, watchResults } = props
    const isRaceDay = (userInfo?.race_date && dayData?.date && moment(userInfo?.race_date)?.format('YYYY MM DD') === moment(moment(dayData?.date))?.format('YYYY MM DD') && userInfo?.type !== 4)
    let isLastDay = userInfo?.race_date && dayData?.date && moment(dayData?.date)?.isAfter(moment(moment(userInfo?.race_date).format('YYYY/MM/DD'))) && userInfo?.type !== 4
    isLastDay = userInfo?.race_date && userInfo?.type === 4 && userInfo?.raceResults?.id && moment(dayData?.date)?.isSameOrAfter(moment(moment(userInfo?.race_date).format('YYYY/MM/DD'))) ? true : isLastDay

    const isRaceDayAndBeyond = userInfo?.race_date ? moment(moment().format('YYYY/MM/DD')).isAfter(moment(moment(userInfo?.race_date).subtract(1, 'd').format('YYYY/MM/DD'))) : false
    const isRaceDayToday = userInfo?.race_date ? moment(moment().format('YYYY/MM/DD')).isSame(moment(moment(userInfo?.race_date).format('YYYY/MM/DD'))) : false

    const [corosScheduledWorkout, setCorosScheduledWorkout] = useState<CorosScheduledWorkout>()

    const { subscription } = useStore((state: ICurrentUser) => state)
    const isFreeWeek = (userInfo?.start && dayData?.date && moment(dayData?.date).isBefore(moment(userInfo?.start).add(7, 'd'))) && subscription?.accessDetails?.type === "basic" ? true : false
    const locked = subscription?.locked && !isFreeWeek
    const trainingPLanAccess = subscription?.accessDetails?.access?.training_plan
    const { plan_notes, plan_pacing, plan_est_duration, plan_video } = trainingPLanAccess || {}
    const estDurationLocked = !plan_est_duration && !isFreeWeek
    const notesLocked = !plan_notes && !isFreeWeek
    const pacingLocked = !plan_pacing && !isFreeWeek
    const videoLocked = !plan_video && !isFreeWeek
    const handleUpdateSurfaceType = async () => {

        // console.log('----changing surface type----')

        let maxWeeks: [] | undefined = []

        if (userInfo?.raceResults) {
            maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
        }

        const overrideDayActivity = getDailyActivityDisplay(
            dayData,
            userInfo,
            dayData?.marathonPaces,
            selectedRace?.value as number,
            dayData?.marathon?.race_day,
            allUserNotes)

        const dailyActivity = overrideDayActivity || allUserNotes ? overrideDayActivity?.split('|') : currentDayActivity?.split('|')
        const isKm = userInfo?.km === "Y" ? "KMS" : "MILES";
        const isKm2 = userInfo?.km === "Y" ? "KM" : "MI";
        let trainVal = dailyActivity?.[0]?.includes(isKm) ? dailyActivity[0] : dailyActivity[0]?.replace(isKm2, isKm)
        trainVal = trainVal?.trim()
        trainVal = trainVal?.split(' ')?.map((tv: any) => is_numeric(tv) ? tv : tv.charAt(0) + tv.slice(1).toLowerCase())?.join(' ')
        const valSplit = trainVal.split(' ')

        let distance = valSplit[0]
        const isNumber = is_numeric(distance || valSplit[0])

        const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == dayData?.marathon?.id)?.distance ? true : false

        distance = !hasCustomDistance && isNumber && rr && maxWeeks ? (distance * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : valSplit[0]

        setCurrentTotalDistance(distance)


        setIntervalPaces([])

        // console.log('selectedRace: ', selectedRace)

        dayData?.marathonPaces?.map((val: any, idx: number) => {


            let paceValue = getConvertedPace(val).value[0]
            const isDefaultSurface = surfaceModifier === 1 || activeSurface === defaultSurfaceType
            let missingPaceValue = false

            // console.log(': activeSurface = ', activeSurface, ': surfaceModifier = ', surfaceModifier)

            // enable this if you want to save edits in default pace when reverting back to default surface
            // if (prevSurfaceType == defaultSurfaceType) {
            // 	initialPaces.push({ pid: val.paceId, pace: String(paceValue) })
            // }

            if (isDefaultSurface) {

                // updateCurrentSurfaceType && console.log(': dayData.week = ', dayData?.marathon?.week, ': selectedWeek = ', selectedWeek.key)
                // updateCurrentSurfaceType && console.log(': dayData.day = ', dayData?.marathon?.day, ': selectedDay = ', selectedDay)

                // const originalInitialValues = JSON.parse(allDaySurfaceType.find((x: any) => x.day === dayData?.marathon?.day && x.week === dayData?.marathon?.week && userInfo?.uid === x.plan_id)?.original_pace || '[]')
                const dayNum = moment(dayData?.date).day() + 1 || moment(dayData?.date).day() + 1 || selectedDay
                const originalInitialValues = JSON.parse(allDaySurfaceType?.find((e: any) => userInfo?.uid === e.plan_id && (e.activity_id === dayData?.marathon?.id))?.original_pace || '[]')

                // choose whether to get from DB or from saved state
                // enable this to get from saved state first (edits get carried over surface change pace)
                // const revertToOriginalPaceValueState = defaultPaces.length == 0 ? defaultPaces[idx]?.pace : originalInitialValues && originalInitialValues[idx]?.pace
                // enable this to get from DB first (when reverting back to default pace, original pace from DB is used)
                const revertToOriginalPaceValue = originalInitialValues.length > 0 ? originalInitialValues[idx]?.pace : defaultPaces.length > 0 && defaultPaces[idx]?.pace

                // console.log('originalInitialValues: ', originalInitialValues)
                // console.log('defaultPaces: ', defaultPaces)

                // originalInitialValues.length > 0 && console.log('>>> getting original pace value from DB: ', revertToOriginalPaceValue)
                // originalInitialValues.length == 0 && defaultPaces.length > 0 && console.log('>>> getting original pace value from state: ', revertToOriginalPaceValue)

                if (revertToOriginalPaceValue) {
                    paceValue = revertToOriginalPaceValue
                    // console.log('>>> switching back to default values: ', paceValue)
                } else if (defaultPaces && defaultPaces[idx]?.pace) {
                    paceValue = defaultPaces[idx]?.pace
                } else {
                    missingPaceValue = true
                }
            }

            if (val.multiplier) {
                paceValue = revertedMultipliedPace(paceValue, val.multiplier)
            }

            const currentMinVal = Number(paceValue.split(' ')[0]?.replace('m', '')) || 0
            const currentSecVal = Number(paceValue.split(' ')[1]?.replace('s', '')) || 0

            const totalMins = currentMinVal + (1 / (60 / currentSecVal))

            const newTotalMins = totalMins / prevSurfaceModifier * surfaceModifier
            const newLeftoverSecs = (newTotalMins % 1) * 60

            const shouldApplyDefaultValues = (isDefaultSurface && prevSurfaceType !== defaultSurfaceType) && !missingPaceValue
            const newMinVal = shouldApplyDefaultValues ? currentMinVal.toString() : (Math.floor(newTotalMins)).toFixed(0)
            const newSecVal = shouldApplyDefaultValues ? (currentSecVal).toString() : newLeftoverSecs.toFixed(0)
            // const newMinVal = (Math.floor(newTotalMins)).toFixed(0)
            // const newSecVal = newLeftoverSecs.toFixed(0)

            // shouldApplyDefaultValues && console.log('>>> applying default values...', newMinVal, newSecVal)

            handlePacesChange(newMinVal, idx, 'm', val, getConvertedPace(val).value[0], val.multiplier)

            handlePacesChange(newSecVal, idx, 's', val, getConvertedPace(val).value[0], val.multiplier)

        })


        setUpdatePaceValues(true)
        setIsSurfaceAdjustedPaceValues(true)
        setPrevSurfaceModifier(surfaceModifier)
        // setPrevSurfaceType(activeSurface)


        // moved after successful updating paces
        // setUpdateCurrentSurfaceType(true)

    }


    const handlePacesChange = (e: any, parentI: any, childI: any, val: any, defaultValue: any, multiplier: number) => {

        let inputVal = e.target?.value ? e.target.value : e

        inputVal = isNaN(Number(inputVal)) ? '' : inputVal

        defaultValue = defaultValue.replaceAll('[object', '')

        const paceId = val.paceId + (val.mar_id * 10000)


        if (!(childI === 's' && Number(inputVal) > 59)) {
            let newVal = inputVal !== '' ? `${inputVal}${childI}|` : defaultValue.split(' ').find((c: any) => c.includes(childI))
            newVal = inputVal === '0' ? `${childI}|` : newVal
            const dSplit = defaultValue.split(' ')
            const isExisting = dSplit.find((x: any) => x.includes(childI))
            const dPace = isExisting ? dSplit.map((x: string) => x.includes(childI) ? newVal : x) : (childI === 's' ? [...dSplit, newVal] : [newVal, ...dSplit])
            const customPace = userCustomPaces.find((x: any) => x.pid == paceId)


            const paceData = customPace ? { ...customPace, pace: dPace.join(' ').trim(), update: true } : { pid: paceId, uid: userInfo?.uid, pace: dPace.join(' ').trim() }
            paceData.defaultValue = defaultValue
            const pacesClone = Object.keys(currentPaces).length === 0 ? { ...paces } : { ...currentPaces }
            const parentClone = pacesClone?.[parentI]

            if (parentClone) {
                const pCSplit = parentClone.pace.split(' ')
                const isPCExisting = pCSplit.find((x: any) => x.includes(childI))
                const splitStored = isPCExisting ? pCSplit.map((x: string) => x.includes(childI) ? newVal : x) : (childI === 's' ? [...pCSplit, newVal] : [newVal, ...pCSplit])
                paceData.pace = splitStored.join(' ').trim()
            }

            paceData.pid = paceId

            pacesClone[parentI] = paceData
            currentPaces = pacesClone
            pacesClone[parentI].multiplier = multiplier ? multiplier : 1
            setPaces(pacesClone)
        }
    }

    const handleUpdateRecovery = async (mobility: Array<string>, strength: Array<string>) => {
        const data = {
            id: userInfo?.raceResults?.recoveryPlan?.id,
            strength_video_ids: strength,
            mobility_video_ids: mobility
        }
        const request = await putReq('/v2/recovery-plan', data)
        if (request.status == "ok") {
            await getRaceResult(undefined, true)
            setHasRecoveryAssessment(true)
        }
    }

    const handleMarkComplete = async (woComplete: boolean, isCommentOnly?: boolean) => {
        setLoadingValues(true)
        const currentNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)

        let nData = currentNote ? { ...currentNote } : {
            marid: dayData?.marathon?.id,
            uid: userInfo?.uid,
            comments: "",
            heart_rate: "",
            distance: null,
            pace: null,
            wo_complete: 0
        }
        const insetThis = isCommentOnly ? {} : { wo_complete: woComplete as boolean }
        nData = { ...nData, ...insetThis, comments: newComment }
        currentNote ? await UpdateUserNotes(nData as UserNotesRecord) : await CreateUserNotes(nData as UserNotesRecord)
        await getAllUserNotes()
        getUserNotes(dayData)
        handleCloseComplete()
        handleCloseEditNotes()
        setLoadingValues(false)
    }

    const handleCompleteNSync = (i: number) => {
        if (i) handleMarkComplete(true)
        else if (corosUserData && corosUserData.status == 1) {
            createCorosWorkout()
        }
        else {
            if (userGarminDetails?.permissions.includes('WORKOUT_IMPORT')) {
                createGarminWorkout()
            }
            else {
                setInvalidGarminPermission(true)
                setFinishedGarminSync(true)
            }
        }

    }

    const handleUpdatePlan = async () => {
        setLoadingValues(true)
        const capKeys = Object.keys(checkedAdjustPlan)
        for (const ck of capKeys) {
            if (checkedAdjustPlan[ck]) {
                const customPace = userCustomPaces.find((x: any) => x.pid == ck)
                if (customPace) {
                    await UpdateUserCustomPace({ ...customPace, pace: checkedAdjustPlan[ck], update: true });
                } else {
                    await CreateUserCustomPace({ pid: Number(ck), uid: userInfo?.uid, pace: checkedAdjustPlan[ck] });
                }
            }
        }
        getOverridePaceData(userInfo?.uid)
        setShowWeatherAlert(false)
        setLoadingValues(false)
    }

    const convertDistance = (distance: number, currentNote: any) => {
        let conversion = { toMiles: false, toKm: false }

        if ((currentNote?.unit || userInfo?.unit) === 'km' && userInfo?.km !== 'Y') {
            conversion = { toMiles: false, toKm: true }
        }
        else if ((currentNote?.unit || userInfo?.unit) === 'mi' && userInfo?.km === 'Y') {
            conversion = { toMiles: true, toKm: false }
        }

        if (conversion.toMiles) {
            //convert km to miles
            return ConvertMetersToMiles(distance * 1000).toFixed(1)
        }
        else if (conversion.toKm) {
            //convert miles to km
            return (distance * 1.60934).toFixed(1)
        }
        else {
            return distance
        }
    }

    const handleSubmit = async (currentDistance: number) => {
        const LEGACY_OFFSET = 50000
        const isLegacyPlan = userInfo?.uid < LEGACY_OFFSET
        setLoadingValues(true)
        const currentNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)
        if (miles && currentDayActivity?.split('|')?.[0].split(' ')?.[0] !== miles) {
            if (currentNote) {
                if (miles !== currentNote?.distance) {
                    const dData = { ...currentNote, distance: isLegacyPlan ? (userInfo?.km === 'Y' ? (+computeKmPace(+miles)?.toFixed(1)) : +miles) : convertDistance(+miles, currentNote) }
                    await UpdateUserNotes(dData as UserNotesRecord).then(async (res) => {
                        if (res.status === 'ok') {
                            await CreateChangeLog(
                                {
                                    user_id: userInfo?.internal_id,
                                    plan_id: userInfo?.uid,
                                    change_type: 'CHANGE_DAY_DISTANCE',
                                    week: selectedWeek.key,
                                    day: selectedDay + 1,
                                    previous_value: String(currentDistance),
                                    new_value: String(convertDistance(+miles, currentNote)),
                                })
                        }
                    })
                    await getAllUserNotes()
                    getUserNotes(dayData)
                }
            } else {
                const dData = {
                    marid: dayData?.marathon?.id,
                    uid: userInfo?.uid,
                    comments: "",
                    heart_rate: "",
                    distance: isLegacyPlan ? (userInfo?.km === 'Y' ? (+computeKmPace(+miles)?.toFixed(1)) : +miles) : convertDistance(+miles, currentNote),
                    unit: userInfo?.unit,
                    pace: null,
                    wo_complete: false
                }
                await CreateUserNotes(dData as UserNotesRecord).then(async (res) => {
                    if (res.status === 'ok') {
                        await CreateChangeLog(
                            {
                                user_id: userInfo?.internal_id,
                                plan_id: userInfo?.uid,
                                change_type: 'CHANGE_DAY_DISTANCE',
                                week: selectedWeek.key,
                                day: selectedDay + 1,
                                previous_value: String(currentDistance),
                                new_value: String(convertDistance(+miles, currentNote)),
                            })
                    }
                })
                await getAllUserNotes()
                getUserNotes(dayData)
            }
        }

        const initialPaces: any = []
        let paceInterval = 0
        for (const d of Object.keys(paces)) {
            paceInterval++
            const pClone = paces[d]

            pClone.pace = pClone?.pace?.replaceAll('|', '')
            pClone.pace = pClone.pace.split(' ').map((pd: any) => (pd === 'm' || pd === 's') ? '' : pd).join(' ').trim()

            const skipPaceChange = (prevSurfaceType.toUpperCase() == 'ROAD' && activeSurface.toUpperCase() == 'TREAD') || (prevSurfaceType.toUpperCase() == 'TREAD' && activeSurface.toUpperCase() == 'ROAD')

            if (skipPaceChange) {
                updatePaceValues && setUpdateCurrentSurfaceType(true)
                getOverridePaceData(userInfo?.uid)
                setLoadingPaces(false)
                return
            }

            if (pClone.update) {
                await UpdateUserCustomPace(pClone).then(async (res) => {

                    // console.log('>>> updated pace of pid', pClone.pid)

                    // console.log('>>> updated pace. previous value: ', pClone.defaultValue)

                    if (defaultPaces.length == 0 && (activeSurface == defaultSurfaceType || prevSurfaceType == defaultSurfaceType)) {
                        // console.log('>>> setting default paces: ', String(pClone?.defaultValue))
                        initialPaces.push({ pid: pClone.pid, pace: String(pClone?.defaultValue) })
                    }

                    // if changed surface type
                    if (updatePaceValues) setUpdateCurrentSurfaceType(true)

                    // console.log('>>> updated pace. old value: ', String(pClone?.defaultValue))

                    if (res.status === 'ok') {
                        await CreateChangeLog(
                            {
                                user_id: userInfo?.internal_id,
                                plan_id: userInfo?.uid,
                                change_type: 'CHANGE_PACE_' + d,
                                week: selectedWeek.key,
                                day: selectedDay + 1,
                                previous_value: String(pClone?.defaultValue),
                                new_value: appliedMultipliedPace(String(pClone?.pace), pClone?.multiplier),
                            }).then((res) => {
                                if (paceInterval >= Object.keys(paces).length) {
                                    handleLoadingPaces()
                                }
                            }).catch((err) => {
                                if (paceInterval >= Object.keys(paces).length) {
                                    handleLoadingPaces()
                                }
                                console.log('[[[ error creating change log ]]]', err)
                            })
                    }
                });
            } else {
                await CreateUserCustomPace(pClone).then(async (res) => {

                    // console.log('>>> updated pace of pid', pClone.pid)

                    if (defaultPaces.length == 0 && (activeSurface == defaultSurfaceType || prevSurfaceType == defaultSurfaceType)) {
                        // console.log('>>> setting default paces: ', String(pClone?.defaultValue))
                        initialPaces.push({ pid: pClone.pid, pace: String(pClone?.defaultValue) })
                    }

                    // if changed surface type
                    if (updatePaceValues) setUpdateCurrentSurfaceType(true)

                    // console.log('>>> updated pace. old value: ', String(pClone?.defaultValue))

                    if (res.status === 'ok' && pClone?.pace) {
                        await CreateChangeLog(
                            {
                                user_id: userInfo?.internal_id,
                                plan_id: userInfo?.uid,
                                change_type: 'CHANGE_PACE_' + d,
                                week: selectedWeek.key,
                                day: selectedDay + 1,
                                previous_value: String(pClone?.defaultValue),
                                new_value: appliedMultipliedPace(String(pClone?.pace), pClone?.multiplier),
                            }).then((res) => {
                                if (paceInterval >= Object.keys(paces).length) {
                                    handleLoadingPaces()
                                }
                            }).catch((err) => {
                                if (paceInterval >= Object.keys(paces).length) {
                                    handleLoadingPaces()
                                }
                                console.log('[[[ error creating change log ]]]', err)
                            })
                    }
                });
            }
            getOverridePaceData(userInfo?.uid)

        }

        // initialPaces.length >= defaultPaces.length && setDefaultPaces(initialPaces)
        setDefaultPaces(initialPaces)

        setLoadingSurface(false)
        setLoadingValues(false)

        handleCloseEdit()
    };

    const handleCloseEdit = () => {
        setMiles('')
        setPaces({})
        setIsEditing(false)
    }

    const handleCloseCrossTrainingModal = () => {
        setDisplayCrossTrainingModal(false)
    }

    const handleCloseEditNotes = () => {
        setNewComment(comment)
        setIsTakingNotes(false)
    }

    const handleChangeActive = (event: React.SyntheticEvent, val: number) => {
        prevActiveTab['current'] = activeTab - val
        setInitTab(val);
    };

    useEffect(() => {
        console.log('>>> selectedActivity: ', selectedActivity)
    }, [selectedActivity])

    useEffect(() => {
        setActiveTab(initTab)
    }, [initTab])

    useEffect(() => {
        defaultPaces.length > 0 && setLoadingPaces(false)
    }, [defaultPaces])

    const handleCloseComplete = () => {
        setIsMarkComplete(false);
    };

    const getFoamRollingLink = (link: string) => {
        const linkA = link.replace('foam-rolling', '').split('#')[1].split('/')
        const convertedLink = `/${linkA[1]}/${linkA[3]}`
        return convertedLink
    }

    const timeToString = (props: any) => {
        const { hr, min, sec } = props
        return (hr || min || sec) ? `${hr ? `${hr.toString().padStart(2, '0')}:` : ''}${min || (!min && hr) ? `${min.toString().padStart(2, '0')}:` : ''}${(sec || !sec && (min || hr)) ? `${Math.floor(sec).toString().padStart(2, '0')}` : ''}` : ''
    }

    const isLongText = (val: any) => {
        return (val?.toString().toLowerCase().includes("repeat") || val?.toString().toLowerCase().includes("recover") || val?.toString().toLowerCase().includes("walk")) ? true : false
    }

    const getCurrentDistance = (marId: number) => {
        const distance = userInfo?.plan_type === "GPT" ? undefined : userNotes?.find((x: any) => x.marid == marId)?.distance
        return distance ? distance : undefined
    }

    const convertTextToIntervalTime = (notes: string, prevVal: any) => {
        const divisor = notes.includes("is equal") ? 1 : notes.includes("is half") ? 2 : notes.includes("is quarter") ? 4 : notes.includes("is 40%") ? 2.5 : 1

        const convertedTime = convertPaceBase(
            prevVal?.notes as string,
            getConvertedPace(prevVal).rawPace,
            userInfo?.km == 'Y' ? true : false, divisor)

        return convertedTime && notes.toLowerCase().includes('recovery') ? "Recover for " + convertedTime : undefined
    }

    const isGarminConnected = (userGarminDetails: any) => {
        if (userGarminDetails?.status == 1)
            return true
        else
            return false
    }

    const loadSurfaceType = async () => {
        setLoadingSurface(true)
        setTimeout(() => {
            setLoadingSurface(false)
            setLoadingInitialLanding(false)
        }, 1000)
    }

    const handleChangeMyLocation = () => {
        // setShowWeatherAlert(false)
        // setChangeLoc(true)
        navReq('/#/profile?changeLocation=1')
    }

    const handleGetGarminSyncData = async (dayData: any, abort: AbortController) => {
        if (dayData?.marathon?.id) {
            const garminSyncData = await getGarminSyncData(userInfo?.uid, dayData.marathon.id, abort.signal)

            if (garminSyncData?.data?.result && garminSyncData?.data?.result.length > 0) {
                setHasSyncData(true)
            }
            else
                setHasSyncData(false)
        }
        else
            setHasSyncData(false)
    }

    useEffect(() => {

        updateCurrentSurfaceType && prevSurfaceType != activeSurface && setLoadingSurfaceChange(true)
        // updateCurrentSurfaceType && console.log('>>> updating current surface type...')

        // setLoadingValues(true)
        let originalPaces: any = []

        if (defaultPaces.length == 0) {
            for (const d of Object.keys(paces)) {
                const pClone = paces[d]
                originalPaces.push({ pid: pClone.pid, pace: String(pClone?.defaultValue) })
            }
            // updateCurrentSurfaceType && console.log('>>> no default paces found. setting original paces: ', originalPaces)
        } else originalPaces = [...defaultPaces]

        // updateCurrentSurfaceType && console.log('::: Original Paces: ', originalPaces)
        // updateCurrentSurfaceType && console.log(': dayData.week = ', dayData?.marathon?.week, ': selectedWeek = ', selectedWeek.key)
        // updateCurrentSurfaceType && console.log(': dayData.day = ', dayData?.marathon?.day, ': selectedDay = ', selectedDay + 1)


        updateCurrentSurfaceType && prevSurfaceType != activeSurface && changeDaySurfaceType({ plan_id: userInfo?.uid, user_id: userInfo?.internal_id, activity_id: dayData?.marathon?.id, week: selectedWeek.key, day: selectedDay + 1, surface_type: activeSurface, original_pace: JSON.stringify(originalPaces) })
            .then((res) => {
                // console.log('[[[ updated surface type ]]]', res)
                CreateChangeLog({
                    user_id: userInfo?.internal_id,
                    plan_id: userInfo?.uid,
                    change_type: 'CHANGE_STATUS',
                    week: selectedWeek.key,
                    day: selectedDay + 1,
                    previous_value: prevSurfaceType,
                    new_value: activeSurface,
                }).then((res) => {
                    setLoadingSurfaceChange(false)
                    // console.log('[[[ created change log ]]]', res)
                }).catch((err) => {
                    setLoadingSurfaceChange(false)
                    console.log('[[[ error creating change log ]]]', err)
                })

                setUpdateCurrentSurfaceType(false)
                setLoadingValues(false)
            }).catch((err) => {
                setLoadingSurfaceChange(false)
                console.log([[['error changing surface type']]], err)
                setUpdateCurrentSurfaceType(false)
                setLoadingValues(false)
            })

        updateCurrentSurfaceType && prevSurfaceType == activeSurface && setUpdateCurrentSurfaceType(false)

    }, [updateCurrentSurfaceType])


    useEffect(() => {

        setLoadingSurface(false)

    }, [activeSurfaceIcon])

    useEffect(() => {
        // console.log('[[[ SWITCHED DAY ]]]')
        setWorkOutDuration(null)
        setDurationList1(null)
        setDurationList2(null)

        loadSurfaceType()

        const dayPaceValues: { id: any; loaded: string; }[] = []

        dayData?.marathonPaces.map((val: any) => {
            dayPaceValues.push({ id: val.mar_id, loaded: 'true' })
        })

        setLoadedPaces(dayPaceValues)

        const abortController = new AbortController();
        if (dayData) {
            handleGetGarminSyncData(dayData, abortController)
        }
        return () => {
            abortController.abort();
        }
    }, [dayData])


    // changing surface type
    useEffect(() => {

        updatePaceValues && setLoadingPaces(true)
        if (userInfo?.uid && (!allDaySurfaceType || allDaySurfaceType.length == 0 || updatePaceValues)) {
            getSurfaceTypeByPlanId(userInfo?.uid).then((res: any) => {
                if (res?.status === 'ok') {
                    setAllDaySurfaceType(res.data.result)
                }
            }).catch((err) => {
                console.log('[[[error getting plan daily surface types]]]', err)
            })
        }
    }, [dayData, updatePaceValues, userInfo])


    useEffect(() => {

        const dayNum = moment(dayData?.date).day() + 1 || moment(dayData?.date).day() + 1 || selectedDay
        const initialSurfaceType = allDaySurfaceType?.find((e: any) => userInfo?.uid === e.plan_id && e.activity_id === dayData?.marathon?.id)?.surface_type
        initialSurfaceType ? setPrevSurfaceType(initialSurfaceType) : setPrevSurfaceType('road')

        initialSurfaceType && initialSurfaceType.includes('tread') ? setDisplayTread(true) : setDisplayTread(false)

    }, [allDaySurfaceType])


    useEffect(() => {
        const abortController = new AbortController();
        const adjustment = getAdjustmentValue() as number;
        if (!getWeatherData || getWeatherData?.length == 0 || adjustment <= 0 || getWTAValue() < 83)
            setIsValid(false)
        else if (getWeatherData && currentLocation && dayData?.marathonPaces.length > 0 && dayData?.marathonPaces[0].pace !== '') {
            setIsValid(true)
        } else
            setIsValid(false)
        return () => {
            abortController.abort();
        }
    }, [getWeatherData, dayData, currentLocation])

    useEffect(() => {
        if (userInfo && dayData) {
            setCurrentId(dayData?.marathon?.id)
            if (dayData?.workouts) {
                const workoutsList = dayData?.workouts
                const videos1: Array<any> = [];
                const videos2: Array<any> = [];
                for (const x in dayData?.workouts) {
                    const workout = workoutsList[x];
                    if (workout.mid == dayData.marathon?.id && workout.title) {
                        if ((workout.title === "Cross Train" || workout.title !== "Cross Train" && workout.title.length > 4) && workout.link === "")
                            videos1.push(workout)
                        else {
                            let id = workout?.link?.split('/').pop()
                            id = id && Number(id) ? Number(id) : ''
                            videos2.push({ ...workout, id })
                        }
                    }
                }
                getVideoDurations(videos2.map((x: any) => x.id), setWorkOutDuration, workOutDuration)
                setWorkoutVideos({ videos1, videos2 })
            }
            if (userNotes) {
                const currentUserNote = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid)
                const userComment = currentUserNote?.comments ? currentUserNote.comments : ''
                const isWoComplete = currentUserNote?.wo_complete ? currentUserNote.wo_complete : 0
                if (userComment !== comment) {
                    setComment(userComment)
                    setNewComment(userComment)
                }
                setIsDayComplete(isWoComplete)
                isWoComplete && isRaceDay && raceResults?.length && setUpdateRaceResult(true)
            }
            if (dayData?.marathonPaces) {
                const mpFiltered = dayData.marathonPaces?.filter((x: any) => x.pace !== '')
                if (mpFiltered?.length > 0) {
                    const newMpFiltered = []
                    let newAdjustedPlan = {}
                    for (const val of mpFiltered) {
                        const adjustDefault = weatherAdjust(getConvertedPace(val, true)?.rawPace)
                        const curConvertedPace = getConvertedPace(val)?.rawPace
                        if (getTotalSec(curConvertedPace) < getTotalSec(adjustDefault)) {
                            newAdjustedPlan = { ...newAdjustedPlan, [`${val.paceId + (val?.mar_id * 10000)}`]: adjustDefault }
                            newMpFiltered.push({ ...val, adjustDefault, curConvertedPace })
                        }
                    }
                    setCheckedAdjustPlan(newAdjustedPlan)
                    setCheckAll(true)
                    setWeatherAlertAdjustList(newMpFiltered)
                }
            }
        }
    }, [userInfo, dayData, userNotes, dayData?.workouts, dayData?.marathonPaces, allUserNotes, currentDayActivity])

    useEffect(() => {
        if (currentDayActivities?.length > 0) {
            const lapsData = currentDayActivities?.filter((e: any) => e?.laps?.filter((e2: any) => e2?.totalDistanceInMeters > 0 && e2?.timerDurationInSeconds > 0)?.length > 0)
            lapsData?.length > 0 ? setLapsData(lapsData) : setLapsData([])
            setInitTab(1)
        }
    }, [currentDayActivities])

    useEffect(() => {
        if (!isLoadingAppleHealth && !isLoadingCoros)
            if (!appleHealthActivity && !corosUserActivity.length && !currentDayActivities.length) {
                setInitTab(0)
                setLapsData([])
            }
    }, [currentDayActivities, appleHealthActivity, corosUserActivity, isLoadingAppleHealth, isLoadingCoros])

    useEffect(() => {
        // console.log('[[[ switched day ]]]')
        setDefaultPaces([])

        const selectedDate = moment(weekDays?.[selectedWeek.key - 1]?.replaceAll('-', '/'))?.add(selectedDay, 'd')?.format('YYYY/MM/DD')
        compareDate(selectedDate)
    }, [selectedDay, selectedWeek])

    useEffect(() => {
        const permission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')
        if (permission.status === "granted") {
            setPermission(permission)
        }
        // console.log('>>> initial load paces: ', paces)
    }, [])

    // useEffect(() => {
    //     if (userInfo?.internal_id)
    //         GetAppleHealtActivity(userInfo.internal_id, '').then((res) => {
    //             if (res && res?.data?.result?.length > 0) {
    //                 setHasAppleHealthData(true)
    //             }
    //         })
    // }, [userInfo])

    useEffect(() => {
        // alert(JSON.stringify(dataGenerated))
        const currentDate = selectedWeek?.week ? moment(selectedWeek?.week).add(selectedDay, 'd').format('YYYY-MM-DD') : ''
        // console.log(watchResults)
        setAppleHealthActivity(undefined)
        if (watchResults && watchResults?.apple?.length > 0) {
            setHasAppleHealthData(true)
            const applewWatchData = watchResults?.apple?.find((x: any) => x.activity_date === currentDate)
            if (applewWatchData) {
                setAppleHealthActivity(applewWatchData)
                setInitTab(1)
                const activity = JSON.parse(applewWatchData.activity) as WorkoutData[]
                if (activity) {
                    const laps = getWorkoutLaps(activity, userInfo)
                    setAppleHealthActivityLaps(laps)
                }
            }
            else if (dataGenerated.length > 0 && currentDate === dataGenerated[0]?.dateParam) {
                try {
                    const data: AppleHealthActivity = {
                        user_id: userInfo?.internal_id,
                        activity_date: currentDate,
                        device_id: requestKey ? requestKey : '',
                        activity: JSON.stringify(dataGenerated),
                        raw_data: '',
                    }

                    CreateAppleHealtActivity(data).then(() => {
                        GetAppleHealtActivity(userInfo?.internal_id, currentDate).then((res) => {
                            if (res) {
                                // setAppleHealthActivity(res?.data.result[0])
                                const activity = JSON.parse(res?.data.result[0]?.activity) as WorkoutData[]
                                if (activity) {
                                    const laps = getWorkoutLaps(activity, userInfo)
                                    setAppleHealthActivityLaps(laps)
                                }
                                setDataGenerated([])
                            }
                        })
                    })
                } catch (error) {
                    alert(JSON.stringify(error))
                }
            }
            else {
                setAppleHealthActivityLaps(undefined)
                setAppleHealthActivity(undefined)
            }
        }
        // if (currentDate && userInfo?.internal_id) {
        //     setIsLoadingAppleHealth(true)
        //     GetAppleHealtActivity(userInfo?.internal_id, currentDate).then((res) => {
        //         if (res) {
        //             console.log('[[[ AHA ]]]', res)
        //             const data = res?.data?.result[0]

        //             if (data) {
        //                 setAppleHealthActivity(data)
        //                 setInitTab(1)
        //                 const activity = JSON.parse(data.activity) as WorkoutData[]
        //                 if (activity) {
        //                     const laps = getWorkoutLaps(activity, userInfo)
        //                     setAppleHealthActivityLaps(laps)
        //                 }
        //             }

        //             // else if (dataGenerated.length > 0 && currentDate === dataGenerated[0]?.dateParam) {
        //             // 	try {
        //             // 		const data: AppleHealthActivity = {
        //             // 			user_id: userInfo?.internal_id,
        //             // 			activity_date: currentDate,
        //             // 			device_id: requestKey ? requestKey : '',
        //             // 			activity: JSON.stringify(dataGenerated),
        //             // 			raw_data: '',
        //             // 		}

        //             // 		CreateAppleHealtActivity(data).then(() => {
        //             // 			GetAppleHealtActivity(userInfo?.internal_id, currentDate).then((res) => {
        //             // 				if (res) {
        //             // 					// setAppleHealthActivity(res?.data.result[0])
        //             // 					const activity = JSON.parse(res?.data.result[0]?.activity) as WorkoutData[]
        //             // 					if (activity) {
        //             // 						const laps = getWorkoutLaps(activity, userInfo)
        //             // 						setAppleHealthActivityLaps(laps)
        //             // 					}
        //             // 					setDataGenerated([])
        //             // 				}
        //             // 			})
        //             // 		})
        //             // 	} catch (error) {
        //             // 		alert(JSON.stringify(error))
        //             // 	}
        //             // }
        //             else {
        //                 setAppleHealthActivityLaps(undefined)
        //                 setAppleHealthActivity(undefined)
        //             }
        //             setIsLoadingAppleHealth(false)
        //         }
        //     })
        // }
    }, [selectedWeek, selectedDay, userInfo, requestKey, dataGenerated, watchResults])

    useEffect(() => {
        if (userInfo?.internal_id && currentId && selectedWeek?.week && selectedDay >= 0 && !loadingValues) {
            const currentDate = selectedWeek?.week ? moment(selectedWeek?.week).add(selectedDay, 'd').format('YYYY-MM-DD') : ''
            getCorosScheduledWorkouts(userInfo?.internal_id, currentDate, currentId).then((res) => {
                console.log('[[[ CSW ]]]', res)
                const result: any = res?.[0]
                if (result) {
                    result.workouts = result.workouts ? JSON.parse(result.workouts) : []
                    setCorosScheduledWorkout(result)
                }
                else
                    setCorosScheduledWorkout(undefined)
            })
        }
    }, [selectedWeek, selectedDay, currentId, userInfo, loadingValues])

    // useEffect(() => {
    //     const currentDate = selectedWeek?.week ? moment(selectedWeek?.week).add(selectedDay, 'd').format('YYYY-MM-DD') : ''
    //     if (appleHealthActivity && !isLoadingAppleHealth) {
    //         const activity = JSON.parse(appleHealthActivity.activity)
    //         if (activity && activity.length > 0 && dataGenerated.length > 0 && activity.length != dataGenerated.length) {
    //             const activityData = JSON.stringify(dataGenerated)
    //             const data = { ...appleHealthActivity, activity: activityData }
    //             UpdateAppleHealtActivity(data).then((res) => {
    //                 if (res) {
    //                     GetAppleHealtActivity(userInfo?.internal_id, currentDate).then((res) => {
    //                         if (res) {
    //                             setAppleHealthActivity(res?.data.result[0])
    //                             setInitTab(1)
    //                             const activity = JSON.parse(res?.data.result[0]?.activity) as WorkoutData[]
    //                             if (activity) {
    //                                 const laps = getWorkoutLaps(activity)
    //                                 setAppleHealthActivityLaps(laps)
    //                             }
    //                             setDataGenerated([])
    //                         }
    //                     })
    //                 }
    //             })
    //         }
    //     }
    // }, [appleHealthActivity, dataGenerated, isLoadingAppleHealth, selectedWeek, selectedDay])

    useEffect(() => {
        if (userInfo && userInfo?.internal_id) {
            getCorosUserByUserId(userInfo?.internal_id).then((res) => {
                const user = res.data.result[0]
                // console.log('!!!!!!!!')
                // console.log('corosUserData', user)
                if (user)
                    setCorosUserData(user)
            })
        }
    }, [userInfo])


    useEffect(() => {
        const currentDate = selectedWeek?.week ? moment(selectedWeek?.week).add(selectedDay, 'd').format('YYYY-MM-DD') : ''
        setCorosUserActivity([])
        if (userInfo && userInfo.internal_id && watchResults?.coros?.length) {
            setIsLoadingCoros(true)
            const res = watchResults?.coros?.find((x: any) => x.activity_date === currentDate)
            // getUserWorkouts(userInfo.internal_id, currentDate).then((res) => {
            if (res) {
                const activities = res.activities ? JSON.parse(res.activities) as CorosWorkout[] : []
                if (activities.length > 0) {
                    if (res.activity_date === currentDate) {
                        if (activities.length > 0) {
                            setCorosUserActivity(activities)
                            console.log('[[[ CUA ]]]', activities)
                            setInitTab(1)
                        }
                    }
                }
                else {
                    setCorosUserActivity([])
                }
            }
            setIsLoadingCoros(false)
            // })
        }
    }, [selectedWeek, selectedDay, userInfo, watchResults])

    useEffect(() => {
        if (updatePaceValues) {

            handleSubmit(currentTotalDistance)
            setUpdatePaceValues(false)
            currentPaces = {}

        }
    }, [updatePaceValues])

    const [openRaceResult, setOpenRaceResult] = useState(false)

    useEffect(() => {
        if ((raceResults?.id || currentDayActivities?.length || appleHealthActivity || corosUserActivity?.length) && isRaceDay)
            setOpenRaceResult(true)
        else
            setOpenRaceResult(false)
    }, [raceResults?.id, isRaceDay, currentDayActivities, appleHealthActivity, corosUserActivity])

    const compareDate = (dateToCheck: string) => {
        const currentDate = moment(moment().format('YYYY/MM/DD'));
        const dateToCompare = moment(dateToCheck);
        setIsAfterCurrentDate(currentDate < dateToCompare)
    }

    const getTodayDate = () => {
        const aDate = weekDays[selectedWeek?.key - 1]
        if (aDate)
            return moment(aDate?.replaceAll('-', '/')).add(selectedDay + 1, 'd')
        else
            return moment()
    }

    const handleGoToDate = () => {
        const currentDayIndex = weekDays?.findIndex((w: any) => moment().isBetween(moment(w.replaceAll('-', '/')).subtract(1, 'days'), moment(w.replaceAll('-', '/')).add(7, 'd')))
        const newD = moment().diff(weekDays[currentDayIndex]?.replaceAll('-', '/'), 'd')
        const d = newD > 0 && newD < 7 ? newD : 0
        setSelectedWeek({ week: weekDays[currentDayIndex], key: currentDayIndex + 1 })
        setSelectedDay(d)
    }

    const todayDate = (date?: any) => {
        const today = moment().format("YYYY/MM/DD")
        const aDate = weekDays[selectedWeek?.key - 1]
        const sWeek = weekDays[0]?.replaceAll('-', '/')
        const cDate = date ? moment(date) : (aDate ? moment(aDate.replaceAll('-', '/')).add(selectedDay, 'd') : moment(sWeek).add((selectedDay ? selectedDay : 0) - 7, 'd'))
        const selectedDisplay = cDate.format("YYYY/MM/DD")
        const isNotToday = selectedDisplay && selectedDisplay !== today
        const displayDate = isNotToday ? moment(selectedDisplay).format('dddd, MMM DD, YYYY') : `Today, ${moment(today).format("MMM DD, YYYY")}`
        const isFuture = moment(selectedDisplay).isAfter(moment(today))
        return <Grid container xs={12} justifyContent="center" alignItems="center" sx={{ p: 0, height: '27px' }}>
            <Box sx={{ fontFamily: 'Poppins-Medium', fontSize: '16px', letterSpacing: 'normal', position: 'relative' }}>
                {userInfo?.is_active_plan && isNotToday && (isFuture ? <IconButton onClick={handleGoToDate} sx={{ position: 'absolute', color: '#010101', fontSize: '18px', p: 0, left: -25 }}>
                    <i className="fa-regular fa-arrow-turn-left" />
                </IconButton> : <IconButton onClick={handleGoToDate} sx={{ position: 'absolute', color: '#010101', fontSize: '18px', p: 0, right: -25 }}>
                    <i className="fa-regular fa-arrow-turn-right" />
                </IconButton>)}
                {displayDate}
            </Box>
        </Grid>
    }

    const getTotalSec = (time: string) => {
        let total = 0
        if (time) {
            for (const t of time.split(' ')) {
                if (t.includes('m')) {
                    total += Number(t.replace('m', '')) * 60
                } else
                    total += Number(t.replace('s', ''))
            }
        }

        return total
    }

    const handleCheckChange = (isThisChecked: any, paceId: any, adjustDefault: any) => {
        const newAdjustedPlan = { ...checkedAdjustPlan, [`${paceId}`]: isThisChecked ? adjustDefault : false }
        if (isThisChecked) {
            const allKeys = Object.keys(newAdjustedPlan)
            if (allKeys?.filter((cp: any) => newAdjustedPlan?.[cp])?.length === allKeys?.length) {
                setCheckAll(true)
            }
        } else setCheckAll(false)
        setCheckedAdjustPlan(newAdjustedPlan)
    }

    const handleCheckAll = (isThisChecked: any) => {
        let newAdjustedPlan = {}
        weatherAlertAdjustList?.map((val: any, idx: number) => {
            const adjustDefault = val?.adjustDefault
            const curConvertedPace = val?.curConvertedPace
            newAdjustedPlan = { ...newAdjustedPlan, [`${val?.paceId + (val?.mar_id * 10000)}`]: isThisChecked ? adjustDefault : false }
        })
        setCheckedAdjustPlan(newAdjustedPlan)
        setCheckAll(isThisChecked)
    }

    const getVideoDetails = (vidId?: string, type?: string) => {
        const vids = recoverVideos.find(x => x.type === type)
        if (vids) {
            const video = vids.videos.find(x => x.vimeo_id === vidId as string)

            return { id: video?.id, videoId: video?.vimeo_id, title: vids?.type || video?.corrective, subtitle: vids?.type ? video?.corrective : video?.part }
        }
        else {
            return undefined
        }

    }

    const assignVideo = (videoId: string, type: string) => {
        const video: Video = {
            id: videoId,
            url: '/#/programs/runsmart-recover?videoId=' + videoId,
            // image: '\\img\\recover.jpg',
            image: `https://vumbnail.com/${videoId}.jpg`,
            category: 'Recover',
            headingTitle: getVideoDetails(videoId, type)?.subtitle as string,
            headingSubtitle: getVideoDetails(videoId, type)?.title as string,
            detailsLine1: '',
            detailsLine2: '',
            headlines: '' || [],
            descriptions: [] || []
        }

        return video
    }

    const getVideoDurations = async (vids: Array<any>, setList: Function, data: Array<any> | null) => {
        if (!data && vids?.length) {
            const finalData: Array<any> = []
            for (const v of vids) {
                if (v) {
                    const response = await fetch(
                        `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${v}`
                    );
                    const data = await response.json();
                    const duration = data?.duration ? data.duration : 0
                    finalData.push({ [v]: duration })
                }
            }
            setList(finalData)
        }
    }

    const handleGetRecoveryWorkoutVideos = (videoIds: string, type: string) => {
        if (videoIds) {
            const vids = videoIds.split(',')
            const videos: Video[] = []
            getVideoDurations(vids, type === 'rwv1' ? setDurationList1 : setDurationList2, type === 'rwv1' ? durationList1 : durationList2)
            vids.forEach((id: any) => {
                videos.push(assignVideo(id, type))
            });
            return videos;
            // setRecoveryVideos(videos)
        }
        else
            return []
    }

    const wrapBorder = (data: any, k: any) => {
        const nW = window.innerWidth > 800 ? 800 : window.innerWidth
        const videoPerRow = Math.floor(nW / 300)
        const colSpace = 12 / videoPerRow
        return data?.length > 0 ? <Grid container key={k + 'v'} alignItems="center" sx={{
            width: '100%',
            fontSize: '16px',
            borderRadius: '5px',
            position: 'relative',
            mt: '36px'
        }}
        >
            <Grid container xs={12} rowSpacing={2} columnSpacing={colSpace !== 12 ? 2 : 0} justifyContent='flex-start' alignItems="center">
                {data?.map((d: any, di: number) => <Grid item xs={colSpace} key={'wB' + di}>{d}</Grid>)}
            </Grid>
        </Grid>
            : ''
    }

    const parseProgramTitle = (title: string) => {
        if (title === "base3")
            return "Base 3"
        else if (title === "basesix")
            return "Base 6"
        else if (title === "yoga")
            return "RunSmart Yoga"
        else
            return title
    }

    const videoContainerList = (data: Array<any>) => {
        const newVL = data.map((x: any, xi: number) => {
            const { headingSubtitle, id, onClick, src, image } = x
            const d = workOutDuration ? workOutDuration : []
            const d1 = durationList1 ? durationList1 : []
            const d2 = durationList2 ? durationList2 : []
            const durationData = [...d, ...d1, ...d2].find((x: any) => x[id])
            const duration = durationData?.[id] ? durationData[id] : 0
            const programTitle = x?.src?.toLowerCase().includes('programs') ? x?.src?.split('/')[3] : x.headingTitle
            let headingTitle = headingSubtitle?.toLowerCase().includes('day') ? headingSubtitle?.replace('Day', 'Workout') : headingSubtitle

            const numbered = findNumberWord(headingTitle)
            if (numbered)
                headingTitle = headingTitle.toLowerCase().replace(numbered.word, numbered.number)

            return {
                programTitle: parseProgramTitle(programTitle),
                headingTitle: capitalizeEachWord(headingTitle),
                duration,
                id,
                src: src ? src : (image || ''),
                onClick: onClick ? onClick : () => handleOpenVideo(x, "recovery")
            }
        })
        const workoutCont: Array<any> = newVL?.length ? newVL.map((x: any, xi: number) => (
            <>
                <WorkoutVideoContainer
                    index={xi}
                    headingTitle={x.programTitle}
                    subTitle={x.headingTitle}
                    duration={x.duration}
                    thumbnail={x.src}
                    onClick={x.onClick}
                />
            </>
        )) : []
        return workoutCont?.length ? <RSFlexCard sx={{ pt: 0 }} videoList={workoutCont} /> : <></>
        // return <RSVideoCard videoList={newVL} />
    }

    const videoContainer = (data: any) => {
        const { headingTitle, headingSubtitle, id, onClick, src } = data
        const d = workOutDuration ? workOutDuration : []
        const d1 = durationList1 ? durationList1 : []
        const d2 = durationList2 ? durationList2 : []
        const durationData = [...d, ...d1, ...d2].find((x: any) => x[id])
        const duration = durationData ? Math.round(durationData[id] / 60) : ''
        return <RSButton1 secondary key={data.id} sx={{ height: '110px' }}
            onClick={onClick ? onClick : () => handleOpenVideo(data, "recovery")}
        >
            <Grid container xs={12} justifyContent='space-between' alignItems='center' sx={{ py: '10px', height: 'inherit' }}>
                <Grid container xs={5.5} sx={{ position: 'relative', overflow: 'hidden', boxShadow: '0px 0px 3px #80808024' }} justifyContent='center' alignItems='center'>
                    <img
                        className='pointer'
                        style={{ width: '100%', borderRadius: '4px' }}
                        src={src ? src : `https://vumbnail.com/${id}.jpg`}
                    />
                </Grid>
                <Grid container xs={6.5} sx={{ height: '100%' }}>
                    <Grid container xs={12} sx={{ px: 2 }} justifyContent='space-between' alignItems='space-between'>
                        <Grid container sx={{ flexDirection: 'column' }} justifyContent='flex-start' alignItems='flex-start'>
                            <Box sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px', textAlign: 'left', lineHeight: '1.2em', mt: '2px', letterSpacing: '-0.2px' }}>
                                {headingSubtitle}
                            </Box>
                            {duration ? <Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px' }}>
                                {duration}min
                            </Box> : (duration !== 0 ? <Box sx={{ position: 'relative', width: '80px', height: '20px' }}>
                                <Box className='loading-days toRight' sx={{ border: 'none !important' }} />
                            </Box> : '')
                            }
                        </Grid>
                        {/*<Grid container justifyContent='flex-start' alignItems='flex-end'>
							<Box sx={{ fontFamily: 'Poppins-Light', fontSize: '14px', textAlign: 'left', fontStyle: 'italic', lineHeight: '1.2em' }}>{headingTitle}</Box>
						</Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </RSButton1>
    }

    const handleMarkFinish = (isC: any) => {
        if (isC) {
            handleMarkComplete(false)
        } else {
            handleCompleteNSync(0)
            setUpdateRaceResult(true)
        }
    }

    const handleLoadingPaces = () => {
        setTimeout(() => {
            setLoadingPaces(false)
        }, 500)
    }


    // used for surface type changing
    const revertedMultipliedPace = (pace: string, paceAppliedMultiplier: number) => {
        const minutesMatch = pace.match(/(\d+)m/)
        const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
        const secondsMatch = pace.match(/(\d+)s/)
        const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0
        const paceMinutes = (minutes + (seconds / 60)) / paceAppliedMultiplier

        const newMinutes = Math.floor(paceMinutes) + 'm'
        const newSeconds = Math.ceil((paceMinutes - Math.floor(paceMinutes)) * 60) + 's'

        return newMinutes + ' ' + newSeconds
    }


    const appliedMultipliedPace = (pace: string, paceAppliedMultiplier: number) => {
        const minutesMatch = pace.match(/(\d+)m/)
        const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
        const secondsMatch = pace.match(/(\d+)s/)
        const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0
        const paceMinutes = (minutes + (seconds / 60)) * paceAppliedMultiplier

        const newMinutes = Math.floor(paceMinutes) + 'm'
        const newSeconds = Math.round((paceMinutes - Math.floor(paceMinutes)) * 60) + 's'

        return newMinutes + ' ' + newSeconds
    }

    const calculatePaceSpeed = (pace: string) => {
        const minutesMatch = pace?.match(/(\d+)m/)
        const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
        const secondsMatch = pace?.match(/(\d+)s/);
        const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0

        const paceMinutes = minutes + (seconds / 60)
        const speed = 1 / (paceMinutes / 60)

        !isFinite(speed) && console.log('pace', pace, 'minutes', minutes, 'seconds', seconds)

        return isFinite(speed) ? speed.toFixed(1) : pace
    }

    const calculateTotalDuration = (isDayActivity: any) => {

        let totalDuration = 0
        let currentExtraTimeMins = 0
        let currentPaceValue = 0

        let maxWeeks: any = []

        if (userInfo?.raceResults) {
            maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
        } else maxWeeks = false

        dayData?.marathonPaces?.map((val: any, idx: number) => {
            const curDistance = getCurrentDistance(dayData?.marathon?.id)
            const distanceValue = distanceInterpret(val?.notes, userInfo?.km == 'Y', val?.pace,
                curDistance && isDayActivity ? curDistance : dayData?.marathon?.distance,
                convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 1]),
                isDayActivity ? curDistance : undefined
            )
            //console.log('>>> userInfo', userInfo)
            //console.log('>>> currentDistance', curDistance)
            //console.log('>>> all', val?.notes, userInfo?.km == 'Y', val?.pace, curDistance && isDayActivity ? curDistance : dayData?.marathon?.distance, convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 1]), isDayActivity ? curDistance : undefined)
            //console.log('>>> distanceValue', distanceValue)

            const isValueLong = isLongText(distanceValue)
            const isMiles = distanceValue?.toLowerCase().includes('mile')
            const isKm = distanceValue?.toLowerCase().includes('k')
            const finalDValue = distanceValue?.split(' ')

            const preferredUnitKm = userInfo?.km === 'Y' ? true : false

            const finalDistance = (isMiles || isKm)
                ? isNaN(distanceValue?.split(' ')[0]) ? 0 : distanceValue?.split(' ')[0]
                : (!isValueLong && distanceValue?.toLowerCase().includes('m'))
                    ? preferredUnitKm ? (Number(distanceValue?.replace(/m/g, '')) * 0.001) : (Number(distanceValue?.replace(/m/g, '')) * 0.000621371)
                    : Number(distanceValue) > 0 ? Number(distanceValue) : 0

            // we subtract 1 since the first value was already included in total duration
            const repeatTimes = distanceValue?.split(' ')[0].toLowerCase().includes('repeat') ? parseInt(finalDValue.slice(1).join(' ').match(/\d+/)[0] || '1') - 1 : 0

            const extraTimeMins = distanceValue?.split(' ')[0].toLowerCase().includes('recover') || distanceValue?.split(' ')[0].toLowerCase().includes('walk') ? computePaceTime(extractTime(finalDValue.slice(1).join(' '))) : 0
            currentExtraTimeMins = extraTimeMins != 0 ? extraTimeMins : currentExtraTimeMins


            const repeatDistance = isValueLong && distanceValue?.split(' ')[0].toLowerCase().includes('repeat')
                ? distanceInterpret(dayData?.marathonPaces[idx - 2]?.notes, userInfo?.km == 'Y', dayData?.marathonPaces[idx - 2]?.pace,
                    curDistance && isDayActivity ? curDistance : dayData?.marathon?.distance,
                    convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 3]),
                    isDayActivity ? curDistance : undefined)?.split(' ')[0]
                : '0'

            const intervalRecoverTime = repeatDistance != 0 && currentExtraTimeMins

            const repeatExtraTimeTotalMins = intervalRecoverTime ? intervalRecoverTime * repeatTimes : 0

            const repeatDistanceConverted = distanceValue?.split(' ')[0].toLowerCase().includes('repeat')
                ? repeatDistance?.toString().includes('m') ?
                    preferredUnitKm ? (Number(repeatDistance?.toString().replace(/m/g, '')) * 0.001) : (Number(repeatDistance?.toString().replace(/m/g, '')) * 0.000621371)
                    : repeatDistance
                : 0

            const paceValue = getConvertedPace(val).value[0].toString()
            currentPaceValue = paceValue == 0 ? currentPaceValue : Number(computePaceTime(paceValue))

            const intervalTotalDistance = finalDistance > 0 ? finalDistance : repeatDistanceConverted * Number(repeatTimes) * currentPaceValue


            const computedDistance = maxWeeks && maxWeeks.length > 0 ? Number(finalDistance) * maxWeeks[selectedWeek?.key - 1] : Number(finalDistance)

            finalDistance === 0 ? totalDuration += (Number(intervalTotalDistance) + Number(repeatExtraTimeTotalMins)) : totalDuration += (Number(computePaceTime(paceValue)) * computedDistance)


            //		 	console.log('------------------- start')
            //			console.log('val', val , getConvertedPace(val))
            //			console.log('paceValue', paceValue)
            //			console.log('distanceValue', distanceValue)
            //			console.log('currentPaceValue', currentPaceValue)
            //			console.log('finalDistance', finalDistance)
            //			console.log('repeatDistance', repeatDistance)
            //			console.log('intervalRecoverTime', intervalRecoverTime)
            //			console.log('extraTimeTotalMins', repeatExtraTimeTotalMins)
            //			console.log('repeatTimes', repeatTimes)
            //			console.log('intervalTotalDistance', intervalTotalDistance)
            //			console.log('------------------- end')

        })

        function computePaceTime(lap: string) {
            const pace = lap
            const minutesMatch = pace.match(/(\d+)m/)
            const minutes = minutesMatch ? parseInt(minutesMatch[1]) : 0
            const secondsMatch = pace.match(/(\d+)s/);
            const seconds = secondsMatch ? parseInt(secondsMatch[1]) : 0

            return minutes + (seconds / 60)
        }

        function extractTime(timePhrase: any) {
            const matchShort = timePhrase.match(/(\d+m\s*)?(\d+s)/)
            const matchLong = !matchShort && timePhrase.toLowerCase().includes('min') && timePhrase.match(/(\d+)\s*min?/i)

            const time = matchLong ? `${matchLong[1]}m` : matchShort ? matchShort[0].trim() : '0'
            return time.toString()
        }

        // console.log('>>> totalDuration', totalDuration)

        return totalDuration

    }
    // console.log('>>> weatherAlertAdjustList', weatherAlertAdjustList)
    const renderSuggestedPace = () => {
        return weatherAlertAdjustList?.map((val: any, idx: number) => {
            const adjustDefault = val?.adjustDefault
            const curConvertedPace = val?.curConvertedPace
            const label = <Grid container xs={12} key={idx}>
                <Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px' }}>
                    {curConvertedPace}/{userInfo?.km === "Y" ? 'km' : "mile"}
                </Box>
                <Box sx={{ px: '3px' }}>
                    <DArrowForward sx={{ fontSize: '28px' }} />
                </Box>
                <Box sx={{ fontFamily: 'Poppins-medium', fontSize: '16px' }}>
                    {adjustDefault}/{userInfo?.km === "Y" ? 'km' : "mile"}
                </Box>
            </Grid>
            return <Grid key={idx} container xs={12} sx={{ pl: '15px' }} justifyContent='flex-start' alignItems='center'>
                <FormControlLabel control={<Checkbox checked={checkedAdjustPlan?.[val?.paceId + (val?.mar_id * 10000)] ? true : false}
                    onChange={(e) => handleCheckChange(e.target.checked, val?.paceId + (val?.mar_id * 10000), adjustDefault)} />} label={label} />
            </Grid>
        }
        )
    }

    const renderWeatherAlert = () => {
        const suggestedMarkUp = renderSuggestedPace()
        return <Grid container sx={{ p: 3, width: '100%', fontFamily: 'Poppins', position: 'relative' }} justifyContent='flex-start' alignItems="center">
            <SH1 size={1} sx={{ width: '100%', mb: 1 }}>Weather Adjustment</SH1>
            <Box sx={{ pb: '5px' }}>
                <P2 size={1}>Your weather score for <span style={{ fontSize: '16px', fontFamily: 'Poppins' }}>{getWeatherData?.data?.name} </span> is <span style={{ fontSize: '16px', fontFamily: 'Poppins' }}> {getWTAValue()}/100. </span>Suggestions to change your pacing below.</P2>
            </Box>
            <Grid container xs={12} sx={{ fontSize: '16px', fontFamily: 'Poppins', py: '20px', pl: '10px' }} justifyContent='flex-end' alignItems='center'>
                {weatherAlertAdjustList?.length > 1 &&
                    <Grid container xs={12} justifyContent='flex-start' alignItems='center'>
                        <FormControlLabel
                            control={<Checkbox checked={checkAll} onChange={(e) => handleCheckAll(e.target.checked)} />}
                            label={<Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }}>Select All</Box>}
                        />
                    </Grid>}
                {weatherAlertAdjustList?.length > 0 ? suggestedMarkUp
                    : <Grid container xs={12} sx={{ fontFamily: 'Poppins', fontSize: '16px' }} justifyContent='center' alignItems='center'>{`You're All Set!`}</Grid>}
            </Grid>
            <Grid container xs={12} justifyContent='center' alignItems="center">
                {['Update Plan', 'Change Location'].map((t, i) => {
                    const isDisabled = Object.keys(checkedAdjustPlan)?.filter((cp: any) => checkedAdjustPlan?.[cp])?.length === 0
                    return <Grid key={i} container xs={12} justifyContent='center' alignItems="center" sx={{ mt: '10px' }}>
                        <RSButton1 secondary={i ? true : false} disabled={i === 0 && isDisabled ? true : false} onClick={() => i ? handleChangeMyLocation() : handleUpdatePlan()} sx={{ width: '200px' }}>{t}</RSButton1>
                    </Grid>
                })
                }
            </Grid>
        </Grid>
    }

    const renderNoActivity = (syncDevice?: boolean) => {
        return <RSCardCont>
            <Grid container xs={12} justifyContent='center' alignItems='center'>
                <P1 card size={1} sx={{ width: '100%', textAlign: 'center' }}>
                    {syncDevice ? 'Sync your watch or device to your training plan.' : 'No activity for this date. Rest up'}
                </P1>
                {!syncDevice && <P1 card size={1} sx={{ width: '100%', textAlign: 'center' }}>or complete your training and check back.</P1>}
            </Grid>
        </RSCardCont>
    }

    const renderSyncDevice = (noData: boolean, syncDevice: boolean) => {
        return noData ? renderNoActivity()
            : syncDevice ? (
                <Box sx={{pt: 2}}>
                    <IntegrationsMainPage userInfo={userInfo} type='training-plan' />
                </Box>
            ) : <></>

    }

    const renderGarminSyncNotification = (isFromDrawer?: boolean, hasGarminSyncError?: boolean) => {
        const isCorosUser = corosUserData && corosUserData.status == 1 ? true : false;
        return (
            <Grid container xs={12} sx={{ mt: '10px', p: isFromDrawer ? '0px 16px 20px 16px' : 0 }} justifyContent='flex-start' alignItems="center">
                <Grid container xs={12} alignItems='flex-end'>
                    <Box sx={{ fontSize: '16px', fontFamily: 'Poppins', width: '100%' }}>{`Sync to ${isCorosUser ? "COROS" : "Garmin"}`}</Box>
                    {!hasGarminSyncError && !invalidGarminPermission ? (
                        <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
                            <CheckCircleOutlineRounded sx={{ color: 'green', scale: '2', marginBottom: '10px', marginLeft: '5px' }} />
                            <Box sx={{ fontSize: '20px', fontFamily: 'Poppins', width: '100%', marginBottom: '10px' }}>{`Activity has been sent!`}</Box>
                            <Box sx={{ fontSize: '15px', lineHeight: 1, fontFamily: 'Poppins', width: '100%', marginBottom: '15px' }}>{`Open the ${isCorosUser ? "COROS" : "Garmin Connect"} App to push the activity to your watch.`}</Box>

                            {isCorosUser && <Box sx={{ fontSize: '15px', lineHeight: 1, fontFamily: 'Poppins', width: '100%' }}>{`Don’t see it? Navigate to Profile > Training Plan Library.`}</Box>}
                        </Box>
                    ) : invalidGarminPermission ? (
                        <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Box sx={{ fontSize: '20px', fontFamily: 'Poppins', width: '100%', marginBottom: '10px' }}>
                                <HighlightOffOutlined sx={{ color: '#F01', scale: '2', marginBottom: '10px', marginLeft: '5px', marginRight: '15px' }} />
                                {`Update Permissions`}</Box>
                            <Box sx={{ fontSize: '15px', lineHeight: 1.3, fontFamily: 'Poppins', width: '100%' }}>{`To sync your plan and your Garmin device, please reconnect your account and enable “Activity” and “Training” permissions.`}</Box>
                            <div className='mt-3 mb-n5'>
                                <IntegrationsMainPage userInfo={userInfo} type='training-plan' />
                            </div>
                        </Box>
                    ) : (
                        <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
                            <HighlightOffOutlined sx={{ color: '#F01', scale: '2', marginBottom: '10px', marginLeft: '5px' }} />
                            <Box sx={{ fontSize: '20px', fontFamily: 'Poppins', width: '100%', marginBottom: '10px' }}>{`Error synching workout data!`}</Box>
                            <Box sx={{ fontSize: '15px', lineHeight: 1, fontFamily: 'Poppins', width: '100%' }}>{`An error occurred during synching workout data to ${isCorosUser ? "COROS" : "Garmin"}, please try again.`}</Box>
                        </Box>
                    )}

                </Grid>
            </Grid>
        )
    }

    const renderNotesArea = (isFromDrawer?: boolean, appleResult?: boolean) => {
        const placeholder = `We’ll use your notes to help personalize your training.`
        return (notesLocked ? <></> :
            <Grid container xs={12} sx={{ mt: appleResult ? 0 : '10px', p: isFromDrawer ? '0px 16px 20px 16px' : 0 }} justifyContent='flex-start' alignItems="center">
                {isFromDrawer && <Grid container xs={12} sx={{ pb: '5px' }} alignItems='flex-end'>
                    <SH3 size={1}>{`Notes`}</SH3>
                </Grid>}

                {isFromDrawer ? <>
                    <Grid container xs={12} justifyContent='center' alignItems="center">
                        <textarea className='text-area-complete' placeholder={placeholder} value={newComment} onChange={(e) => setNewComment(e.target.value)} />
                    </Grid>
                    <Grid container xs={12} sx={{ my: '10px' }} justifyContent='center' alignItems="center">
                        <RSButton1 sx={{ mt: '10px' }} onClick={() => handleMarkComplete(false, true)}>Save</RSButton1>
                    </Grid>
                </>
                    : <CustomAccordion sx={{ mb: 2 }} title={`${comment ? 'Edit' : 'Add'} notes for today`} style2 card titleIcon={<i className="fa-light fa-note-sticky" />}>
                        <P2 sx={{ pb: 1 }} onClick={() => setIsTakingNotes(true)}>
                            {comment || placeholder}
                            <i className="fa-light fa-pen-to-square blue-text" style={{ fontSize: '15px', paddingLeft: '5px' }} />
                        </P2>
                    </CustomAccordion>}
            </Grid>)
    }

    const renderWatchComplete = (raceTime?: number) => {
        console.log('>>> renderWatchComplete', raceResults, comment, raceTime)
        return <CompletePlan userInfo={userInfo} selectedRace={selectedRace} handleSubmitCompletePlan={handleSubmitCompletePlan} isFromWatch raceResults={raceResults} raceTime={raceTime ? Math.round(raceTime) : undefined} defaultComment={comment} gptPlanId={gptPlanId} />
    }

    const renderGarminLaps = (activityNum: number) => {
        if (lapsData?.length > 0) {
            let activityIndex = -1
            const running = []
            const otherActivities = []
            // console.log('lapsData', lapsData)
            for (const act of lapsData) {
                activityIndex++
                // console.log(activityIndex, activityNum)
                // if (activityIndex != activityNum) continue
                const isRunning = act.activityType === 'RUNNING' || act.activityType === 'TREADMILL_RUNNING'
                const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'
                // console.log(act.laps.length)
                // console.log('act', act.laps)
                const table = act.laps?.length > 1 && <>
                    <Grid container xs={12} justifyContent='flex-start' alignItems="center">
                        <Grid container xs={12} sx={{ pb: 1 }} justifyContent='flex-start' alignItems="center">
                            <Grid container xs={1} justifyContent='center' alignItems='center'><P4>Lap</P4></Grid>
                            <Grid container xs={3} justifyContent='center' alignItems='center'><P4>Time</P4></Grid>
                            <Grid container xs={3} justifyContent='flex-end' alignItems='center'>
                                <P4 sx={{ position: 'relative' }}>
                                    Distance
                                    <P1 size={-2} sx={{ position: 'absolute', right: 0, bottom: -10 }} >{paceUnit}</P1>
                                </P4>
                            </Grid>
                            <Grid container xs={5} justifyContent='flex-end' alignItems='center'>
                                <P4 sx={{ position: 'relative' }}>
                                    Avg Pace
                                    <P1 size={-2} sx={{ position: 'absolute', right: 0, bottom: -10 }} >min/{paceUnit}</P1>
                                </P4>
                            </Grid>
                        </Grid>
                        {act.laps.map((lap: any, i: number) => {
                            const lapDistance = act.laps[i - 1] ? lap.totalDistanceInMeters - act.laps[i - 1].totalDistanceInMeters : lap.totalDistanceInMeters
                            const lD = userInfo?.km == 'Y' ? (lapDistance / 1000)?.toFixed(2) : ConvertMetersToMiles(lapDistance)?.toFixed(2)

                            const lapTime = act.laps[i - 1] ? lap.timerDurationInSeconds - act.laps[i - 1].timerDurationInSeconds : lap.timerDurationInSeconds
                            const lapUnit = userInfo?.km == 'Y' ? 'km' : 'mile'

                            return i ? (
                                <Grid container xs={12} sx={{ my: 1 }}>
                                    <Grid container xs={1} justifyContent='center' alignItems='center'>
                                        <P1>{i}</P1>
                                    </Grid>
                                    <Grid container xs={3} justifyContent='center' alignItems='center'>
                                        <P1>{ConvertS2M(lap.timerDurationInSeconds - act?.laps?.[i - 1].timerDurationInSeconds)}</P1>
                                    </Grid>
                                    <Grid container xs={3} justifyContent='flex-end' alignItems='center'>
                                        <P1>{lD}</P1>
                                    </Grid>
                                    <Grid container xs={5} justifyContent='flex-end' alignItems='center'>
                                        <P1>{
                                            i == act.laps.length - 1 || (userInfo?.km == 'Y' && Number(String((i > 0 ? (lap.totalDistanceInMeters - act.laps[i - 1].totalDistanceInMeters) / 1000 : lap.totalDistanceInMeters / 1000))) != 1 || userInfo?.km != 'Y' && Number(ConvertMetersToMiles((i > 0 ? Number(lap.totalDistanceInMeters - act.laps[i - 1].totalDistanceInMeters) : lap.totalDistanceInMeters))?.toFixed(1)) != 1)
                                                ? getGarminPace(userInfo?.km == 'Y' ? true : false, (Number(lap.totalDistanceInMeters - act.laps[i - 1].totalDistanceInMeters) / Number(lap.timerDurationInSeconds - act.laps[i - 1].timerDurationInSeconds))).replace(/\s*\/\s*mile/i, '').replace(/\s*\/\s*km/i, '').replace(/Infinity/g, '1').replace(/NaN/g, '1').trim() :
                                                ((i > 0 ? ConvertS2M(lap.timerDurationInSeconds - act.laps[i - 1].timerDurationInSeconds) : ConvertS2M(lap.timerDurationInSeconds)))
                                        }</P1>
                                    </Grid>
                                </Grid>
                            ) : ''
                        })}
                    </Grid>
                </>

                table && (isRunning ? running.push(table) : otherActivities.push(table))
            }
            return <>{[...running, ...otherActivities]}</>
        }
    }

    const renderGarminActivity = (isFromDrawer?: boolean) => {

        const parsePace = (speed: number, unit: string) => {
            const paceDuration = getGarminPace(userInfo?.km == 'Y' ? true : false, speed)
            const paceValueArr = paceDuration ? paceDuration.split(' ') : []
            const paceMin = paceValueArr?.find((v: any) => v.includes('m'))?.replace('m', ':')
            const paceSec = paceValueArr?.find((v: any) => v.includes('s'))?.replace('s', '')?.padStart(2, '0')

            return `${paceMin ? paceMin : ''}` + `${paceSec ? paceSec : ''}` + "/" + `${unit}`
        }
        const curDate = dayData?.date
        const aDate = weekDays[selectedWeek?.key - 1]
        const sWeek = weekDays[0]?.replaceAll('-', '/')
        const cDate = curDate ? moment(curDate) : (aDate ? moment(aDate.replaceAll('-', '/')).add(selectedDay, 'd') : moment(sWeek).add((selectedDay ? selectedDay : 0) - 7, 'd'))
        const selectedDisplay = cDate.format("YYYY/MM/DD")

        if (currentDayActivities?.length > 0) {
            let activityIndex = -1
            const running = []
            const otherActivities = []
            let completeAdded = false
            let totalRaceDuration = 0
            for (const act of currentDayActivities) {
                activityIndex++
                const actInfoList = []
                const hasPositionData = act?.samples?.filter((x: any) => (x?.latitudeInDegree && x?.latitudeInDegree != 0) || (x?.lat && x?.lat != 0)).length > 10
                const isRunning = act.activityType.toLowerCase().includes("running")
                const actDistance = act.distanceInMeters ? (userInfo?.km == "N" ? ConvertMetersToMiles(act.distanceInMeters as number)?.toFixed(1) : Number(act.distanceInMeters as number / 1000)?.toFixed(1)) : ''
                const actUnit = userInfo?.km == "N" ? 'miles' : 'km'
                const paceUnit = userInfo?.km == "N" ? 'mi' : 'km'

                const trainingData: any = [...actDistance && Number(actDistance) > 0 ? [{
                    title: 'Distance',
                    values: [{
                        value: actDistance,
                        label: Number(actDistance) != 0 ? actUnit : ''
                    }]
                }] : []]

                if (act.averageSpeedInMetersPerSecond) {
                    const paceDuration = getGarminPace(userInfo?.km == 'Y' ? true : false, act.averageSpeedInMetersPerSecond)
                    const paceValueArr = paceDuration ? paceDuration.split(' ') : []
                    const paceMin = paceValueArr?.find((v: any) => v.includes('m'))?.replace('m', ':')
                    const paceSec = paceValueArr?.find((v: any) => v.includes('s'))?.replace('s', '')?.padStart(2, '0')

                    trainingData.push({
                        title: 'Pace',
                        values: [{
                            value: `${paceMin || ''}${paceSec || ''}`,
                            label: `/${paceUnit}`
                        }]
                    })
                }

                if (act.durationInSeconds) {
                    const actDuration = timeToString(ConvertS2TimeObject(act.durationInSeconds))
                    actDuration && trainingData.push({
                        title: 'Time',
                        values: [{
                            value: actDuration
                        }]
                    })
                }

                act.averageHeartRateInBeatsPerMinute && trainingData.push({
                    title: 'Avg Heart Rate',
                    values: [{
                        value: act.averageHeartRateInBeatsPerMinute
                    }]
                })

                act.activeKilocalories && trainingData.push({
                    title: 'Total Calories',
                    values: [{
                        value: act.activeKilocalories
                    }]
                })

                const isRaceData = isRunning && isRaceDay
                totalRaceDuration += isRaceData ? act.durationInSeconds : 0
                const trainingCardProps = {
                    trainingHeader: isRunning ? 'Running' : "Training",
                    trainingDesc: act.activityName,
                    trainingSubDesc: act.deviceName,
                    icon: isRunning ? `fa-duotone fa-solid fa-person-running` : `fa-duotone fa-solid fa-dumbbell`,
                    trainingData,
                    raceDay: !completeAdded && isRaceData,
                    completePlan: !completeAdded && isRaceData && renderWatchComplete(totalRaceDuration),
                    additionalIcon: requestKey && isRunning ? <SharePlan
                        activityName={act?.activityName || ''}
                        distance={actDistance || ''}
                        unit={actUnit || ''}
                        duration={timeToString(ConvertS2TimeObject(act.durationInSeconds)) || ''}
                        pace={parsePace(act.averageSpeedInMetersPerSecond, paceUnit) || ''}
                        calories={act.activeKilocalories || ''}
                        heartRate={act.averageHeartRateInBeatsPerMinute || 0}
                        date={moment(selectedDisplay).format('dddd, MMM DD, YYYY') || ''}
                        isRaceDay={isRaceDay}
                    /> : undefined,
                    isResults: true
                }
                completeAdded = completeAdded || isRaceData
                const elements = ((act?.laps?.length || 0) > 0) || trainingData?.length ? <RSTrainingCard {...trainingCardProps}>
                    <P3 size={-1} sx={{ mb: 1, mx: 1 }}>Details</P3>
                    {isRunning && <CustomAccordion title="View laps" style2 titleIcon={<i className="fa-light fa-stopwatch" />}>
                        {renderGarminLaps(activityIndex)}
                    </CustomAccordion>}
                    {hasPositionData && !isMobile ? (
                        <Box sx={{ my: 1 }}>
                            <CustomAccordion title="View map" style2 titleIcon={<i className="fa-light fa-map" />}>
                                <TrainingActivityMap samples={act.samples} />
                            </CustomAccordion>
                        </Box>
                    ) : hasPositionData && isMobile ? <div onClick={() => {
                        setSelectedActivity({ title: act.activityName, samples: act.samples })
                        setShowMap(true)
                    }}><Box sx={{
                        my: 1, borderRadius: "5px", width: "100%", px: 2, py: 1.5, backgroundColor: "#f5f5f5", display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'
                    }}>
                            <Box sx={{ mr: 1, display: 'flex' }}>
                                <i className="fa-light fa-map me-2" />
                                <P1>View Map</P1>
                            </Box>
                            <i className="fa-regular fa-chevron-right me-1" />
                        </Box>
                    </div> : <></>
                    }
                    {isRunning && <div
                        onClick={() => {
                            setSelectedActivity({ title: act.activityName, samples: act.samples, activityId: act.activityId })
                            setShowEffortFeedback(true)
                        }}>
                        <Box sx={{
                            borderRadius: "5px", width: "100%", px: 2, py: 1.5, backgroundColor: "#f5f5f5", display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', mt: hasPositionData ? 0 : 1
                        }}>
                            <Box sx={{ mr: 1, display: 'flex' }}>
                                <i className="fa-light fa-gauge-high me-2" />
                                <P1>How did this run feel?</P1>
                            </Box>
                            <i className="fa-regular fa-chevron-right me-1" />
                        </Box>
                    </div>}

                    {/* // show only for garmin users */}
                    {userGarminDetails?.status && isRunning && <div
                        onClick={() => {
                            setSelectedActivity({ title: act.activityName, samples: act.samples, activityId: act.activityId })
                            setShowHeartRateTracker(true)
                        }}>
                        <Box sx={{
                            mt: 1, borderRadius: "5px", width: "100%", px: 2, py: 1.5, backgroundColor: "#f5f5f5", display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'
                        }}>
                            <Box sx={{ mr: 1, display: 'flex' }}>
                                <i className="fa-light fa-heart me-2" />
                                <P1>Heart Rate</P1>
                            </Box>
                            <i className="fa-regular fa-chevron-right me-1" />
                        </Box>
                    </div>}
                </RSTrainingCard > : <></>

                isRunning ? running.push(elements) : otherActivities.push(elements)
            }
            const finalList = [...running, ...otherActivities]
            finalList.length && finalList.splice(1, 0, renderNotesArea())
            return <>{finalList}</>
        }
        return ''
    }

    const renderActivity = (isFromDrawer?: boolean, isAssessmentDone?: boolean, isRD?: boolean) => {
        let rr: RaceResult | undefined = undefined;
        let maxWeeks: [] | undefined = []

        if (userInfo?.raceResults) {
            rr = userInfo?.raceResults
            maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
        }

        // console.log('userInfo', userInfo)
        // console.log('selectedRace', selectedRace)
        // console.log('allUserNotes', allUserNotes)

        const overrideDayActivity = getDailyActivityDisplay(
            dayData,
            userInfo,
            dayData?.marathonPaces,
            selectedRace?.value as number,
            dayData?.marathon?.race_day,
            allUserNotes)
        const isEdited = userNotes?.find((x: any) => x.marid == dayData?.marathon?.id && x.uid == userInfo?.uid) || dayData?.marathonPaces?.find((val: any) => userCustomPaces?.find((x: any) => x.pid == (val.paceId + (val.mar_id * 10000))))
        const dailyActivity = overrideDayActivity || allUserNotes ? overrideDayActivity?.split('|') : currentDayActivity?.split('|')
        const isKm = userInfo?.km === "Y" ? "KMS" : "MILES";
        const isKm2 = userInfo?.km === "Y" ? "KM" : "MI";
        let trainVal = isAssessmentDone ? 'Cross Train' : dailyActivity?.[0]?.includes(isKm) ? dailyActivity[0] : dailyActivity[0]?.replace(isKm2, isKm)
        trainVal = trainVal?.trim()
        const isShowEdit = trainVal?.toUpperCase() === 'REST' || trainVal?.toUpperCase() === 'CROSS TRAIN'
        trainVal = trainVal?.split(' ')?.map((tv: any) => is_numeric(tv) ? tv : tv.charAt(0) + tv.slice(1).toLowerCase())?.join(' ')

        const desc = dailyActivity?.[1]?.trim()
        const isDayActivity = desc?.toUpperCase() === 'BASE' || (desc?.toUpperCase() === "RECOVERY" && !(dayData?.marathonPaces?.length > 1)) || desc?.toUpperCase() === "10K PACE" || isRD || dayData?.marathonPaces.length < 2
        const valSplit = trainVal.split(' ')

        let distance = valSplit[0]
        const isNumber = is_numeric(distance || valSplit[0])

        const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == dayData?.marathon?.id)?.distance ? true : false

        distance = !hasCustomDistance && isNumber && rr && maxWeeks ? (distance * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : valSplit[0]

        const hasDuration = dayData?.marathonPaces?.length > 0
        const totalDuration = Number(calculateTotalDuration(isDayActivity).toFixed(0))

        const trainType = dailyActivity.length > 1 ? dailyActivity[2]?.trim() : 'none'
        const isYoga = trainType?.toLowerCase().includes('yoga')
        const isStrength = trainType?.toLowerCase().includes('strength')
        const isStretch = trainType?.toLowerCase().includes('stretch')
        const isRoll = trainType?.toLowerCase().includes('roll')
        const trainIcon = isYoga ? 'fa-solid fa-spa' : isStrength ? 'fa-solid fa-dumbbell' : isStretch ? 'fa-solid fa-child-reaching' : isRoll ? 'fa-solid fa-circle-dashed' : 'fa-solid fa-running'
        const isTraining = titleCase(valSplit.slice(1).join(' ')).toLowerCase().includes('train')

        const isRest = titleCase(valSplit).toLowerCase().includes('rest') || trainVal.toLowerCase().includes('rest')
        const isRacePace = trainVal.toLowerCase().includes('race') || (dailyActivity && dailyActivity.find((e: any) => e.toLowerCase().includes('race')) && !dailyActivity.find((e: any) => e.toLowerCase().includes('race day')))

        // dayNum can be used instead of id, but reordering days will cause issues
        // const dayNum =  moment(dayData?.date).day() + 1 || moment(dayData?.date).day() + 1 || selectedDay

        const daySurfaceType = allDaySurfaceType?.find((e: any) => userInfo?.uid === e.plan_id && (e.activity_id === dayData?.marathon?.id))?.surface_type

        const isCompleteInActive = isFromDrawer && !isMarkComplete
        const isEditable = !isShowEdit && !isFromDrawer
        const isSeeLaps = trainVal?.toUpperCase().includes('RACE')
        const isStretchRoll = isSeeLaps && (desc?.toLowerCase() === 'stretch,roll' || desc?.toLowerCase() === 'stretch')
        const isTodayAndFuture = moment(getTodayDate()).isSameOrAfter(moment())
        const trainGuidanceDesc = getGuidance(isStretchRoll || (rr && recoveryAssessmentsDays) ? 'stretch' : (trainType || ''))
        const guidanceDesc = isTraining ? trainGuidanceDesc : (getGuidance(desc) || getGuidance(trainVal))
        const allButtons = <>
            {
                //  previously '(currentDayActivities && currentDayActivities?.length == 0 && trainVal?.toUpperCase() !== 'REST') ?'
                //  but removed to enable sending workouts to garmin even after results
                (!pacingLocked && ((trainVal?.toUpperCase() !== 'REST') ?
                    !isMarkComplete && <Grid container alignItems="center" justifyContent={'flex-end'} sx={{ mt: 1 }}>
                        {!isTraining && <Grid sx={{ mt: '10px' }} justifyContent={'flex-end'} alignItems="center">
                            {!isFromDrawer && <Button onClick={() => setOpenSurface(true)}>
                                <Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
                                    <Box sx={{ height: '30px', color: 'black' }}>{!loadingSurface && activeSurfaceIcon && daySurfaceType ? activeSurfaceIcon : loadingSurface ? <Spinner animation="grow" /> : <RSIcon type='road' />}</Box>
                                    <Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light', color: 'black' }}>{'Surface'}</Grid>
                                </Grid>
                            </Button>}
                        </Grid>}
                        {!isFromDrawer ?
                            [isTodayAndFuture && trainVal?.toUpperCase() !== "CROSS TRAIN" ? `${(userGarminDetails?.status && !hasSyncData || corosUserData && corosUserData.status == 1 && !corosScheduledWorkout) ? 'Send' : userGarminDetails?.status && hasSyncData || corosUserData && corosUserData.status == 1 && corosScheduledWorkout ? 'Synced' : ''}` : undefined
                                , `${isDayComplete ? 'Completed' : 'Complete'}`,
                            ].map((t, i) => {
                                const isCompleted = t === 'Completed'
                                const isSynced = t === "Synced"
                                const isActionToComplete = t === 'Complete'
                                const isActionToSend = t === 'Send'
                                return !(i && isAfterCurrentDate) && t && <Grid key={i} sx={{ mt: '10px', mr: '-0.4rem' }} justifyContent={'flex-end'} alignItems="center">
                                    <Button onClick={() => (isCompleted || isSynced) ? handleMarkComplete(false) : handleCompleteNSync(i)}
                                        sx={{ color: '#010101' }}>
                                        <Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
                                            {/* {isC && (i ? <i className="fa-light fa-watch" style={{ position: 'absolute', left: -25, marginTop: '1px', fontSize: '40px' }} /> : <CheckIcon sx={{ position: 'absolute', left: -25, mt: '1px', fontSize: '40px' }} />)} */}
                                            {isActionToSend && !isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" style={{ fontSize: '30px' }}></i>}
                                            {isActionToComplete && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#999' }}></i>}
                                            {isCompleted && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
                                            {isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
                                            <Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light' }}>{t}</Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                            }
                            ) :
                            // @@@ Edit Activity Details Save Button
                            <Grid container xs={12} sx={{ mx: '20px', mb: '10px', mt: '1rem' }} justifyContent='center' alignItems="center">
                                <RSButton1 sx={{ mt: '1rem' }}
                                    onClick={async () => {
                                        await handleSubmit(distance).then(() => {
                                            setIsSurfaceAdjustedPaceValues(false)
                                        })

                                    }}>Save</RSButton1>
                            </Grid>}
                    </Grid>
                    : ''))
            }
        </>

        const activityActionsButtons = <>
            {
                (trainVal?.toUpperCase() !== 'REST') ?
                    !isMarkComplete && <Grid container alignItems="center" justifyContent={'flex-end'}
                        sx={{ mt: isValid ? '0rem' : '1rem', pr: '-1rem' }} >

                        {!isTraining && <Grid sx={{ mt: '10px', mr: '-0.4rem' }} justifyContent={'flex-end'} alignItems="center">
                            {!isFromDrawer && <Button onClick={() => setOpenSurface(true)}>
                                <Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
                                    <Box sx={{ height: '30px', color: 'black' }}>{!loadingSurface && activeSurfaceIcon && daySurfaceType ? activeSurfaceIcon : loadingSurface ? <Spinner animation="grow" /> : <RSIcon type='road' />}</Box>
                                    <Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light', color: 'black' }}>{'Surface'}</Grid>
                                </Grid>
                            </Button>}
                        </Grid>}

                        {!isFromDrawer ?
                            [isTodayAndFuture && trainVal?.toUpperCase() !== "CROSS TRAIN" ? `${(userGarminDetails?.status && !hasSyncData || corosUserData && corosUserData?.status == 1 && !corosScheduledWorkout) ? 'Send' : userGarminDetails?.status && hasSyncData || corosUserData && corosUserData.status == 1 && corosScheduledWorkout ? 'Synced' : ''}` : undefined,
                            `${isDayComplete ? 'Completed' : 'Complete'}`
                            ].map((t, i) => {
                                const isCompleted = t === 'Completed'
                                const isSynced = t === "Synced"
                                const isActionToComplete = t === 'Complete'
                                const isActionToSend = t === 'Send'
                                return !(i && isAfterCurrentDate) && t && <Grid key={i} sx={{ mt: '10px', mr: '-0.4rem' }} justifyContent={'flex-end'} alignItems="center">
                                    <Button onClick={() => (isCompleted || isSynced) ? handleMarkComplete(false) : handleCompleteNSync(i)}
                                        sx={{ color: '#010101' }}>
                                        <Grid sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }} justifyContent='center' alignItems='center'>
                                            {/* {isC && (i ? <i className="fa-light fa-watch" style={{ position: 'absolute', left: -25, marginTop: '1px', fontSize: '40px' }} /> : <CheckIcon sx={{ position: 'absolute', left: -25, mt: '1px', fontSize: '40px' }} />)} */}
                                            {isActionToSend && !isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" aria-hidden="true" style={{ fontSize: '30px' }}></i>}
                                            {isActionToComplete && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#999' }}></i>}
                                            {isCompleted && <i className="fa-solid fa-circle-check" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
                                            {isSynced && <i className="fa-kit fa-regular-watch-smart-circle-arrow-left" aria-hidden="true" style={{ fontSize: '30px', color: '#9FCD2B' }}></i>}
                                            <Grid sx={{ fontSize: '11px', fontFamily: 'Poppins-Light' }}>{t}</Grid>
                                        </Grid>
                                    </Button>
                                </Grid>
                            }
                            ) : <Grid container xs={12} sx={{ mx: '20px', mb: '10px' }} justifyContent='center' alignItems="center">
                                <RSButton1 sx={{ mt: '10px' }} onClick={async () => await handleSubmit(distance)}>Save</RSButton1>
                            </Grid>}
                    </Grid>
                    : ''
            }
        </>

        const renderCrossTrainingVideos = () => videoLocked ? <Box sx={{ width: '100%', pt: (rr && recoveryAssessmentsDays) ? 0 : 2 }}>
            <SH5 sx={{ textTransform: 'capitalize' }}>{isStretchRoll || isStretch || (rr && recoveryAssessmentsDays) ? 'stretching' : trainType} Training Guidance:</SH5>
            <P2 card>{trainGuidanceDesc}</P2>
        </Box> : <>
            {
                rr && recoveryAssessmentsDays && isAssessmentDone && workoutVideos?.videos2?.length == 0 ? (
                    <>
                        {
                            videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.mobility_video_ids && rr.recoveryPlan.mobility_video_ids !== "" ? rr.recoveryPlan.mobility_video_ids : defaultExercises.mobility, 'mobility'))
                        }
                    </>

                ) : undefined
            }

            {
                !isFromDrawer && trainVal?.toLowerCase() !== "rest" && Object.keys(workoutVideos).map((w, k) =>
                    workoutVideos[w]?.length > 0 &&
                    <>
                        {
                            w === 'videos1' && !rr ?
                                <Grid container key={k + 'v'} alignItems="center" sx={{
                                    border: '1px solid rgba(0, 0, 0, 0.176)',
                                    width: '100%',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    mt: '36px'
                                }}
                                >
                                    <Grid container xs={12} sx={{ p: '15px' }} justifyContent='flex-start' alignItems="center">
                                        {workoutVideos[w].map((wv: any, i: number) =>
                                            <Grid container key={i + w} sx={{ fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start' alignItems="center">
                                                <img
                                                    style={{ width: '20px', height: '20px', marginRight: '10px' }}
                                                    src="./images/icons/tick-mark.png"
                                                    alt="tick-mark"
                                                />
                                                {wv?.description} {wv?.title?.toUpperCase() !== 'CROSS TRAIN' ? wv.title : ''}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                : w === 'videos2' && rr ? (
                                    <Grid container xs={12} justifyContent='flex-start' alignItems="center">
                                        {
                                            rr && (workoutVideos.videos2[0]?.link.toLowerCase().includes('foam') || trainVal?.toUpperCase() === "CROSS TRAIN") ? (
                                                <>
                                                    {
                                                        !recoveryAssessmentsDays && (hasAssessment || rr.recoveryPlan.mobility_video_ids) && <Grid container xs={12} sx={{ pt: '20px', textDecoration: 'underline', color: '#010101', mb: '-25px' }} justifyContent="flex-start" alignItems="center">
                                                            <Button onClick={() => setShowAssessment(true)} sx={{ textTransform: 'none', color: '#010101', fontSize: '14px', FontFamily: 'Poppins-Light' }}>Retake assessment</Button>
                                                        </Grid>
                                                    }
                                                    {
                                                        videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.mobility_video_ids && rr.recoveryPlan.mobility_video_ids !== "" ? rr.recoveryPlan.mobility_video_ids : defaultExercises.mobility, 'mobility'))
                                                    }
                                                </>

                                            ) : rr && (workoutVideos.videos2[0]?.link.toLowerCase().includes('base') || workoutVideos.videos2[0]?.link.toLowerCase().includes('yoga') || workoutVideos.videos2[0]?.link.toLowerCase().includes('exercises')) ? (
                                                <>
                                                    {
                                                        !recoveryAssessmentsDays && (hasAssessment || rr.recoveryPlan.strength_video_ids) && <Grid container xs={12} sx={{ pt: '20px', textDecoration: 'underline', color: '#010101', mb: '-25px' }} justifyContent="flex-start" alignItems="center">
                                                            <Button onClick={() => setShowAssessment(true)} sx={{ textTransform: 'none', color: '#010101', fontSize: '14px', fontFamily: 'Poppins-Light' }}>Retake assessment</Button>
                                                        </Grid>
                                                    }
                                                    {
                                                        videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.strength_video_ids && rr.recoveryPlan.strength_video_ids !== "" ? rr.recoveryPlan.strength_video_ids : defaultExercises.strength, 'strength'))
                                                    }
                                                </>
                                            ) : undefined
                                        }
                                    </Grid>
                                ) : w === 'videos2' ?
                                    <>{videoContainerList(workoutVideos[w].map((wv: any, i: number) => {
                                        const wvData = getWorkoutVideoDetails(wv?.link)
                                        const eType = wvData?.exercise_type
                                        return { id: wv.id, headingSubtitle: eType ? eType : wvData?.main_headline, headingTitle: eType ? wvData?.main_headline : wv?.title, src: wvData?.img_title, onClick: () => handleOpenVideo(wv?.link) }
                                    }))}</> : undefined
                        }
                    </>
                )
            }
            {
                rr && trainVal?.toUpperCase() === "REST" ?
                    <>
                        {
                            videoContainerList(handleGetRecoveryWorkoutVideos(rr.recoveryPlan.mobility_video_ids && rr.recoveryPlan.mobility_video_ids !== "" ? rr.recoveryPlan.mobility_video_ids : defaultExercises.mobility, 'mobility'))
                        }
                    </>
                    : undefined
            }
        </>

        const renderCrossTrainingButton = (trainIcon: string) => {

            return <Box sx={{ display: 'flex', width: '100%', backgroundColor: 'white', m: '0.8rem 0 0.4rem 0', borderRadius: '10px', flexDirection: 'column', overflow: 'hidden' }}>
                <Box sx={{ display: 'flex', width: '100%', p: '1.4rem 1.4rem 1rem 1.4rem', flexDirection: 'column' }}>
                    <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center', alignItems: 'center' }}>
                        <i className={`fa-duotone fa-solid ${trainIcon}`} style={{ fontSize: '22px', marginRight: '10px' }}></i>
                        <SH4 size={3}>Cross Training</SH4>
                    </Grid>
                    <Grid container xs={12}>{renderCrossTrainingVideos()}</Grid>
                </Box>
                {videoLocked && <RSUnlock userInfo={accountInfo} />}
            </Box>
        }

        const trainingCardProps = {

            trainingHeader: !isFromDrawer && !(isAssessmentDone) ? `${!isRest && !isTraining && daySurfaceType ? daySurfaceType.charAt(0).toUpperCase() + daySurfaceType.slice(1) : ''} ${isTraining ? 'Cross Training' : isRest ? 'Rest' : 'Running'}` : '',
            trainingDesc: (isSeeLaps && trainVal) || (desc && (desc === 'RECOVERY' ? 'Recover' : desc)),
            icon: isTraining ? trainIcon : isRest ? 'fa-face-sleeping' : '',
            color: getColorCode((isSeeLaps && trainVal) || desc),
            userInfo: !isFromDrawer && pacingLocked && accountInfo,
            locked: pacingLocked,
            editable: !isRaceDay && isEditable,
            onClick: () => setIsEditing(true),
            raceDay: isRaceDay,
            afterRaceDay: isRaceDayAndBeyond,
            raceDayToday: isRaceDayToday,
            ...isFromDrawer && pacingLocked && { boxSx: { p: 0 } },
            trainingData: !isTraining && !isRest && !isRacePace && !isFromDrawer && !raceResults?.id ? [
                ...!isRacePace ? [{
                    title: 'Distance',
                    values: [{
                        value: isNumber ? Number(distance).toFixed(1) : trainVal,
                        label: titleCase(valSplit.slice(1).join(' ')).replace(/les/g, '').replace(/kilometers/g, 'km').toLowerCase()
                    }]
                }] : [],
                ...!estDurationLocked && hasDuration && totalDuration ? [{
                    title: isRaceDay ? 'Race Goal' : 'Est. Time',
                    values: [{
                        value: totalDuration < 60 ? '' : `${isRaceDay ? '' : '~'}${Math.floor(totalDuration / 60)}`,
                        label: 'hr' + (totalDuration > 60 ? 's' : '')
                    }, {
                        value: totalDuration < 60 ? `${isRaceDay ? '' : '~'}${totalDuration}` : (totalDuration % 60 || ''),
                        label: 'min' + ((totalDuration % 60) > 1 ? 's' : '')
                    }]
                }] : []
            ] : []
        }

        return (
            <Box key={trainVal} sx={{ display: 'flex', flexDirection: `column${isFromDrawer && isMarkComplete ? '-reverse' : ''}`, width: '100%', mt: isFromDrawer ? '0px' : '10px', fontFamily: 'Poppins', position: 'relative', visibility: openRaceResult && !isMobile ? 'hidden' : 'visible' }}>

                <RSTrainingCard {...trainingCardProps}>

                    {/*XT days*/}
                    {isTraining && <Grid>{renderCrossTrainingVideos()}</Grid>}


                    <Grid container xs={12} sx={{ flexDirection: 'column', mt: 2 }}>
                        {!isShowEdit && !pacingLocked && <Grid container sx={{ p: 0 }}>
                            <Grid container alignItems="center" >
                                {isFromDrawer && <Grid container xs={12} sx={{ px: '5px', pb: '1rem', borderBottom: '1px solid #AAA' }} justifyContent='flex-start' alignItems="center">
                                    {/* @@@ Edit Total Distance */}
                                    <Grid container xs={12} sx={{ pb: 2 }}>
                                        <P1 size={3} >Edit Run</P1>
                                    </Grid>
                                    <Grid container xs={desc ? 5 : 12} sx={{ lineHeight: 1.2, fontFamily: 'Poppins-Light', fontSize: '20px' }} justifyContent='flex-start' alignItems="center">
                                        {isNumber && isCompleteInActive ? <><input className='daily-activity-input' type='number' value={miles} onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} onChange={(e) => setMiles(e.target.value)} placeholder={distance} /> {valSplit.slice(1).join(' ')}</> : trainVal}
                                    </Grid>
                                    <Grid container xs={7} sx={{ lineHeight: 1.2, fontFamily: 'Poppins-Light', fontSize: '20px', textTransform: 'uppercase' }} justifyContent='flex-start' alignItems="center">
                                        {desc && <Box sx={{ width: '16px', height: '16px', backgroundColor: `${getColorCode(desc)}`, mr: '8px', borderRadius: '2px' }} />}
                                        {desc && (desc === 'RECOVERY' ? 'RECOVER' : desc)}
                                    </Grid>
                                </Grid>}

                                {dayData?.marathonPaces.length > 0 && (dayData?.marathonPaces[0].notes !== "" || dayData?.marathonPaces[0].pace !== "") &&
                                    <Grid container xs={12} sx={{ pt: isFromDrawer ? '15px' : 0 }} justifyContent='flex-start' alignItems="center">

                                        {isFromDrawer && <b style={{ fontFamily: 'Poppins-Bold', fontSize: '18px', marginTop: '0.5rem', marginBottom: '0.5rem' }}>Details</b>}

                                        <CustomAccordion title="View Details" openInit style2 titleIcon={<i className="fa-light fa-file-lines" />} normal={isFromDrawer}>
                                            <Grid container xs={12}>
                                                <Grid container xs={6}><P4>Distance</P4></Grid>
                                                <Grid container xs={6}><P4>Pace</P4></Grid>
                                            </Grid>

                                            {dayData?.marathonPaces?.map((val: any, idx: number) => {
                                                const curDistance = getCurrentDistance(dayData?.marathon?.id)
                                                let distanceValue = distanceInterpret(val?.notes, userInfo?.km == 'Y', val?.pace,
                                                    curDistance && isDayActivity ? curDistance : dayData?.marathon?.distance,
                                                    convertTextToIntervalTime(val?.notes, dayData.marathonPaces[idx - 1]),
                                                    isDayActivity ? curDistance : undefined
                                                )

                                                const originalPaceValue = getConvertedPace(val).value[0]

                                                // fallback incase pace value is empty for whatever reason
                                                const nextPaceValue = dayData?.marathonPaces[idx + 1] && getConvertedPace(dayData?.marathonPaces[idx + 1])?.value[0]
                                                const prevPaceValue = dayData?.marathonPaces[idx - 1] && getConvertedPace(dayData?.marathonPaces[idx - 1])?.value[0]

                                                let paceValue = originalPaceValue != '' ? originalPaceValue : nextPaceValue ? nextPaceValue : prevPaceValue


                                                if (val && userCustomPaces && userCustomPaces?.length) {
                                                    const paceId = val.paceId + (val.mar_id * 10000)
                                                    const customPace = userCustomPaces.find((p: any) => p.pid == paceId)
                                                    if (customPace) {
                                                        paceValue = customPace.pace
                                                    }
                                                }

                                                const paceUnit = getConvertedPace(val).value[0] !== "" ? "/" + getConvertedPace(val)?.value[1]?.trim() : ""
                                                const isValueLong = isLongText(distanceValue)
                                                const hasTimeTrial = !isDayActivity ? distanceValue?.includes('Time Trial') : false
                                                distanceValue = distanceValue?.toLowerCase()?.includes("repeat") ? `${distanceValue} total.` : distanceValue
                                                distanceValue = hasTimeTrial ? distanceValue?.replace(' Time Trial', 's') : distanceValue
                                                const finalDValue = distanceValue?.split(' ')
                                                const paceValueArr = paceValue?.split(' ')
                                                const v1 = paceValueArr?.find((v: any) => v.includes('m'))
                                                const v2 = paceValueArr?.find((v: any) => v.includes('s'))
                                                const curVal = paces?.[idx]?.pace?.split(' ')
                                                const c1 = curVal?.find((v: any) => v.includes('m'))
                                                const c2 = curVal?.find((v: any) => v.includes('s'))
                                                const grouped = groupPaces(dayData?.marathonPaces[idx + 1]?.notes.trim(), val?.notes?.trim(), val?.pace?.trim(), idx)
                                                const isValueLongNext = isLongText(dayData?.marathonPaces[idx + 1]?.notes.trim())


                                                let rr: RaceResult | undefined = undefined;
                                                let maxWeeks: [] | undefined = []

                                                if (userInfo?.raceResults) {
                                                    rr = userInfo?.raceResults
                                                    maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
                                                }

                                                const isNumber = finalDValue ? is_numeric(finalDValue[0]) : false

                                                if (isNumber) {
                                                    if (finalDValue[0]) {
                                                        finalDValue[0] = !hasCustomDistance && is_numeric(finalDValue[0]) && rr && maxWeeks ? (finalDValue[0] * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : finalDValue[0]
                                                    }
                                                    if (finalDValue[1]) {
                                                        finalDValue[1] = titleCase(finalDValue[1])
                                                    }

                                                }

                                                const treadUnit = displayTread ? userInfo?.km === "Y" ? '  kmph' : ' mph' : ''
                                                return <Grid container key={idx + 'dp'} sx={{ my: 1 }}>

                                                    {/* @@@ Distance Intervals Values */}
                                                    {<Grid container xs={isValueLong ? 6 : 6} alignItems='center' sx={{ overflowWrap: 'break-word', ml: isDayActivity ? '0.4rem' : 0, mr: '-0.4rem', mt: isValueLong ? '-10px' : 0, mb: isValueLong ? '3px' : (grouped && !isValueLongNext) ? '-15px' : 0, lineHeight: isValueLong ? '1em' : '', fontFamily: 'Poppins-light', fontSize: isValueLong ? '14px' : '16px', color: isValueLong ? 'gray' : 'black' }}>
                                                        {/* {!isNaN(Number(finalDValue[0])) ? Number(finalDValue[0]).toFixed(1) : finalDValue[0]} */}

                                                        {/* {finalDValue.slice(1).join(' ').includes('mile') || finalDValue.slice(1).join(' ').includes('kilo') ? '' : finalDValue.slice(1).join(' ')} */}
                                                        {finalDValue[0]} {!isDayActivity ? finalDValue.slice(1).join(' ') : userInfo?.km === "Y" ? 'km' : 'miles'}
                                                        {/* {testDistance} */}
                                                    </Grid>
                                                    }

                                                    {hasTimeTrial && <Grid container xs={12} alignItems='center' sx={{ lineHeight: '1em', fontFamily: 'Poppins-light', fontSize: '14px', color: 'gray' }}>
                                                        Time Trial
                                                    </Grid>
                                                    }

                                                    {/* @@@ Pace Values */}
                                                    {!isValueLong && !loadingPaces && !loadingSurface ? <Grid container xs={6} alignItems='center' >
                                                        {isFromDrawer && !isMarkComplete && !isValueLong && paceValue ?
                                                            <>
                                                                {/* @@@ Edit Pace Input */}
                                                                <input type='number' className='daily-activity-input' placeholder={v1 ? v1 : '0m'} value={(c1 || c1 === 'm|') && c1.includes('|') ? (c1 === 'm|' ? 0 : c1.replace('m|', '')) : ''} onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} onChange={(e) => handlePacesChange(e, idx, 'm', val, paceValue, val.multiplier)} />
                                                                <input type='number' max="59" className='daily-activity-input' placeholder={v2 ? v2 : '0s'} value={(c2 || c2 === 's|') && c2.includes('|') ? (c2 === 's|' ? 0 : c2.replace('s|', '')) : ''} onKeyDown={(e) => (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()} onChange={(e) => handlePacesChange(e, idx, 's', val, paceValue, val.multiplier)} />
                                                            </>
                                                            : <P1>
                                                                {prevSurfaceType?.toLowerCase().includes('tread') ? calculatePaceSpeed(paceValue) : paceValue} {calculatePaceSpeed(paceValue) != '' ? treadUnit : ''} {treadUnit == '' && paceUnit}
                                                            </P1>
                                                        }
                                                    </Grid>
                                                        : !isValueLong && <Grid container sx={{ height: '15px', width: '100px' }} className='loading-pace toRight' />
                                                    }
                                                </Grid>
                                            }
                                            )}

                                            {!isFromDrawer && !isShowEdit && isValid && !pacingLocked && !isRaceDay && <Grid sx={{ mt: '10px' }} container xs={12} justifyContent='flex-end' alignItems="flex-end">
                                                <RSButton1
                                                    onClick={() => {
                                                        setShowWeatherAlert(true);
                                                        handleCheckAll(true)
                                                    }}
                                                    sx={{ p: '5px', width: 'auto' }}
                                                >
                                                    <P1>
                                                        <WeatherIcon sx={{ mr: '5px' }} />
                                                        Weather Alert
                                                    </P1>
                                                </RSButton1>
                                            </Grid>}


                                        </CustomAccordion>
                                    </Grid>
                                }


                                {/* @@@ Complete and Sync/Send Buttons */}
                                {!isDayActivity && !isFromDrawer && !isRaceDay && activityActionsButtons}
                            </Grid>
                        </Grid>}
                        {guidanceDesc && pacingLocked && !isTraining ? (isFromDrawer ? <RSUpgrade userInfo={accountInfo}
                            title='Enable edits with RunSmart Pro.'
                            description='Unlock editing and other premium features that keep you on track and training smart.'
                        /> : <Box sx={{ width: '100%', pb: 2 }}>
                            <SH5>General Pace Guidance:</SH5>
                            <P2 card>{guidanceDesc}</P2>
                        </Box>) : <></>}
                    </Grid>

                    {isRaceDay && (isRaceDayAndBeyond || isRaceDayToday) && <RSButton1 sx={{ mt: 3 }} onClick={() => setUpdateRaceResult(true)}>
                        Enter results
                    </RSButton1>}
                    {(isDayActivity || isFromDrawer) && !isRaceDay && allButtons}
                </RSTrainingCard>

                {/* @@@ notes area plan tab*/}
                {!isFromDrawer && !isRaceDay && !(moment(selectedWeek?.week?.replaceAll('-', '/'))?.add(selectedDay, 'd')?.isAfter(moment(userInfo?.race_date)) && userInfo?.type !== 4) ? renderNotesArea() : <></>}

                {/* effort feedback but in plan tab */}
                {/* <Box sx={{ display: 'flex', width: '100%', backgroundColor: 'white', p: '1.4rem 1.4rem 1.4rem 1.4rem', m: '0.8rem 0 0.4rem 0', borderRadius: '10px' }}>
					<div style={{ marginTop: '0.4rem' }}
						onClick={() => {
							console.log('fefe')
							setSelectedActivity({ title: 'test', samples: [], activityId: 123123 })
							setShowEffortFeedback(true)

						}}>
						<CustomAccordion popup={true} title="How did this run feel?" style2 titleIcon={<i className="fa-light fa-gauge-high" />} normal={isFromDrawer}>
							<TrainingDayFeedback planId={userInfo?.uid} planDayId={dayData?.marathon?.id} currentDate={dayData?.date}></TrainingDayFeedback>
						</CustomAccordion>
					</div>
				</Box> */}

                {/* heart rate trackers but in plan tab */}
                {/* <Box sx={{ display: 'flex', width: '100%', backgroundColor: 'white', p: '1.4rem 1.4rem 1.4rem 1.4rem', m: '0.8rem 0 0.4rem 0', borderRadius: '10px' }}>
                    <div style={{ marginTop: '0.4rem', backgroundColor: 'blue' }}
                        onClick={() => {
                            setSelectedActivity({ title: 'test', samples: [], activityId: 24242424 })
                            setShowHeartRateTracker(true)
                        }}>
                        <Box sx={{
                            mt: '8rem', borderRadius: "5px", width: "100%", px: 2, py: 1.5, backgroundColor: "#f5f5f5", display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'
                        }}>
                            <Box sx={{ mr: 1, display: 'flex' }}>
                                <i className="fa-light fa-heart" />
                                <P1 sx={{ ml: '0.5rem' }}>Heart Rate</P1>
                            </Box>
                            <i className="fa-regular fa-chevron-right me-1" />
                        </Box>
                    </div>
                </Box> */}


                {
                    rr && recoveryAssessmentsDays && (isAssessmentDone || rr.recoveryPlan.mobility_video_ids || rr.recoveryPlan.strength_video_ids) && <Grid container xs={12} sx={{ pt: '20px', textDecoration: 'underline', color: '#010101', mb: '-25px' }} justifyContent="flex-start" alignItems="center">
                        <Button onClick={() => setShowAssessment(true)} sx={{ textTransform: 'none', color: '#010101', fontSize: '14px', fontFamily: 'Poppins-Light' }}>Retake assessment</Button>
                    </Grid>
                }

                {!isFromDrawer && workoutVideos.videos2.length > 0 && !isTraining && !isRaceDay && renderCrossTrainingButton(trainIcon)}
            </Box >
        )
    }

    const renderStartRecover = () => {
        const isBeforeRD = userInfo?.race_date ? moment(moment().format('YYYY/MM/DD')).isBefore(moment(userInfo.race_date).add(1, 'd').format('YYYY/MM/DD')) : false
        return <Grid container>
            <Grid container className='faded faded-bottom' xs={12} sx={{ overflow: 'hidden' }} justifyContent="flex-start" alignItems="center">
                <span>
                    <img src="img/recover.jpg" />
                </span>
            </Grid>
            <Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
                Your Recovery Plan is ready.
            </Grid>
            <Grid container xs={12} sx={{ px: 2, lineHeight: '1em', fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center' }} >
                Take the next step in your running journey with your Physical Therapist-designed recovery assessment and plan.
            </Grid>
            {(!raceResults || raceResults.length === 0) && <Grid container xs={12} sx={{ mt: '40px', px: 2 }} >
                <RSButton1 disabled={isBeforeRD} onClick={() => setUpdateRaceResult(true)} sx={{ p: '8px 0px' }}>
                    {isBeforeRD ? `Begins ${moment(userInfo?.race_date).add(1, 'd').format('MMM DD')}` : 'Enter Race Results'}
                </RSButton1>
            </Grid>}
        </Grid>
    }

    const renderAddMaintenancePlan = () => {
        return <AddMaintenancePlan addMaintenance={addMaintenance} setAddMaintenance={setAddMaintenance} doNotAddMaintenance={doNotAddMaintenance} setDoNotAddMaintenance={setDoNotAddMaintenance} userInfo={userInfo} getRaceResult={getRaceResult} setLoadingValues={setLoadingValues} />
    }

    const renderTakeNextStep = () => {
        return <Grid container>
            <Grid container className='faded faded-bottom' xs={12} sx={{ overflow: 'hidden' }} justifyContent="flex-start" alignItems="center">
                <span>
                    <img src="img/recover.jpg" />
                </span>
            </Grid>
            <Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
                Take the Next Step
            </Grid>
            <Grid container xs={12} sx={{ px: 2, lineHeight: '1em', fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center' }} >
                Build a plan to continue your running journey
            </Grid>
            <Grid container xs={12} sx={{ mt: '40px', px: 2 }} >
                <Button onClick={() => navigate('/training-plan/build/step-1', { replace: true })} variant='contained' sx={{ boxShadow: 'none', color: '#010101', width: '100%', fontFamily: 'Poppins', border: '1px solid #9FCD2B', p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: '#9FCD2B !important', textTransform: 'none' }}>
                    Add Next Plan
                </Button>
            </Grid>
        </Grid>
    }

    const renderBuildMaintenancePlan = () => {
        return <Grid container>
            <Grid container className='faded faded-bottom' xs={12} sx={{ overflow: 'hidden' }} justifyContent="flex-start" alignItems="center">
                <span>
                    <img src="img/recover.jpg" />
                </span>
            </Grid>
            <Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
                Build Your Maintenance Plan
            </Grid>
            <Grid container xs={12} sx={{ px: 2, lineHeight: '1em', fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center' }} >
                {`Remaining consistent in your running will not only keep you fit, it's essential to remaining injury-free.`}
            </Grid>
            <Grid container xs={12} sx={{ mt: '40px', px: 2 }} >
                <Button onClick={() => setAddMaintenance(true)} variant='contained' sx={{ boxShadow: 'none', color: '#010101', width: '100%', fontFamily: 'Poppins', border: '1px solid #9FCD2B', p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: '#9FCD2B !important', textTransform: 'none' }}>
                    Add Maintenance Plan
                </Button>
            </Grid>
            <Grid container xs={12} sx={{ pt: '20px', pb: '5px', fontSize: '14px', textDecoration: 'underline', color: '' }} justifyContent="center" alignItems="center">
                <Button onClick={() => setDoNotAddMaintenance(true)} sx={{ textTransform: 'none', color: '#485D13' }}>{`Don't add maintenance plan`}</Button>
            </Grid>
        </Grid>
    }

    const renderLastDay = () => {
        if (userInfo?.type === 4 && userInfo?.raceResults?.id) {
            return userInfo?.raceResults?.recoveryPlan?.skip_maintenance_plan ? renderTakeNextStep() : renderBuildMaintenancePlan()
        }
        return renderStartRecover()
    }

    const renderMap = () => {
        return <Box sx={{ height: '100%' }}>
            <RSHeaderCont className='ellipsis-1l' noBanner isStatic={!isMobile} backClick={() => setShowMap(false)} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px', }} title={selectedActivity?.title} />
            <TrainingActivityMap isMobile={isMobile} samples={selectedActivity?.samples || []} />
        </Box>
    }

    const renderEffortFeedback = () => <Box sx={{ height: '100%' }}>
        <RSHeaderCont className='ellipsis-1l' noBanner isStatic={!isMobile} backClick={() => setShowEffortFeedback(false)} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px' }} title={'Rate Your Run'} />
        <TrainingDayFeedback planId={userInfo?.uid} planDayId={dayData?.marathon?.id} currentDate={dayData?.date} activityId={selectedActivity?.activityId}></TrainingDayFeedback>
    </Box>

    const renderHeartRateTracker = () => <HeartRateBase
        showHeartRateTracker={showHeartRateTracker} setShowHeartRateTracker={setShowHeartRateTracker}
        userDataActivity={selectedActivity?.samples || []} planId={userInfo?.uid}
        accountProfile={accountProfile}
        selectedActivity={selectedActivity}
        userInfo={userInfo}
        dayData={dayData}
    ></HeartRateBase>



    const renderRaceDay = () => {
        const sqCount = window?.innerWidth ? Math.floor(window.innerWidth / 20) : 0
        const isC = isDayComplete ? true : false
        const isMetric = accountProfile?.measurement_system === 'metric'

        const raceSelectionValue: any = raceSelection.find((rs: any) => rs.raceValue === selectedRace?.value)
        const unit = isMetric ? 'km' : 'mi'
        const { distanceValue, distanceValueKM } = raceSelectionValue || {}
        const finishValue = raceResults?.id && raceResults?.finished_time_in_sec ? raceResults.finished_time_in_sec : 0
        const finishTime = timeToString(ConvertS2TimeObject(finishValue))
        const paceValue = raceResults?.id && raceResults?.finished_time_in_sec && distanceValue ? raceResults.finished_time_in_sec / distanceValue : 0
        const paceTime = timeToString(ConvertS2TimeObject(paceValue))

        const trainingCardProps = {
            trainingHeader: raceResults?.race_name || raceSelectionValue?.event_name || '',
            trainingDesc: raceSelectionValue?.complete_plan || '',
            icon: 'fa-solid fa-running',
            color: getColorCode('RACE DAY'),
            userInfo: accountInfo,
            editable: true,
            onClick: () => setUpdateRaceResult(true),
            trainingData: [{
                title: 'Distance',
                values: [{
                    value: (isMetric ? distanceValueKM : distanceValue) || '',
                    label: unit
                }]
            }, {
                title: 'Pace',
                values: [{
                    value: paceTime || '',
                    label: `/${unit}`
                }]
            }, {
                title: 'Time',
                values: [{
                    value: finishTime,
                    label: ''
                }]
            }]
        }


        return <RSTrainingCard {...trainingCardProps} />
    }

    const handleSubmitCompletePlan = async (body: any, isFromWatch?: boolean) => {
        if (body) {
            if (raceResults?.id) {
                const newBody = { ...body, id: Number(raceResults?.id) }
                const abortController = new AbortController()
                await UpdateRaceResult(newBody as UserRaceResults, abortController.signal)
            } else {
                localStorage.setItem('rpReady', '1')
                localStorage.setItem('recoveryAssessment', '0')
                await CreateRaceResult(body as UserRaceResults)
            }
        }
        await getRaceResult()
        !isFromWatch && setUpdateRaceResult(false)
        //!isFromWatch && navigate('/profile?timeline=true', { replace: true })
    }

    const getLoading = () => {
        if (isGarminConnected(userGarminDetails))
            return loadingGarminActivity
        else if (appleHealthPermission?.status === "granted")
            return isLoadingAppleHealth
        else if (corosUserData?.status == 1)
            return isLoadingCoros
        else
            return false
    }

    useEffect(() => {
        if (isRaceDay && getLoading())
            setLoadingWatch(true)
        else
            setLoadingWatch(false)
    }, [isRaceDay, isLoadingAppleHealth, isLoadingCoros, loadingGarminActivity])

    const renderWatchLoader = () => {
        return <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 4 }}>
            <Box sx={{ height: '80px', width: '80px' }}>
                <Lottie animationData={RunningLoader} />
            </Box>
            <P1 desc sx={{ mt: 1 }}>Loading data, please wait...</P1>
        </Box>
    }

    const renderWatches = (raceD?: boolean) => {
        const syncDevice = !isGarminConnected(userGarminDetails) && !(appleHealthPermission?.status === "granted" || appleHealthActivity) && !(corosUserData && corosUserData.status == 1) && !(isLoadingAppleHealth || isLoadingCoros || loadingGarminActivity)
        if (syncDevice) {
            return renderSyncDevice(false, true)
        } else if ((isGarminConnected(userGarminDetails) && loadingGarminActivity && currentDayActivities.length == 0)
            || (appleHealthPermission?.status === "granted" && isLoadingAppleHealth && !appleHealthActivity)
            || (corosUserData && corosUserData.status == 1 && isLoadingCoros && corosUserActivity.length == 0)
            || ((!isGarminConnected(userGarminDetails) && corosUserData && corosUserData.status == 0 && appleHealthPermission?.status !== "granted" && (loadingGarminActivity || isLoadingCoros || isLoadingAppleHealth)))) {
            return renderWatchLoader()
        } else if (currentDayActivities?.length > 0) {
            return renderGarminActivity()
        } else if (appleHealthActivity) {
            return <>
                <AppleWatchWorkout
                    userInfo={userInfo}
                    selectedDay={selectedDay}
                    selectedWeek={selectedWeek}
                    appleHealthActivity={appleHealthActivity}
                    isLoading={isLoadingAppleHealth}
                    workoutLaps={appleHealthActivityLaps}
                    renderNotesArea={renderNotesArea}
                    renderWatchComplete={isRaceDay && renderWatchComplete}
                    isRaceDay={isRaceDay}
                    planDates={
                        {
                            date: dayData?.date,
                            weekDays,
                            selectedWeek,
                            selectedDay
                        }
                    }
                    setSelectedActivity={setSelectedActivity}
                    setShowMap={setShowMap}
                    setShowEffortFeedback={setShowEffortFeedback}
                    setShowHeartRateTracker={setShowHeartRateTracker}
                    renderNoActivity={renderNoActivity}
                />
                {/* {appleHealthActivityLaps && appleHealthActivityLaps?.length > 0 && <AppleWatchWorkoutLaps data={appleHealthActivityLaps} userInfo={userInfo} />} */}
            </>
        } else if (corosUserActivity && corosUserActivity?.length > 0) {
            return <><CorosWatchWorkout
                renderNotesArea={renderNotesArea}
                isLoading={isLoadingCoros}
                activities={corosUserActivity}
                userInfo={userInfo}
                renderWatchComplete={isRaceDay && renderWatchComplete}
                planDates={
                    {
                        date: dayData?.date,
                        weekDays,
                        selectedWeek,
                        selectedDay
                    }
                }
                isRaceDay={isRaceDay}
                setSelectedActivity={setSelectedActivity}
                setShowMap={setShowMap}
                setShowEffortFeedback={setShowEffortFeedback}
                setShowHeartRateTracker={setShowHeartRateTracker}
                renderNoActivity={renderNoActivity}
            /></>
        } else if (!currentDayActivities.length && !appleHealthActivity && !corosUserActivity.length && !isLoadingCoros && !isLoadingAppleHealth) {
            return raceD ? '' : renderSyncDevice(true, false)
        } else
            return ''
    }

    const renderActiveTab = (isAssessmentDone?: boolean) => {
        if (activeTab === 1) {
            return renderWatches()
        } else if (activeTab === 2) {
            // if (currentDayActivities.length > 0)
            // 	return renderGarminLaps()
        }
        else
            return renderActivity(false, isAssessmentDone)
    }

    const renderRaceResult = () => {
        const watchData = renderWatches(true)
        const fN = accountProfile?.first_name?.split(' ')?.[0] || ''
        const raceSelectionValue: any = raceSelection.find((rs: any) => rs.raceValue === selectedRace?.value)

        return <>
            <RSDrawer
                bottom={!isMobile}
                popUpWidth='600px'
                popUpHeight='800px'
                open={openRaceResult}
                onClose={() => navigate(fr === 'tj' ? '/profile?timeline=true' : '/', { replace: true })}
            >
                <Box>
                    <Grid container xs={12}>
                        {isMobile && <RSHeaderCont drawer backClick={() => navigate(fr === 'tj' ? '/profile?timeline=true' : '/', { replace: true })}
                            title='Race Results' />}
                        <Box sx={{ width: '100%', px: 3, py: 2 }}>
                            <H2 size={4} card sx={{ py: 1 }}>{`Way to go${fN ? `, ${fN}` : ''}!`}</H2>
                            <P1 size={2} card >{`Congrats on finishing your training and completing your ${raceSelectionValue?.complete_plan || 'race'}!`}</P1>
                        </Box>
                        <Grid container sx={{ px: 3, py: 2 }}>
                            <Grid container xs={12} sx={{ position: 'relative' }}>
                                {(isLoadingAppleHealth || isLoadingCoros || loadingGarminActivity) ? renderWatchLoader()
                                    : <>{watchData || renderRaceDay()}</>}
                            </Grid>
                        </Grid>
                        <A size={1} sx={{ px: 3, mb: 3 }} onClick={() => {
                            setLoadingNewResult(true)
                            setTimeout(() => {
                                setLoadingNewResult(false)
                            }, 2000)

                            const isNoRaceDay = userInfo?.type === 4
                            let weekNum = 0
                            let dayNum = 0
                            if (!isNoRaceDay) {
                                const recovDay = moment(userInfo?.race_date).add(1, 'd')
                                weekNum = weekDays?.findIndex((wd: any) => {
                                    const newWD = wd?.replaceAll('-', '/')
                                    return moment(recovDay)?.isBetween(moment(newWD)?.subtract(1, 'd'), moment(newWD).add(7, 'd'))
                                }) || 0
                                dayNum = moment(recovDay).diff(moment(weekDays?.[weekNum]), 'days')
                            }

                            setSelectedWeek({ week: weekDays[weekNum], key: weekNum + 1 })
                            setSelectedDay(weekNum ? dayNum : moment(weekDays[0]).diff(moment(userInfo?.start), 'days'))
                            setOpenRaceResult(false)
                        }}>View training plan</A>
                    </Grid>
                </Box>
            </RSDrawer>
            <CompletePlan updateRaceResult={updateRaceResult} setUpdateRaceResult={setUpdateRaceResult} userInfo={userInfo} raceResults={raceResults} selectedRace={selectedRace} handleSubmitCompletePlan={handleSubmitCompletePlan} gptPlanId={gptPlanId} />
        </>
    }

    const renderCrossTrainActivities = () => <>
    </>

    const renderAssessmentIntro = () => {
        return <Grid container sx={{ pt: '10px' }}>
            <Grid container xs={12} sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '20px', lineHeight: '1.4em' }}>
                Welcome to Your Recovery Assessment!
            </Grid>

            <Grid container xs={12} sx={{ flexDirection: 'column', pt: '25px' }}>
                <Box sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px', lineHeight: '1.2em' }}>Estimated Time:</Box>
                <Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px', lineHeight: '1.5em' }}>15 - 30 minutes</Box>
            </Grid>

            <Grid container xs={12} sx={{ flexDirection: 'column', pt: '25px' }}>
                <Box sx={{ fontFamily: 'Poppins-SemiBold', fontSize: '16px', lineHeight: '1.2em' }}>About:</Box>
                <Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px', lineHeight: '1.4em' }}>
                    Recovery is a crucial part of your training journey. It’s more than a few days off or waiting for soreness to subside.
                </Box>
                <Box sx={{ fontFamily: 'Poppins-Light', fontSize: '16px', lineHeight: '1.4em', pt: '25px' }}>
                    Use our Physical Therapist-designed assessment to uncover and fix imbalances that developed through training.
                </Box>
            </Grid>

            <Grid container xs={12} sx={{ mt: '30px' }} justifyContent='flex-start' alignItems='center'>
                <Button onClick={() => setShowAssessment(true)} variant='contained' sx={{ boxShadow: 'none', height: '50px', width: '200px', fontFamily: 'Poppins', border: '1px solid #9FCD2B !important', p: '8px 20px', borderRadius: '10px', fontSize: '14px', backgroundColor: '#9FCD2B !important', color: '#010101', textTransform: 'none', justifyContent: 'flex-start', alignItems: 'center' }}>
                    Begin Assessment
                    <ArrowForward sx={{ position: 'absolute', right: 0, fontSize: '25px', stroke: '#9FCD2B', strokeWidth: '1px', mr: '5px' }} />
                </Button>
            </Grid>
        </Grid>
    }

    const rr: RaceResult = userInfo?.raceResults
    const recWeeks = rr?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0
    const isRecovery = userInfo?.uid == rr?.maintenance_plan_id;

    const hasAssessment = localStorage.getItem('recoveryAssessment') == "1" || hasRecoveryAssessment ? true : false

    const startDate = weekDays[0]
    const currentWeek = weekDays[selectedWeek.key - 1]
    const currDate = moment(dayData?.date)

    let assessmentVideosDays = false;
    let recoveryAssessmentsDays = false;

    if (rr && isRecovery) {
        const recoveryType = rr?.recoveryPlan?.recoveryType;
        assessmentVideosDays = moment(currDate).isBefore(moment(startDate).add(recoveryType?.assessment_starts_in_days, 'days'))

        recoveryAssessmentsDays = moment(currDate).isSameOrAfter(moment(startDate).add(recoveryType?.assessment_starts_in_days, 'days')) && moment(currDate).isBefore(moment(startDate).add(recoveryType?.resume_running_on_day, 'days'))
    }

    const renderAssessmentDrawer = () => {
        return <RSDrawer
            bottom
            open={showAssessment}
            onClose={() => setShowAssessment(false)}
        >
            <Grid container xs={12} sx={{ pt: '20px' }}>
                <RecoveryAssessments closeAssessment={() => setShowAssessment(false)} handleUpdateRecovery={handleUpdateRecovery} currentData={userInfo} userInfo={accountInfo} />
            </Grid>
        </RSDrawer>
    }

    return <Box sx={{ p: isLastDay ? 0 : 3, pt: '20px', height: 'fit-content' }}>

        <SurfaceTypes open={openSurface} setOpen={setOpenSurface} activeSurface={activeSurface} setActiveSurface={setActiveSurface} setPrevSurfaceModifier={setPrevSurfaceModifier} prevSurfaceType={prevSurfaceType} setSurfaceModifier={setSurfaceModifier} setSurfaceUnit={setSurfaceUnit} handleUpdateSurfaceType={handleUpdateSurfaceType} setActiveSurfaceIcon={setActiveSurfaceIcon} isKm={userInfo?.km === "Y"} />
        {!loadingValues && isRecovery && renderAssessmentDrawer()}
        {!isLastDay && todayDate(dayData?.date)}
        {renderAddMaintenancePlan()}
        <AnimatePresence mode='wait' initial={false} >
            {currentId === dayData?.marathon?.id && <motion.div
                key={dayData?.date}
                initial={{ x: isForward ? '100%' : '-100%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: isForward ? '-100%' : '100%', opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                {
                    !loadingValues && isRecovery && assessmentVideosDays ? (
                        <>
                            <RecoveryVideos userInfo={accountInfo} handleOpenVideo={handleOpenVideo} />
                        </>
                    )
                        : !loadingValues && isRecovery && recoveryAssessmentsDays && !hasAssessment ? (
                            <>
                                {renderAssessmentIntro()}
                            </>
                        )
                            : !loadingValues && !loadingSurfaceChange && !loadingInitialLanding ? (<>

                                {!isLastDay && <Grid container xs={12} justifyContent='center' alignItems='center'>
                                    {/* isGarminConnected(userGarminDetails) ? 'Garmin' : (appleHealthPermission?.status === "granted" || appleHealthActivity) ? 'Apple Watch' : 'Sync Device', lapsData?.length ? 'Laps' : '' */}
                                    {
                                        ['Plan',
                                            // 'Results'
                                            !isGarminConnected(userGarminDetails) && !(appleHealthPermission?.status === "granted" || appleHealthActivity) && !(corosUserData && corosUserData.status == 1) ? 'Sync Device' : 'Results',
                                        ].map((t, i) => {
                                            const isGarminDisabled = t === 'Garmin' && (!currentDayActivities?.length || !userGarminDetails?.status)
                                            return t && <Button sx={{ textTransform: 'none', px: '15px', pb: '10px', color: 'black !important' }}
                                                key={i}
                                                className={i === activeTab ? "selected" : ""}
                                                onClick={(e) => handleChangeActive(e, i)}
                                            >
                                                <H2 size={1} sx={{ position: 'relative', p: '0px 4px 2px 4px', textTransform: 'upperCase' }}>
                                                    {t}
                                                    {i === activeTab ? (
                                                        <motion.div className="underlineTab default-tabs" layoutId="underline" />
                                                    ) : null}
                                                </H2>
                                            </Button>
                                        }
                                        )}
                                </Grid>}


                                {/* @@@ Tab Contents */}
                                <Box sx={{ mt: '6px' }}>

                                    <AnimatePresence mode='wait' initial={false}>
                                        <motion.div
                                            key={activeTab}
                                            custom={initTab - prevActiveTab?.current}
                                            variants={variants}
                                            initial="enter"
                                            animate="center"
                                            exit='exit'
                                            transition={{ duration: 0.2 }}
                                        >
                                            {(isRaceDay || isLastDay) && renderRaceResult()}
                                            {isRaceDay && !activeTab ?
                                                <>
                                                    {renderActivity(false, false, true)}
                                                    {/*renderRaceDay()*/}
                                                </>
                                                : isLastDay ?
                                                    renderLastDay()
                                                    : <>
                                                        {/* @@@ Main Contents */}
                                                        {renderActiveTab((isRecovery && recoveryAssessmentsDays && hasAssessment))}

                                                        {/*Edit Activities Drawer*/}
                                                        {activeTab === 0 &&
                                                            <RSDrawer
                                                                bottom
                                                                fitContent
                                                                open={isEditing}
                                                                popUpWidth='fit-content'
                                                                onClose={handleCloseEdit}
                                                            >
                                                                {renderActivity(true, (isRecovery && recoveryAssessmentsDays && hasAssessment))}
                                                            </RSDrawer>
                                                        }

                                                        {/*Mark complete drawer depreciated???*/}
                                                        <RSDrawer
                                                            bottom
                                                            fitContent
                                                            open={isMarkComplete}
                                                            onClose={() => setIsMarkComplete(false)}
                                                        >
                                                            {activeTab && currentDayActivities?.length > 0 ? renderGarminActivity(true) : renderActivity(true, (isRecovery && recoveryAssessmentsDays && hasAssessment))}
                                                        </RSDrawer>
                                                        {/*Weather Alert Drawer*/}
                                                        <RSDrawer
                                                            bottom
                                                            fitContent
                                                            popUpWidth='fit-content'
                                                            open={showWeatherAlert}
                                                            onClose={() => setShowWeatherAlert(false)}
                                                        >
                                                            {renderWeatherAlert()}
                                                        </RSDrawer>

                                                        <RSDrawer
                                                            bottom
                                                            fitContent
                                                            open={isTakingNotes && !notesLocked}
                                                            onClose={handleCloseEditNotes}
                                                        >
                                                            {renderNotesArea(true)}
                                                        </RSDrawer>

                                                        <RSDrawer
                                                            bottom
                                                            fitContent
                                                            open={finishedGarminSync}
                                                            onClose={() => setFinishedGarminSync(false)}
                                                        >
                                                            {renderGarminSyncNotification(true, hasGarminSyncError)}
                                                        </RSDrawer>

                                                        <RSDrawer fitContent popUpWidth={"auto"} bottom={!isMobile} open={showMap} onClose={() => {
                                                            setShowMap(false)
                                                        }}>
                                                            {renderMap()}
                                                        </RSDrawer>

                                                        <RSDrawer fitContent popUpWidth={"auto"} bottom={!isMobile} open={showEffortFeedback} onClose={() => {
                                                            setShowEffortFeedback(false)
                                                        }}>
                                                            {renderEffortFeedback()}
                                                        </RSDrawer>

                                                        {renderHeartRateTracker()}
                                                    </>
                                            }
                                        </motion.div>
                                    </AnimatePresence>

                                </Box>
                            </>) : undefined}
            </motion.div>}
        </AnimatePresence>
        {(loadingSurfaceChange || loadingInitialLanding) && !loadingValues && <Loader />}

    </Box>

}

export default DailyActivity;
