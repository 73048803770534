/* eslint-disable @typescript-eslint/no-empty-function */
import { FC, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import AddIcon from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/AddCircleOutline';
import MoreIcon from '@mui/icons-material/MoreVert';

import { IRaceType } from './build/RaceType'
import { RaceOptions } from './build/RaceOptions'
import { UserInfoProps } from '../../modules/types'
import { titleCase } from '../../modules/miscUtils'
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
//import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import { GetRaceResult, UpdateRaceResult, GetUserVdotChanges, DeactivatePlan, GetUserInfo } from '../../modules/trainingPlanActions'
import CompletePlan from '../../components/your-training-plan-sidebar/CompletePlan'
import AddMaintenancePlan from '../../components/your-training-plan-sidebar/AddMaintenancePlan'
import RSNavCont from '../../components/layout/RSNavCont'
//import RSHeaderCont from '../../components/layout/RSHeaderCont'
import RSDrawer from '../../components/layout/RSDrawer'
import RSJourneyCard from '../../components/cards/RSJourneyCard'
import { RSButton1 } from '../../components/buttons'
import { SH3, H4, P1, A } from '../../components/text/RSTypography'
import { UserRaceResults } from '../../api/v2/types'
import raceSelection from './build/RaceSelection.json'
import useSWR from 'swr';
import useRNBridge from '../../hooks/useRNBridge';
import { navReq } from '../../modules/apiConsume';
import useGPTTrainingPlan from '../race/useGPTTrainingPlan';
import { IAiTrainingPlanStore } from '../../store/createAITrainingPlanStore';
import useStore from '../../store/useStore';

interface YourTrainingJourneyProps {
	onCloseModal?: () => void,
	mainLoading?: boolean,
	setMainLoading?: any
}

const YourTrainingJourney: FC<UserInfoProps & YourTrainingJourneyProps> = ({ userInfo: accountInfo, onCloseModal, mainLoading, setMainLoading }) => {
	const navigate = useNavigate();
	//const isMobile = useIsMobileScreen();
	const { computeKmPace, ConvertFullTo5k, ConvertFullToHalf, ConvertM2H2, ConvertFullto10k, ConvertS2TimeObject } = useTrainingPlanUtils()
	const [loading, setLoading] = useState<any>(false)
	const [raceResultSaved, setRaceResultSaved] = useState(false)
	const [overview, setOverview] = useState<any>(false)
	const [deleteConfirmation, setDeleteConfirmation] = useState<any>(false)
	const [journeyList, setJourneyList] = useState<Array<any>>([])
	const [updateRaceResult, setUpdateRaceResult] = useState(false)
	const [openMore, setOpenMore] = useState<any>(null)
	const [addMaintenance, setAddMaintenance] = useState(false)
	const [doNotAddMaintenance, setDoNotAddMaintenance] = useState(false)
	const navBar = useRef()

	const { sendDataToReactNative } = useRNBridge()
	const { convertToLegacyTrainingInfo } = useGPTTrainingPlan()
	const { aiTrainingPlans, isLoadingPlans, setAiTrainingPlans } = useStore((state: IAiTrainingPlanStore) => state)

	const { data: userJourney, mutate } = useSWR(accountInfo?.account_id ? `/training-plan/userinfo?uid=${accountInfo?.account_id}` : null)

	const { data: userTrainingPlans, isLoading } = useSWR(accountInfo?.account_id && (aiTrainingPlans?.length == 0 || !aiTrainingPlans) ? `/gpt-training-plan-info/all?userId=${accountInfo?.account_id}` : null)

	const handleSubmitCompletePlan = async (body: any) => {
		const abortController = new AbortController()
		if (overview?.result?.id && body) {
			setLoading(true)
			const newBody = { ...body, id: Number(overview.result.id) }
			await UpdateRaceResult(newBody as UserRaceResults, abortController.signal).then(async () => {
				// getJourney(abortController.signal, overview?.result?.training_plan_id)
				await mutate()
				await getJourney(userJourney, overview?.result?.training_plan_id)
			})
		}
		setUpdateRaceResult(false)
	}

	const handleDelete = async () => {
		const abortController = new AbortController()
		if (overview?.uid) {
			setLoading(true)
			await DeactivatePlan(overview?.uid, abortController.signal).then(async () => {
				// getJourney(abortController.signal)
				await mutate()
			})
			setOverview(false)
			setDeleteConfirmation(false)
			setOpenMore(null)
		}
	}


	const getRaceResult = async (result: any, abort: AbortSignal, id?: number) => {
		const finalData: Array<any> = []
		if (result?.length) {
			for (const d in result) {
				let jData = result[d]
				if (jData?.status) {
					await GetUserVdotChanges(jData.uid as number, abort).then((res: any) => {
						jData = { ...jData, vDotAdjustment: res?.data?.result }
					})
					await GetRaceResult(jData.internal_id as number, jData.uid as number, abort, jData.type as number).then((res: any) => {
						const raceResults = res?.data?.result
						const recoveryLength = raceResults?.recoveryPlan?.recoveryType?.recovery_length_in_weeks
						let raceDate = jData?.race_date
						if (recoveryLength && raceDate) {
							raceDate = moment(jData?.start).add(recoveryLength, 'weeks').subtract(1, 'd').toISOString()
						}
						jData = { ...jData, result: res?.data?.result, race_date: raceDate }
					})
				}

				if (id && id === jData?.result?.training_plan_id) {
					setOverview(jData)
				}
				finalData.push(jData)
			}
		}

		setLoading(false)
		setJourneyList(finalData)
	}


	const getJourney = async (userJourney: any, id?: number, userTrainingPlans?: any) => {
		// const accId = accountInfo?.account_id ? accountInfo.account_id : 0
		setLoading(true)
		// await GetJourney(accId, abort).then((res) => {
		const trainingPlans: any[] = []
		setAiTrainingPlans(userTrainingPlans)
		if (userTrainingPlans?.length > 0) {
			userTrainingPlans?.map((tp: any) => {
				trainingPlans.push(convertToLegacyTrainingInfo(tp))
			})
		}

		let result = userJourney?.data?.result

		if (result?.length > 0 && trainingPlans?.length > 0)
			result = [...result, ...trainingPlans]
		else if (!trainingPlans?.length && result?.length)
			result = [...result]
		else if (trainingPlans?.length && !result?.length)
			result = [...trainingPlans]
		else
			result = []

		result = result?.sort((a: any, b: any) => {
			if (moment(a.start) > moment(b.start))
				return -1
			else if (moment(a.start) < moment(b.start))
				return 1
			else {
				if (moment(a.race_date) > moment(b.race_date))
					return -1
				else if (moment(a.race_date) < moment(b.race_date))
					return 1
				else
					return 0
			}

		})
		const abortController = new AbortController()
		getRaceResult(result, abortController.signal, id)
		// })
	}

	const getRG = (result: any) => {
		const targetTime = result?.vDotAdjustment?.length > 0 ? (result?.vDotAdjustment[result?.vDotAdjustment?.length - 1]?.targetm) : result?.targetm;
		const type = (result?.plan_type?.toUpperCase()?.includes('GPT') && 4) || result?.type;

		switch (type) {
			case 0:
				return ConvertFullToHalf(targetTime)
			case 1:
				return ConvertM2H2(targetTime)
			case 2:
				return ConvertFullTo5k(targetTime)
			case 3:
				return ConvertFullto10k(targetTime)
			case 4:
				return ConvertM2H2(targetTime)
			default:
				return ''
		}

	}

	const handleRedirect = async () => {
		const abortController = new AbortController()
		await GetUserInfo(accountInfo?.account_id as number, abortController.signal).then(async (res: any) => {
			const latest = res?.data?.result[0]
			navigate(`/training-plan/view?planId=${latest?.uid}${onCloseModal ? '&profile=1' : ''}`, { replace: true })
		})

	}

	const handleGetJourney = async () => {
		await mutate()
		await getJourney(userJourney, undefined, aiTrainingPlans.length ? aiTrainingPlans : userTrainingPlans?.data?.result)
	}

	const timeToString = (props: any) => {
		const { hr, min, sec } = props
		return (hr || min || sec) ? `${hr ? `${hr.toString().padStart(2, '0')}:` : ''}${min || (!min && hr) ? `${min.toString().padStart(2, '0')}:` : ''}${(sec || !sec && (min || hr)) ? `${Math.floor(sec).toString().padStart(2, '0')}` : ''}` : ''

	}

	const wrapDrawer = (element: any, open: boolean, setOpen: Function) => {
		return <RSDrawer
			bottom
			popUpHeight='90%'
			open={open}
			onClose={() => setOpen(false)}
		>
			{element}
		</RSDrawer>
	}

	const renderRaceResult = () => {
		const selectedRace = RaceOptions.find((x) => x.value == (overview?.type as number)) as IRaceType
		const raceResults = overview?.result
		return overview?.result?.id && <CompletePlan handleSubmitCompletePlan={handleSubmitCompletePlan} updateRaceResult={updateRaceResult} setUpdateRaceResult={setUpdateRaceResult} userInfo={overview} raceResults={raceResults} selectedRace={selectedRace} />
	}

	const renderRecoveryPlanReady = () => {
		return <Box className='bg-main'>
			<Grid container sx={{ m: 'auto' }} >
				<Grid container className='faded faded-bottom' xs={12} sx={{ overflow: 'hidden' }} justifyContent="flex-start" alignItems="center">
					<span>
						<img src="img/recover.jpg" />
					</span>
				</Grid>
				<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
					Begin Your Recovery.
				</Grid>
				<Grid container xs={12} sx={{ px: 2, lineHeight: '1em', fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center' }} >
					Take the next step in your running journey with your Physical Therapist-designed recovery assessment and plan.
				</Grid>
				<Grid container xs={12} sx={{ mt: '40px' }} justifyContent='center' alignItems='center'>
					<RSButton1 onClick={async () => {
						setRaceResultSaved(false)
						handleRedirect()
					}}
						sx={{ width: '300px', maXWidth: '70%' }}
					>
						View Plan
					</RSButton1>
				</Grid>
				<Grid container xs={12} sx={{ mt: '10px' }} justifyContent='center' alignItems='center'>
					<RSButton1 secondary onClick={() => setRaceResultSaved(false)} sx={{ width: '300px' }}>
						Dismiss
					</RSButton1>
				</Grid>
			</Grid>
		</Box>
	}

	const renderDeleteModal = () => {
		return <Modal
			open={deleteConfirmation ? true : false}
			onClose={() => setDeleteConfirmation(false)}
		>
			<Box className='bg-main' sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: 'absolute', borderRadius: '5px', pt: '10px', top: '50%', left: '50%', width: '80%', backgroundColor: 'white', transform: 'translate(-50%, -50%)', maxWidth: '400px' }}>
				<Grid container xs={12} sx={{ px: '20px', fontFamily: 'Poppins', fontSize: '16px', borderBottom: '1px solid rgba(0, 0, 0, 0.176)' }} justifyContent='flex-start'>
					Delete Plan
				</Grid>
				<Grid container xs={12} sx={{ px: '20px', pt: '10px', fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start'>
					{`You're about to delete this plan.`}
				</Grid>
				<Grid container xs={12} sx={{ px: '20px', fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start'>
					Do you want to proceed?
				</Grid>
				<Grid container xs={12} sx={{ px: '10px', fontFamily: 'Poppins', fontSize: '16px', pt: '25px', pb: '15px' }} justifyContent='flex-end' alignItems='center'>
					<Grid container xs={4} justifyContent='center' alignItems='center' >
						<RSButton1 onClick={() => setDeleteConfirmation(false)} secondary sx={{ mr: '2px' }}>Cancel</RSButton1>
					</Grid>
					<Grid container xs={4} justifyContent='center' alignItems='center'>
						<RSButton1 onClick={handleDelete} sx={{ width: '100%', ml: '2px' }}>Proceed</RSButton1>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	}

	const renderOverView = () => {
		const isDNF = overview?.result?.is_dnf ? true : false
		const isRecover = overview?.result?.user_id && overview?.type === 4 ? true : false
		const selectedRace = RaceOptions.find((x) => x.value == (overview.type as number)) as IRaceType
		let title = (selectedRace?.value == 0 || selectedRace?.value == 1) ? `${selectedRace?.title} ${selectedRace?.subTitle}` : selectedRace?.title
		title = overview?.result?.race_name && !isRecover ? overview.result.race_name : (isRecover ? 'Recovery Plan' : (title?.includes('K') ? title : titleCase(title)))
		let distanceValue: any = raceSelection.find((rs: any) => rs.raceValue === selectedRace?.value)
		distanceValue = overview?.km === 'N' ? distanceValue?.distanceValue : distanceValue?.distanceValueKM
		const unit = overview?.km === 'N' ? 'MI' : 'KM'
		const hasResult = overview?.result?.id && overview?.type !== 4 ? true : false
		const finishValue = hasResult && overview?.result?.finished_time_in_sec ? overview.result.finished_time_in_sec : 0
		const finishTime = timeToString(ConvertS2TimeObject(finishValue))
		const paceValue = hasResult && overview?.result?.finished_time_in_sec && distanceValue ? overview.result.finished_time_in_sec / distanceValue : 0
		const paceTime = timeToString(ConvertS2TimeObject(paceValue))
		return (<>
			{renderDeleteModal()}
			<Box ref={navBar} sx={{ p: 2, pr: '10px', height: '60px', pb: '0px', width: '100%', position: 'fixed', zIndex: 10, backgroundColor: 'white', boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.15)' }}>
				<Grid container sx={{ position: 'relative' }}>
					<Grid container xs={12} justifyContent="space-between" alignItems="center">
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<ArrowBack onClick={() => setOverview(false)} />
							<Box sx={{ ml: '10px', fontFamily: 'Poppins', fontSize: '18px', letterSpacing: 'normal' }}>
								{title}
							</Box>
						</Box>
						<Box>
							<IconButton onClick={() => setOpenMore(navBar?.current)} sx={{ p: 0, color: '#010101' }}>
								<MoreIcon sx={{ fontSize: '25px' }} />
							</IconButton>
						</Box>
					</Grid>
					<Popover
						id={openMore ? 'pop' : undefined}
						open={openMore}
						anchorEl={openMore}
						onClose={() => setOpenMore(null)}
						sx={{
							'& .MuiPopover-paper': {
								boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.15)',
								ml: 2,
								borderRadius: '10px 0px 10px 10px',
								backgroundColor: 'white',
								width: '100px'
							}
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					>
						{hasResult && !isDNF && <Grid container justifyContent='flex-start' alignItems='center'>
							<Button onClick={() => {
								setOpenMore(null)
								setUpdateRaceResult(true)
							}}
								variant='outlined' sx={{ borderRadius: '0', width: '100%', justifyContent: 'flex-start', backgroundColor: 'transparent !important', fontFamily: 'Poppins', fontSize: '14px', color: '#010101', textTransform: 'none', border: 'none !important' }}
							>
								Edit
							</Button>
						</Grid>}
						<Grid container justifyContent='flex-start' alignItems='center'>
							<Button
								onClick={() => {
									setOpenMore(false)
									setDeleteConfirmation(true)
								}
								}
								variant='outlined'
								sx={{ borderRadius: '0', width: '100%', justifyContent: 'flex-start', backgroundColor: 'transparent !important', fontFamily: 'Poppins', fontSize: '14px', color: '#010101', textTransform: 'none', border: 'none !important' }}
							>
								Delete
							</Button>
						</Grid>
					</Popover>
				</Grid>
			</Box>
			<Grid container sx={{ maxWidth: '700px !important', mx: 'auto', pt: '86px', px: 4 }}>
				<Grid container xs={12} sx={{ fontSize: '30px', fontFamily: 'Poppins-SemiBold' }}>
					{title}
				</Grid>
				{overview?.type !== 4 && <Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins', lineHeight: '0.5em' }}>
					{overview.race_date ? moment(overview.race_date).format('MMMM DD, YYYY') : ''}
				</Grid>}
				{hasResult && !isDNF && <Grid container xs={12} sx={{ pt: '40px' }}>
					<Grid container xs={6} sx={{ flexDirection: 'column' }} justifyContent='center' alignItems='center'>
						<Box sx={{ fontSize: '30px', fontFamily: 'Poppins-SemiBold', lineHeight: '1em' }}>{finishTime}</Box>
						<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Light' }}>FINISH TIME</Box>
					</Grid>
					<Grid container xs={6} sx={{ flexDirection: 'column' }} justifyContent='center' alignItems='center'>
						<Box sx={{ fontSize: '30px', fontFamily: 'Poppins-SemiBold', lineHeight: '1em' }}>{paceTime}</Box>
						<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Light' }}>PACE / {unit}</Box>
					</Grid>
				</Grid>}
				<Grid container xs={12} sx={{ mt: '30px' }} justifyContent='center' alignItems='center'>
					<RSButton1 onClick={() => navigate(`/training-plan/view${overview.uid ? '?planId=' + overview.uid : ''}${onCloseModal ? '&profile=1' : ''}`, { replace: true })} sx={{ width: '80%', p: '8px 0px' }}>
						VIEW PLAN
						<ArrowForward sx={{ position: 'absolute', right: 0, fontSize: '30px', stroke: '#9FCD2B', strokeWidth: '1px', mr: '5px' }} />
					</RSButton1>
				</Grid>
			</Grid>
		</>
		)
	}

	const renderHeader = (isNotEmpty: boolean) => {
		return (
			<Grid container>
				<Grid container xs={12} justifyContent="center" alignItems="center">
					<Box onClick={() => onCloseModal && onCloseModal()}>
						<SH3 size={3}>{isNotEmpty ? 'Training Timeline' : `Let's begin your journey`}</SH3>
					</Box>
				</Grid>
				<Grid container xs={12} sx={{ pt: 2 }} justifyContent="center" alignItems="center">
					<P1 sx={{ mx: '2.5px' }}>To create a new running plan,</P1>
					<A p1 sx={{ mx: '2.5px' }} onClick={() => navReq('/#/explore')}>explore RunSmart running plans</A>
				</Grid>
			</Grid>
		)
	}

	const renderJourney = () => {
		let hasActive = -1
		const availablePlans = journeyList?.filter((s: any) => s.status)
		const isNotEmpty = availablePlans?.length > 0 ? true : false
		return <>
			{!loading && renderHeader(isNotEmpty)}
			<Box sx={{ pt: 2, m: 'auto' }}>
				{isNotEmpty && availablePlans.map((jD: any, jIndex: number) => {
					const jData = {
						...jD,
						start: jD.start?.slice(0, jD.start?.indexOf('T'))?.replaceAll('-', '/'),
						race_date: jD.race_date?.slice(0, jD?.race_date?.indexOf('T'))?.replaceAll('-', '/'),
						signup_date: jD.signup_date?.slice(0, jD?.signup_date?.indexOf('T'))?.replaceAll('-', '/')
					}

					const recoverDeleted = !jIndex && journeyList.find((j: any) => j.uid === jData?.result?.maintenance_plan_id && j?.type === 4 && !j.status) ? true : false
					const activePlans = availablePlans.filter(x => x.is_active_plan == true)
					const lastActive = activePlans[activePlans.length - 1]
					const isDNF = jData?.result?.is_dnf ? true : false
					const isRecover = jData?.result?.user_id && jData?.type === 4 ? true : false
					const isRecoverHasMaintenance = jData?.result?.recoveryPlan?.skip_maintenance_plan ? true : false
					const selectedRace = RaceOptions.find((x) => x.value == (jData.type as number)) as IRaceType
					let title = (selectedRace?.value == 0 || selectedRace?.value == 1) ? `${selectedRace?.title} ${selectedRace?.subTitle}` : selectedRace?.title
					title = jData?.event_name ? jData?.event_name : jData?.result?.race_name && !isRecover ? jData.result.race_name : (isRecover ? 'Recover' : (title?.includes('K') ? title : titleCase(title)))
					const raceName = jData?.event_name ? (selectedRace?.value == 0 || selectedRace?.value == 1) ? titleCase(`${selectedRace?.title} ${selectedRace?.subTitle}`) : selectedRace?.title : ''
					const raceDate = jData?.race_date ? `${moment(jData.race_date).format('MMMM DD, YYYY')}` : ''
					const resultTime = jData?.result?.finished_time_in_sec && !isRecover ? jData.result.finished_time_in_sec : 0
					const finalTime = timeToString(ConvertS2TimeObject(resultTime))
					const hasMaintenance = !jIndex && jData?.type !== 4 && !recoverDeleted
					const planLength = isRecover ? (jData?.result?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0) : jData?.weeks
					const isCurrentActive = jData.uid == lastActive?.uid ? true : false
					hasActive = isCurrentActive ? jIndex : hasActive
					const isFuture = isCurrentActive ? false : (moment(jData?.race_date).isAfter(moment()) || (hasActive > 0 && hasActive < jIndex))
					const isPast = isCurrentActive ? false : moment().isAfter(moment(jData?.race_date))
					const remainingDays = jData?.race_date ? moment(moment(jData.race_date).format('YYYY/MM/DD')).diff(moment(moment().format('YYYY/MM/DD')), 'd') : 0
					const dD = jData.distance ? (jData?.km === 'Y' ? computeKmPace(jData.distance)?.toFixed(1) : jData.distance) : 0
					const displayDistance = `${dD} ${jData?.km === 'Y' ? 'km' : 'mile'}${dD > 1 ? 's' : ''}`

					const timeStr = getRG(jData)?.split(' ')
					const dHR = timeStr?.find((t: string) => t.includes('h'))
					const dMIN = timeStr?.find((t: string) => t.includes('m'))
					const dSEC = timeStr?.find((t: string) => t.includes('s'))
					const rgFinal = timeToString({ hr: (dHR ? Number(dHR.replace('h', '')) : 0), min: (dMIN ? Number(dMIN.replace('m', '')) : 0), sec: (dSEC ? Number(dSEC.replace('s', '')) : 0) })
					const newUserInfo = { ...jData, raceResults: jData?.result }
					if (isRecover && !jIndex) {
						const recoverType = newUserInfo?.raceResults?.recoveryPlan?.recoveryType;
						const weekTruncateCount = newUserInfo?.weeks - (recoverType?.recovery_length_in_weeks);
						newUserInfo.race_date = moment(newUserInfo?.race_date).subtract(weekTruncateCount, 'weeks').toISOString()
					}
					const isWeekBefore = raceDate && !jIndex ? moment().isAfter(moment(jData.race_date).subtract(8, 'days')) : false
					return <Grid key={jIndex} sx={{ width: '100%', margin: 'auto' }} container justifyContent='flex-end'>
						{(hasMaintenance || (isRecover && !isRecoverHasMaintenance)) && !jIndex && isWeekBefore && <>
							{isRecover && <AddMaintenancePlan addMaintenance={addMaintenance} setAddMaintenance={setAddMaintenance} doNotAddMaintenance={doNotAddMaintenance} setDoNotAddMaintenance={setDoNotAddMaintenance} userInfo={newUserInfo} handleGetJourney={handleGetJourney} />}
							<Grid xs={12} sx={{ mb: '20px', position: 'relative' }} container justifyContent='center' alignItems='center'>
								<Button disabled={!isRecover} onClick={() => setAddMaintenance(true)} sx={{ width: '100%', borderRadius: '5px', py: '10px', flexDirection: 'column', border: '2px dashed rgba(0, 0, 0, 0.176)', textTransform: 'none' }}>
									<Box sx={{ color: '#010101', fontFamily: 'Poppins-Medium', fontSize: '16px' }}>Up Next:</Box>
									<Box sx={{ color: '#010101', fontFamily: 'Poppins', fontSize: '16px' }}>{isRecover ? 'Maintenance Plan' : 'Recovery Plan'}</Box>
								</Button>
								{isRecover && <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
									<RemoveCircle onClick={() => setDoNotAddMaintenance(true)} sx={{ fontSize: '25px', transform: 'rotate(45deg)' }} />
								</Box>}
							</Grid>
						</>}
						{/* <Grid container xs={1.5} sx={{ pb: '20px', position: 'relative' }} justifyContent='center' alignItems='center'>
							<Grid container sx={{ zIndex: 1, backgroundColor: `${isCurrentActive ? '#bfd38c' : 'white'} !important`, color: '#010101', fontFamily: 'Poppins-Medium', fontSize: '14px', width: '34px', height: '34px', borderRadius: '40px', border: `1px solid ${isPast ? 'white' : '#9FCD2B'}` }} justifyContent='center' alignItems='center'>
								{circleTitle}
							</Grid>
							<Box sx={{ height: '100%', width: '1px', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.176)', top: 0, left: '50%', transform: 'translate(-50%, 0%)', background: !jIndex ? jIndex === availablePlans?.length - 1 ? 'none' : 'linear-gradient(transparent 50%, rgba(0, 0, 0, 0.176) 50%)' : jIndex === availablePlans?.length - 1 ? 'linear-gradient(rgba(0, 0, 0, 0.176) 50%, transparent 50%)' : 'default' }} />
						</Grid> */}
						<Grid xs={12} sx={{ borderRadius: '5px', mb: '20px' }} container justifyContent='center' alignItems='center'>
							<RSJourneyCard onClick={() => navigate(`/training-plan${jData.plan_type === "GPT" ? '-v3' : ''}/view?fr=tj${jData.uid ? '&planId=' + jData.uid : ''}${onCloseModal ? '&profile=1' : ''}`)} isPast={isPast} isCurrentActive={isCurrentActive} >
								<Grid container xs={12} sx={{ flexDirection: 'column' }} justifyContent='center'>
									<H4 size={1} sx={{ width: '100%', textAlign: 'left' }}>{title}</H4>
									{jData?.type !== 4 && <Box sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '1.1em', fontFamily: 'Poppins', fontSize: '14px', color: '#010101' }}>{raceName}</Box>}
									{resultTime ? <Box sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '1.1em', fontFamily: 'Poppins', fontSize: '14px', color: '#010101' }}>
										Result: {isDNF ? 'DNF' : finalTime}
									</Box> : ''}
									{isCurrentActive && jData?.type !== 4 && remainingDays > -1 && <Box sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '1.3em', fontFamily: 'Poppins', fontSize: '14px', color: '#010101' }}>{remainingDays > 0 ? `${remainingDays} day${remainingDays > 1 ? 's' : ''} remaining` : 'Today'}!</Box>}
									{raceDate && jData?.type !== 4 && <Box sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '1.5em', fontFamily: 'Poppins', fontSize: '11px', color: '#010101' }}>{raceDate}</Box>}

									{jData?.type !== 4 && !isDNF && <Box sx={{ width: '100%', borderBottom: `1px dashed ${isCurrentActive ? 'white' : '#8080803d'}`, my: '8px' }} />}

									{(!isDNF || jData?.type === 4) && <>
										<Box sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '1.1em', fontFamily: 'Poppins-Bold', fontSize: '14px', color: '#010101' }}>Plan</Box>
										<Box sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '1.5em', fontFamily: 'Poppins', fontSize: '11px', color: '#010101' }}>
											{isFuture ? `Starts on ${moment(jData?.start).add(1, 'd').format('MMMM DD, YYYY')}`
												: `Started ${moment(jData?.start).add(1, 'd').format('MMMM DD')} for ${planLength} week${planLength > 1 ? 's' : ''}`}
										</Box>

										<Grid container xs={12}>
											{!isRecover && <Grid container xs={3.5} sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '2em', fontFamily: 'Poppins', fontSize: '14px', color: '#010101' }}>
												{displayDistance}
											</Grid>}
											{!isRecover && <Grid container xs={3} sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '2em', fontFamily: 'Poppins', fontSize: '14px', color: '#010101' }}>
												Level {jData.training_level}
											</Grid>}
											{rgFinal && jData?.type !== 4 && <Grid container xs={5.5} sx={{ display: 'flex', justifyContent: 'flex-start', lineHeight: '2em', fontFamily: 'Poppins', fontSize: '14px', color: '#010101' }}>
												Goal: {rgFinal}
											</Grid>}
										</Grid>

										<Box sx={{ position: 'absolute', top: 0, right: '0', display: 'flex' }} >
											{jData?.result?.is_best_pr && jData?.type !== 4 && !isFuture ? <Box sx={{ pr: '10px' }}><Box sx={{ height: '26px', width: '24px', backgroundColor: '#742F4E', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: 'Poppins-Medium', fontSize: '14px', color: 'white', textAlign: 'center' }}>
												PR
											</Box>
												<Box sx={{ borderTop: '12px solid #742F4E', borderRight: '12px solid transparent', borderLeft: '12px solid transparent' }} />
											</Box> : ''}
											{jData?.result?.is_bq && jData?.type !== 4 && !isFuture ? <Box sx={{ pr: '10px' }}><Box sx={{ height: '24px', width: '24px', backgroundColor: '#DE4A47', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', fontFamily: 'Poppins-Medium', fontSize: '14px', color: 'white', textAlign: 'center' }}>
												<Box sx={{ width: '75%' }}><img src='img/bq.png' /></Box>
											</Box>
												<Box sx={{ borderTop: '12px solid #DE4A47', borderRight: '12px solid transparent', borderLeft: '12px solid transparent' }} />
											</Box> : ''}
										</Box>
									</>}
								</Grid>
							</RSJourneyCard>
						</Grid>

					</Grid>
				})}
			</Box>
		</>
	}

	useEffect(() => {
		if (!overview) {
			setRaceResultSaved(false)
		}
	}, [overview])

	useEffect(() => {
		const isFromSR = localStorage.getItem('rpReady')
		if (isFromSR) {
			setRaceResultSaved(true)
			localStorage.setItem('rpReady', '')
		}
	}, [])

	useEffect(() => {
		setLoading(true)
		const abortController = new AbortController()
		if (accountInfo) {
			accountInfo.account_id && getJourney(userJourney, undefined, aiTrainingPlans?.length ? aiTrainingPlans : userTrainingPlans?.data?.result)
		}

		return () => {
			abortController.abort()
		}
	}, [accountInfo, userJourney, userTrainingPlans, aiTrainingPlans])

	useEffect(() => {
		const dataToSend = {
			requestType: 'cast-show-bottom-menu',
			payload: overview ? false : true,
			timestamp: new Date().getTime(),
		}

		sendDataToReactNative(dataToSend);
	}, [overview])

	useEffect(() => {
		if (setMainLoading) {
			(loading || isLoading || isLoadingPlans) !== mainLoading && setMainLoading?.(loading || isLoading || isLoadingPlans)
		}
	}, [loading, isLoading, isLoadingPlans])

	const renderAll = () => {
		return <Box className='training-plan-cont' sx={{ backgroundColor: 'none', margin: 'auto', overflow: 'hidden', py: 3 }}>
			{wrapDrawer(renderRecoveryPlanReady(), raceResultSaved, setRaceResultSaved)}
			{wrapDrawer(renderOverView(), overview, setOverview)}
			{renderJourney()}
			{renderRaceResult()}
		</Box>
	}
	return setMainLoading ? renderAll() : <RSNavCont hideHeader={true} hideFooter={true} loaderProps={{ active: loading || isLoading }}>
		{renderAll()}
	</RSNavCont>
}

export default YourTrainingJourney
