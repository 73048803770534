/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState, useEffect, useRef, Suspense, lazy } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import useSWR from 'swr'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu';

// import AdditionalPlans from './home/AdditionalPlans'
// import RunningPlan from './home/RunningPlan'
// import Favorites from './home/FavoritesHome'
// import IntegrationsMainPage from './integrations/IntegrationsMainPage'
import { AuthPageProps, RSTabsDataProps } from '../modules/types'
import WithAuth from '../components/WithAuth'
import RSNavCont from '../components/layout/RSNavCont'
import RSHeaderCont from '../components/layout/RSHeaderCont'
import RSDrawer from '../components/layout/RSDrawer'
import RSCardCont from '../components/cards/RSCardCont'
import RSIcon from '../components/icons/RSIcon'
import { SH4, P1, P2, P3 } from '../components/text/RSTypography'
import useIsMobileScreen from '../hooks/useIsMobileScreen'
import useRNBridge from '../hooks/useRNBridge'
import useRSTabs from '../hooks/useRSTabs'
import useStore from '../store/useStore'
import { ICurrentUser } from '../store/createAccountStore'
import { getReq } from '../modules/apiConsume'
import { RECURLY_PUBLIC_KEY, SHARE_REWARD_AMOUNT } from '../modules/cliEnvValues';
import { UpdateNotificationStatus } from '../modules/weeklyOutlookActions'
// import UpgradePlan from './account/UpgradePlan'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
// import PastDue from '../components/page-specific/account/PastDue'
// import { signOut } from '../modules/accountActions'
import useSale from '../hooks/useSale'
import Loader from '../components/animation/Loader'
import UpgradePlan from './account/UpgradePlan'
import IntegrationsMainPage from './integrations/IntegrationsMainPage'

const Home: FC<AuthPageProps> = ({ userInfo, hasLoaded }) => {
	const navigate = useNavigate()
	const isMobile = useIsMobileScreen()

	const [runningLoading, setRunningLoading] = useState(false)
	const [isEmpty, setIsEmpty] = useState(false)

	const [showWeeklyOutlookDrawer, setShowWeeklyOutlookDrawer] = useState<boolean>(false)
	const [announcementData, setAnnouncementData] = useState<Array<any>>([])
	const [openAnnouncement, setOpenAnnouncement] = useState<boolean>(false)
	const [displayPopupConnectWatch, setDisplayPopupConnectWatch] = useState<boolean>(false)
	const [garminLoading, setGarminLoading] = useState<boolean>(true)
	const [userGarminDetails, setUserGarminDetails] = useState<any>()
	const appleHealthPermission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')
	const watchConnected = (userGarminDetails?.['status'] == 1 || appleHealthPermission?.status === 'granted') ? true : false
	const [openUpgradePro, setOpenUpgradePro] = useState<boolean>(false)
	const [openPaymentDetails, setOpenPaymentDetails] = useState<boolean>(false)


	const { data: outlookNotification, mutate } = useSWR(userInfo?.account_id ? `/notification/user/get-last?user_id=${userInfo?.account_id}` : null, { refreshInterval: 0 })

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const initialRef = useRef<any>(true)

	const { sendDataToReactNative } = useRNBridge()

	//lazy loading
	const RunningPlan = lazy(() => import("./home/RunningPlan"));
	const AdditionalPlans = lazy(() => import("./home/AdditionalPlans"));
	const Favorites = lazy(() => import("./home/FavoritesHome"));
	// const IntegrationsMainPage = lazy(() => import("./integrations/IntegrationsMainPage"));
	// const UpgradePlan = lazy(() => import("./account/UpgradePlan"));
	const PastDue = lazy(() => import("../components/page-specific/account/PastDue"));

	const { notificationStatus, subscription, isPastDue } = useStore((state: ICurrentUser) => state)
    const isUserBasic = subscription?.accessDetails?.type === "basic" ? true : false
	const isCouponUser = subscription?.active?.subscription_type && subscription?.active?.subscription_type === "coupon" ? true : false
	const isInProTrial = subscription?.accessDetails?.pro_trial
	const remainingDays = subscription?.accessDetails?.remaining_days
	const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false
	// const { mobileType } = useDeviceDetect()

	const { activeSale, isLoadingSale, saleEnd } = useSale()

	const unreadAnnouncement = announcementData?.filter((d: any) => d.isUnread)?.length + ((isUserBasic || isCouponUser) ? 1 : 0) || 0

	const tabList: RSTabsDataProps = [{
		title: 'RUNNING',
		body: <Suspense fallback={<Loader active />}>
			<RunningPlan userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} setIsEmpty={setIsEmpty} showWeeklyOutlookDrawer={showWeeklyOutlookDrawer} setShowWeeklyOutlookDrawer={setShowWeeklyOutlookDrawer} />
		</Suspense >,
	}, {
		title: 'PROGRAMS',
		body: <Suspense fallback={<Loader active />}>
			<AdditionalPlans isBasicUser={isUserBasic} userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} />
		</Suspense>
	}, {
		title: 'FAVORITES',
		body: <Suspense fallback={<Loader active />}>
			<Favorites userInfo={userInfo} hasLoaded={hasLoaded} mainLoading={runningLoading} setMainLoading={setRunningLoading} />
		</Suspense>,
	}]

	const { tabsUI, tab } = useRSTabs({
		data: tabList,
		btnContSX: { width: '100%', justifyContent: 'space-evenly' },
		btnSX: { px: window.innerWidth < 350 ? 1 : 2 }
	})

	const handleGetStoredAnnouncement = (initialData?: Array<any>) => {
		const newAnnouncementData = (initialData || announcementData)?.map((aData: any) => {
			const sName = localStorage.getItem(aData?.storageName || '')
			const isUnread = aData?.storageName ? (sName ? moment(sName).diff(moment(), 'days') > 12 : true) : (aData?.isUnread || false)
			if (isUnread && aData?.storageName)
				localStorage.removeItem(aData?.storageName || '')
			return { ...aData, isUnread }
		}).filter((d: any) => d.title)
		const unreadArr = newAnnouncementData?.filter((d: any) => d.isUnread) || []
		const readArr = newAnnouncementData?.filter((d: any) => !d.isUnread) || []
		setAnnouncementData([...unreadArr, ...readArr])
	}


	const handleCloseAllAnnouncement = () => {
		announcementData?.forEach((aData: any) => {
			aData?.storageName && localStorage.setItem(aData.storageName, moment().format('YYYY/MM/DD'))
			aData?.isUnread && aData?.onRead?.()
		})
		handleGetStoredAnnouncement()
	}

	const handleCloseAnnouncement = (name: string) => {
		localStorage.setItem(name, moment().format('YYYY/MM/DD'))
		handleGetStoredAnnouncement()
	}


	const getGarminDetails = async () => {
		const response = await getReq('/v2/garmin/user')
		const data = response?.data?.result?.[0]
		setUserGarminDetails(data)
		setGarminLoading(false)
	}

	const updateWeeklyOutlookNotification = async (notificationId: string) => {
		await UpdateNotificationStatus(notificationId, 'opened')
		mutate()
	}

	const checkOutlookValidity = (outlookDate: Date) => {
		const today = new Date()
		const diff = moment(today).diff(moment(outlookDate), 'days')
		return diff < 8
	}

	useEffect(() => {
		if (!anchorEl && !initialRef.current)
			handleCloseAllAnnouncement()
	}, [anchorEl])

	useEffect(() => {
		if (!openAnnouncement && !initialRef.current)
			handleCloseAllAnnouncement()
		initialRef.current = false
	}, [openAnnouncement])

	useEffect(() => {
		return () => {
			(openAnnouncement || anchorEl) && handleCloseAllAnnouncement()
		}
	}, [anchorEl, openAnnouncement])

	useEffect(() => {
		setGarminLoading(userGarminDetails ? false : true)
		if (userInfo?.account_id && !userGarminDetails)
			getGarminDetails()

	}, [userInfo])

	useEffect(() => {

		const outlookNotificationResult = outlookNotification?.data?.result?.[0]
		const outlookContents = outlookNotificationResult?.contents ? JSON.parse(outlookNotificationResult?.contents) : {}
		// const outlookContents = {}
		const notifStausReady = localStorage.getItem('notification-status')
		// console.log('userid', userInfo?.account_id)
		// console.log('outlookNotificationResult', outlookNotification)


		const existingOutlook = outlookNotificationResult?.contents && JSON.parse(outlookNotificationResult?.contents)?.contents?.en != ''

		// existingOutlook && console.log('outlookNotificationResult', JSON.parse(outlookNotificationResult?.contents)?.contents?.en)
		// console.log('existingOutlook', existingOutlook)

		const announcementData = [!watchConnected && !garminLoading ? {
			title: 'Sync your training',
			description: 'Bring your training plan to life with real-time insights and advance tracking',
			icon: 'fa-sharp fa-light fa-watch',
			storageName: 'syncTraining',
			selectButton: 'Sync watch',
			onClick: () => setDisplayPopupConnectWatch(true)
		} : {},
		outlookContents && (outlookContents?.contents?.en || outlookContents?.subtitle?.en) && checkOutlookValidity(outlookNotificationResult?.date_created) && !garminLoading ? {
			title: 'Weekly Outlook',
			description: outlookContents?.contents?.en || outlookContents?.subtitle?.en || '',
			rsIcon: <RSIcon type='weekly-outlook' />,
			isUnread: (outlookNotificationResult?.status === 'opened' || outlookContents?.type === "weekly_outlook") ? false : true,
			onClick: () => setShowWeeklyOutlookDrawer(true),
			date: outlookNotificationResult?.date_created,
			onRead: () => {
				outlookNotificationResult?.notification_id && updateWeeklyOutlookNotification(outlookNotificationResult.notification_id)
			}
		} : {},
		!notificationStatus && isNativeApp && notifStausReady === "1" ? {
			title: 'Turn on notifications',
			description: 'Training made simple: real-time updates to keep you moving forward',
			icon: 'fa-sharp fa-light fa-bell',
			storageName: 'announcementNotif',
			selectButton: 'Turn on',
			onClick: () => {
				const dataToSend = {
					requestType: 'notification-permission',
					payload: {
						requestPermission: true
					},
					timestamp: new Date().getTime(),
				}
				sendDataToReactNative(dataToSend)
				handleCloseAnnouncement('announcementNotif')
				//setDisplayPopupNotification(false)
			}
		} : {}, {
			title: 'Share & Earn Store Cred.',
			description: `Share your link and earn $${SHARE_REWARD_AMOUNT} on the RunSmart store for every friend who joins`,
			icon: 'fa-sharp fa-light fa-paper-plane',
			storageName: 'shareRS',
			selectButton: 'Take me there',
			onClick: () => navigate('/profile', { replace: true })
		}
		].filter((d: any) => d.title)

		handleGetStoredAnnouncement(announcementData)
	}, [notificationStatus, watchConnected, garminLoading, outlookNotification])

	const renderUpgradeToPro = () => {
		return <Box className='gray-dark-bg white-text' sx={{ display: 'flex', flexDirection: 'column', mt: isMobile ? 3 : 1, mx: 2, mb: 0, borderRadius: '4px', p: 2, maxWidth: "600px" }}>
			<Box sx={{ display: 'flex', justifyContent: "start", alignItems: 'start', }}>
				<img src='/images/icons/pro-icon.png' style={{ height: '32px', width: '32px', marginRight: 10 }} />
				<Box>
					<P3 size={2}>{activeSale && isUserBasic ? 'Pro Sale is Live!' : 'Upgrade to Pro'}</P3>
					{
						isCouponUser ? <P2 sx={{ mt: 0.7 }} card size={-0.8}>Your <b>{subscription?.active?.plan_code}</b> coupon ends in <b>{moment(subscription?.active?.end_date).diff(moment(), "days")} days.</b> Upgrade to keep full access to pro features.</P2> :
							isInProTrial ? <P2 sx={{ mt: 0.7 }} card size={-0.8}>{activeSale && isUserBasic ? `Update to RunSmart Pro and save 45%. Sale ends ${saleEnd} ` : 'Take your training to the next level with full access, advanced insights, and personalized features.'}</P2>
								: <P2 card sx={{ mt: 0.7 }} size={-0.8}>{activeSale && isUserBasic ? `Update to RunSmart Pro and save 45%. Sale ends ${saleEnd} ` : 'Enjoy unlimited access to all pro features. Upgrade now to get started.'}</P2>
					}
				</Box>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: "end", alignItems: 'center', mt: 2 }}>
				<P2 onClick={() => setOpenUpgradePro(true)} size={0} sx={{ mx: 3, cursor: "pointer" }}>Learn More</P2>
				<P2 onClick={() => { 
					setOpenPaymentDetails(true) 
				}} size={0} sx={{ cursor: "pointer" }}>{`Upgrade`}
					<i className="fa-light fa-chevron-right white-text" style={{ fontSize: '12px', marginLeft: 3 }} />
				</P2>
			</Box>
		</Box >
	}

	const renderAnnouncement = () => {
		return <>
			<RSHeaderCont isStatic={!isMobile} backClick={isMobile ? () => setOpenAnnouncement(false) : undefined} barSX={{ borderRadius: isMobile ? 0 : '5px 5px 0px 0px' }} title='Notifications' />
			{(isUserBasic || isCouponUser) && renderUpgradeToPro()}
			<Grid container xs={12} style={{ marginTop: isUserBasic ? -17 : 0 }}>
				{announcementData?.length > 0 && hasLoaded ? <Box sx={{ p: 2 }}>
					{announcementData?.map((aData: any, aIndex: number) => {
						const isUnread = aData?.isUnread
						return <Box key={aIndex + '-plan-announcement'} sx={{ pb: isMobile ? 0 : 1, pt: isMobile ? 2 : 1 }}>
							<RSCardCont onClick={aData?.onClick} isLoading={!hasLoaded} sx={{ border: 'none !important', height: 'auto', p: 0, alignItems: 'center', flexDirection: 'row' }}>
								<Box sx={{ display: 'flex', width: '100%' }}>
									<Box className={isUnread ? 'pink-bg' : 'white-bg'} sx={{ width: '8px' }} />
									<Box sx={{ width: '35px', ml: 1, textAlign: 'left', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
										{
											aData?.rsIcon || (aData?.logo ? <img src={aData?.logo} style={{ scale: '1.1' }} />
												: (aData?.icon ?
													<i className={aData?.icon} style={{ fontSize: '25px' }} />
													: <></>
												))
										}
									</Box>
									<Box sx={{ width: `calc(100% - 51px)`, pr: 2, pt: '13px', pb: '12px', display: 'flex', flexDirection: 'column' }}>
										<SH4 title>{aData?.title}</SH4>
										<P2 className='ellipsis-3l' subtitle sx={{ pb: '1px' }}>{aData?.description}</P2>
										{aData.date && <P2 className='gray-disabled-text' size={-1} sx={{ pb: '1px', width: '100%', textAlign: 'right', mt: '4px', mb: '-4px' }}>{moment(aData.date).format('MMMM D, YYYY')}</P2>}
									</Box>
								</Box>
							</RSCardCont>
						</Box>
					})}
				</Box> : <></>}
			</Grid>
		</>
	}

	const renderBellButton = () => {
		return <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Button onClick={isMobile ? () => setOpenAnnouncement(true) : handleClick} sx={{ color: '#010101', minWidth: '0px', py: 0, px: isMobile ? 0 : 1, borderRadius: isMobile ? '50%' : '0px', flexDirection: 'column' }}>
				<Box sx={{ display: 'flex', position: 'relative' }}>
					{unreadAnnouncement > 0 && <Box className='pink-bg white-text' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, right: 0, width: isMobile ? '12px' : '15px', height: isMobile ? '12px' : '15px', borderRadius: '50%' }}>
						<P1 size={isMobile ? -2 : -1}>{unreadAnnouncement}</P1>
					</Box>}
					<i className="fa-light fa-bell" style={{ fontSize: isMobile ? '25px' : '27px' }} />
				</Box>
				{!isMobile && <P1 desc>Notifications</P1>}
			</Button>
		</Box>
	}

	return (
		<RSNavCont additionalChildren={renderBellButton()} loaderProps={{ active: !hasLoaded || runningLoading, isBehindHeader: false }} hideHeader={isMobile} contClass='background-bottom' contSX={{ backgroundImage: isMobile ? `url(${isEmpty && !tab ? '/img-new/homepage/cover_images/runsmart_no_plan_half.jpg' : '/img-new/race-events/Runner-Group-Mobile.jpg'})` : '' }}>
			{isMobile && <RSHeaderCont hasLogo title="Training">
				{renderBellButton()}
			</RSHeaderCont>}

			<Grid item xs={12} sx={{ px: 0, pt: 2, height: '100%', pb: '100px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) 85%, transparent 100%)' }}>
				{/*renderAnnouncement2()*/}
				{tabsUI}
			</Grid>

			<RSDrawer fullWidth open={openAnnouncement} onClose={() => setOpenAnnouncement(false)}>
				{renderAnnouncement()}
			</RSDrawer>

			<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
				<Elements>
					<UpgradePlan userInfo={userInfo} setOpenPaymentDetails={setOpenPaymentDetails} openPaymentDetails={openPaymentDetails} setOpenUpgradePro={setOpenUpgradePro} openUpgradePro={openUpgradePro} userSubscriptionBasic={isUserBasic} />
				</Elements>
			</RecurlyProvider>

			<RSDrawer bottom fitContent open={displayPopupConnectWatch} onClose={() => setDisplayPopupConnectWatch(false)}>
				<Box sx={{ py: 2, px: 3 }}>
					<IntegrationsMainPage userInfo={userInfo} />
				</Box>
			</RSDrawer>

			<RSDrawer sx={{ maxWidth: isMobile ? "100%" : "400px" }} fitContent open={isPastDue} noClose onClose={() => { null }}>
				<PastDue userInfo={userInfo} />
			</RSDrawer>

			{!isMobile && <Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				slotProps={{
					paper: {
						elevation: 0,
						sx: {
							maxWidth: 400,
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&::before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: isMobile ? 14 : 43,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					},
				}}
				sx={{
					'& .MuiMenu-list': {
						padding: 0,
						backgroundColor: '#f6f6f6',
						borderRadius: '5px'
					}
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				{renderAnnouncement()}
			</Menu>}
		</RSNavCont>
	)
}

export default WithAuth(Home);
