import camelCase from 'lodash.camelcase'
import { planCodes } from '../api/values'
import { DEFAULT_COSTS, defaultPlanCostArgs } from './values'
import { getReq, putReq, postReq, navReq, getReqHome } from './apiConsume'
import { AccountProfile } from '../api/v2/account/Account.types'

/**
 * Account Data
 */

// Get Account Info
export const getAccountInfo = async () =>
	(await getReq('/account-info'))?.data || {}

// Get All Account Info
export const getAllAccountInfo = async (
	args?: Partial<{ account: boolean; subscription: boolean; billing: boolean; balance: boolean }>
) => {
	const responses = await Promise.all([
		args?.account === false ? ({} as any) : getReq('/verify'),
		args?.subscription === false ? ({} as any) : getReq('/subscription-info'),
		args?.billing === false ? ({} as any) : getReq('/billing-info'),
		args?.balance === false ? ({} as any) : getReq('/balance-info')
	])

	return {
		account: responses[0],
		subscription: responses[1],
		billing: responses[2],
		balance: responses[3],
	}
}

export const getAllAccounts = async () => await getReq('/account-info/all')


// Update Account Info
export const updateAccountInfo = async (args: {
	username?: string
	email?: string
	oldEmail?: string
	firstName: string
	lastName: string
	gender: string
	dob?: string
	// favorite: string
	joinReason?: string
	distanceUnit?: string,
	dayStart?: string,
	height?: number,
	weight?: number,
}, type?: string) => {
	const data: any = {
		first_name: args.firstName || '',
		last_name: args.lastName || '',
		gender: args.gender || '',
		dob: args.dob || null,
		// favorite: args.favorite || '',
	}
	if (args.joinReason != null) data.join_reason = args.joinReason || ''
	if (args.username) data.username = args.username
	if (args.email && args.email != args.oldEmail) {
		data.email = args.email
	}
	if (args.distanceUnit) data.distance_unit = args.distanceUnit
	if (args.dayStart) data.day_start = args.dayStart
	if (args.height) data.height = args.height
	if (args.weight) data.weight = args.weight

	return await putReq(`/update-account-info${type ? '?type=' + type : ''}`, data)
}

// Sign Up
export const signUp = async (args: {
	username: string
	email: string
	password: string
	signup_method?: string
	referral_code?: string
}) =>
	await postReq('/signup', {
		username: args.username,
		email: args.email,
		password: args.password,
		signup_method: args.signup_method || '',
		referral_code: args.referral_code || ''
	})

// Log In
export const logIn = async (args: { username: string; password: string }) =>
	await postReq('/login', {
		username: args.username,
		password: args.password,
	})

export const autoLogin = async (args: { user_id: number }) =>
	await postReq('/login/auto', {
		user_id: args.user_id
	})

// Update Password
export const updatePassword = async (password: string, newPassword: string) =>
	await putReq('/update-password', {
		password: password || '',
		new_password: newPassword || '',
	})

// Update Password from Email
export const updatePasswordFromEmail = async (args: {
	email: string
	code: string
	newPassword: string
}) =>
	await putReq('/update-password-from-email', {
		email: args.email,
		code: args.code,
		new_password: args.newPassword || '',
	})

// Reset Password
export const resetPassword = async (email: string) =>
	await postReq('/reset-password', { email })

// Sign Out
export const signOut = async () => {
	await getReq('/logout')
	navReq('/')
}

// Destroy Session
export const destroySession = async () => {
	await getReq('/logout')
}

/**
 * Subscription Data
 */

// Get Subscription By Id
export const getSubscriptionById = async (subscriptionId: string) => {
	const response = await getReq('/subscription?subscription_id=' + subscriptionId)
	const result = response?.data?.result;
	return { result }
}

// Get Subscription Info
export const getSubscriptionInfo = async () => {
	const response = await getReq('/subscription-info')
	const isActive = response?.data?.active ? true : false
	const subscription =
		response?.data?.active || response?.data?.expired || undefined
	return { isActive, subscription }
}

// Get Subscription Info Error
export const getSubscriptionInfoWithError = async () => {
	const response = await getReq('/subscription-info')
	const isActive = response?.data?.active ? true : false
	const subscription = response?.data?.active || response?.data?.expired || undefined
	const error = response?.data?.error
	return { isActive, subscription, error }
}

// Change Subscription
export const changeSubscription = async (
	id: string,
	planCode: string,
	hasPendingId = false
) => {
	try {
		const result = await putReq('/change-subscription', {
			id,
			plan_code: planCode,
			has_pending: hasPendingId ? 1 : 0,
		})
		if (result?.status == 'ok') return true
	} catch (err) {
		// Do Nothing
	}
	return false
}

// Create Subscription
export const createSubscription = async (planCode: string, signup_method?: string) => {
	try {
		const result = await postReq('/create-subscription', {
			plan_code: planCode,
			signup_method: signup_method
		})
		if (result?.status == 'ok') return true
	} catch (err) {
		// Do Nothing
	}
	return false
}

// Create Subscription
export const createFutureSubscription = async (planCode: string, startAt: Date) => {
	try {
		const result = await postReq('/create-subscription/future', {
			plan_code: planCode,
			start_at: startAt
		})
		if (result?.status == 'ok') return true
	} catch (err) {
		// Do Nothing
	}
	return false
}

// Create Impact Click ID
export const createImpactClickId = async (userId: number, clickId: string, conversion_oid: string, status: string) => {
	try {
		const result = await postReq('/v2/impact/clickid', {
			user_id: userId,
			irclickid: clickId,
			conversion_oid: conversion_oid,
			subscription_oid: '',
			status: status,

		})
		if (result?.status == 'ok') return true
	} catch (err) {
		// Do Nothing
	}
	return false
}

// UPDATE Impact Click ID
export const updateImpactClickId = async (data: { id: number, status: string }) => {
	try {
		const result = await putReq('/v2/impact/clickid', data)
		if (result?.status == 'ok') return true
	} catch (err) {
		// Do Nothing
	}
	return false
}

// Create purchase
export const createPurchase = async (planCode: string, tokenId: string) => {
	try {
		const result = await postReq('/create-purchase', {
			plan_code: planCode,
			token_id: tokenId
		})
		if (result?.status == 'ok') return true
	}
	catch (err) {
		// Do Nothing
	}
	return false;
}

// Cancel Subscription
export const cancelSubscription = async (id: string, args: any = {}) =>
	await postReq('/cancel-subscription', {
		id,
		...args,
	})

/**
 * Billing Data
 */

// Update Billing Info
export const updateBillingInfo = async (id: string) =>
	await postReq('/update-billing-info', {
		id: id != null ? String(id) : '',
	})


// Get Account Balance
export const getAccountBalance = async () => {
	const response = await getReq('/balance-info')

	// change these
	const hasPendingPayment = response?.data?.active ? true : false
	const subscription =
		response?.data?.active || response?.data?.expired || undefined


	return { hasPendingPayment, subscription }
}



/**
 * Plan Data
 */

// Get Plan Cost
export const getPlanCost = async (planCode: string) => {
	const result = await getReq('/get-plans')
	const planKey = camelCase(planCode.split('runsmart-').pop())
	return result?.data?.[planKey] || {}
}

// Get Plan Costs
export const getPlanCosts = async (
	args: Partial<typeof defaultPlanCostArgs> = {}
) => {
	const { hasOffer, isRenew, isYearlyRenewOnly, hasSale } = {
		...defaultPlanCostArgs,
		...args,
	}
	const result = await getReq('/get-plans')

	const isMonthlyRenew = isRenew && !isYearlyRenewOnly
	const monthlyCodeName: keyof typeof planCodes = isMonthlyRenew
		? 'monthlyRenew'
		: 'monthly'
	const annualCodeName: keyof typeof planCodes = hasSale
		? 'annualFlash'
		: hasOffer && isRenew
			? 'annualDiscountRenew'
			: hasOffer && !isRenew
				? 'annualDiscount'
				: !hasOffer && isRenew
					? 'annualRenew'
					: 'annual'

	const basicCodeName: keyof typeof planCodes = 'basic'
	const monthlyProCodeName: keyof typeof planCodes = 'monthlyPro'
	const annualProCodeName: keyof typeof planCodes = 'annualPro'

	const basic = result?.data?.[basicCodeName] != null
		? (result.data[basicCodeName] as number)
		: DEFAULT_COSTS.basic

	const monthly =
		result?.data?.[monthlyCodeName] != null
			? (result.data[monthlyCodeName] as number)
			: DEFAULT_COSTS.monthly
	const annual =
		result?.data?.[annualCodeName] != null
			? (result.data[annualCodeName] as number)
			: DEFAULT_COSTS.annual

	return {
		monthly,
		annual,
		monthlyCode: planCodes[monthlyCodeName],
		annualCode: planCodes[annualCodeName],
		annualFlash: 99,
		annualFlashCode: 'pro-yearly-sale',
		basic,
		basicCode: planCodes.basic,
		annualPro: result?.data?.[annualProCodeName] || DEFAULT_COSTS.annualPro,
		annualProCode: planCodes.annualPro,
		monthlyPro: result?.data?.[monthlyProCodeName] || DEFAULT_COSTS.monthlyPro,
		monthlyProCode: planCodes.monthlyPro,
	}
}

/**
 * Video Data
 */

// Get Video Progress Data
export const getVideoProgressData = async (
	accountId: number,
	joinReason: number
) =>
	await getReqHome('/video-progress-data', {
		args: [accountId, joinReason],
	})

// Get Video Progress Data V2
export const getVideoProgressDataV2 = async (
	accountId: number,
	joinReason: number
) =>
	await getReqHome('/v2/video-progress-data', {
		args: [accountId, joinReason],
	})

// Fetch Video Media URL
export const fetchMediaVideoUrl = async () =>
	await postReq('/get-video-media-url', {
		url: String((window as any).castVideoUrl as string || '') || '',
	})

// Update Video Progress
export const updateVideoProgress = async (args: {
	accountId: number
	videoId: number
	title: string
	duration: number
	isFirst: 0 | 1
	progress: number[]
}) =>
	await postReq('/update-video-progress', {
		account_id: args.accountId,
		video_id: args.videoId,
		name: args.title,
		duration: args.duration,
		first: args.isFirst,
		progress: args.progress,
	})

// Update Video Progress
export const updateVideoProgressV2 = async (args: {
	accountId: number
	videoId: number
	title: string
	duration: number
	isFirst: 0 | 1
	progress: number[]
}) =>
	await postReq('/v2/update-video-progress', {
		account_id: args.accountId,
		video_id: args.videoId,
		name: args.title,
		duration: args.duration,
		first: args.isFirst,
		progress: args.progress
	})

/**
 * Misc Data
 */

// Get Home Data
export const getHomeData = async (accountId: number, joinReason: number) =>
	await getReqHome('/home-data', {
		args: [accountId, joinReason],
	})

// Email Guide
export const emailGuide = async (args: {
	username: string
	accountId: number
	emailId: number
	toEmail: string
	guideFile: string
	guideType: string
}) =>
	await postReq('/email-guide', {
		username: args.username,
		account_id: args.accountId,
		email_id: args.emailId,
		to_email: args.toEmail,
		guide_file: args.guideFile,
		guide_type: args.guideType,
	})


// Get Video Continue
export const getHomeVideoContinue = async (user_id: number, searchKey: string) =>
	await getReqHome('/v2/continue-videos', { user_id, searchKey })

// Get Pending Delete
export const getPendingDelete = async () => {
	const response = await getReq('/delete-account')
	const hasPendingDeleteAccount = response?.data?.hasPendingDeleteAccount ? true : false
	return hasPendingDeleteAccount
}

//Get or generate account token for external subscription

export const getExternalAccount = async (type: "AppleAppStore" | "GooglePlayStore", requestKey: string) => {
	const result = await getReq('/external-account?type=' + type + '&externaAccountId=' + requestKey)
	return result
}

//Redeem Coupon

export const redeemCoupon = async (couponCode: string, signupMethod: string, email: string) => {
	const result = await postReq('/coupon/redeem', { couponCode, signupMethod, email })
	return result
}

//On-boarding
export const GetOnBoarding = async (userId: number, signal: AbortSignal) =>
	await getReq(`/v2/onboarding/user-progress?user_id=${userId}`, { signal: signal })

export const CreateOnBoarding = async (args: { user_id: number, user_progress: string }) =>
	await postReq(`/v2/onboarding/user-progress`, args)

export const UpdateOnBoarding = async (args: { user_id: number, user_progress: string }) =>
	await putReq(`/v2/onboarding/user-progress`, args)

//Account Profile
export const UpdateAccountProfile = async (args: AccountProfile) =>
	await putReq(`/v2/update-account-profile`, args)

export const UpdateAccountEmail = async (args: { newEmail: string, oldEmail: string, username: string }) =>
	await putReq(`/v2/update-email`, args)

export const GetAccountProfile = async (userId: number) =>
	await getReq(`/v2/account-profile?account_id=${userId}`)

export const GetSearchedAccountProfile = async (userId: number) =>
	await getReq(`/v2/search/account-profile?account_id=${userId}`)

// Account External Id

export const GetExternalId = async (externalId: string) =>
	await getReq(`/v2/external-id?external_id=${externalId}`)

export const CreateExternalId = async (args: { user_id: number, external_id: string, device: string }) =>
	await postReq(`/v2/external-id`, args)

export const UpdateNotificationStatus = async (external_id: string, notificationStatus: number) =>
	await postReq(`/v2/external-id/update-notification-status`, { external_id: external_id, notification_status: notificationStatus })