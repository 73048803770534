/* eslint-disable @typescript-eslint/no-unused-vars */

import { Button, Typography, Grid, Box } from '@mui/material'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import WithAuth from '../../components/WithAuth'
import { H1, SH2, P1 } from '../../components/text/RSTypography'
import RSCardCont from '../../components/cards/RSCardCont'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import useQueryParams from '../../hooks/useQueryParams'
import useRNBridge from '../../hooks/useRNBridge'
import { getReq, host, navReq, postReq } from '../../modules/apiConsume'
import { AuthPageProps } from '../../modules/types'
import { IAppleHealthKitStore } from '../../store/createAppleHealthKitStore'
import { IComponentStore } from '../../store/createComponentStore'
import { IGarminStore } from '../../store/createGarminStore'
import useStore from '../../store/useStore'
import integrations from './integrations.json'
import { COROS_CLIENT_ID, COROS_URL } from '../../modules/cliEnvValues'
import { authorizeCorosUser, deAuthorizeCorosUser, getCorosUserByUserId, getUserWorkouts } from '../../modules/corosActions'
import useAppEvents from '../../hooks/useAppEvents'

const ALLOWED_USER = [16123]

const IntegrationsMainPage: FC<{ type?: string, side?: boolean, reload?: () => void, userInfo?: any }> = ({ userInfo, type, side, reload }) => {
    const { setToast } = useStore((state: IComponentStore) => state)
    const { setGarminDailyDetails, setCurrentDayActivities } = useStore((state: IGarminStore) => state)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCoros, setIsLoadingCoros] = useState(false)
    const [isLoadingApplePermission, setIsLoadingApplePermission] = useState(false)
    const [verifyPageUrl, setVerifyPageUrl] = useState()
    const [userGarminDetails, setUserGarminDetails] = useState<any>()
    const [integrationStatus, setIntegrationStatus] = useState<string>()
    const { oauth_token, oauth_verifier, code, state, garmin_connect } = useQueryParams()
    const { sendDataToReactNative } = useRNBridge()
    const { mobileType } = useDeviceDetect()
    const { setPermission, permission: appleHealthPermission } = useStore((state: IAppleHealthKitStore) => state)
    const deviceId = localStorage.getItem('deviceRequestKey')
    const [retryVerifyAuth, setRetryVerifyAuth] = useState(false)
    const [retryCount, setRetryCount] = useState(0)
    const MAX_RETRY = 10
    const isNativeIOS = deviceId && mobileType.toLowerCase() === 'ios'

    const [corosUserData, setCorosUserData] = useState<any>()

    const { createEvent } = useAppEvents()

    const getGarminDetails = async (isConnect: boolean) => {
        const response = await getReq('/v2/garmin/user')
        const data = response.data.result[0]
        if (isConnect) {
            if (data.status == 1) {
                setRetryCount(0)
                setRetryVerifyAuth(false)
            }
            else {
                setRetryCount((prev) => prev + 1)
                setRetryVerifyAuth(true)
            }
        }
        // console.log(data)
        setUserGarminDetails(data)
    }

    const handleRequestAppleHealthPermission = async () => {

        setIsLoadingApplePermission(true)

        const dataToSend = {
            requestType: 'healthkit-request-permission',
            payload: {
                user_id: userInfo?.account_id || userInfo?.internal_id,
            },
            timestamp: new Date().getTime(),
        }

        sendDataToReactNative(dataToSend)

    }

    const handelGetDailyActivities = async () => {
        const authToken = userGarminDetails?.access_token
        const authSecret = userGarminDetails?.token_secret
        const start = moment().subtract(1, 'days').subtract(12, 'hours')
        const uploadStartTimeInSeconds = Math.floor(start.clone().startOf('day').valueOf() / 1000);
        const uploadEndTimeInSeconds = Math.floor(start.clone().startOf('day').add(86400, 'seconds').valueOf() / 1000);

        const response = await getReq(`/v2/garmin/user/dailyActivityDetails?access_token=${authToken}&token_secret=${authSecret}&uploadStartTimeInSeconds=${uploadStartTimeInSeconds}&uploadEndTimeInSeconds=${uploadEndTimeInSeconds}`)
        // console.log(uploadStartTimeInSeconds, uploadEndTimeInSeconds)
        // console.log(response)
    }

    const handleDisconnectAppleHealth = async () => {
        setIsLoadingApplePermission(true)
        localStorage.removeItem('healthkit-permission')
        setPermission(undefined)
        setIsLoadingApplePermission(false)
        userInfo?.account_id ? await createEvent(userInfo?.account_id, "WATCH_DISCONNECTED") : undefined
    }

    const handleDisconnectCoros = () => {
        setIsLoadingCoros(true)
        deAuthorizeCorosUser(Number(userInfo?.account_id)).then(async (res) => {
            if (res) {
                userInfo?.account_id ? await createEvent(userInfo?.account_id, "WATCH_DISCONNECTED") : undefined
                getCorosUserByUserId(Number(userInfo?.account_id)).then((res) => {
                    const user = res.data.result[0]
                    if (user)
                        setCorosUserData(user)
                })
            }
            setIsLoadingCoros(false)
        })
    }

    const handleDisconnect = async () => {
        try {
            setIsLoading(true)
            const response = await postReq('/v2/garmin/deregister', {
                access_token: userGarminDetails?.access_token,
                token_secret: userGarminDetails?.token_secret,
                garmin_id: userGarminDetails?.garmin_id
            })
            if (response?.status == "ok") {
                await getGarminDetails(false)
                setIsLoading(false)
                setGarminDailyDetails([])
                setCurrentDayActivities([])
                userInfo?.account_id ? await createEvent(userInfo?.account_id, "WATCH_DISCONNECTED") : undefined
                navReq('/#/')
            }

        } catch (error) {
            setIsLoading(false)
            setIntegrationStatus("error")
        }

    }

    const handleIntegrate = async (url: string) => {

        try {
            if (isNativeIOS && userInfo?.account_id) {
                const dataToSend = {
                    requestType: 'open-rs-shop',
                    payload: { url: `https://app.runsmartonline.com/#/?garmin_connect=auto&user_id=${userInfo?.account_id}` },
                    timestamp: new Date().getTime(),
                }
                sendDataToReactNative(dataToSend);
                navReq('/#/')
                // navReq('/#/?garmin_connect=auto&user_id=' + userInfo?.account_id)
            }
            else {
                setIsLoading(true)
                const response = await getReq(url)
                if (response && response?.status === "ok") {
                    const data = response?.data;
                    localStorage.setItem('authSecret', data?.authSecret)
                    localStorage.setItem('authToken', data?.authToken)
                    if (data?.verifyParams && data?.authToken && data?.authSecret) {
                        setTimeout(() => {
                            setVerifyPageUrl(data?.verifyParams)
                        }, 1000)
                        setIsLoading(false)

                    } else {
                        setIsLoading(false)
                        setIntegrationStatus("error")
                    }
                }
                else {
                    setIsLoading(false)
                    setIntegrationStatus("error")
                }
            }
        } catch (error) {
            setIsLoading(false)
            setIntegrationStatus("error")
        }

    }

    const handleVerifyAuth = async () => {
        const authToken = localStorage.getItem('authToken')
        const authSecret = localStorage.getItem('authSecret')
        try {
            setIsLoading(true)
            const response = await getReq(`/v2/garmin/verify-auth?oauth_verifier=${oauth_verifier}&auth_token=${authToken}&auth_secret=${authSecret}`)
            if (response && response?.status === "ok") {
                setIsLoading(false)
                localStorage.setItem('dismissSync', '0')
                // localStorage.removeItem('authToken')
                // localStorage.removeItem('authSecret')
                setIntegrationStatus("success")

                userInfo?.account_id ? await createEvent(userInfo?.account_id, "WATCH_CONNECTED", "GARMIN") : undefined
                // type === "training-plan" ? navReq('/#/training-plan/view') : undefined
                await getGarminDetails(true)
                reload && reload()
            }
            else {
                setIsLoading(false)
                setIntegrationStatus("error")
            }
        } catch (error) {
            setIsLoading(false)
            setIntegrationStatus("error")
        }
    }

    const handleCorosIntegration = async () => {
        const authUrl = `${COROS_URL}/oauth2/authorize?client_id=${COROS_CLIENT_ID}&state=${userInfo?.account_id || userInfo?.internal_id}&response_type=code&redirect_uri=https://app.runsmartonline.com/#/profile`
        setIsLoadingCoros(true)
        if (userInfo && (userInfo?.account_id || userInfo?.internal_id))
						window.location.assign(authUrl)
        setTimeout(() => {
            setIsLoadingCoros(false)
        }, 1000)
    }

    useEffect(() => {
        if (type === "training-plan") {
            if (integrationStatus)
                setToast({
                    show: integrationStatus ? true : false,
                    title: integrationStatus == "error" ? "Error Message" : "Integration successful!",
                    message: integrationStatus == "error" ? "An error occurred during integration, please try again." : "You have completed connecting your Garmin account!",
                    variant: integrationStatus == "error" ? "danger" : "success",
                    onClose: () => {
                        setIntegrationStatus(undefined)
                        // navReq('/#/')
                    },
                    duration: 5000
                })
        }
        else {
            setToast({
                show: integrationStatus ? true : false,
                title: integrationStatus == "error" ? "Error Message" : "Integration successful!",
                message: integrationStatus == "error" ? "An error occurred during integration, please try again." : "You have completed connecting your Garmin account!",
                variant: integrationStatus == "error" ? "danger" : "success",
                onClose: () => {
                    setIntegrationStatus(undefined)
                    // navReq('/#/')
                },
                duration: 5000
            })
        }

    }, [integrationStatus, type])

    useEffect(() => {
        if (verifyPageUrl) {
            window.location.assign(verifyPageUrl)
        }
    }, [verifyPageUrl])

    useEffect(() => {
        if (oauth_token && oauth_verifier) {
            handleVerifyAuth()
        }
    }, [oauth_token, oauth_verifier])

    useEffect(() => {
        if (retryVerifyAuth && retryCount < MAX_RETRY)
            handleVerifyAuth()
    }, [retryVerifyAuth, retryCount])

    useEffect(() => {
        if (userInfo)
            getGarminDetails(false)
    }, [userInfo])

    useEffect(() => {
        if (garmin_connect)
            handleIntegrate("/v2/garmin/connect-url")
    }, [garmin_connect])

    useEffect(() => {
        if (userInfo && userInfo?.account_id) {
            getCorosUserByUserId(userInfo?.account_id).then((res) => {
                const user = res.data.result[0]
                if (user)
                    setCorosUserData(user)
            })
        }
    }, [userInfo])

    useEffect(() => {
        if (code && state && userInfo && userInfo.account_id == Number(state) && !corosUserData) {
            setIsLoadingCoros(true)
            authorizeCorosUser(code, Number(state)).then(async (res) => {
                if (res) {
                    userInfo?.account_id ? await createEvent(userInfo?.account_id, "WATCH_CONNECTED", "COROS") : undefined
                    getCorosUserByUserId(Number(state)).then((res) => {
                        const user = res.data.result[0]
                        if (user)
                            setCorosUserData(user)
                    })
                }
                setIsLoadingCoros(false)
            })
        }
    }, [code, state, userInfo, corosUserData])

    useEffect(() => {
        const permission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')
        if (permission.status === "granted") {
            setPermission(permission)
        }
        return () => {
            setIsLoading(false)
        }
    }, [])

    // useEffect(() => {
    //     if (corosUserData && corosUserData.status == 1 && userInfo && userInfo.account_id == corosUserData.user_id) {
    //         const activity_date = moment().format('YYYY-MM-DD')
    //         getUserWorkouts(corosUserData.user_id, activity_date).then((res) => {
    //             if (res) {
    //                 console.log(res)
    //             }
    //         })
    //     }
    // }, [corosUserData, userInfo])

    useEffect(() => {
        // alert(JSON.stringify(appleHealthPermission))
        // setAppleHealthPermission(HkPermissions)
        if (appleHealthPermission?.status === "granted") {
            userInfo?.account_id ? createEvent(userInfo?.account_id, "WATCH_CONNECTED", "APPLE_HEALTH_KIT") : undefined
            setIntegrationStatus("success")
            setIsLoadingApplePermission(false)
        }
        else if (appleHealthPermission?.status === "denied") {
            setIntegrationStatus("error")
            setIsLoadingApplePermission(false)
        }
    }, [appleHealthPermission])

    const renderWatchCont = (watchData: any) => {
    	const { img, status, date, permissions, onClick, loading } = watchData
    	return <Grid container xs={12} sx={{mb: 2}}>
				<RSCardCont onClick={onClick} isLoading={loading}>
					<Box sx={{ width: 'calc(100% - 20px)'}}>
						<Grid container xs={12} justifyContent={status ? 'center' : 'start'}>
							<img style={{  width: 'auto', height: '40px' }} src={img}></img>
						</Grid>
						{status && <Grid container sx={{flexDirection: 'column', mt: 2}}>
							<Grid container>
								<SH2 desc sx={{mr: 1, display: 'flex', alignItems: 'center'}}>Status:</SH2>
								<P1 className='primary-dark-text' desc>Connected</P1>
							</Grid>
							<Grid container>
								<SH2 desc sx={{mr: 1, display: 'flex', alignItems: 'center'}}>Connected:</SH2>
								<P1 desc>{moment(date).format('MMMM D, YYYY h:mm A')}</P1>
							</Grid>
							{permissions && <Grid container>
								<SH2 desc sx={{mr: 1, display: 'flex', alignItems: 'center'}}>Permissions:</SH2>
								<P1 desc>{permissions}</P1>
							</Grid>}
						</Grid>}
					</Box>
					<Box sx={{ width: '20px', position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }}>
						<i className="fa-solid fa-chevron-right" style={{ fontSize: '20px' }} />
					</Box>
				</RSCardCont>
			</Grid>
    }

    const garminConnected = userGarminDetails?.['status'] == 1
    const appleHealthConnected = appleHealthPermission?.status === 'granted'
    const corosConnected = corosUserData?.status == 1

    const renderAllWatch = () => {
			const watchList = [{
				img: '/images/icons/connect-logo-black.svg',
				status: garminConnected,
				onClick: garminConnected ? handleDisconnect : () => handleIntegrate("/v2/garmin/connect-url"),
				date: userGarminDetails?.last_modified,
				loading: isLoading,
				permissions: userGarminDetails?.permissions.split(',').map((x: string, key: number) => (key != userGarminDetails?.permissions.split(',').length - 1 ? x.split('_')[0] + ' | ' : x.split('_')[0]))
			}, ...isNativeIOS ? [{
				img: '/images/icons/apple-watch-logo.svg',
				status: appleHealthConnected,
				onClick: appleHealthConnected ? handleDisconnectAppleHealth : handleRequestAppleHealthPermission,
				date: appleHealthPermission?.date,
				loading: isLoadingApplePermission,
				permissions: JSON.stringify(appleHealthPermission?.permissions)?.replace(/"/g, '').replace(/,/g, ' | ').replaceAll('[', '').replaceAll(']', '')
			}] : [], {
				img: '/img/coros_logo3.png',
				status: corosConnected,
				onClick: corosConnected ? handleDisconnectCoros : handleCorosIntegration,
				loading: isLoadingCoros,
				date: corosUserData?.last_modified,

			}]

			const newList: any = []
			watchList.forEach((watch: any) => {
				const body = renderWatchCont(watch)
				if (watch.status)
					newList.unshift(body)
				else
					newList.push(body)
			})

    	return newList
    }

    return (
        <>
            <H1 size={1} card sx={{ width: '100%' }}>Connect Your Watch</H1>
            <P1 size={1} sx={{ width: '100%', mb: 2 }}>Pick a device to get started</P1>
						{renderAllWatch()}
        </>
    )
}

export default IntegrationsMainPage
