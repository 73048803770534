/* eslint-disable @typescript-eslint/no-unused-vars */

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";
import { GetEffortFeedback, SaveEffortFeedback } from "../../modules/trainingPlanActions"
import moment from "moment";
import { P1, P4 } from "../text/RSTypography";
// import { DAYS_OF_WEEK } from "./DaysOptions"



const TrainingDayFeedback = (props: any) => {

    const { planId, planDayId, currentDate, activityId } = props

    const currentDay = moment(currentDate).format('dddd').toLowerCase()

    const [displayIntro, setDisplayIntro] = useState<any>(true)
    const [sliderValue, setSliderValue] = useState<any>('');
    const [displayTextContent, setDisplayTextContent] = useState<any>(effortDescriptions[0])
    const [isLoadingData, setIsLoadingData] = useState<any>(true)


    const handleEffortScoreChange = (value: any) => {
        value > 0 && setDisplayIntro(false)
        // !displayIntro && setSliderValue(value)
        handleEffortScoreSave()
    }


    const handleEffortScoreSave = async () => {

        SaveEffortFeedback({ plan_id: planId, plan_day_id: planDayId, activity_id: activityId, effort_score: sliderValue, day: currentDay }).then((res) => {

            console.log(res)

        }).catch((err) => {

            console.log(err)
        })
    }


    useEffect(() => {

        console.log('!!! activityId', activityId)

        const effortDescription = effortDescriptions.find((effortDescription) => effortDescription.value >= sliderValue && effortDescription.value != '')

        if (displayIntro) setDisplayTextContent(effortDescriptions[0])
        else effortDescription && setDisplayTextContent(effortDescription)

    }, [sliderValue])


    useEffect(() => {

        setIsLoadingData(true)

        GetEffortFeedback(planId, planDayId, activityId).then((res) => {

            const data = res.data.result
            setDisplayIntro(false)
            setIsLoadingData(false)

            if (data.length > 0) {

                // get the latest effort score
                const effortScore = data[data.length - 1]?.effort_score
                console.log('effort score', effortScore)
                setSliderValue(effortScore)

            }

        }).catch((err) => {

            setIsLoadingData(false)
            setDisplayIntro(false)
            console.log(err)
        })

    }, [])


    return <Box sx={{ mx: 3, mt: '3rem', pb: '2rem' }}>
        {/* <Box sx={{ width: '100%', backgroundColor: '#f6f6f6', p: '0.4rem 0 1.4rem', m: '0.8rem 0 0.4rem 0', borderRadius: '10px' }}> */}

        {/* <Grid sx={{ px: '1.4rem', mt: '0.8rem', display: 'flex', alignItems: 'center'}}>
            <i className="fa-light fa-gauge-high" style={{ fontSize: '1.3rem', marginRight: '0.4rem', marginLeft: '-0.3rem' }}></i>How did this run feel?
        </Grid> */}

        <Box sx={{ display: 'flex', width: '100%', backgroundColor: 'white', height: '22rem', borderRadius: '10px' }}>
            <Grid sx={{ px: '1.4rem', maxHeight: isLoadingData ? '0rem' : '20rem', overflow: 'hidden', ml: '0.3rem', mr: '0.9rem', my: '1rem' }}>

                <P4 sx={{my: '1rem'}}>How did this run feel?</P4>

                <Slider
                    // aria-label="Temperature"
                    defaultValue={0}
                    value={sliderValue}
                    // getAriaValueText={handleSliderChange}
                    // valueLabelDisplay="auto"
                    shiftStep={30}
                    step={1}
                    marks
                    min={0}
                    max={10}
                    sx={{
                        mx: '0.6rem',
                        color: 'black',
                        height: '6px',
                        '& .MuiSlider-mark': {
                            borderRadius: '50%',
                            marginLeft: '-2px',
                            height: '6px',
                            width: '6px',
                            overflow: 'hidden',
                            color: '#9FCD2B',
                            backgroundColor: '#9FCD2B'
                        },
                        '& .MuiSlider-track': {
                            backgroundColor: '#9FCD2B',
                            borderColor: '#9FCD2B',
                            color: '#9FCD2B'
                        }
                    }}
                    onChange={(e, value) => setSliderValue(value)}
                    onChangeCommitted={(e, value) => handleEffortScoreChange(value)}
                />

                <Grid container xs={12} sx={{ mt: '-1rem', mb: '2rem', mx: '0.6rem' }}>
                    <Grid item xs={4} sx={{ fontSize: '11px', fontFamily: 'Poppins-Light' }}>
                        EASY
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'center', fontSize: '11px', fontFamily: 'Poppins-Light' }}>
                        MODERATE
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right', fontSize: '11px', fontFamily: 'Poppins-Light' }}>
                        MAX EFFORT
                    </Grid>
                </Grid>

                <Box>
                    {/* <p style={{ fontFamily: 'Poppins-Semibold', fontSize: '1.1rem', marginBottom: 0 }}>{displayTextContent?.header}</p> */}
                    <P4 sx={{ mb: '0.8rem' }}>{displayTextContent?.header}</P4>
                    <P1>{displayTextContent?.body}</P1>
                </Box>

            </Grid>
        </Box>

        <Box sx={{mt: '2.5rem', mx: '1.6rem'}}>
            <P4>{effortExplanations[0]}</P4>
            <P1 sx={{mt: '1rem'}}>{effortExplanations[1]}</P1>
            <P1 sx={{mt: '1rem'}}>{effortExplanations[2]}</P1>
        </Box>
    </Box >
}

export default TrainingDayFeedback;



const effortDescriptions = [

    {
        value: '',
        header: 'Perceived Effort Scale:',
        body: `Effort isn’t just about pace—it's about how your body feels during a run. Use the Perceived Effort scale (0-10) to track how challenging your run was. Over time, we’ll use this data to help you train smarter, recover better, and hit your goals.`
    },
    {
        value: 3,
        header: `What’s Easy?`,
        body: `Breathing is steady, and conversation flows effortlessly. Feels like a warm-up or recovery run—relaxed and controlled.`
    },
    {
        value: 6,
        header: `What’s Moderate?`,
        body: `You’re working, but still in control. Talking takes effort, but short sentences are doable. Think of this as a steady effort you could hold for a while.`
    },
    {
        value: 10,
        header: `What’s Max Effort?`,
        body: `Breathing is heavy, and talking? Not happening. Your legs burn, your heart pounds, and you're pushing to the limit—whether it’s a hard workout, a race, or an all-out sprint.`
    }

]



const effortExplanations = [
    `Why this matters?`
    ,
    `Your effort rating isn’t just a number—it’s a key piece of the puzzle. We combine it with other data points to analyze your performance, track fatigue, and fine-tune your training.`
    ,
    `Over time, this helps us suggest personalized adjustments to keep you progressing while avoiding overtraining. The smarter you train, the better you run.`
]
