import { FC } from 'react'
import { motion } from "framer-motion";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {P1} from '../text/RSTypography'


export type RSToggleButtonProps = {
	label?: string,
	active: boolean,
	setActive: any,
	style2?: boolean,
	loading?: boolean
}


const RSToggleButton: FC<RSToggleButtonProps> = ({label , active, setActive, style2, loading}) => {

	const renderBTN = () => {
		return <Box className={`switch ${active ? 'fe' : ''}`} sx={{ backgroundColor: active ? '#9FCD2B !important' : '#80808070' }} onClick={() => setActive(!active)}>
			<motion.div className="handle" layout
				transition={{
					type: "spring",
					stiffness: 700,
					damping: 30
				}} />
		</Box>
	}

	return label ? <Box sx={{ mb: '10px', position: 'relative', backgroundColor: 'white', border: loading ? 'none' : '1px solid rgba(0, 0, 0, 0.23)', px: '10px', py: style2 ? 0 : '10px', height: style2 ? '40px' : 'auto', borderRadius: '5px', display: 'flex', width: '100%' }}>
		{loading && <Box className='loading-days toRight' sx={{border: 'none !important', position: 'absolute', height: '100%', width: '100%', top: 0, left: 0}}/>}
		<Grid container xs={8} sx={{ pl: '10px' }} justifyContent="flex-start" alignItems="center">
			{style2 ? <P1 desc>{label}</P1> : <Box sx={{ fontFamily: 'Poppins', fontSize: '16px' }} >
				{label}
			</Box>}
		</Grid>

		<Grid container xs={4} justifyContent="flex-end" alignItems="center">
			{renderBTN()}
		</Grid>
	</Box> : renderBTN()
}

export default RSToggleButton;
