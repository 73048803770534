import startCase from 'lodash.startcase'
import camelCase from 'lodash.camelcase'

// Title Case
export const titleCase = (val: string) => startCase(camelCase(val))
export const capCase = (val: string | undefined) => val ? val.charAt(0).toUpperCase() + val.slice(1) : val

// Round Number
export const roundNumber = (val: number | string, places = 2) =>
	Number(Number(val).toFixed(places))

// Do Nothing Click
export const doNothingClick = (e: any) => e.preventDefault()

// get zone color
export const getZoneColors = (zc: string, bg?: boolean) => {
	let zcClass = ''
	switch (zc) {
		case 'zone1':
			zcClass = 'zone-blue'
			break;
		case 'zone2':
			zcClass = 'zone-green'
			break;
		case 'zone3':
			zcClass = 'zone-yellow'
			break;
		case 'zone4':
			zcClass = 'zone-orange'
			break;
		case 'zone5':
			zcClass = 'zone-red'
			break;
		default:
			zcClass =  ''
	}
	return `${zcClass}${bg ? '-bg' : ''}`
};

//get color
export const getColorCode = (activity: string | undefined) => {
	const included = (x: string) => activity?.toUpperCase()?.includes(x)
	switch (true) {
		case included('REST'):
			return '#FFF'
		case included('CROSS TRAIN'):
		case included('XT'):
			return '#FBF5B7'
		case included('EASY'):
		case included('RECOVERY'):
			return 'hsl(206, 52%, 95%)'
		case included('BASE'):
			return 'hsl(206, 52%, 90%)'
		case included('MIXED'):
		case included('PROGRESSION'):
			return 'hsl(206, 52%, 85%)'
		case included('TEMPO'):
			return 'hsl(206, 52%, 80%)'
		case included('INTERVAL'):
			return 'hsl(206, 52%, 75%)'
		case included('TRACK'):
			return 'hsl(206, 52%, 70%)'
		case included('SEE LAPS'):
		case included('PACE'):
			return 'hsl(206, 52%, 65%)'
		case included('RACE'):
		case included('RACE DAY'):
			return 'hsl(206, 52%, 60%)'
		default:
			return '#FBF5B7'
	}
}

// get guidance
export const getGuidance = (activity: string | undefined) => {
	const included = (x: string) => activity?.toUpperCase()?.includes(x)
	switch (true) {
		case included('EASY'):
		case included('RECOVERY'):
			return `A relaxed, low-effort run to promote recovery and maintain consistency. Should feel light and conversational.`
		case included('BASE'):
			return `A steady, moderate-effort run that builds aerobic endurance. Run at a comfortable pace where you can hold a conversation.`
		case included('MIXED'):
		case included('PROGRESSION'):
			return `Start easy and gradually increase your pace, finishing faster than you started. Helps improve stamina and pacing control.`
		case included('TEMPO'):
			return `A sustained effort run at a "comfortably hard" pace—challenging but controlled. Builds speed and endurance for race day.`
		case included('INTERVAL'):
			return `Alternates short bursts of fast running with periods of recovery. Improves speed, efficiency, and anaerobic fitness.`
		case included('SEE LAPS'):
		case included('PACE'):
			return `Short intervals run at your goal race pace, with controlled recovery in between. Helps dial in pacing and build confidence for race day.`
		case included('STRENGTH'):
			return `Focus on balance, core, and glute strength to build stability and power for running. Helps improve efficiency and reduce injury risk.`
		case included('STRETCH'):
			return `Focus on flexibility and mobility to improve range of motion and reduce injury risk.`
		case included('YOGA'):
			return `A targeted flow between postures designed to enhance core strength, balance, and flexibility, with an emphasis on opening the hips and ankles.`
		default:
			return ''
	}
}

export const getColorCodeLEGACY = (activity: string | undefined) => {
	const included = (x: string) => activity?.toUpperCase()?.includes(x)
	switch (true) {
		case included('BASE'):
			return '#FFC352'
		case included('TEMPO'):
			return '#38E0F0'
		case included('MIXED'):
			return '#B50F8F'
		case included('PROGRESSION'):
			return '#B50F8F'
		case included('TRACK'):
			return '#2961DA'
		case included('INTERVAL'):
			return '#E12B2B'
		case included('RECOVERY'):
			return '#09BCD3'
		case included('CROSS TRAIN'):
			return '#7D0CC3'
		case included('RACE'):
			return '#9FCD2B'
		case included('RACE DAY'):
			return '#13C30C'
		default:
			return 'black'
	}
}

const strToNum = (s: any) => s ? s * 1 : 0

export const hexToRGB = (hexData: string, opacity?: string | number) => {
	const hex: any = '0x' + (hexData.replace('#', ''))
	const r = hex >> 16 & 0xFF
	const g = hex >> 8 & 0xFF
	const b = hex & 0xFF
	return `rgb(${r}, ${g}, ${b}, ${opacity || 1})`
}

export const getHeight = (isMetric: any, heightObj: any) => {
	const { feet = 0, inch = 0, cm = '' } = heightObj || {}
	const cmToFeet = (c: any) => c ? c / 30.48 : 0
	const inchToCm = (i: any) => i ? (2.54 * i) : 0
	const feetToCm = (f: any) => f ? (30.48 * f) : 0

	const finalCm = isMetric ? cm : (feetToCm(feet) + inchToCm(inch))
	let finalFeet = feet
	let finalInch = inch
	if (isMetric) {
		const newFeetRaw = cm ? cmToFeet(cm) : 0
		const newFeetWhole = newFeetRaw ? Math.floor(newFeetRaw) : 0
		const newInch = newFeetRaw ? newFeetRaw - (newFeetWhole) : 0
		finalFeet = newFeetWhole
		finalInch = Math.round(newInch * 12)
	}
	return {
		feet: finalFeet ? strToNum(finalFeet) : 0,
		inch: finalInch ? strToNum(finalInch) : 0,
		cm: finalCm ? strToNum(Number(finalCm)?.toFixed(2)) : 0
	}
}

export const getWeight = (isMetric: any, weightObj: any) => {
	const { kg = 0, lbs = 0 } = weightObj || {}
	const finalKg = isMetric ? kg : (lbs ? lbs * 0.45359237 : 0)
	const finalLbs = !isMetric ? lbs : (kg ? kg * 2.2046226218 : 0)
	return {
		kg: finalKg ? strToNum(Number(finalKg)?.toFixed(1)) : '',
		lbs: finalLbs ? strToNum(Number(finalLbs)?.toFixed(1)) : ''
	}
}
