import React, { FC, useEffect, useRef, useState } from 'react';
// import { Samples } from '../../api/v2/garmin-connect/garmin-connect.types';
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../../modules/cliEnvValues';
import { P3 } from '../../components/text/RSTypography';
import { Box } from '@mui/system';
import { RingSpinner } from 'react-spinner-overlay';

interface TrainingActivityMapProps {
    samples: any[];
    isMobile?: boolean;
}

interface Route {
    lat: number;
    lng: number;
    timestamp: number;
}

const TrainingActivityMap: FC<TrainingActivityMapProps> = ({ samples, isMobile }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    const [route, setRoute] = useState<Route[]>([]);
    const [segmentedRoute, setSegmentedRoute] = useState<{ path: Route[]; color: string }[]>([]);
    const mapRef = useRef<google.maps.Map | null>(null);

    const containerStyle = {
        width: '100%',
        height: isMobile ? '100%' : '500px'
    };

    const speedColors = ["#0349a0", "#1c73d0", "#3c96f3", "#55a9fc", "#82c725", "#83c81c", "#facb4f", "#f78d1f", "#e96912", "#d22021"];

    const interpolateColor = (color1: string, color2: string, factor: number) => {
        const hex = (c: string) => parseInt(c, 16);
        const r = Math.round(hex(color1.slice(1, 3)) * (1 - factor) + hex(color2.slice(1, 3)) * factor);
        const g = Math.round(hex(color1.slice(3, 5)) * (1 - factor) + hex(color2.slice(3, 5)) * factor);
        const b = Math.round(hex(color1.slice(5, 7)) * (1 - factor) + hex(color2.slice(5, 7)) * factor);
        return `rgb(${r}, ${g}, ${b})`;
    };

    const getSpeedColor = (speed: number, minSpeed: number, maxSpeed: number) => {
        const index = Math.floor(((speed - minSpeed) / (maxSpeed - minSpeed)) * (speedColors.length - 1));
        const baseColor = speedColors[Math.min(index, speedColors.length - 2)];
        const nextColor = speedColors[Math.min(index + 1, speedColors.length - 1)];
        return interpolateColor(baseColor, nextColor, 0.5);
    };

    const haversineDistance = (point1: Route, point2: Route) => {
        const toRad = (value: number) => (value * Math.PI) / 180;
        const R = 6371;
        const dLat = toRad(point2.lat - point1.lat);
        const dLon = toRad(point2.lng - point1.lng);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(point1.lat)) * Math.cos(toRad(point2.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const smoothRoute = (route: Route[], interval = 10) => route.filter((_, index) => index % interval === 0);

    const getCenterCoords = (route: Route[]) => {
        if (!route.length) return { lat: 0, lng: 0 };
        const avgLat = route.reduce((sum, p) => sum + p.lat, 0) / route.length;
        const avgLng = route.reduce((sum, p) => sum + p.lng, 0) / route.length;
        return { lat: avgLat, lng: avgLng };
    };

    const fitMapToBounds = (map: google.maps.Map, route: Route[]) => {
        if (!route.length) return;

        const bounds = new google.maps.LatLngBounds();
        route.forEach((point) => bounds.extend(new google.maps.LatLng(point.lat, point.lng)));

        if (!bounds.isEmpty()) {
            map.fitBounds(bounds);

            // Prevent over-zooming if the route is very small
            google.maps.event.addListenerOnce(map, "bounds_changed", () => {
                if (map?.getZoom && map.getZoom() as number > 16) map.setZoom(16);
                if (map?.getZoom && map.getZoom() as number < 5) map.setZoom(5);
            });
        }
    };

    const onLoad = (map: google.maps.Map) => {
        mapRef.current = map;
        if (route.length) fitMapToBounds(map, route);
    };

    useEffect(() => {
        if (samples.length) {
            const newRoute = samples
                .map((sample) =>
                    (sample.latitudeInDegree && sample.longitudeInDegree) || (sample.lat && sample.lng)
                        ? { lat: sample.latitudeInDegree || sample.lat, lng: sample.longitudeInDegree || sample.lng, timestamp: sample.startTimeInSeconds || sample.sts || sample.ts }
                        : null
                )
                .filter((point): point is Route => point !== null);

            setRoute(newRoute);
        }
    }, [samples]);

    useEffect(() => {
        if (route.length < 2) return;

        const smoothedRoute = smoothRoute(route, 15);
        const speeds = smoothedRoute.map((p, i) =>
            i === 0 ? 0 : haversineDistance(smoothedRoute[i - 1], p) / ((p.timestamp - smoothedRoute[i - 1].timestamp) / 3600)
        );
        const minSpeed = Math.min(...speeds);
        const maxSpeed = Math.max(...speeds);

        const segments = smoothedRoute.slice(1).map((point, i) => ({
            path: [smoothedRoute[i], point],
            color: getSpeedColor(speeds[i], minSpeed, maxSpeed),
        }));

        setSegmentedRoute(segments);
    }, [route]);

    if (!isLoaded) return <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <RingSpinner loading={!isLoaded} color={'#9fcd2b'} size={40} />
        <P3 sx={{ mt: 2, color: "gray" }}>Loading, please wait...</P3>
    </Box>

    return (
        <Box sx={{ px: 1, position: isMobile ? "fixed" : "relative", height: isMobile ? "calc(100% - 110px)" : "auto", width: "100%" }}>
            <GoogleMap
                onLoad={onLoad}
                mapContainerStyle={containerStyle}
                center={getCenterCoords(route)}
                zoom={14}
                mapTypeId="terrain"
                options={{
                    mapTypeControl: false,
                    // mapTypeControlOptions: { style: google.maps.MapTypeControlStyle.DROPDOWN_MENU },
                    streetViewControl: false,
                    fullscreenControl: false,
                    zoomControl: true,
                    clickableIcons: false,
                    minZoom: 5, // Prevent excessive zoom out
                    maxZoom: 18, // Prevent excessive zoom in
                }}
            >
                {segmentedRoute.map((segment, index) => (
                    <Polyline key={index} path={segment.path} options={{ strokeColor: segment.color, strokeOpacity: 1.0, strokeWeight: 7, geodesic: false }} />
                ))}
                {route.length > 0 && <Marker position={route[0]} label="Start" />}
                {route.length > 1 && <Marker position={route[route.length - 1]} label="End" />}
            </GoogleMap>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                <span style={{ marginRight: "10px", fontSize: "1rem" }}>Slower</span>
                {speedColors.map((color, i) => (
                    <div key={i} style={{ background: color, width: "30px", height: "10px", marginLeft: -1 }}></div>
                ))}
                <span style={{ marginLeft: "10px", fontSize: "1rem" }}>Faster</span>
            </div>
        </Box>
    );
};

export default TrainingActivityMap;