import { useState } from 'react'
import { CreateEvent } from '../modules/appEventActions'

const useAppEvents = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const createEvent = async (user_id: number, event_type: string, metadata?: string) => {
        setIsLoading(true)
        const request = await CreateEvent({
            user_id: user_id,
            event_type: event_type,
            metadata: metadata
        }).then(() => {
            setIsLoading(false)
        }).catch(err => {
            // alert(err)
            setError(err)
            setIsLoading(false)
        })

        return request
    }

    return {
        isLoading,
        error,
        createEvent
    }

}

export default useAppEvents