import React, { FC, useState } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import RSDrawer from "../layout/RSDrawer";
import useIsMobileScreen from "../../hooks/useIsMobileScreen";
import { RSButton1 } from '../buttons'
import { H1, P1 } from "../text/RSTypography";
import RunSmartPro from "../../pages/account/RunSmartPro";

export type RSUpgradeProps = {
	title: string,
	description: string,
	userInfo: any
	btnFullWidth?: boolean
}

const RSUpgrade: FC<RSUpgradeProps> = ({ title, description, userInfo, btnFullWidth }) => {
	// const [openPaymentDetails, setOpenPaymentDetails] = useState<boolean>(false)
	const [openUpgradePro, setOpenUpgradePro] = useState<boolean>(false)
	const isMobile = useIsMobileScreen()

	return <Grid container xs={12} className='bg-main' sx={{ p: 3 }}>
		<Box sx={{ width: '100%' }}>
			<img src='/images/icons/pro-icon-word.png' style={{ height: '20px', width: 'auto' }} />
		</Box>
		<H1 card size={5} sx={{ pb: 1 }}>{title}</H1>
		<P1 card>{description}</P1>
		<RSButton1 onClick={() => setOpenUpgradePro(true)} sx={{ mt: 2, width: btnFullWidth ? "100%" : "50%", maxHeight: "45px" }}>{"Explore Pro"}</RSButton1>

		<RSDrawer bottom={!isMobile} popUp={!isMobile} fitContent={isMobile ? false : true} popUpWidth={'500px'} popUpHeight={!isMobile ? '70%' : 'auto'} open={openUpgradePro} onClose={() => setOpenUpgradePro(false)}>
			<RunSmartPro setOpen={setOpenUpgradePro} userInfo={userInfo} />
		</RSDrawer >
	</Grid>
};

export default RSUpgrade;
