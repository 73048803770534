import { AuthUserInfo } from "../api/types"
import { IGoogleUser, StoreSlice } from "./types"

export interface IOfferParams {
    offerParam: string,
    hasOffer: boolean,
}

export interface IPlanProperties {
    monthCost: number,
    yearCost: number,
    yearlyByMonthCost: number,
    savingsPercent: number,
    trialDate: string,
    yearlyDate: string,
    expirationDate: string
}

export interface ICurrentUser {
    currentUser: Partial<AuthUserInfo> | undefined,
    setCurrentUser: (currentUser: Partial<AuthUserInfo>) => void,
    hasOffer: IOfferParams,
    setHasOffer: (hasOffer: IOfferParams) => void,
    isMonthlySelected: boolean,
    setIsMonthlySelected: (bool: boolean) => void,
    planProperties: IPlanProperties | undefined
    setPlanProperties: (properties: IPlanProperties | undefined) => void,
    googleUser: IGoogleUser | undefined,
    setGoogleUser: (googleUser: IGoogleUser) => void,
    subscription: { active?: any, expired?: any, accessDetails?: any, locked?: boolean },
    setSubscription: (subscription: { active?: any, expired?: any, accessDetails: any, locked?: boolean }) => void,
    billingInfo: any,
    setBillingInfo: (billingInfo: any) => void,
    balanceInfo: any,
    setBalanceInfo: (balanceInfo: any) => void,
    account: any,
    setAccount: (accountInfo: any) => void,
    userCoords: any,
    setUserCoords: (coords: any) => void,
    notificationStatus: boolean,
    setNotificationStatus: (status: boolean) => void,
    notificationDeepLink: string,
    setNotificationDeepLink: (linkType: string) => void,
    isPastDue: boolean,
    setIsPastDue: (isPastDue: boolean) => void,
    pastDueBalance: number,
    setPastDueBalance: (balance: number) => void,
    onBoardingData: any,
    setOnBoardingData: (data: any) => void,
}

const createAccountStore: StoreSlice<ICurrentUser> = (set) => ({
    currentUser: undefined,
    setCurrentUser: (currentUser: Partial<AuthUserInfo>) => set(() => ({ currentUser: currentUser })),
    hasOffer: { offerParam: '', hasOffer: false },
    setHasOffer: (hasOffer: IOfferParams) => set(() => ({ hasOffer: hasOffer })),
    isMonthlySelected: false,
    setIsMonthlySelected: (bool: boolean) => set(() => ({ isMonthlySelected: bool })),
    planProperties: undefined,
    setPlanProperties: (properties: IPlanProperties | undefined) => set(() => ({ planProperties: properties })),
    googleUser: undefined,
    setGoogleUser: (googleUser: IGoogleUser) => set(() => ({ googleUser: googleUser })),
    subscription: { active: undefined, expired: undefined, accessDetails: undefined, locked: true },
    setSubscription: (subscription: { active?: any, expired?: any, accessDetails?: any, locked?: boolean }) => set(() => ({ subscription: subscription })),
    billingInfo: undefined,
    setBillingInfo: (billingInfo: any) => set(() => ({ billingInfo: billingInfo })),
    balanceInfo: undefined,
    setBalanceInfo: (balanceInfo: any) => set(() => ({ balanceInfo: balanceInfo })),
    account: undefined,
    setAccount: (account: any) => set(() => ({ account: account })),
    userCoords: undefined,
    setUserCoords: (coords: any) => set(() => ({ userCoords: coords })),
    notificationStatus: false,
    setNotificationStatus: (status: boolean) => set(() => ({ notificationStatus: status })),
    notificationDeepLink: '',
    setNotificationDeepLink: (linkType: string) => set(() => ({ notificationDeepLink: linkType })),
    isPastDue: false,
    setIsPastDue: (isPastDue: boolean) => set(() => ({ isPastDue: isPastDue })),
    pastDueBalance: 0,
    setPastDueBalance: (balance: number) => set(() => ({ pastDueBalance: balance })),
    onBoardingData: undefined,
    setOnBoardingData: (data: any) => set(() => ({ onBoardingData: data })),
})

export default createAccountStore
