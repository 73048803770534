/* eslint-disable @typescript-eslint/no-unused-vars */

import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import RSButton from "../../components/buttons/RSButton"
import { ChangeShareDestination, GetShareDestination } from "../../modules/shareActions"
import RSDrawer from "../../components/layout/RSDrawer"

const ReferralSettings = () => {

    const [formTextShareLink, setFormTextShareLink] = useState('')
    const [currentDestinationLink, setCurrentDestinationLink] = useState('')
    const [displaySuccessPopup, setDisplaySuccessPopup] = useState(false)

    const handleChangeShareDestinationLink = async () => {
        formTextShareLink != '' && ChangeShareDestination(formTextShareLink).then((res) => {
            console.log('>>> ChangeShareDestination', res)
            setDisplaySuccessPopup(true)
        }).catch((err) => {
            console.log('err', err)
        })
    }


    const handleGetShareDestinationLink = async () => {

        await GetShareDestination().then((res) => {
            const sampleRefLink = res.data.result?.link[0]?.referral_link
            const destinationLink = sampleRefLink ? sampleRefLink.split('signup')[0] : ''
            setCurrentDestinationLink(destinationLink)
        }).catch((err) => {
            console.log('err', err)
        })
    }


    useEffect(() => {
        handleGetShareDestinationLink()
    }, [])


    const renderTopConfig = () => <>
        <div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ height: '5rem', backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '0.3rem', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ fontFamily: 'Poppins-Bold', color: 'black', width: '26rem' }}>Destination Link</div>
                <Form.Control
                    value={formTextShareLink}
                    type="text"
                    placeholder={currentDestinationLink}
                    onChange={(e) => setFormTextShareLink(e.target.value)}
                // onBlur={() => handleUpdateSale("subtext")}
                />
                <RSButton secondary sx={{ height: '2.6rem', ml: '0.4rem' }}
                    onClick={handleChangeShareDestinationLink}
                >Change Link</RSButton>
            </div>
        </div>
    </>

const renderSideConfig = () => <>
<div className='half-md-card w-100 card mx-auto p-3 mt-0' style={{ backgroundColor: 'white' }}>
    <div style={{ fontFamily: 'Poppins-Bold', color: 'black', marginLeft: '0.3rem' }}>Destination Link</div>
    <Form.Control
        style={{ marginBottom: '0.3rem' }}
        value={formTextShareLink}
        type="text"
        as="textarea"
        rows={1}
        placeholder={''}
        onChange={(e) => setFormTextShareLink(e.target.value)}
    // onBlur={() => handleUpdateSale("subtext")}
    />
    <RSButton secondary sx={{ mb: '1rem', height: '2.6rem' }}
        onClick={handleChangeShareDestinationLink}
    >Change Link</RSButton>

</div>
</>

    return <Grid container>

        <Grid container xs={12} justifyContent='center'>
					<Box sx={{width: '100%', maxWidth: '600px'}}>
            {renderSideConfig()}
					</Box>
        </Grid>

        <RSDrawer bottom open={displaySuccessPopup} popUpWidth={'600px'} onClose={() => setDisplaySuccessPopup(false)}>
            <div style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <div style={{ marginTop: '2rem', marginBottom: '1rem', fontSize: '1.2rem', width: 'auto', fontWeight: 'bold', textAlign: 'center' }}>Link successfully changed!</div>
            </div>
        </RSDrawer>

    </Grid>
}

export default ReferralSettings
