/* eslint-disable @typescript-eslint/no-unused-vars */
// Main Styles
import '../styles/main.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../modules/chromeCast'
import 'react-calendar/dist/Calendar.css';

// Auth
import SignIn from '../pages/account/SignIn'
import ChoosePlan from '../pages/account/ChoosePlan'
import UserInfo from '../pages/account/UserInfo'
import Account from '../pages/account/Account'
import ForgotPassword from '../pages/account/ForgotPassword'
import ResetPassword from '../pages/account/ResetPassword'

// Cancel
import CancelStep1 from '../pages/account/cancel/Step1'
import CancelStep2 from '../pages/account/cancel/Step2'
import CancelStep4 from '../pages/account/cancel/Step4'
import CancelStep5 from '../pages/account/cancel/Step5'

// Home
import Home from '../pages/Home'
import HomeClone from '../pages/HomeClone'

//Explore
import Explore from '../pages/explore/Explore'

// Webinars
import Webinars from '../pages/webinars/Webinars'
import Webinar from '../pages/webinars/Webinar'

// Programs
import Programs from '../pages/programs/v2/Programs'
// import Mechanix from '../pages/programs/Mechanix'
import Video from '../pages/programs/v2/ProgramWorkouts'

// Guides
import Guides from '../pages/guides/Guides'
import Guide from '../pages/guides/Guide'

// Exercises
import Exercises from '../pages/Exercises'

// Other
import JoinVideo from '../pages/misc/JoinVideo'
// import Favorites from '../pages/misc/Favorites'
import Invite from '../pages/misc/Invite'
// import PhpFrame from '../pages/misc/PhpFrame'
import NotFound from '../pages/misc/NotFound'

// Search Race
import RaceSearch from '../pages/search/RaceSearch'

// Weekly Outlook
//import WeeklyOutlook from '../pages/home/WeeklyOutlook'

// Dependencies
import ScrollToTop from '../hooks/useScrollToTop'
import { FC, Fragment, Suspense, useEffect, useRef, useState } from 'react'
import { RECAPTCHA_SITE_KEY, RECURLY_KEY, RECURLY_PUBLIC_KEY } from '../modules/cliEnvValues'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { Route, Routes } from 'react-router-dom'
import { WEBINAR_DOWNLOADS } from '../modules/values'
import {
	homeLinks,
	webinarLinks,
	programLinks,
	exerciseLinks,
	guideLinks,
	// trainingLinks,
	// trainingFrameRoutes,
} from '../modules/linkInfo'
import GetStartedTrainingPlan from '../pages/training-plan/GetStartedTrainingPlan'
import StepOne from '../pages/training-plan/build/StepOne'
import StepTwo from '../pages/training-plan/build/StepTwo'
import StepThree from '../pages/training-plan/build/StepThree'
import StepFour from '../pages/training-plan/build/StepFour';
import StepRecover from '../pages/training-plan/build/StepRecover';
import YourTrainingJourney from '../pages/training-plan/YourTrainingJourney'
import YourTrainingPlanClone from '../pages/training-plan/YourTrainingPlanClone'
import YourTrainingPlan from '../pages/training-plan/YourTrainingPlan'
import DownloadPlan from '../pages/training-plan/DownloadPlan'
import ApplePay from '../pages/payment/ApplePay';
import ChooseSignUp from '../pages/account/ChooseSignUp';
import { Helmet } from 'react-helmet';
import { RouletteSpinnerOverlay } from 'react-spinner-overlay';
import { IComponentStore } from '../store/createComponentStore';
import useStore from '../store/useStore';
import ExercisesMain from '../pages/exercises/ExercisesMain';
import ExercisePage from '../pages/exercises/ExercisePage';
import Checkout from '../pages/account/checkout/Checkout';
import Favorites from '../pages/favorites/Favorites';
import ResetInfoPage from '../pages/reset/ResetInfoPage';
import AdminPage from '../pages/admin/AdminPage';
import StartSurvey from '../pages/onboarding/StartSurvey';
import QuestionPage from '../pages/onboarding/QuestionPage';
import SuggestedPage from '../pages/onboarding/SuggestedPage';
import DefaultPage from '../pages/onboarding/DefaultPage';
import EndSurvey from '../pages/onboarding/EndSurvey';
import SurveyPopup from '../pages/onboarding/SurveyPopup';
import AnnouncementPopup from '../pages/reset/AnnouncementPopup';
import MidfootProject from '../pages/midfoot-project/MidfootProject';
import WaiverOfLiability from '../pages/misc/WaiverOfLiability';
import TermsOfUse from '../pages/misc/TermsOfUse';
import PrivacyPolicy from '../pages/misc/PrivacyPolicy';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Recover from '../pages/recover/Recover';
import IntegrationsMainPage from '../pages/integrations/IntegrationsMainPage';
import LogInPage from '../pages/onboarding/v2/LogInPage';
// import VimeoPlayer from './misc/VimeoPlayer';
import { IVimeoPlayerStore } from '../store/createVimeoPlayerStore';
import useQueryParams from '../hooks/useQueryParams';
import VideoPlayerPage from '../pages/VideoPlayerPage';
import { IGoogleCastStore } from '../store/createGoogleCastStore';
import useRNBridge from '../hooks/useRNBridge';
import { isMobile, isIOS, isAndroid } from 'react-device-detect'
import GenericModal from './misc/GenericModal';
import AppVersion from '../appversion.json'
import useSWR from 'swr';
import Profile from '../pages/profile/ProfileOld';
import ProfileNew from '../pages/profile/Profile';
import GPTTrainingPlan from '../pages/training-plan/GPTTrainingPlan';
// import SessionCheck from './session/SessionCheck';
import ReturnPage from '../pages/ReturnPage';
import { ICurrentUser } from '../store/createAccountStore';
import AdminPageV2 from '../pages/admin/AdminPageV2';
import { IAppleHealthKitStore } from '../store/createAppleHealthKitStore';
import moment from 'moment';
import { GenerateRefLink, IncrementReferralLinkHits } from '../modules/shareActions';
import { CreateExternalId, getExternalAccount, GetExternalId, UpdateNotificationStatus } from '../modules/accountActions';
import adminDashVars from '../pages/admin/adminDashVars.json';
import { postReq } from '../modules/apiConsume';
import useAppEvents from '../hooks/useAppEvents';
import Loader from './animation/Loader';
// import TestHKData from './TestHKData.json';
// import { CreateAutoSyncActivity } from '../modules/appleHealthKitActions';
//PDF viewer
// import PdfViewer from '../pages/PdfViewer'


/**
 * App Component
 */

const App: FC = () => {

	const { data: fuelProgram } = useSWR(`/programs/20`, { revalidateOnFocus: false, revalidateOnReconnect: false })

	const { isLoading, isSuccess, loadingMsg, setIsMobile, setCurrentAppVersion, currentAppVersion } = useStore((state: IComponentStore) => state)
	const { showAnnouncementPopup, setShowAnnouncementPopup, setLastRNBridgeMessage } = useStore((state: IComponentStore) => state)
	const { vimeoId } = useStore((state: IVimeoPlayerStore) => state)
	const { setDataGenerated, setPermission } = useStore((state: IAppleHealthKitStore) => state)

	const { sendDataToReactNative } = useRNBridge()

	const { setSelectedVideo, setActiveVideo, setCastStatus, selectedVideo, activeVideo, castStatus } = useStore((state: IGoogleCastStore) => state)

	const { requestKey: deviceKey, newVersion, ref, urlKey } = useQueryParams()

	const requestKey = localStorage.getItem('deviceRequestKey') || deviceKey
	const storedUrlKey = localStorage.getItem('urlKey') || urlKey
	const [showUpdateModal, setShowUpdateModal] = useState(false)
	const [invalidScreen, setInvalidScreen] = useState(false)
	const { createEvent } = useAppEvents()

	const hasFetched = useRef(false);
	const hasNotifStatusUpdated = useRef(false);
	const hasSessionEventCreated = useRef(false);
	const hasNotifStatusEventCreated = useRef(false);

	const { setUserCoords, userCoords, currentUser, setNotificationStatus, notificationStatus, setNotificationDeepLink } = useStore((state: ICurrentUser) => state)

	const HEALTHKIT_PERMISSIONS = ["HeartRate", "Workouts", "DistanceWalkingRunning", "BackgroundDelivery", "WorkoutRoute"]

	const handleClosePopup = () => {
		localStorage.setItem("PopupViewed_Fuel", "1")
		setShowAnnouncementPopup()
	}

	const handleUpdateApp = () => {
		setShowUpdateModal(false)
		if (isMobile && isIOS) {
			handleOpenUrl('https://apps.apple.com/app/1507476659')
		}
		else if (isMobile && isAndroid)
			handleOpenUrl('https://play.google.com/store/apps/details?id=com.runsmartonline');
	}

	const handleGetExternalAccount = async () => {
		const localStorageRequestKey = localStorage.getItem('deviceRequestKey')
		const external = await getExternalAccount("AppleAppStore", requestKey || localStorageRequestKey as string)
		return external
	}

	const handleSaveExternalSubscription = async (data: any) => {
		await postReq('/v2/external-subscription', data).then(async () => {
			console.log('External Subscription Saved')
		}).catch((error) => {
			console.log(error)
		})
	}

	const handleOpenUrl = (url: string) => {
		if (isMobile) {
			const dataToSend = {
				requestType: 'open-rs-shop',
				payload: { url: url },
				timestamp: new Date().getTime(),
			}
			sendDataToReactNative(dataToSend);
		} else
			window.open(url, '_blank')
	}

	const handleMessageFromReactNative = async (event: any) => {
		try {
			const dataFromReactNative = JSON.parse(event?.data);
			setLastRNBridgeMessage(event?.data)
			if (dataFromReactNative.requestKey == requestKey)
				if (dataFromReactNative.requestType == 'google-cast-active-icon') {
					if (dataFromReactNative.payload.videoId) {
						setActiveVideo(dataFromReactNative.payload.videoId)
						setCastStatus('playing')
					}
					// else {
					// 	setCastStatus('active')
					// }
				} else if (dataFromReactNative.requestType == 'google-cast-inactive-icon') {
					setCastStatus('inactive')
					setActiveVideo('')
				}
				else if (dataFromReactNative.requestType == 'google-cast-state') {
					if (dataFromReactNative.payload === 'connecting')
						setCastStatus('loading')
					else if (dataFromReactNative.payload === 'connected')
						setCastStatus('active')
					else
						setCastStatus('inactive')
				}
				else if (dataFromReactNative.requestType === 'native-geo-position' && !userCoords) {
					if (dataFromReactNative.payload.coords) {
						setUserCoords(dataFromReactNative?.payload?.coords)
						//alert('Native Geo Position: ' + JSON.stringify(dataFromReactNative.payload.coords))
					}
				}
				else if (dataFromReactNative.requestType === 'native-healthkit-daily-activity') {
					setDataGenerated([])
					if (dataFromReactNative.payload)
						setDataGenerated(dataFromReactNative.payload)
				}
				else if (dataFromReactNative.requestType === 'native-healthkit-permission') {
					if (dataFromReactNative.payload && dataFromReactNative.payload.status === "granted") {
						// alert(JSON.stringify(dataFromReactNative.payload))
						// localStorage.removeItem('healthkit-permission')
						localStorage.setItem('healthkit-permission', JSON.stringify({
							...dataFromReactNative.payload,
							date: moment().format('YYYY-MM-DD HH:mm:ss')
						}))

						setPermission({
							...dataFromReactNative.payload,
							date: moment().format('YYYY-MM-DD HH:mm:ss')
						})
					}
				}
				else if (dataFromReactNative.requestType === 'notification-permission-status') {
					setNotificationStatus(dataFromReactNative.payload)
					localStorage.setItem('notification-status', '1')
				}
				else if (dataFromReactNative.requestType === 'notification-deeplink') {
					setNotificationDeepLink(dataFromReactNative.payload)
				}
				else if (dataFromReactNative.requestType === 'generate-referral-link') {
					if (currentUser?.account_id)
						await GenerateRefLink(currentUser?.account_id as number).then(async (res) => {
							await createEvent(currentUser?.account_id as number, "generate-referral-link", JSON.stringify({ location: "share_image" }))
							const refLink = res.data.result.referral_link
							const dataToSend = {
								requestType: 'referral-link',
								payload: refLink,
								timestamp: new Date().getTime(),
							}
							sendDataToReactNative(dataToSend)
						}).catch(err => {
							alert(err)
							const dataToSend = {
								requestType: 'referral-link',
								payload: "ERROR",
								timestamp: new Date().getTime(),
							}
							sendDataToReactNative(dataToSend)
						})
				}
				else if (dataFromReactNative.requestType === "set-new-features") {
					if (dataFromReactNative.payload) {
						const newFeatures = dataFromReactNative.payload || []
						localStorage.setItem('new-features', JSON.stringify(newFeatures))
					}
				}
				else if (dataFromReactNative.requestType === "create-event-log") {
					if (dataFromReactNative.payload && currentUser?.account_id) {
						if (dataFromReactNative.payload.eventType !== "generate-referral-link")
							await createEvent(currentUser?.account_id as number, dataFromReactNative.payload.eventType)
					}
				}
				else if (dataFromReactNative.requestType === "google-success-sub-mobileapp") {
					const externalData = { user_id: currentUser?.account_id, type: 'google', purchase_token: dataFromReactNative.payload, is_expired: 0, app_account_token: requestKey }
					await handleSaveExternalSubscription(externalData)
				}
				else if (dataFromReactNative.requestType === "apple-success-sub-mobileapp") {
					const externalData = { user_id: currentUser?.account_id, type: 'apple', purchase_token: dataFromReactNative.payload, is_expired: 0, app_account_token: requestKey }
					await handleSaveExternalSubscription(externalData)
				}
		} catch (err) {
			// console.log(err)
		}
	};

	useEffect(() => {
		if (requestKey) {
			window.addEventListener('message', handleMessageFromReactNative);
		}

		return () => {
			window.removeEventListener('message', handleMessageFromReactNative);
		};
	}, [requestKey, userCoords, currentUser]);

	useEffect(() => {
		if (vimeoId)
			setSelectedVideo(vimeoId)
	}, [vimeoId])


	useEffect(() => {
		if (requestKey) {

			const dataToSend = {
				requestType: 'notification-permission',
				payload: {
					permissionCheck: true
				},
				timestamp: new Date().getTime(),
			}
			sendDataToReactNative(dataToSend)

			setIsMobile(true)
		}

		if (newVersion) {
			setCurrentAppVersion(newVersion)
		}
	}, [requestKey, newVersion])

	useEffect(() => {
		if (requestKey && currentAppVersion && currentAppVersion !== AppVersion.value && AppVersion.autoUpdate) {
			const shouldUpdate = isMobile && isIOS && AppVersion.devices.includes('ios') || isMobile && isAndroid && AppVersion.devices.includes('android')
			if (shouldUpdate)
				setShowUpdateModal(true)
		}
		else
			setShowUpdateModal(false)
	}, [currentAppVersion, requestKey])

	useEffect(() => {
		if (!hasSessionEventCreated.current) {
			if (currentUser?.account_id) {
				// CreateEvent({
				// 	user_id: currentUser?.account_id as number || 0,
				// 	event_type: "SESSION_START",
				// }).then(res => {
				// 	console.log('Event Log Created', res)
				// }).catch(err => {
				// 	// alert(err)
				// 	console.log(err)
				// })

				createEvent(currentUser?.account_id as number, "SESSION_START", typeof window.navigator === "undefined" ? "" : navigator.userAgent).then(res => {
					// console.log('SESSION STARTED', res)
				}).catch(err => {
					console.log(err)
				})
				hasSessionEventCreated.current = true
			}
		}
	}, [currentUser])

	useEffect(() => {
		const notifStausReady = localStorage.getItem('notification-status')
		if (!hasNotifStatusEventCreated.current) {
			if (currentUser?.account_id && notifStausReady === "1") {
				createEvent(currentUser?.account_id as number, notificationStatus ? "NOTIFICATIONS_ON" : "NOTIFICATIONS_OFF", typeof window.navigator === "undefined" ? "" : navigator.userAgent)
				hasNotifStatusEventCreated.current = true
				setTimeout(() => {
					hasNotifStatusEventCreated.current = false
				}, 2000)
			}
		}
	}, [notificationStatus])

	useEffect(() => {
		if (requestKey && currentUser?.account_id) {
			try {
				const appleHealthPermission = JSON.parse(localStorage.getItem('healthkit-permission') || '{}')
				if (appleHealthPermission && appleHealthPermission.status === 'granted') {
					const currentPermissions = appleHealthPermission?.permissions
					if (currentPermissions && currentPermissions.length > 0) {
						const missingPermissions = HEALTHKIT_PERMISSIONS?.filter(permission => !currentPermissions.includes(permission))
						if (missingPermissions.length > 0) {
							// alert('Missing Permissions: ' + missingPermissions.join(', '))
							const dataToSend = {
								requestType: 'healthkit-request-permission',
								payload: {
									user_id: currentUser?.account_id,
								},
								timestamp: new Date().getTime(),
							}

							sendDataToReactNative(dataToSend)
						}
					}
					else {
						const dataToSend = {
							requestType: 'healthkit-request-permission',
							payload: {
								user_id: currentUser?.account_id,
							},
							timestamp: new Date().getTime(),
						}

						sendDataToReactNative(dataToSend)
					}
				}
			} catch (err) {
				// console.log(err)
			}
		}
	}, [requestKey, currentUser])

	useEffect(() => {
		const dataToSend = {
			requestType: 'cast-show-bottom-menu',
			payload: true,
			timestamp: new Date().getTime(),
		}

		sendDataToReactNative(dataToSend);
	}, [])


	useEffect(() => {
		if (ref) {
			localStorage.setItem('referral_code', ref)
			IncrementReferralLinkHits(ref)
		}
	}, [ref])


	useEffect(() => {
		const windowLocation = window.location.href
		if (windowLocation.includes('code') && windowLocation.includes('state')) {
			const params = windowLocation.split('?')[1].split('&')
			const code = params[0].split('=')[1]
			const state = params[1].split('=')[1]
			if (code && state) {
				const tempUrl = `https://app.runsmartonline.com/#/profile?code=${code}&state=${state}`
				window.location.assign(tempUrl)
			}
		}

	}, [window.location.href])

	useEffect(() => {
		if (requestKey && currentUser?.account_id) {
			const data = {
				user_id: currentUser?.account_id,
				device: isMobile && isIOS ? 'ios' : isMobile && isAndroid ? 'android' : 'web',
				external_id: requestKey,
			}

			if (!hasFetched.current) {

				if (isMobile && isIOS) {
					handleGetExternalAccount().then((res) => {
						console.log(res)
					}).catch((err) => {
						console.log(err)
					})
				}

				GetExternalId(requestKey).then((res) => {
					if (res && res.data.result.length === 0) {
						CreateExternalId(data).then((res) => {
							return res
						}).catch((err) => {
							console.log(err)
						})
					}
				}).catch((err) => {
					console.log(err)
				})
				hasFetched.current = true;
			}
		}
	}, [requestKey, currentUser, isMobile, isIOS, isAndroid, hasFetched])

	useEffect(() => {
		if (currentUser && currentUser?.account_id && requestKey) {
			if (notificationStatus !== null && !hasNotifStatusUpdated.current) {
				UpdateNotificationStatus(requestKey, notificationStatus ? 1 : 0)
				hasNotifStatusUpdated.current = true
			}
		}
	}, [notificationStatus, currentUser, requestKey])

	useEffect(() => {
		if (urlKey && !requestKey) {
			localStorage.setItem('urlKey', urlKey)
		}
	}, [urlKey, requestKey])

	useEffect(() => {
		if (adminDashVars.SECURE_URL && !requestKey) {
			if (storedUrlKey && storedUrlKey !== adminDashVars.URL_SECURE_KEY || !storedUrlKey) {
				window.location.assign('https://app.runsmartonline.com')
			}
		}
	}, [storedUrlKey, adminDashVars, requestKey])

	// useEffect(() => {

	// 	if (currentUser && currentUser?.account_id) {
	// 		const data = {
	// 			user_id: currentUser?.account_id,
	// 			device_id: currentUser?.account_id + "-TEST",
	// 			activity: JSON.stringify(TestHKData),
	// 			activity_date: TestHKData[0].dateParam,
	// 			raw_data: ''
	// 		}
	// 		if (!hasFetchedTest.current) {
	// 			CreateAutoSyncActivity(data).then((res) => {
	// 				console.log(res)
	// 			})
	// 			setDataGenerated(TestHKData)
	// 			hasFetchedTest.current = true;
	// 		}
	// 	}
	// }, [currentUser, hasFetchedTest])

	// Render
	// console.log('App Rendered')
	return (
		// <HashRouter>
		<>
			{invalidScreen && <ReturnPage />}

			{/* <SessionCheck fallback={sessionFallback} setInvalidScreen={setInvalidScreen} return={true}> */}
			<ScrollToTop />
			{/* Recurly Provider */}
			<RecurlyProvider publicKey={RECURLY_KEY} />
			<Helmet>
				<script src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" defer></script>
				<script src="https://kit.fontawesome.com/95543b35f3.js" crossOrigin="anonymous"></script>
				{/* <script src="impact.js" defer></script> */}
			</Helmet>
			{/* </SessionCheck> */}

			{/* App Root */}
			<div id="app-root">

				{/* <SessionCheck fallback={sessionFallback} setInvalidScreen={setInvalidScreen} return={false}> */}

				<GenericModal
					type='update-app'
					show={showUpdateModal}
					title="Update RunSmart"
					text={'A new version of the app is now available for download.'}
					setShow={setShowUpdateModal}
					keyboard={false}
					backdrop="static"
					button1Action={handleUpdateApp}
					button1Text={'Update'}
					btnFW={true}
				// button2Action={() => setShowUpdateModal(false)}
				// button2Text='Cancel'
				/>

				<SurveyPopup showPopup />
				<AnnouncementPopup
					program={fuelProgram?.data?.result[0] || undefined}
					showPopup={showAnnouncementPopup}
					closePopup={handleClosePopup}
				/>
				<RouletteSpinnerOverlay zIndex={2000} loading={isLoading || isSuccess} color={isLoading ? '#322' : 'none'} size={50} message={
					(<span className='text-center' style={{ marginTop: `${isLoading && !loadingMsg ? '-90px' : isLoading && loadingMsg ? '-180px' : '-20px'}` }}>
						{loadingMsg ? (
							<p className='loading-msg'>{isLoading ? loadingMsg : undefined}</p>
						) : (
							<h4>{isLoading ? 'PLEASE WAIT' : 'SUCCESS'}</h4>
						)}
						{isSuccess ? (<img style={{ maxWidth: '60px' }} src="images/icons/correct.png" />) : undefined}
					</span>)} />
				{/* Routes */}
				<Suspense fallback={<Loader active />}>
					<Routes>
						{/* Auth */}
						<Route path="/login" element={
							<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
								<SignIn />
							</GoogleReCaptchaProvider>
						} />
						<Route path="/choose-plan" element={
							<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
								<Elements>
									<Checkout />
								</Elements>
							</RecurlyProvider>
						} />
						<Route path="/signup-email" element={
							<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
								<SignIn isSignUp />
							</GoogleReCaptchaProvider>
						} />
						<Route path="/admin-dashboard" element={<AdminPage />} />
						<Route path="/admin-dash" element={<AdminPageV2 />} />
						<Route path="/start" element={<StartSurvey />} />
						<Route path="/end" element={<EndSurvey />} />
						<Route path="/onboarding-default" element={<DefaultPage />} />
						<Route path="/suggested-programs" element={<SuggestedPage />} />
						<Route path="/onboarding-survey/questions" element={<QuestionPage />} />
						<Route path="/runsmart-reset/info" element={<ResetInfoPage />} />
						<Route path="/user-info" element={<UserInfo />} />
						<Route path="/choose-plan-old" element={<ChoosePlan />} />
						{/* <Route path="/my-account" element={<Account />} /> */}
						<Route path="/renew" element={<Account isRenew />} />
						<Route path="/update-billing" element={<Account isBilling />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset-password" element={<ResetPassword />} />
						{/* <Route path="/signup" element={<ChooseSignUp isSignUp />} /> */}
						<Route path="/signup" element={<LogInPage />} />
						<Route path="/join-strength" element={<ChooseSignUp isSignUp pageType='strength' />} />
						<Route path="/waiver-of-liability" element={<WaiverOfLiability />} />
						<Route path="/terms-of-use" element={<TermsOfUse />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />

						{/* <Route path="/apple-pay-success" element={<ApplePaySuccess />} /> */}
						<Route path="/choose-plan-apple" element={
							<RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
								<Elements>
									<ApplePay />
								</Elements>
							</RecurlyProvider>
						} />

						{/* Cancel */}
						<Route path="/cancel/step-1" element={<CancelStep1 />} />
						<Route path="/cancel/step-2" element={<CancelStep2 />} />
						<Route path="/cancel/step-4" element={<CancelStep4 />} />
						<Route path="/cancel/step-5" element={<CancelStep5 />} />

						{/* Program Splash */}
						{/* <Route path="/programs/intro" element={<ProgramSplash />} /> */}

						{/* Home */}
						{homeLinks.map((path, index) => (
							<Route key={index} path={path} element={<Home />} />
							// <Route key={index} path={path} element={<RaceSearch />} />
						))}

						<Route path="/home-v1" element={<HomeClone />} />

						{/* Explore */}
						{/*<Route path="/weekly-outlook" element={<WeeklyOutlook />} />*}

					{/* Explore */}
						<Route path="/explore" element={<Explore />} />

						{/* Profile */}
						<Route path="/profile-new" element={<Profile />} />
						<Route path="/profile" element={<ProfileNew />} />

						{/* Webinars */}
						<Route path="/webinars" element={<Webinars />} />
						{Object.keys(webinarLinks).map((key, index) => {
							const webinarType = key as keyof typeof webinarLinks
							return (
								<Route
									key={index}
									path={webinarLinks[webinarType].substring(2)}
									element={
										<Webinar
											webinarType={webinarType}
											noDownloads={WEBINAR_DOWNLOADS[webinarType] ? false : true}
										/>
									}
								/>
							)
						})}
						{/* <Route path="/pdf-viewer" element={<PdfViewer />} /> */}
						{/* Programs */}
						<Route path="/programs" element={<Programs />} />
						<Route path="/programs/midfoot" element={<Programs />} />

						{Object.keys(programLinks).map((key) => {
							const programType = key as keyof typeof programLinks
							const isMechanix = programType == 'Mechanix'
							const isMidfoot = programType == 'Midfoot'
							const isRecover = programType == 'Recover'
							const basePath = programLinks[programType].substring(2)
							const videoPath = `${basePath}/:videoId`
							return (
								<Fragment key={key}>
									{/* {isMechanix ? (
									<>
										<Route path={basePath} element={<Mechanix />} />
										<Route path={videoPath} element={<Mechanix />} />
									</>
								) : */}
									{
										isMidfoot ? (
											<>
												<Route path={basePath} element={<MidfootProject />} />
												<Route path={videoPath} element={<MidfootProject />} />
											</>
										) : isRecover ? (
											<>
												<Route path={basePath} element={<Recover />} />
												<Route path={videoPath} element={<Recover />} />
											</>
										)
											: (
												<>
													<Route
														path={basePath}
														element={<Video programType={programType} />}
													/>
													<Route
														path={videoPath}
														element={<Video programType={programType} />}
													/>
												</>
											)
									}
								</Fragment>
							)
						})}

						{/* Guides */}
						<Route path="/guides" element={<Guides />} />
						{Object.keys(guideLinks).map((key) => {
							const guideType = key as keyof typeof guideLinks
							const basePath = guideLinks[guideType].substring(2)
							const videoPath = `${basePath}/:videoId`
							return (
								<Fragment key={key}>
									<Route
										path={basePath}
										element={<Guide guideType={guideType} />}
									/>
									<Route
										path={videoPath}
										element={<Guide guideType={guideType} />}
									/>
								</Fragment>
							)
						})}

						{/* Exercises */}

						<Route
							path="/exercises"
							element={<ExercisesMain />}
						/>
						<Route
							path={`/exercises/:videoId`}
							element={<ExercisePage />}
						/>

						{Object.keys(exerciseLinks).map((key) => {
							const exerciseType = key as keyof typeof exerciseLinks
							const basePath = exerciseLinks[exerciseType].substring(2)
							const videoPath = `${basePath}/:videoId`
							return (
								<Fragment key={key}>
									<Route
										path={basePath}
										element={<Exercises exerciseType={exerciseType} />}
									/>
									<Route
										path={videoPath}
										element={<Exercises exerciseType={exerciseType} />}
									/>
								</Fragment>
							)
						})}

						{/* Training */}
						<Route path="/training-plan-journey/view" element={<YourTrainingJourney />} />
						<Route path="/training-plan/view" element={<YourTrainingPlanClone />} />
						<Route path="/training-plan-v1/view" element={<YourTrainingPlan />} />
						<Route path="/training-plan/start" element={<GetStartedTrainingPlan />} />
						<Route path="/training-plan/build/step-1" element={<StepOne />} />
						<Route path="/training-plan/build/step-2" element={<StepTwo />} />
						<Route path="/training-plan/build/step-3" element={<StepThree />} />
						<Route path="/training-plan/build/step-4" element={<StepFour />} />
						<Route path="/training-plan/build/step-recover" element={<StepRecover />} />
						<Route path="/training-plan/download" element={<DownloadPlan />} />

						{/* Join Video */}
						<Route path="/join-video" element={<JoinVideo />} />
						{Object.keys(exerciseLinks).map((key) => {
							const exerciseType = key as keyof typeof exerciseLinks
							const basePath = exerciseLinks[exerciseType].substring(2)
							const videoPath = `${basePath}/:videoId`
							return (
								<Fragment key={key}>
									<Route
										path={basePath}
										element={<Exercises exerciseType={exerciseType} />}
									/>
									<Route
										path={videoPath}
										element={<Exercises exerciseType={exerciseType} />}
									/>
								</Fragment>
							)
						})}

						{/* Training */}
						<Route path="/training-plan-journey/view" element={<YourTrainingJourney />} />
						<Route path="/training-plan/view" element={<YourTrainingPlanClone />} />
						<Route path="/training-plan-v3/view" element={<GPTTrainingPlan />} />
						<Route path="/training-plan-v1/view" element={<YourTrainingPlan />} />
						<Route path="/training-plan/start" element={<GetStartedTrainingPlan />} />
						<Route path="/training-plan/build/step-1" element={<StepOne />} />
						<Route path="/training-plan/build/step-2" element={<StepTwo />} />
						<Route path="/training-plan/build/step-3" element={<StepThree />} />
						<Route path="/training-plan/build/step-4" element={<StepFour />} />
						<Route path="/training-plan/build/step-recover" element={<StepRecover />} />
						<Route path="/training-plan/download" element={<DownloadPlan />} />

						{/* Join Video */}
						<Route path="/join-video" element={<JoinVideo />} />

						{/* Favorites */}
						<Route path="/favorites" element={<Favorites />} />

						{/* Invites */}
						<Route path="/invites" element={<Invite />} />

						{/* Fallback */}
						<Route path="/*" element={<NotFound />} />

						<Route path="/video-player" element={<VideoPlayerPage />} />

						{/* Integrations */}
						<Route path="/integrations" element={<IntegrationsMainPage />} />

						{/* Search Race */}
						<Route path="/search-race" element={<RaceSearch />} />
					</Routes>
				</Suspense>
				{/* </SessionCheck> */}
			</div>
		</>
	)
}

export default App
