/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef, ComponentClass } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from "framer-motion";

import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Arrow from '@mui/icons-material/ArrowRightAltRounded';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RSButton1 } from '../../../components/buttons'
import RSNavCont from '../../../components/layout/RSNavCont'
import { H1, SH2, P2 } from '../../../components/text/RSTypography'
import { signOut } from '../../../modules/accountActions'
import useIsMobileScreen from '../../../hooks/useIsMobileScreen'

const theme = createTheme({
	palette: {
		primary: {
			main: '#9FCD2B',
			light: '#fff',
			dark: '#fff',
			contrastText: '#010101',
		},
	},
});
const variants = {
	enter: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		opacity: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			opacity: 0,
			x: direction > 0 ? "-100%" : "100%"
		};
	}
};

const useProgressIndicator = (props: any) => {

	const { data, complete, doOnNext, goTo, addedProps = {} } = props
	const [bodyData, setBodyData] = useState<any>({})
	const [step, setStep] = useState(data?.[0]?.skip ? 1 : 0)
	const [subStep, setSubStep] = useState(data?.[step]?.desc ? 0 : 1)
	const [initStep, setInitStep] = useState(data?.[0]?.skip ? 1 : 0)
	const [initSubStep, setInitSubStep] = useState(data?.[step]?.desc ? 0 : 1)
	const [endStep, setEndStep] = useState('')
	const [enableBackNext, setEnableBackNext] = useState(false)
	const [hideButtons, setHideButtons] = useState(false)
	const [nextAvailable, setNextAvailable] = useState(true)
	const [allowSkip, setAllowSkip] = useState(false)
	const [logOutConfirmation, setLogOutConfirmation] = useState<any>(false)
	const [isLoading, setIsLoading] = useState(false)
	const stepLength = data?.length || 0
	const subL = data?.[step]?.progressData?.length || 0
	const focusPage = data?.[step]?.focus
	const hideNext = data?.[step]?.hideNext
	const subStepLength = subL + 1
	const progress = subStepLength ? (100 / subStepLength) * (subStep) : 10 * (subStep)
	const prevActiveSubStep = useRef<any>(0)
	const animationRef = useRef<any>({ current: '' });
	const navigate = useNavigate()
	const isMobile = useIsMobileScreen();
	const btnHeight = window.innerHeight < 850 ? window.innerHeight : 850

	const increaseProgress = () => {
		data && [...data]?.[step]?.complete === subStep + 1 && complete(bodyData)
		if (stepLength && (step + 1 !== stepLength) && !data?.[step]?.end) {
			const addSubStep = subStep + 1
			if (subStepLength > addSubStep) {
				prevActiveSubStep['current'] = subStep
				setInitSubStep(addSubStep)
			} else {
				const addStep = step + 1
				if (stepLength >= addStep)
					setInitStep(addStep)

				prevActiveSubStep['current'] = -1
				setInitSubStep(data?.[step+1]?.desc ? 0 : 1)
			}
			setEnableBackNext(false)
		} else {
			setEndStep('end')
			data?.[stepLength - 1]?.end?.(bodyData)
		}
	}

	const decreaseProgress = () => {
		if (stepLength) {
			const subtractSubStep = subStep - 1
			if (subStep === (data?.[step]?.desc ? 0 : 1)) {
				const subtractStep = step - 1
				const prevSubL = data?.[subtractStep]?.progressData?.length
				const prevSubStepLength = prevSubL ? prevSubL : 9
				setInitStep(subtractStep)
				prevActiveSubStep['current'] = prevSubStepLength + 1
				setInitSubStep(prevSubStepLength)
			} else {
				prevActiveSubStep['current'] = subStep
				setInitSubStep(subtractSubStep)
			}
			setEndStep('')
			setEnableBackNext(false)
		}
	}

	const nextAvailability = (n: boolean) => {
		n !== nextAvailable && setNextAvailable(n)
	}

	const renderLogoutModal = () => {
		return <Modal
			open={logOutConfirmation ? true : false}
			onClose={() => setLogOutConfirmation(false)}
		>
			<Box className='bg-main' sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', position: 'absolute', borderRadius: '5px', pt: '10px', top: '50%', left: '50%', width: '80%', backgroundColor: 'white', transform: 'translate(-50%, -50%)' }}>
				<Grid container xs={12} sx={{ px: '20px', fontFamily: 'Poppins', fontSize: '16px', borderBottom: '1px solid rgba(0, 0, 0, 0.176)' }} justifyContent='flex-start'>
					Log Out
				</Grid>
				<Grid container xs={12} sx={{ px: '20px', pt: '10px', fontFamily: 'Poppins-Light', fontSize: '16px' }} justifyContent='flex-start'>
					Are you sure you want to log out?
				</Grid>
				<Grid container xs={12} sx={{ px: '10px', fontFamily: 'Poppins', fontSize: '16px', pt: '25px', pb: '15px' }} justifyContent='flex-end' alignItems='center'>
					<Grid container xs={4} justifyContent='center' alignItems='center' >
						<RSButton1 onClick={() => setLogOutConfirmation(false)} secondary sx={{ mr: '2px' }}>No</RSButton1>
					</Grid>
					<Grid container xs={4} justifyContent='center' alignItems='center'>
						<RSButton1 onClick={signOut} sx={{ width: '100%', ml: '2px' }}>Yes</RSButton1>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	}

	useEffect(() => {
		if (!enableBackNext) {
			setTimeout(() => {
				setEnableBackNext(true)
			}, 1000)
		}
	}, [enableBackNext])

	useEffect(() => {
		if (goTo?.step && goTo?.subStep) {
			setInitStep(goTo.step)
			setInitSubStep(goTo.subStep)
		}
	}, [goTo])

	useEffect(() => {
		if (data?.[step]?.skip) {
			const nStep = step + 1
			nStep < stepLength && setInitStep(nStep)
		}
	}, [data?.[step]?.skip])

	useEffect(() => {
		doOnNext?.()
	}, [subStep])

	useEffect(() => {
		setStep(initStep)
	}, [initStep])

	useEffect(() => {
		setSubStep(initSubStep)
	}, [initSubStep])

	useEffect(() => {
		setAllowSkip(allowSkip)
	}, [allowSkip])

	const renderUI = () => {
		const progressList = [...data]
		// const noProgressIndicator = progressList?.[step]?.noProgressIndicator?.includes(subStep)
		const noProgressIndicator = true
		const noBackButton = true
		const last: any = progressList.splice(progressList?.length - 1, 1) || [];
		const BodyForm = progressList?.[step]?.progressData?.[subStep - 1]
		const IntroForm = data?.[step]?.intro
		const hasBack = (step && !(data?.[step]?.noBack?.includes(subStep))) && (data?.length - 1 >= step) && !hideButtons ? true : false
		const hasNext = (!(data?.[step]?.noNext?.indexOf(subStep) > -1) || !progress) && !hideButtons
		const isEnd = step === stepLength - 1 && subStep === subStepLength - 1
		return <RSNavCont plain disableHome hideHeader={false} centerHeader={isMobile} hideFooter backClick={isMobile && hasBack && decreaseProgress} backClickDisabled={!enableBackNext} loaderProps={{active: isLoading}}>
			<Grid container sx={{ position: 'relative', flexDirection: 'column', height: isMobile || (!isMobile && !subStep) ? '100%' : 'auto', width: '100%', overflowX: 'hidden', pt: hideButtons ? 0 : 4, minHeight: isMobile ? 0 : `${btnHeight}px` }} justifyContent='space-between'>
				<ThemeProvider theme={theme}>
					{renderLogoutModal()}
					{step === -1 && <Box onClick={() => setLogOutConfirmation(true)} sx={{ cursor: 'pointer', height: '10px', width: '10px', position: 'absolute', top: 5, right: 15 }}><i className="fa-regular fa-arrow-right-from-bracket"></i></Box>}
					{subStep ? <Grid container sx={{ width: '100%' }}>
						{!noProgressIndicator && <Grid container xs={12} sx={{ height: '40px', mb: '30px', px: 3 }}>
							{progressList?.map((d: any, di: number) => {
								return <Grid key={'data' + di} container sx={{ width: `calc(${100 / progressList.length}% - ${40 / progressList.length}px)` }} justifyContent='center' alignItems='center'>
									<Box sx={{ fontSize: '20px', height: '40px', width: '40px', borderRadius: '100px', backgroundColor: `rgba(159,205,43,${step < di ? 0.3 : 1})`, color: 'black', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										{d.icon}
									</Box>
									<Box sx={{ width: 'calc(100% - 40px)', color: '#9fcd2b' }}>
										<LinearProgress variant="determinate" color='inherit' value={di === step ? progress : step < di ? 0 : 100} />
									</Box>
								</Grid>
							})}
							{last?.map((d: any, di: number) => {
								return <Box key={'last' + di} sx={{ fontSize: '20px', height: '40px', width: '40px', borderRadius: '100px', backgroundColor: `rgba(159,205,43,${step === progressList?.length ? 1 : 0.3})`, color: 'black', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									{d.icon}
								</Box>
							})
							}
						</Grid>}

						{progressList?.[step]?.fixedTitle && <Grid container sx={{ pb: 2, px: 3 }} justifyContent='flex-start' alignItems='center'>
							<H1 size={5} >
								{progressList?.[step]?.title}
							</H1>
						</Grid>}

						{!isMobile && hasBack && <Grid container xs={12} sx={{px: 2, pb: 3}}>
							<Button disabled={!enableBackNext} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: '#010101', p: 0, minWidth: 0 }} onClick={() => decreaseProgress()} >
								<ArrowBack />
								<SH2 size={5} sx={{pl: '5px'}}>Back</SH2>
							</Button>
						</Grid>}

						<AnimatePresence mode='wait' initial={true}>
							<motion.div
								key={`step ${step} ${subStep}`}
								custom={initSubStep - prevActiveSubStep?.current}
								variants={variants}
								initial="enter"
								animate="center"
								exit='exit'
								transition={{ duration: 0.2 }}
								style={{ width: '100%', height: 'fit-content', paddingBottom: '150px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) calc(100% - 160px), transparent calc(100% - 100px))' }}
							>
								<Grid container xs={12} justifyContent='center' alignItems='center' sx={{px: 3}}>
									{BodyForm && <BodyForm done={increaseProgress} bodyData={bodyData} setBodyData={setBodyData} nextAvailability={nextAvailability} setAllowSkip={setAllowSkip} {...addedProps}/>}
								</Grid>
							</motion.div>
						</AnimatePresence>
					</Grid> : <AnimatePresence mode='wait' initial={true}>
						<motion.div
							key={`step ${step} ${endStep}`}
							custom={endStep ? 0 : 1}
							variants={variants}
							initial="enter"
							animate="center"
							exit='exit'
							transition={{ duration: 0.2 }}
							style={{ height: hideButtons ? 'auto' : '100%', width: '100%' }}
						>
							{IntroForm ? <Box sx={{paddingBottom: isMobile ? '150px' : 0, width: '100%', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) calc(100% - 160px), transparent calc(100% - 100px))'}}>
									<IntroForm bodyData={bodyData} focusTrain={focusPage} setHideButtons={setHideButtons} onboarding={true} setIsLoading={setIsLoading}/>
								</Box> : <Box sx={{height: '100%', width: '100%', maxHeight: '850px', position: 'relative'}}>
									<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'absolute', top: '30%', transform: 'translateY(-80%)', justifyContent: 'center', alignItems: 'center', paddingBottom: '20px', background: 'linear-gradient(180deg, rgba(246,246,246,1) 0%, rgba(246,246,246,0.8) calc(100% - 20px), transparent 100%)', mt: '20px' }}>
									<Box sx={{ height: '60px', width: '60px', borderRadius: '100px', fontSize: '35px', backgroundColor: '#9fcd2b', color: 'black', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										{data?.[step]?.icon}
									</Box>
									<H1 size={2} sx={{ pb: 1, pt: 2 }}>
										{data?.[step]?.title}
									</H1>
									<P2 desc sx={{ maxWidth: '250px', textAlign: 'center' }}>
										{data?.[step]?.desc}
									</P2>
								</Box>
							</Box>}
						</motion.div>
					</AnimatePresence>}
					{((hasBack && !noBackButton) || (hasNext && !hideNext)) && <Box className='default-mw' sx={{ position: data?.[step]?.navStatic?.indexOf(subStep) > -1 || (!isMobile && subStep) ? 'relative' : 'fixed', width: '100%', py: 3, top: !isMobile && !subStep ? btnHeight : 'unset', bottom: isMobile ? 0 : 'unset', left: !isMobile && !subStep ? '50%' : 0, transform: !isMobile && !subStep ? 'translateX(-50%)' : 'none', px: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
						<AnimatePresence mode='wait' initial={false}>
							<Grid container xs={12} sx={{ height: '50px'}} justifyContent='space-between'>
								<Grid container xs={5.8} sx={{ height: '100%' }} justifyContent='flex-start'>
									{hasBack && !noBackButton ? <RSButton1 secondary disabled={!enableBackNext} onClick={() => decreaseProgress()} sx={{ height: '50px', maxWidth: '150px', minWidth: '0px', width: 'inherit' }}>
										<Grid container sx={{ ml: '-5px' }} justifyContent='center' alignItems='center'>
											<i className="fa-regular fa-chevron-left" style={{ marginRight: '10px', fontSize: '20px' }} />
											<Box>Back</Box>
										</Grid>
									</RSButton1> : ''}
								</Grid>
								<Grid container xs={5.8} sx={{ height: '100%', position: 'relative' }} justifyContent='flex-end'>
									{hasNext && !hideNext && <RSButton1 disabled={(!nextAvailable && (subStep !== 0) && !allowSkip) || !enableBackNext} onClick={() => (allowSkip && !nextAvailable) ? navigate('/') : increaseProgress()} sx={{ height: '50px', maxWidth: '150px', minWidth: '0px', width: 'inherit' }}>
										<Grid container sx={{ mr: '-5px' }} justifyContent='center' alignItems='center'>
											<Box>{allowSkip && !nextAvailable ? 'Do Later' : 'Next'}</Box>
											<i className="fa-regular fa-chevron-right" style={{ marginLeft: '10px', fontSize: '20px' }} />
										</Grid>
									</RSButton1>}
								</Grid>
							</Grid>
						</AnimatePresence>
					</Box>}
				</ThemeProvider>
			</Grid>
		</RSNavCont >
	}

	return {
		progressUI: renderUI(),
		increaseProgress,
		decreaseProgress,
		setBodyData,
		bodyData,
		step
	}
}

export default useProgressIndicator
