/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef, SetStateAction } from 'react';
import Slider from 'react-slick'
import moment from 'moment';
import { strtotime } from 'locutus/php/datetime'
import is_numeric from 'locutus/php/var/is_numeric'

import { Reorder, AnimatePresence, motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import WeekCalendar from './WeekCalendar';
import WeekSwipeBar from './WeekSwipeBar';
import { DAYS, DAYS_ALT } from '../../pages/training-plan/build/DaysOptions';
import { CreateChangeLog, CreateUserOverride, createChangeLogEditDailies, getGarminSyncData, removeScheduledWorkout } from '../../modules/trainingPlanActions';
import useStore from '../../store/useStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import { ICurrentUser } from '../../store/createAccountStore'
import { RaceResult, UserOverrideRecord } from '../../api/v2/types';
import { titleCase } from '../../modules/miscUtils'
import RSButton from '../../components/buttons/RSButton'
import RSDrawer from '../../components/layout/RSDrawer'
import RSUpgrade from '../../components/misc/RSUpgrade'
import Loader from '../../components/animation/Loader'
import useIsMobileScreen from '../../hooks/useIsMobileScreen';
import useRNBridge from '../../hooks/useRNBridge';
import { IAppleHealthKitStore } from '../../store/createAppleHealthKitStore';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { UpdateAItrainingPlan } from '../../modules/aiTrainingPlanActions';

const MotionBox = motion(Box)

const variants = {
	enter: (direction: number) => {
		return {
			x: direction === 0 ? 0 : direction > 0 ? "100%" : "-100%"
		};
	},
	center: {
		zIndex: 1,
		x: 0
	},
	exit: (direction: number) => {
		return {
			zIndex: 0,
			x: direction < 0 ? "100%" : "-100%"
		};
	}
};

const sampleCrossTrain = ['Yoga', 'Pilates', 'Swim', 'Bike', 'Row', 'Strength', 'Stretch']

const WeekSelector = (props: any) => {

	const isMobile = useIsMobileScreen();
	const { sendDataToReactNative } = useRNBridge()

	const { userOverrides, setCurrentDayActivity } = useStore((state: ITrainingPlanStore) => state)

	const { permission: appleHealthPermission } = useStore((state: IAppleHealthKitStore) => state)
	const deviceId = localStorage.getItem('deviceRequestKey')
	const { mobileType } = useDeviceDetect()

	const isNativeIOS = deviceId && mobileType.toLowerCase() === 'ios'

	const { calendarData, selectedWeek, setSelectedWeek, weekDays, marathonDailyValues, userInfo,
		getDailyActivityDisplay, selectedRace, selectedDay, setSelectedDay, loadingValues: parentLoading,
		getUserOverrides, getUserRestComments, dayData, allUserNotes, editWeek, setEditWeek, getColorCode,
		setIsForward, weekLoading, raceResults, calendarOpen, setCalendarOpen,
		updatedDBdayArrangement, setUpdatedDBdayArrangement, noRefetch, setNoRefetch, setLoadingNewData, rawUserInfo, accountInfo } = props
	const [daysOfWeek, setDaysOfWeek] = useState<Array<any>>([])
	const [daysCrossTrain, setDaysCrossTrain] = useState<Array<any>>(sampleCrossTrain)
	const [daysPrevCrossTrain, setDaysPrevCrossTrain] = useState<Array<any>>([])
	const [currentOverrides, setCurrentOverrides] = useState<Array<UserOverrideRecord>>([])
	const [direction, setDirection] = useState(0);
	const daysOfWeekClone = useRef<any>({ current: [] })
	const weekContRef = useRef();
	const offsetHeight = weekContRef?.['current']?.['offsetParent']?.['offsetTop']
	const screenHeight = window?.screen?.availHeight
	const [longPress, setLongPress] = useState<boolean>(false)
	const [isPrevNext, setIsPrevNext] = useState<number>(0)
	const [newAddWeek, setNewAddWeek] = useState<any>('')
	const [slideIndex, setSlideIndex] = useState(0);
	const [calendarMove, setCalendarMove] = useState(false);
	const [settings, setSettings] = useState<any>({
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		swipe: calendarOpen ? false : true,
		beforeChange: (current: any, next: any) => setSlideIndex(next),
		initialSlide: selectedWeek?.key ? selectedWeek.key - (newAddWeek ? 0 : 1) : 0
	})
	const [reordering, setReordering] = useState(true)
	const [dayClickLoading, setDayClickLoading] = useState(false)
	const [disabledReorderXT, setDisabledReorderXT] = useState<any>(false)

	const { subscription } = useStore((state: ICurrentUser) => state)
	const isFreeWeek = (userInfo?.start && dayData?.date && moment(dayData?.date).isBefore(moment(userInfo?.start).add(7, 'd'))) && subscription?.accessDetails?.type === "basic" ? true : false
	const locked = !subscription?.accessDetails?.access?.training_plan?.plan_reorder && !isFreeWeek

	const increment = useRef<any>({ current: '' });
	const weekRef = useRef<any>({ current: '' });
	const sliderRef = useRef<any>();


	const loadingValues = parentLoading || dayClickLoading || reordering || updatedDBdayArrangement || noRefetch
	let legacyPlan = false


	const isReordered = daysOfWeekClone?.['current']?.length && daysOfWeekClone?.['current']?.find((d: any, i: number) => d !== daysOfWeek?.[i]) ? true : false

	const handleEditClick = () => {
		daysOfWeekClone['current'] = daysOfWeek
	}

	const handleCloseEdit = () => {
		daysOfWeekClone?.['current'] && setDaysOfWeek(daysOfWeekClone['current'])
		daysOfWeekClone['current'] = ''

		setDaysCrossTrain(daysPrevCrossTrain)

		setEditWeek(false)
	}

	const removeScheduledWorkouts = async (newList: any) => {
		const abortController = new AbortController();
		for (const list of newList) {
			if (list.day !== list.old_day && list.mid) {
				const garminSyncData = await getGarminSyncData(userInfo?.uid, list.mid, abortController.signal)
				if (garminSyncData?.data?.result?.length > 0) {
					const workout_id = garminSyncData?.data?.result[0]?.workout_id
					const data = {
						user_id: userInfo?.internal_id,
						plan_id: userInfo?.uid,
						workout_id: workout_id,
						mar_id: list.mid
					}

					await removeScheduledWorkout(data)
				}

			}
		}
	}

	const assignCrossTrainValues = () => {

		const crossTrainDays: SetStateAction<any[]> = []

		daysOfWeek?.forEach((data, index) => {
			const note = getDailyActivityDisplay(marathonDailyValues?.[data.mid], userInfo,
				marathonDailyValues?.[data.mid]?.marathonPaces,
				selectedRace?.value as number,
				marathonDailyValues?.[data.mid]?.marathon?.race_day,
				allUserNotes)

			const displayNote = note ? note?.toUpperCase().split('|') : ''

			// displayNote[1] && displayNote[1].trim() != '' ? crossTrainDays.push(displayNote[1].trim() + index.toString()) : crossTrainDays.push('TEST' + index.toString())

			const crossTrainDay = {
				day: data.day,
				activity: displayNote[2] ? displayNote[2].trim() : ''
			}

			crossTrainDays.push(crossTrainDay)
		})

		setDaysCrossTrain(crossTrainDays)
		setDaysPrevCrossTrain(crossTrainDays)
	}

	const handleSaveButton = async () => {
		setReordering(true)
		setEditWeek(false)
		const newList: any = []

		const oldOrder: any[] = []
		const newOrder: any[] = []

		const assignedXT: any[] = []
		daysOfWeek.forEach((d, i) => {
			const isRestDay = marathonDailyValues[d.mid]?.marathonPaces[0]?.pace === 'Rest' && marathonDailyValues[d.mid]?.workouts[0]?.title === ''
			const dayXT = daysCrossTrain[i]?.activity



			if (d.mid && marathonDailyValues[d.mid] && isRestDay) {
				newList.push({ day: i, old_day: d.id, user_id: userInfo?.uid, mid: marathonDailyValues[d.mid].marathon.id, week: marathonDailyValues[d.mid].marathon.week, rest_day: 'Y', activity: dayXT })
			}
			else if (d.mid && marathonDailyValues[d.mid] && !isRestDay) {
				newList.push({ day: i, old_day: d.id, user_id: userInfo?.uid, mid: marathonDailyValues[d.mid].marathon.id, week: marathonDailyValues[d.mid].marathon.week, rest_day: 'N', activity: dayXT })
			}
			else {
				newList.push({ day: i, old_day: d.id, user_id: userInfo?.uid, mid: 0, week: selectedWeek.key, rest_day: 'Y', activity: dayXT })
			}

			const runType = marathonDailyValues[d.mid]?.marathonPaces.length > 1 ? marathonDailyValues[d.mid]?.marathonPaces.find((i: { pace: string; }) => i.pace.toLowerCase().includes('tempo') || i.pace.toLowerCase().includes('threshold')) ? 'Tempo' : marathonDailyValues[d.mid]?.marathonPaces.find((i: { pace: string; }) => i.pace.toLowerCase().includes('interval') || i.pace.toLowerCase().includes('repetition')) ? 'Interval' : "Progression" : marathonDailyValues[d.mid]?.marathonPaces[0]?.pace || ''
			assignedXT.push({ day: d.id + 1, mid: d.mid, dayName: moment(d.id, 'd').format('dddd'), activity: daysCrossTrain[i]?.activity, distance: marathonDailyValues[d.mid]?.marathon?.distance || 0, runType: runType })
		})

		newList.forEach((d: any, i: any) => {
			oldOrder.push({ day: d.old_day + 1, run_id: d.mid })
			// prevOrder.push({ day: d.day + 1, mid: d.mid, xt: d.activity })
			newOrder.push({ day: d.day + 1, run_id: d.mid, xt: d.activity })
		})

		// console.log('daysOfWeek', daysOfWeek)
		// console.log('newList', newList)
		// console.log('newOrder', newOrder)
		// console.log('oldOrder', oldOrder)
		// console.log('daysCrossTrain', daysCrossTrain)
		// console.log('selectedWeek', selectedWeek)

		const orderedDays = [...assignedXT].sort((a, b) => a.mid - b.mid)
		const prevOrder = [...oldOrder].sort((a, b) => a.day - b.day)

		prevOrder.map((d: any, i: number) => {
			const dayPrevXT = daysPrevCrossTrain[i]?.activity
			d.xt = dayPrevXT
		})

		// console.log('unordered assignedXT', assignedXT)
		// console.log('ordered assignedXT', orderedDays)
		if (userInfo?.plan_type?.toUpperCase() != 'GPT') {
			updateCustomUserDayArrangement(newList, prevOrder, newOrder)
		}
		else
			await updateDBplanSelectedWeek(orderedDays, assignedXT).then((res) => {
				updateCustomUserDayArrangement(newList, prevOrder, newOrder)
			})

	}


	const handleLongPress = (e: any) => {
		clearTimeout(increment['current'])
		increment['current'] = setTimeout(() => {
			setLongPress(true)
		}, 2000)
	}

	const handleLongPressEnd = (e: any) => {
		clearTimeout(increment['current'])
		setLongPress(false);
		editWeek && e.preventDefault()
	}

	const handleDateClick = (val: string, data: any, isActiveWeek: boolean) => {
		let week
		let day = 0
		if (weekDays?.length && val) {
			for (let w = 0; w < weekDays.length; w++) {
				const curWeek = weekDays[w]
				const curWeekClone = curWeek?.replaceAll('-', '/')
				if (moment(val?.replaceAll('-', '/')).isBetween(moment(curWeekClone).subtract(1, 'd'), moment(curWeekClone).add(7, 'd'))) {
					week = { week: curWeek, key: w + 1 }
					day = moment(val).diff(moment(curWeek), 'days')
					break;
				}
				if (w === weekDays.length - 1) {
					const startW = weekDays[0]?.replaceAll('-', '/')
					if (moment(val?.replaceAll('-', '/')).isBefore(moment(startW))) {
						const newDay = moment(val)?.diff(moment(startW)?.subtract(7, 'd'), 'days')
						week = { week: '', key: 0 }
						day = newDay ? newDay : day
					}
				}
			}
		}
		week && !isActiveWeek && setSelectedWeek(week)
		setSelectedDay(day)
	}


	const updateDBplanSelectedWeek = async (orderedDays: any, assignedXT: any) => {

		const planId = userInfo?.uid >= 50000 ? userInfo?.uid - 50000 : userInfo?.uid

		// // formatting orderedDays to match the daily_runs format in the DB

		const weekData: { day_name: any; run_distance: number; run_type: string; cross_training: any; }[] = []
		let defaultPlan = rawUserInfo?.training?.find((x: any) => x.week === selectedWeek.key)?.daily_runs
		defaultPlan = JSON.parse(defaultPlan)
		orderedDays.forEach((day: any, dI: number) => {
			// currently being disregarded
			// let runDistance = 0
			// let runType = 'Easy'
			// const dayDate = moment(selectedWeek.week).add(dI, 'd').format('YYYY-MM-DD')
			// if (calendarData[dayDate]) {
			// 	console.log('>>> calendar data for', dayDate, calendarData[dayDate])
			// 	runDistance = calendarData[dayDate].activitiesValue?.marathon?.distance || 0
			// 	runType = calendarData[dayDate].activitiesValue?.marathonPaces[0]?.pace || 'NO RT 2'
			// } else {
			// 	runDistance = day.distance
			// 	runType = day.runType
			// 	console.log('>>> no calendar data for', dayDate)
			// }

			weekData.push(
				{
					"day_name": day.dayName,
					"run_distance": defaultPlan[dI]?.run_distance || 0,
					"run_type": day.runType,
					"cross_training": day.activity
				}
			)
		})

		const updatedPlan = {
			plan_id: planId,
			week: selectedWeek.key,
			user_id: userInfo?.internal_id,
			daily_runs: JSON.stringify(weekData),
		}


		UpdateAItrainingPlan(updatedPlan).then((res) => {

			// console.log('[saved to db]', res)

			setDaysPrevCrossTrain(assignedXT)

			// commented out, XT is saved and included in the daily_runs change order
			// createChangeLogEditDailies({
			// 	plan_id: Number(planId) + 50000,
			// 	week: selectedWeek.key,
			// 	day: 0,
			// 	previous_value:  JSON.stringify(assignedXT),
			// 	new_value: JSON.stringify(weekData),
			// 	user_id: userInfo?.internal_id,
			// 	change_type: 'REASSIGN_XT'
			// })
			// .then((res) => {
			// 	console.log('>>> saving logs', res)
			// })
			// .catch((err) => {
			// 	console.log('>>> saving logs err', err)
			// })

			return res

		}).catch((err: any) => {
			console.log('[saving to DB err]', err)
			return err
		})

	}


	const updateCustomUserDayArrangement = async (newList: any, oldOrder: any, newOrder: any) => {

		const saveOverrideData = await CreateUserOverride(newList)
		if (saveOverrideData && saveOverrideData.data.messages === "success") {

			await getUserOverrides(null, true)
			await CreateChangeLog(
				{
					user_id: userInfo?.internal_id,
					plan_id: userInfo?.uid,
					change_type: 'REORDER_DAYS_&_XT',
					week: selectedWeek.key,
					day: selectedDay + 1,
					previous_value: JSON.stringify(oldOrder),
					new_value: JSON.stringify(newOrder),
				})

			await removeScheduledWorkouts(newList)
			await getUserRestComments()
			setSelectedWeek({ week: weekDays[selectedWeek.key - 1], key: selectedWeek.key })

			setReordering(false)
			setUpdatedDBdayArrangement && setUpdatedDBdayArrangement({ week: weekDays[selectedWeek.key - 1], key: selectedWeek.key })
		} else {
			setReordering(false)
		}

	}


	const checkIsRecoveryDays = (startDate: any, currentWeek: any, currDate: any) => {

		const rr: RaceResult = userInfo?.raceResults
		const recWeeks = rr?.recoveryPlan?.recoveryType?.recovery_length_in_weeks || 0
		const isRecovery = userInfo?.uid == rr?.maintenance_plan_id;


		let assessmentVideosDays = false;
		let recoveryAssessmentsDays = false;

		if (rr && isRecovery) {
			const recoveryType = rr?.recoveryPlan?.recoveryType;
			assessmentVideosDays = moment(currDate).isBefore(moment(startDate).add(recoveryType?.assessment_starts_in_days, 'days'))

			recoveryAssessmentsDays = moment(currDate).isSameOrAfter(moment(startDate).add(recoveryType?.assessment_starts_in_days, 'days')) && moment(currDate).isBefore(moment(startDate).add(recoveryType?.resume_running_on_day, 'days'))
		}

		if (assessmentVideosDays)
			return 'R'
		else if (recoveryAssessmentsDays)
			return 'A'
		else
			return undefined
	}


	useEffect(() => {
		if (userInfo?.plan_type?.toUpperCase() != 'GPT') setDisabledReorderXT(true)
		else setDisabledReorderXT(false)
	}, [userInfo])

	useEffect(() => {

		if (!setLoadingNewData) legacyPlan = false
		setLoadingNewData && setLoadingNewData(loadingValues)

	}, [loadingValues])

	useEffect(() => {
		if (longPress) {
			clearTimeout(increment['current'])
			handleEditClick()
			setLongPress(false);
			setEditWeek(true)
		}
	}, [longPress])

	useEffect(() => {
		editWeek && handleEditClick()
	}, [editWeek])

	useEffect(() => {

		const initialSlide = selectedWeek?.key ? selectedWeek.key - (newAddWeek ? 0 : 1) : 0

		setSlideIndex(initialSlide)
		setSettings({
			...settings,
			beforeChange: (current: any, next: any) => setSlideIndex(next),
			initialSlide
		})
		sliderRef?.current?.slickGoTo(initialSlide)
		setDayClickLoading(false)
	}, [selectedWeek, newAddWeek])

	useEffect(() => {
		if (userInfo?.start && weekDays?.length > 0) {
			setReordering(false)
			const newWeekList = weekDays
			const start = moment(userInfo.start.replaceAll('-', '/')).add(1, 'day').format('YYYY/MM/DD')
			const weekStart = moment(newWeekList[0].replaceAll('-', '/')).format('YYYY/MM/DD')
			const weekStartBefore = moment(newWeekList[0].replaceAll('-', '/')).subtract(7, 'd').format('YYYY/MM/DD')
			if (moment(start).isBetween(weekStartBefore, weekStart))
				setNewAddWeek(weekStartBefore.replaceAll('/', '-'))
			else
				setNewAddWeek('')
		}

		if (updatedDBdayArrangement) {
			setSelectedWeek(updatedDBdayArrangement)
			setNoRefetch(true)
			setUpdatedDBdayArrangement(false)

			setReordering(false)
		}
	}, [weekDays, userInfo])

	useEffect(() => {
		const currentDate = selectedWeek?.week ? moment(selectedWeek?.week).add(selectedDay, 'd').format('YYYY-MM-DD') : ''
		if (isNativeIOS && currentDate && appleHealthPermission && appleHealthPermission?.status === "granted") {
			const dataToSend = {
				requestType: 'healthkit-get-daily-activity',
				payload: currentDate,
				timestamp: new Date().getTime(),
			}

			sendDataToReactNative(dataToSend)
		}
	}, [selectedWeek, selectedDay, appleHealthPermission, isNativeIOS])

	useEffect(() => {
		const isAfterOrRaceDay = moment(new Date()).isSameOrAfter(moment(userInfo?.race_date)) && !(userInfo?.type === 4 && !userInfo?.raceResults?.id)
		if (isPrevNext > 0) {
			if (selectedWeek?.key === 1) {
				const lastW = weekDays[0]?.replaceAll('-', '/')
				if (userInfo?.start && moment(userInfo.start)?.add(1, 'day')?.isBetween(moment(lastW)?.subtract(7, 'd'), moment(lastW))) {
					const newD = moment(userInfo.start)?.add(1, 'day')?.diff(moment(lastW)?.subtract(7, 'd'), 'd')
					setSelectedDay(newD ? newD : 0)
					setSelectedWeek({ week: '', key: 0 })
				}
			} else {
				const goToWeek = weekDays[selectedWeek?.key - 2]
				const isSameOrAfterRaceDayWeek = moment(goToWeek?.replaceAll('-', '/')).isSameOrAfter(moment(userInfo?.race_date)) || moment(userInfo?.race_date).isBetween(moment(goToWeek?.replaceAll('-', '/')), moment(goToWeek?.replaceAll('-', '/')).add(7, 'd'))
				if (goToWeek) {
					setSelectedDay(isAfterOrRaceDay && isSameOrAfterRaceDayWeek ? moment(userInfo?.race_date).day() : 0)
					setSelectedWeek({ week: goToWeek, key: selectedWeek.key - 1 })
				}
			}
		} else if (isPrevNext < 0) {
			const goToWeek = weekDays[selectedWeek?.key]
			const isSameOrAfterRaceDayWeek = moment(goToWeek?.replaceAll('-', '/')).isSameOrAfter(moment(userInfo?.race_date)) || moment(userInfo?.race_date).isBetween(moment(goToWeek?.replaceAll('-', '/')), moment(goToWeek?.replaceAll('-', '/')).add(7, 'd'))
			if (goToWeek) {
				setSelectedDay(isAfterOrRaceDay && isSameOrAfterRaceDayWeek ? moment(userInfo?.race_date).day() : 0)
				setSelectedWeek({ week: goToWeek, key: selectedWeek.key + 1 })
			}
		}
		clearTimeout(increment['current'])
		editWeek && setEditWeek(false)
	}, [isPrevNext])

	useEffect(() => {
		const initialSlide = selectedWeek?.key ? selectedWeek.key - (newAddWeek ? 0 : 1) : 0
		setSettings({
			...settings,
			swipe: calendarOpen ? false : true,
			initialSlide
		})
		if (!calendarOpen) {
			document.body.style.overflowY = 'auto !important'
			document.body.style.position = 'initial !important'
		}
	}, [calendarOpen])

	useEffect(() => {
		setSettings({
			...settings,
			swipe: calendarMove ? false : true
		})
	}, [calendarMove])

	const renderGoBack = () => {
		const isRaceDay = userInfo?.type === 4 ? 'Last Day' : 'Race Day'
		const isActivePlan = userInfo?.is_active_plan
		const isPastRacePlan = !isActivePlan && (raceResults?.id || moment().isAfter(moment(userInfo?.race_date))) ? true : false
		const isFutureRacePlan = !isActivePlan && (!raceResults?.id && moment(moment(userInfo?.start).add(1, 'days')).isAfter(moment())) ? true : false
		let newWeekList = weekDays
		if (newAddWeek)
			newWeekList = [newAddWeek, ...newWeekList]

		const currentDayIndex = isFutureRacePlan ? 0 : weekDays?.findIndex((w: any) => moment().isBetween(moment(w.replaceAll('-', '/')).subtract(1, 'days'), moment(w.replaceAll('-', '/')).add(7, 'd')))
		const raceDayIndex = weekDays?.findIndex((w: any) => moment(userInfo?.race_date).isBetween(moment(w.replaceAll('-', '/')).subtract(1, 'days'), moment(w.replaceAll('-', '/')).add(7, 'd')))

		const hasCurrentDay = currentDayIndex > -1
		const hasRaceDay = raceDayIndex > -1
		const index = (isPastRacePlan ? raceDayIndex : currentDayIndex)
		const initialSlide = index + (newAddWeek ? 1 : 0)

		let d = 0
		let rd = 0
		if (hasCurrentDay) {
			const newD = moment().diff(weekDays[currentDayIndex]?.replaceAll('-', '/'), 'd')
			d = newD > 0 && newD < 7 ? newD : 0
		}

		if (hasRaceDay) {
			const newD = moment(userInfo?.race_date).diff(weekDays[raceDayIndex]?.replaceAll('-', '/'), 'd')
			rd = newD > 0 && newD < 7 ? newD : 0
		}

		return slideIndex !== initialSlide && !loadingValues && !editWeek && <Box sx={{ position: 'absolute', height: 'fit-content', width: '100%', bottom: -40, display: 'flex', justifyContent: 'flex-end' }}>
			<Box onClick={() => {
				sliderRef?.current?.slickGoTo(initialSlide)
				if (index + 1 !== selectedWeek?.key) {
					setSelectedWeek({ week: weekDays[index], key: index + 1 })
				}

				if (d !== selectedDay) {
					if (isPastRacePlan)
						setSelectedDay(rd)
					else
						setSelectedDay(d)
				}
			}}
				sx={{ cursor: 'pointer', fontSize: '16px', color: '#0c71c3', right: 0, height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: '10px' }}>
				{slideIndex > initialSlide && <i className="fa-light fa-circle-arrow-left"></i>}
				<Box sx={{ fontSize: '16px', px: '5px', color: '#0c71c3' }}>{isPastRacePlan ? isRaceDay : (isFutureRacePlan ? 'Back to Start' : 'Today')}</Box>
				{slideIndex < initialSlide && <i className="fa-light fa-circle-arrow-right"></i>}
			</Box>
		</Box>
	}

	const renderDays = () => {
		let isRD = false
		const widthDays = isMobile ? 60 : 120

		// console.log(':::: week :::::')

		return (<>

			{/* ml: isMobile ? `10vw` : `7rem` */}
			<Grid xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', mt: '1.1rem' }}>
				<Grid xs={isMobile ? 2.7 : 4}></Grid>
				<Grid xs={isMobile ? 4.3 : 4} sx={{ color: 'black' }}>
					Running
				</Grid>
				<Grid xs={4} sx={{ color: 'black' }}>
					{!disabledReorderXT ? 'Cross Training' : ''}
				</Grid>
			</Grid>

			<Grid sx={{ m: 2, mt: 1, position: 'relative', display: 'flex', flexDirection: 'row' }} container>

				{/* <Box sx={{ width: `${widthDays}px` }}> */}
				<Box sx={{ width: isMobile ? `10vw` : `7rem` }}>
					{(userInfo?.monday_start ? DAYS_ALT : DAYS)?.map((data, index) => {
						return <Grid xs={12} container key={'days-reorder-' + index} sx={{ mb: '5px', width: '100%', height: '50px' }} justifyContent='flex-start' alignItems='center'>
							<Box sx={{ fontSize: '16px', fontFamily: 'Poppins', color: 'black' }}>{titleCase(data.day?.slice(0, isMobile ? 3 : data.day.length))}</Box>
						</Grid>
					})}
				</Box>

				{/* @@@ draggable distance and run type */}
				<Reorder.Group axis="y" as="div" values={daysOfWeek} onReorder={setDaysOfWeek} style={{ width: isMobile ? '40vw' : '13.5rem', display: 'flex', position: 'relative' }} >

					<Box sx={{ ml: '0.2rem' }}>
						{daysOfWeek?.map((data, index) => {
							const note = getDailyActivityDisplay(marathonDailyValues?.[data.mid], userInfo,
								marathonDailyValues?.[data.mid]?.marathonPaces,
								selectedRace?.value as number,
								marathonDailyValues?.[data.mid]?.marathon?.race_day,
								allUserNotes)

							const displayNote = note ? note?.toUpperCase().split('|') : ''
							let desc = displayNote[1] ? displayNote[1].trim() : ''

							const underlineColor = getColorCode(displayNote?.[1]?.trim() || displayNote?.[0]?.trim() || '')
							desc = desc === 'CROSS TRAIN' ? 'XT' : desc
							desc = isMobile ? (desc?.toLowerCase() === "progression" ? "PROG." : desc?.toLowerCase() === "recovery" ? 'RECOV.' : desc) : desc
							const isKm = userInfo?.km === "Y" ? "KMS" : "MILES";
							const isKm2 = userInfo?.km === "Y" ? "KM " : "MI ";
							let finalD = displayNote[0] && !(displayNote[0]?.includes(isKm)) ? displayNote[0]?.replace(isKm2, isKm) : displayNote[0]

							let rr: RaceResult | undefined = undefined;
							let maxWeeks: [] | undefined = []

							if (userInfo?.raceResults) {
								rr = userInfo?.raceResults
								maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
							}

							const finalSplit = finalD?.split(' ')
							const isNumber = finalSplit ? is_numeric(finalSplit[0]) : false

							const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == data?.mid)?.distance ? true : false

							if (isNumber) {
								if (finalSplit[0]) {
									finalSplit[0] = !hasCustomDistance && is_numeric(finalSplit[0]) && rr && maxWeeks ? (finalSplit[0] * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : finalSplit[0]
								}
								if (finalSplit[1]) {
									finalSplit[1] = titleCase(finalSplit[1])
								}
								finalD = finalSplit?.join(' ')
							} else
								finalD = titleCase(finalD)
							isRD = userInfo?.training_level !== 4 && marathonDailyValues?.[data.mid]?.marathonPaces?.[0]?.pace?.toLowerCase() === 'race day' ? true : isRD

							const isRest = finalD.toUpperCase().includes('REST') ? true : false
							const isXT = finalD.toUpperCase().includes('CROSS') ? true : false
							const isEmpty = isRest || isXT ? true : false

							return (
								<Reorder.Item dragListener={!loadingValues && editWeek && selectedWeek?.week && !isRD && !locked} as="div" key={data.day} value={data} style={{ width: isMobile ? '40vw' : '13.5rem' }} >
									<Grid key={data.day} container xs={12} justifyContent="center" alignItems="center">
										<Button
											disabled={true}
											sx={{
												color: `${editWeek ? '#1976d2 !important' : ''}`,
												border: locked && isEmpty ? 'none' : `1px ${isEmpty ? 'dashed #555' : 'solid rgba(0, 0, 0, 0.176)'}  !important`,
												borderRadius: '10px',
												mb: '5px', width: '100%',
												backgroundColor: !isEmpty ? underlineColor : '#F8F8F8',
												height: '50px',
												textTransform: 'none',
												boxShadow: 'none',
												justifyContent: 'space-between'
											}}
										>
											{loadingValues && <Box sx={{ left: -1 }} className='loading-days toRight' />}
											{desc && <Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Light', color: 'black' }}>{desc}</Box>}
											<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Light', color: 'black' }}>{finalD && !isRest && !isXT ? finalD : ''}</Box>
										</Button>
									</Grid>
								</Reorder.Item>
							)
						})}
					</Box>
				</Reorder.Group>

				{/* @@@ draggable XT / cross train type */}
				<Reorder.Group axis="y" as="div" values={daysCrossTrain} onReorder={setDaysCrossTrain} style={{ width: isMobile ? '40vw' : '13.5rem', display: 'flex', position: 'relative' }}>
					<Box sx={{ width: `calc( 100% - ${widthDays}px )`, ml: '0.6rem' }}>
						{daysCrossTrain?.map((data) => {
							const isEmpty = data?.activity === '' ? true : false

							return (
								<Reorder.Item as="div" key={data.day} value={data} dragListener={!disabledReorderXT && !locked} style={{ width: isMobile ? '40vw' : '13.5rem' }}>
									<Grid key={data.day} container xs={12} justifyContent="center" alignItems="center">
										<Button
											disabled={true}
											sx={{
												color: `${editWeek ? '#1976d2 !important' : ''}`,
												border: locked && isEmpty ? 'none' : `1px ${isEmpty || disabledReorderXT ? 'dashed #555' : 'solid rgba(0, 0, 0, 0.176)'}  !important`,
												borderRadius: '10px',
												mb: '5px', width: '100%',
												backgroundColor: !isEmpty && !disabledReorderXT ? '#FBF5B7' : disabledReorderXT ? '#BBB' : '#F8F8F8',
												boxShadow: 'none',
												height: '50px',
												textTransform: 'none',
												justifyContent: 'end'
											}}
										>
											{/* {data?.activity ? data?.activity : data} */}
											{!disabledReorderXT && <Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Light', color: 'black', mr: '0.2rem' }}>{data?.activity}</Box>}
										</Button>
									</Grid>
								</Reorder.Item>
							)
						})}
					</Box>
				</Reorder.Group>
			</Grid>
		</>)
	}

	const renderDaysRunsOnly = () => {
		let isRD = false
		const widthDays = isMobile ? 60 : 120
		return (
			<Grid sx={{ m: 2, mt: 1, position: 'relative' }} container>
				<Reorder.Group axis="y" as="div" values={daysOfWeek} onReorder={setDaysOfWeek} className='reorder-div'>
					<Box sx={{ width: `${widthDays}px` }}>
						{(userInfo?.monday_start ? DAYS_ALT : DAYS)?.map((data, index) => {
							return <Grid xs={12} container key={'days-reorder-' + index} sx={{ mb: '5px', width: '100%', height: '50px' }} justifyContent='flex-start' alignItems='center'>
								<Box sx={{ fontSize: '16px', fontFamily: 'Poppins', color: 'black' }}>{titleCase(data.day?.slice(0, isMobile ? 3 : data.day.length))}</Box>
							</Grid>
						})}
					</Box>
					<Box sx={{ width: `calc( 100% - ${widthDays}px )` }}>
						{daysOfWeek?.map((data, index) => {
							const note = getDailyActivityDisplay(marathonDailyValues?.[data.mid], userInfo,
								marathonDailyValues?.[data.mid]?.marathonPaces,
								selectedRace?.value as number,
								marathonDailyValues?.[data.mid]?.marathon?.race_day,
								allUserNotes)
							const displayNote = note ? note?.toUpperCase().split('|') : ''
							let desc = displayNote[1] ? displayNote[1].trim() : ''
							const underlineColor = getColorCode(displayNote?.[1]?.trim() || displayNote?.[0]?.trim() || '')
							desc = desc === 'CROSS TRAIN' ? 'XT' : desc
							//desc = isMobile ? (desc?.toLowerCase() === "progression" ? "PROG." :  desc?.toLowerCase() === "recovery" ? 'RECOV.' : desc) : desc
							const isKm = userInfo?.km === "Y" ? "KMS" : "MILES";
							const isKm2 = userInfo?.km === "Y" ? "KM " : "MI ";
							let finalD = displayNote[0] && !(displayNote[0]?.includes(isKm)) ? displayNote[0]?.replace(isKm2, isKm) : displayNote[0]

							let rr: RaceResult | undefined = undefined;
							let maxWeeks: [] | undefined = []

							if (userInfo?.raceResults) {
								rr = userInfo?.raceResults
								maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
							}

							const finalSplit = finalD?.split(' ')
							const isNumber = finalSplit ? is_numeric(finalSplit[0]) : false

							const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == data?.mid)?.distance ? true : false

							if (isNumber) {
								if (finalSplit[0]) {
									finalSplit[0] = !hasCustomDistance && is_numeric(finalSplit[0]) && rr && maxWeeks ? (finalSplit[0] * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : finalSplit[0]
								}
								if (finalSplit[1]) {
									finalSplit[1] = titleCase(finalSplit[1])
								}
								finalD = finalSplit?.join(' ')
							} else
								finalD = titleCase(finalD)
							isRD = userInfo?.training_level !== 4 && marathonDailyValues?.[data.mid]?.marathonPaces?.[0]?.pace?.toLowerCase() === 'race day' ? true : isRD
							return (
								<Reorder.Item dragListener={!loadingValues && editWeek && selectedWeek?.week && !isRD} as="div" key={data.day} value={data} className='reorder-div'>
									<Grid key={data.day} container xs={12} justifyContent="center" alignItems="center">
										<Button
											disabled={true}
											variant='outlined'
											sx={{
												color: `${editWeek ? '#1976d2 !important' : ''}`,
												border: '1px solid rgba(0, 0, 0, 0.176) !important',
												mb: '5px', width: '100%',
												backgroundColor: underlineColor,
												height: '50px',
												textTransform: 'none',
												boxShadow: loadingValues ? 'none' : '-2px 2px 4px rgba(0, 0, 0, 0.15)',
												justifyContent: 'space-between'
											}}
										>
											{loadingValues && <Box sx={{ left: -1 }} className='loading-days toRight' />}
											{desc && <Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Light', color: 'black' }}>{desc}</Box>}
											<Box sx={{ fontSize: '16px', fontFamily: 'Poppins-Light', color: 'black' }}>{finalD ? finalD : ''}</Box>
										</Button>
									</Grid>
								</Reorder.Item>
							)
						})}
					</Box>
				</Reorder.Group>
			</Grid>
		)
	}

	const renderWeeks = () => {
		let disableBack = true
		let disabledForward = true
		let weekRange = ''
		if (weekDays?.length > 0 && selectedWeek?.week) {
			weekRange = `Week of ${moment(weekDays[selectedWeek.key - 1]).format('MMM DD')}-${moment(weekDays[selectedWeek.key - 1]).add(6, 'd').format('DD')}`
			disableBack = !(selectedWeek.key - 1)
			disabledForward = weekDays.length === (selectedWeek.key)
		}

		return (
			<>
				{locked ? <></> : (
					<Box sx={{ p: '20px 16px 0px 16px' }} ref={weekContRef}>
						<Grid container>
							<Grid container xs={12} justifyContent='space-between' alignItems='flex-start'>
								<Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
									<Box sx={{ fontSize: '18px', fontFamily: 'Poppins' }}>Edit Schedule</Box>
									<Box sx={{ display: 'flex', fontSize: '16px', fontFamily: 'Poppins-Light', alignItems: 'center', lineHeight: '1em' }}>{weekRange}</Box>
									<Box sx={{ mb: '10px', fontSize: '16px', fontFamily: 'Poppins-Light', lineHeight: '1.4em' }}>Drag days to reorder them</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				)}
			</>
		)
	}

	const renderWeekSelector = () => {
		const title = 'Reorder to fit your schedule.'
		const desc = 'Life gets busy—and Pro lets you adjust. Unlock reordering, editing, and other premium tools designed to keep you flexible, consistent, and training smarter.'
		return (<Box className='week-cont bg-main' sx={{ mt: 0, position: 'relative', height: '100%', borderRadius: '4px' }}>
			{renderWeeks()}
			<Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', width: '100%' }}>
				{locked ? <RSUpgrade userInfo={accountInfo} title={title} description={desc} /> : <></>}
				<Grid xs={12} container justifyContent="center" alignItems="flex-end">
					{disabledReorderXT ? renderDaysRunsOnly() : renderDays()}
				</Grid>
				{!locked ? <Grid xs={12} sx={{ py: 1 }} container justifyContent="center" alignItems="flex-end">
					{/* disabled={loadingValues || !isReordered} */}
					<RSButton sx={{ fontSize: '18px', height: '55px', m: '0px 16px 5px 16px', maxWidth: '200px' }} onClick={handleSaveButton}>Save</RSButton>
				</Grid> : <></>}
			</Box>
		</Box>)
	}

	const renderDaysMain = () => {
		const isKm = userInfo?.km === "Y" ? "KMS" : "MILES";
		const isKm2 = userInfo?.km === "Y" ? "KM " : "MI ";
		return (
			<AnimatePresence initial={false} mode='wait'>
				<MotionBox drag={loadingValues ? undefined : 'x'}
					key={selectedWeek?.week}
					initial={{ x: isPrevNext > 0 ? '-100%' : isPrevNext === 0 ? '0' : '100%', opacity: isPrevNext === 0 ? 1 : 0 }}
					animate={{ x: 0, opacity: 1 }}
					exit={{ x: isPrevNext > 0 ? '100%' : isPrevNext === 0 ? '0' : '-100%', opacity: isPrevNext === 0 ? 1 : 0 }}
					transition={{ duration: 0.245 }}
					dragConstraints={{ left: 0, right: 0 }}
					dragElastic={{ right: weekDays?.[selectedWeek?.key - 2] ? 0.3 : 0, left: weekDays?.[selectedWeek?.key] ? 0.3 : 0 }}
					sx={{ pt: 2, px: '10px', pb: '10px' }}
					onPan={(e: any) => {
						if (increment['current']) {
							clearTimeout(increment['current'])
							increment['current'] = ''
						}
						editWeek && setEditWeek(false)
					}}
					onDragEnd={(e: any) => {
						if (e?.pageX) {
							if (e?.offsetX < -100) {
								setIsForward(1)
								setIsPrevNext(isPrevNext < 0 ? isPrevNext - 1 : -1)
								clearTimeout(increment['current'])
								increment['current'] = ''
								editWeek && setEditWeek(false)
							} else if (e?.offsetX > 100) {
								setIsForward(0)
								setIsPrevNext(isPrevNext > 0 ? isPrevNext + 1 : 1)
								clearTimeout(increment['current'])
								increment['current'] = ''
								editWeek && setEditWeek(false)
							}
						}
					}}
				>
					<Grid container>
						{daysOfWeek?.map((data, index) => {
							const startDate = weekDays[0]
							const currentWeek = weekDays[selectedWeek.key - 1]
							const currDate = moment(currentWeek?.replaceAll('-', '/')).add(index, 'd')
							const recoveryDay = checkIsRecoveryDays(startDate, currentWeek, currDate)
							const isCurrentDay = moment(currDate.format('YYYY/MM/DD')).isSame(moment().format('YYYY/MM/DD'))
							let rr: RaceResult | undefined = undefined;
							let maxWeeks: [] | undefined = []

							if (userInfo?.raceResults) {
								rr = userInfo?.raceResults
								maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
							}
							const isRecoveryPlanAdd = userInfo?.type === 4 && userInfo?.raceResults?.id ? 0 : 1
							const skipMaintenance = !isRecoveryPlanAdd && userInfo?.raceResults?.recoveryPlan?.skip_maintenance_plan
							const add1Day = moment(selectedWeek?.week).add(selectedDay, 'd').isAfter(moment(userInfo?.race_date).add(1, 'd')) ? 0 : isRecoveryPlanAdd
							const isLastDate = userInfo?.race_date && (moment(userInfo?.race_date)?.add(isRecoveryPlanAdd, 'd')?.format('YYYY MM DD') === moment(selectedWeek?.week)?.add(data.id, 'd')?.format('YYYY MM DD') || (moment(selectedWeek?.week).add(data.id, 'd').isSameOrAfter(moment(userInfo?.race_date).add(isRecoveryPlanAdd, 'd')) && (selectedDay === index))) && !(userInfo?.type === 4 && !userInfo?.raceResults?.id)
							const isInValidDate = (!selectedWeek?.week && moment(weekDays[0]).subtract(7 - data.id, 'd').isBefore(moment(userInfo?.start)) ? true : false) || ((userInfo?.race_date && moment(selectedWeek?.week).add(data.id, 'd').isAfter(moment(userInfo?.race_date).add(add1Day, 'd')) && !(selectedDay === index)) && !(userInfo?.type === 4 && !userInfo?.raceResults?.id))
							const isActive = selectedDay === index && !isInValidDate
							const dailyActivity = getDailyActivityDisplay(marathonDailyValues?.[data.mid], userInfo,
								marathonDailyValues?.[data.mid]?.marathonPaces,
								selectedRace?.value as number,
								marathonDailyValues?.[data.mid]?.marathon?.race_day,
								allUserNotes)?.toUpperCase()?.split("|")

							let trainVal = dailyActivity ? dailyActivity[0]?.replace(isKm, "")?.replace(isKm2, "")?.trim()?.split(' ') : ''
							const isNumber = is_numeric(trainVal[0])
							const desc = dailyActivity ? dailyActivity[1]?.trim() : ''
							trainVal = trainVal ? trainVal?.join(' ') : ''
							trainVal = isNumber ? trainVal : trainVal?.split(' ')?.map((v: string) => v === 'CROSS' ? 'X' : v.charAt(0))?.join('')
							const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == data?.mid)?.distance ? true : false

							trainVal = !hasCustomDistance && isNumber && rr && maxWeeks ? (trainVal * maxWeeks[selectedWeek?.key - 1]).toFixed(1) : trainVal
							trainVal = recoveryDay ? recoveryDay : trainVal
							const underlineColor = recoveryDay ? 'unset' : getColorCode(desc)
							return <Grid container key={index} xs={12 / daysOfWeek.length} sx={{ p: '0px 1px', height: '58px' }} justifyContent='center' alignItems='center'>
								<Button
									disabled={isInValidDate}
									onTouchStart={handleLongPress}
									onTouchEnd={(e) => handleLongPressEnd(e)}
									onClick={() => {
										if (!loadingValues) {
											window.scrollTo(0, 0)
											setIsForward(index > selectedDay ? 1 : 0)
											setSelectedDay(index)
										}
									}}
									sx={{ backgroundColor: `${isActive ? '#bfd38c' : '#f2f2f8'} !important`, position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px', border: `1px solid ${isActive ? '#9FCD2B' : '#cdcdcd'}`, minWidth: '100%', justifyContent: 'flex-start', color: '#010101' }}
								>
									{(loadingValues || weekLoading) && <Box className='loading-days' />}
									{isCurrentDay && <Box sx={{ width: '18px', height: '18px', backgroundColor: '#E12B2B', position: 'absolute', top: 0, borderRadius: '10px' }} />}
									<Box sx={{ zIndex: 1, lineHeight: 0.5, fontSize: '12px', color: isActive ? (isCurrentDay ? 'white' : '#010101') : isInValidDate ? 'rgba(0, 0, 0, 0.2)' : (isCurrentDay ? 'white' : 'rgba(0, 0, 0, 0.7)') }}>{data.day.charAt(0)}</Box>
									{!isInValidDate && <Box sx={{ zIndex: 1, lineHeight: 2, fontFamily: 'Poppins-Extralight', fontSize: '1.75em', color: '#010101' }}>{isLastDate ? (!isRecoveryPlanAdd ? skipMaintenance ? 'P' : 'M' : 'SR') : trainVal}</Box>}
									{!isInValidDate && desc && <Box sx={{ zIndex: 1, width: '70%', height: '3px', borderRadius: '10px', position: 'absolute', bottom: '4px', backgroundColor: underlineColor }} />}
								</Button>
							</Grid>
						})
						}
					</Grid>
				</MotionBox>
			</AnimatePresence>)
	}

	const getVal = (data: any, date: any, isActive: boolean, dayIndex: number) => {
		let finalVal: any = ''
		let underlineCode: any = ''
		let desc = ''
		let colorCode = 'white'
		let dailyActivity: any = ''
		const specificDate = date.format('YYYY-MM-D')
		const specificDateIOS = date.format('YYYY/MM/DD')
		const isCurrentDay = moment(specificDateIOS).isSame(moment().format('YYYY/MM/DD'))
		let isAssessment = false
		if (data && userInfo && Object.keys(userInfo).length) {
			const result = data[specificDate]
			const { monday_start, km, start, race_date, weeks, type } = userInfo
			const recoveryDay = userInfo?.type !== 4 && moment(race_date).add(1, 'days').format('YYYY-MM-D')
			const maintenanceDay = userInfo?.type === 4 && userInfo?.raceResults?.id && moment(race_date).format('YYYY-MM-D')
			const skipMaintenance = maintenanceDay && userInfo?.raceResults?.recoveryPlan?.skip_maintenance_plan
			const raceDay = moment(race_date).format('YYYY-MM-D')
			const isKm = km === "Y" ? "KMS" : "MILES";
			const isKm2 = km === "Y" ? "KM" : "MI";

			let assessmentVideosDays = false;
			let recoveryAssessmentsDays = false
			let rr: RaceResult | undefined = undefined;
			let maxWeeks: [] | undefined = []

			if (userInfo?.raceResults) {
				rr = userInfo?.raceResults
				maxWeeks = JSON.parse(rr?.recoveryPlan?.recoveryType?.max_week_percentage as string | "[]");
				assessmentVideosDays = moment(date).isBefore(moment(moment(Object.keys(data)[0]?.replaceAll('-', '/'))).add(rr?.recoveryPlan?.recoveryType.assessment_starts_in_days, 'days'))

				recoveryAssessmentsDays = moment(date).isSameOrAfter(moment(Object.keys(data)[0]?.replaceAll('-', '/')).add(rr?.recoveryPlan?.recoveryType.assessment_starts_in_days, 'days')) && moment(date).isBefore(moment(Object.keys(data)[0]?.replaceAll('-', '/')).add(rr?.recoveryPlan?.recoveryType.resume_running_on_day, 'days'))
				isAssessment = assessmentVideosDays || recoveryAssessmentsDays
			}

			const thisWeek = false
			// enable this if problem still happens in rearranging
			// const thisWeek =  result?.activitiesValue?.week == marathonDailyValues?.[daysOfWeek[dayIndex]?.mid]?.marathon?.week

			if (result) {
				dailyActivity = getDailyActivityDisplay(
					thisWeek ? marathonDailyValues?.[daysOfWeek[dayIndex]?.mid] : result?.activitiesValue,
					userInfo,
					thisWeek ? marathonDailyValues?.[daysOfWeek[dayIndex]?.mid]?.marathonPaces : result?.activitiesValue?.marathonPaces,
					selectedRace?.value as number,
					thisWeek ? marathonDailyValues?.[daysOfWeek[dayIndex]?.mid]?.marathon?.race_day : result?.activitiesValue?.marathon?.race_day,
					allUserNotes
				)?.toUpperCase().split("|")
				if (dailyActivity) {
					desc = dailyActivity[1]?.trim()
					colorCode = dailyActivity[0]?.toLowerCase()?.includes('laps') || dailyActivity[0]?.toUpperCase()?.includes('RACE PACE') ? getColorCode('race pace') : (dailyActivity[0]?.toLowerCase()?.includes('rest') ? 'inherit' : getColorCode(desc))
					desc = desc ? desc?.toLowerCase()?.split(' ')?.map((dd: any) => is_numeric(dd.charAt(0)) ? dd.toUpperCase() : dd.charAt(0).toUpperCase() + dd.slice(1))?.join(' ') : ''
					desc = desc === "Progression" ? "Prog." : desc === "Recovery" ? 'Recov.' : desc

					if (assessmentVideosDays) {
						desc = 'Rest'
						finalVal = 'R'
						colorCode = getColorCode('Rest')
					}
					else if (recoveryAssessmentsDays) {
						desc = 'Assess'
						finalVal = 'A'
						colorCode = getColorCode('Rest')
					} else if (dailyActivity[0] === "REST" && strtotime(specificDate) <= strtotime(start) || dailyActivity[0] === "REST" && strtotime(specificDate) >= strtotime(moment(race_date).add(3, 'days')) || specificDate === maintenanceDay) {
						if (specificDate === recoveryDay)
							finalVal = 'SR'
						else if (specificDate === maintenanceDay)
							finalVal = skipMaintenance ? 'P' : 'M'
						else if (moment(specificDateIOS).isBefore(race_date))
							finalVal = 'R'
					} else {
						if (dailyActivity[0] !== "REST") {
							let trainVal = dailyActivity[0]?.replace(isKm, "")?.replace(isKm2, "").trim().split(' ')
							const isNumber = is_numeric(trainVal[0])
							let desc = dailyActivity[1]?.trim()
							trainVal = trainVal.join(' ')

							let currentWeek = 0;
							weekDays.forEach((e: any, i: number) => {
								if (moment(specificDateIOS).isSameOrAfter(moment(e)) && moment(specificDateIOS).isSameOrBefore(moment(e).add(6, 'days')))
									currentWeek = i
							});

							const hasCustomDistance = allUserNotes?.find((x: any) => x?.marid == result?.activitiesValue?.marathon?.id)?.distance ? true : false

							trainVal = !hasCustomDistance && isNumber && rr && maxWeeks ? (trainVal * maxWeeks[currentWeek]).toFixed(1) : trainVal
							const colorCode = getColorCode(desc)
							desc = desc ? desc?.toLowerCase()?.split(' ')?.map((dd: any) => is_numeric(dd.charAt(0)) ? dd.toUpperCase() : dd.charAt(0).toUpperCase() + dd.slice(1))?.join(' ') : ''
							const isXT = trainVal === 'CROSS TRAIN'
							if (isNumber || isXT) {
								finalVal = isXT ? 'XT' : trainVal
								underlineCode = desc ? colorCode : ''
							} else
								finalVal = titleCase(trainVal)
						} else
							finalVal = raceDay === specificDate ? type != 4 ? 'RD' : 'M' : 'R'
					}
				}
			} else {
				if (specificDate === recoveryDay)
					finalVal = 'SR'
				else if (specificDate === maintenanceDay)
					finalVal = skipMaintenance ? 'P' : 'M'
				else if (start && race_date && strtotime(specificDate) > strtotime(moment(start)?.format('YYYY-MM-D')) && strtotime(specificDate) < strtotime(moment(race_date)?.format('YYYY-MM-D')))
					finalVal = 'R'
			}
		}

		finalVal = (finalVal?.toUpperCase()?.includes('RACE') || (dailyActivity && dailyActivity.find((x: any) => x.toUpperCase().includes('RACE')) && !dailyActivity.find((x: any) => x.toUpperCase().includes('RACE DAY')))) ? 'RP' : finalVal
		colorCode = finalVal === 'RP' ? getColorCode('RACE PACE') : colorCode
		const d = moment(specificDateIOS).format('D')
		const isInvalid = d?.toLowerCase() === 'invalid date'

		const crossTrainType = (dailyActivity.length > 2 && dailyActivity[2]) || ''
		const crossTrainActivities = Array.from(new Set(dailyActivity[2]?.toLowerCase()?.split(',').filter((item: string) => item != "").map((item: string) => item.trim().replace(/\s+/g, ''))))
		const fontColor = desc?.toUpperCase() === 'RACE DAY' ? 'white' : '#010101'
		const finalArrList = []
		desc = desc == "Stretch,roll" && finalVal === 'RP' ? "Stretch" : desc
		// day # on top of calendar day
		!isInvalid && finalArrList.push(
			<Box key={specificDateIOS + '-d'} sx={{ width: '100%', height: '12px', zIndex: 1, lineHeight: 1, display: 'flex', fontSize: '12px', justifyContent: 'center', alignItems: 'center', color: isActive ? (isCurrentDay ? 'white' : '#010101') : !finalVal ? 'rgba(0, 0, 0, 0.2)' : (isCurrentDay ? 'white' : 'rgba(0, 0, 0, 0.7)') }}>
				<div style={{ position: isCurrentDay && !isActive ? 'absolute' : 'static', top: 0, width: '100%', height: isActive ? '14px' : '15px', backgroundColor: isCurrentDay ? 'red' : colorCode, justifyContent: 'center', alignItems: 'center' }}></div>

				<div style={{ position: 'absolute', fontSize: '10px', color: `${fontColor} !important`, textTransform: 'capitalize' }}>{d === '1' ? `${moment(specificDateIOS).format('MMM')} ` : ''}{d}</div>
				<span className={`daily-activity-desc week-calendar ${desc?.toUpperCase() === 'RACE DAY' ? 'rd-week-selector' : ''}`}>
					{desc?.toUpperCase() === 'RECOVERY' ? 'Recover' : (desc?.toUpperCase() === 'CROSS TRAIN' ? 'XT' : (desc !== "Stretch" && (dailyActivity && (!dailyActivity.find((x: any) => x.toUpperCase().includes('RACE')) || dailyActivity.find((x: any) => x.toUpperCase().includes('RACE DAY'))))) ? desc?.replace('10K ', '')?.replace('5K ', '') : '')}

				</span>
			</Box>)

		// selected day # top highlight
		// isCurrentDay && finalArrList.push(<Box sx={{ width: '48px', height: '18px', backgroundColor: '#E12B2B', position: 'absolute', top: 0, borderRadius: '10px' }} />)

		// calendar day center content value
		finalVal && finalArrList.push(<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
			<Box key={specificDateIOS + '-da'} sx={{ zIndex: 1, marginTop: '15px', lineHeight: 1.1, fontFamily: 'Poppins-Extralight', fontSize: '24px', color: `${fontColor} !important` }}>{finalVal}</Box>
			{crossTrainType != '' || (finalVal === 'RP' && (desc === "Stretch" || desc === "Stretch,roll")) && <Box sx={{ width: '0.8rem', height: '0.8rem', borderRadius: '1rem', backgroundColor: '#555' }} />}
			{crossTrainType != '' && <Box sx={{ marginLeft: `${crossTrainActivities.length > 1 ? '0px' : '0px'}`, position: 'relative', height: '16px', width: '100%', display: 'flex', mt: '1px' }} >
				{!isAssessment && crossTrainActivities?.map((cta: any, i: number) => {
					const isStrength = cta?.toLowerCase()?.includes('strength')
					const isYoga = cta?.toLowerCase()?.includes('yoga')
					const isStretch = cta?.toLowerCase()?.includes('stretch')
					const isRoll = cta?.toLowerCase()?.includes('roll')

					return <Box key={i} sx={{ width: '16px', height: '16px', position: 'absolute', left: `calc((100% / ${crossTrainActivities.length + 1})*${i + 1})`, transform: 'translateX(-50%)' }}>
						<Box sx={{ width: '100%', height: '100%', borderRadius: '50%', backgroundColor: `#555`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<i style={{ fontSize: '10px', color: '#ffffff' }}
								className={`fa-solid ${isRoll && 'fa-circle-dashed'} ${isStretch && 'fa-child-reaching'} ${isYoga && 'fa-spa'} ${isStrength && 'fa-dumbbell'}`} />
						</Box>
					</Box>
				})}
			</Box>}
		</Box>)

		// legacy color bar
		// underlineCode && finalArrList.push(<Box sx={{ zIndex: 1, width: '70%', height: '6px', borderRadius: '10px', position: 'absolute', bottom: '4px', backgroundColor: underlineCode }} />)
		return <div style={{ width: '100%', height: '100%', backgroundColor: colorCode, overflow: 'hidden', border: `2.5px solid ${isActive ? '#3252D6' : (finalVal === 'R' || finalVal === 'A' || finalVal === 'SR' ? '#F6F5F5' : colorCode)}`, borderRadius: 'inherit' }}>{finalArrList}</div>
	}

	const calendarDefault = 340
	const weekHeight = isMobile ? 75 : 90
	const weekMB = 5

	const renderWeekList = (newWeekList: any) => {
		const sWeek = selectedWeek?.week ? selectedWeek.week.replaceAll('-', '/') : newWeekList[0]
		const sDate = sWeek ? moment(sWeek).add(selectedDay, 'days').format('YYYY-MM-D') : ''

		return newWeekList?.map((wd: any, wdi: number) => {
			const isActiveWeek = selectedWeek?.week === wd

			return <Grid container id={`newWeekList-${wdi}`} xs={12} key={'ld' + wdi} sx={{ height: `${weekHeight}px`, px: '10px', width: '100%', display: 'flex !important', mb: `${weekMB}px` }} >
				{[...Array(7)].map((_, i) => {
					const date = moment(wd?.replaceAll('-', '/')).add(i, 'days')
					const calendarKey = moment(date).format('YYYY-MM-D')
					const calendarKey2 = moment(date).format('YYYY-MM-DD')
					const isActive = sDate === calendarKey
					const v = getVal(calendarData, date, isActive, i)
					// const isDisabled = v?.length === 1
					// const isNotEmpty = v?.length || sDate
					const overRaceDay = moment(date).isAfter(moment(userInfo?.race_date)?.add((userInfo?.type !== 4 ? 1 : 0), 'days'))
					return <Grid container className='item-calendar' key={'ldb' + ((wdi + 1) * i)} xs={12 / 7} sx={{ p: '0px 1px', height: '100%' }}>
						<Button disabled={isActive || overRaceDay}
							onTouchStart={isActiveWeek && !calendarOpen ? handleLongPress : undefined}
							onTouchEnd={(e) => isActiveWeek && !calendarOpen && handleLongPressEnd(e)}
							onClick={() => {
								setDayClickLoading(true)
								window.scrollTo(0, 0)
								setIsForward(moment(sDate).isBefore(calendarKey) ? 1 : 0)
								handleDateClick(calendarKey2, calendarData, isActiveWeek)
								setCalendarOpen(false)
								const backdropCont = document.getElementById('calendar-backdrop') as HTMLElement
								const backdropContChild = document.getElementById('calendar-backdrop-child') as HTMLElement
								if (backdropCont) {
									backdropCont.style.height = '0'
									backdropContChild.style.opacity = '0'
								}

							}}
							sx={{ p: '0', position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px', minWidth: '100%', justifyContent: 'flex-start', color: '#010101', overflow: 'hidden' }}
						>
							{v}
						</Button>
					</Grid>
				})}
				{/* // active day week-longbar */}
				{/* <Grid sx={{width: '100vw', height: '14px', backgroundColor: 'red', marginTop: '-4px', zIndex: '99999'}}></Grid> */}
			</Grid>
		})
	}
	const renderWeekCalendar = () => {
		let newWeekList = weekDays
		if (newAddWeek)
			newWeekList = [newAddWeek, ...newWeekList]
		const lastWeek = newWeekList[newWeekList.length - 1]?.replaceAll('-', '/')
		const nwl = [...newWeekList, moment(lastWeek).add(7, 'days').format('YYYY-MM-D'), moment(lastWeek).add(14, 'days').format('YYYY-MM-D'), moment(lastWeek).add(21, 'days').format('YYYY-MM-D')]
		const calList = renderWeekList(nwl)
		const staticList = renderWeekList(newWeekList)
		return isMobile ? (newWeekList?.map((wd: any, wdi: number) => slideIndex === wdi ? <WeekCalendar key={wdi} calendarDefault={calendarDefault} weekHeight={weekHeight + weekMB} list={nwl} weekList={calList} calendarOpen={calendarOpen} initialSlide={wdi} /> : staticList?.[wdi])) : staticList
	}

	const renderAllDays = () => {
		if (editWeek && weekRef?.current)
			return weekRef.current
		else {
			const daysList = userInfo?.monday_start ? ['M', 'T', 'W', 'T', 'F', 'S', 'S'] : ['S', 'M', 'T', 'W', 'T', 'F', 'S']
			const calendarDataArr = Object.keys(calendarData)
			const calendarDataLength = calendarDataArr?.length
			let newWeekList = weekDays || []
			if (newAddWeek)
				newWeekList = [newAddWeek, ...newWeekList]
			const lastWeekIndex = newWeekList.length - 1
			const all = <Box className='login-v2' sx={{ minHeight: 0 }}>
				<Grid container xs={12} sx={{ px: '10px', width: '100%', height: '30px', display: 'flex !important', position: 'relative' }} >
					{/*!(slideIndex === 0) && <Button onClick={()=>sliderRef?.current?.slickGoTo(0)} sx={{position: 'absolute', left: 10, fontSize: '16px', minWidth: 0, p: 0, color: '#010101', top: '50%', transform: 'translateY(-50%)'}}>
						<i className="fa-solid fa-arrow-left-to-line"/>
					</Button>*/}
					{daysList.map((d: any, di: number) => {
						return <Grid container key={di + 'n'} xs={12 / 7} sx={{ px: '1px', pb: '2px', pt: '3px' }} justifyContent='center' alignItems='center'>
							<Box sx={{ fontFamily: 'Poppins-Medium' }}>{d}</Box>
						</Grid>
					})}
					{/*!(slideIndex === lastWeekIndex) && <Button onClick={()=>sliderRef?.current?.slickGoTo(lastWeekIndex)} sx={{position: 'absolute', right: 10, fontSize: '16px', minWidth: 0, p: 0, color: '#010101', top: '50%', transform: 'translateY(-50%)'}}>
						<i className="fa-solid fa-arrow-right-to-line"/>
					</Button>*/}
				</Grid>
				<Box id='calendar-week-slider' className={isMobile ? 'default-bs' : ''} sx={{ height: 'auto', width: '100%', overflowY: 'hidden' }}>
					{calendarDataLength && !loadingValues && !weekLoading ?
						<Slider {...settings} ref={sliderRef}>
							{renderWeekCalendar()}
						</Slider>
						: <Grid container xs={12} sx={{ height: `${weekHeight}px`, px: '10px', mb: `${weekMB}px`, width: '100%', display: 'flex !important' }} >
							{[...Array(7)].map((_, di) => {
								return <Grid container key={di + 'nb'} xs={12 / 7} sx={{ p: '0px 1px', height: '100%' }}>
									<Button sx={{ backgroundColor: '#f2f2f8 !important', position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '5px', border: `1px solid #cdcdcd`, minWidth: '100%', justifyContent: 'flex-start', color: '#010101' }}>
										<Box className='loading-days' />
									</Button>
								</Grid>
							})}
						</Grid>
					}
					{isMobile && <WeekSwipeBar weekHeight={weekHeight + weekMB} calendarDefault={calendarDefault} isLoading={!(calendarDataLength && !loadingValues && !weekLoading)} setCalendarOpen={setCalendarOpen} calendarOpen={calendarOpen} setCalendarMove={setCalendarMove} />}
				</Box>
			</Box>
			weekRef.current = all
			return all
		}
	}

	useEffect(() => {
		setDayClickLoading(false)
		selectedDay != 0 && !updatedDBdayArrangement && setReordering(false)
	}, [selectedDay])

	useEffect(() => {

		// not needed anymore ?
		if (dayData) {
			const overrides = userOverrides?.filter((x: any) => x.week == selectedWeek.key) as Array<UserOverrideRecord>
			if (overrides && overrides.length > 0) {
				const isDiff = !currentOverrides || currentOverrides?.length !== overrides?.length || currentOverrides?.find((d: any, di) => JSON.stringify(d) !== JSON.stringify(overrides?.[di])) ? true : false
				if (isDiff) {
					setCurrentOverrides(overrides)
				}
			} else {
				setCurrentOverrides([])
				setCurrentDayActivity(
					getDailyActivityDisplay(dayData, userInfo, dayData?.marathonPaces, selectedRace?.value as number, dayData?.marathon?.race_day, allUserNotes)?.toUpperCase()
				)
			}
		}

		marathonDailyValues && assignCrossTrainValues()

		// console.log('=====================================')
		// console.log('daysOfWeek', daysOfWeek)
		// console.log('dayData', dayData)
		// console.log('marathonDailyValues', marathonDailyValues)
		// console.log('calendarData', calendarData)
		// console.log('userInfo', userInfo)
		// console.log('=====================================')

	}, [dayData, userOverrides, selectedWeek])

	useEffect(() => {
		const dW = (userInfo?.monday_start ? DAYS_ALT : DAYS).map((x) => {
			let newMid = userOverrides?.filter(y => y.day === x.id && selectedWeek.key === y.week)?.[0]?.mid
			if (newMid === undefined) {
				for (const t in marathonDailyValues) {
					if (marathonDailyValues[t]?.marathon?.day === x.id + 1 && marathonDailyValues[t]?.marathon?.id)
						newMid = marathonDailyValues[t].marathon.id
				}
			}
			return { ...x, mid: newMid }
		})
		const isDiff = !daysOfWeek || dW?.find((d: any, di) => JSON.stringify(d) !== JSON.stringify(daysOfWeek?.[di])) ? true : false
		if (isDiff) {
			setDaysOfWeek(dW)
			daysOfWeekClone['current'] = editWeek ? dW : ''
		}

	}, [userInfo, userOverrides, marathonDailyValues, selectedWeek])

	return <>
		{/*renderDaysMain()*/}
		{renderAllDays()}
		{/*renderGoBack()*/}
		<RSDrawer
			bottom
			drawerHeight='100%'
			popUpWidth='600px'
			open={editWeek}
			onClose={handleCloseEdit}
		>
			{renderWeekSelector()}
		</RSDrawer>
		<Loader active={legacyPlan} />
	</>
}

export default WeekSelector
