/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    // GabShareButton,
    // HatenaShareButton,
    // InstapaperShareButton,
    // LineShareButton,
    // LinkedinShareButton,
    // LivejournalShareButton,
    // MailruShareButton,
    // OKShareButton,
    // PinterestShareButton,
    // PocketShareButton,
    // RedditShareButton,
    // TelegramShareButton,
    // TumblrShareButton,
    TwitterShareButton,
    TwitterIcon,
    ViberShareButton,
    ViberIcon,
    // VKShareButton,
    // WhatsappShareButton,
    // WorkplaceShareButton,
} from "react-share"

import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { RSButton1, RSButton2 } from "../../components/buttons"
import RSButton from "../../components/buttons/RSButton"
import RSDrawer from "../../components/layout/RSDrawer"
import { SH1, SH4, P1, P2 } from '../../components/text/RSTypography'
import { FC, useEffect, useRef, useState } from "react"
import useRNBridge from "../../hooks/useRNBridge"
import useStore from "../../store/useStore"
import { ICurrentUser } from "../../store/createAccountStore"
import { GenerateRefLink } from "../../modules/shareActions"
import useIsMobileScreen from "../../hooks/useIsMobileScreen"
import { Snackbar } from "@mui/material"
import { RouletteSpinner } from "react-spinner-overlay"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SHARE_REWARD_AMOUNT } from "../../modules/cliEnvValues"
import useAppEvents from "../../hooks/useAppEvents"

type ShareInviteProps = {
    newPage?: boolean
    location?: string
}

const ShareInvite: FC<ShareInviteProps> = ({ newPage, location }) => {
    const { currentUser } = useStore((state: ICurrentUser) => state)
    const isMobileScreen = useIsMobileScreen()

    //////////////////////////////////////////
    // const shareUrl = window.location.href
    // const shareReferralLink = 'https://runsmartonline.com/programs/'
    const isNativeApp = localStorage.getItem('deviceRequestKey') ? true : false

    const messageHeader1 = "Let's get fit together!"
    const messageHeader2 = 'Runsmart'
    const messageText = 'Join me and train smarter using the RunSmart App. Try 14-days free: '


    const { sendDataToReactNative } = useRNBridge()

    const [openPopupShare, setOpenPopupShare] = useState(false)
    const [referralLink, setReferralLink] = useState('')
    const [loading, setLoading] = useState(false)
    const [showConfirmCopy, setShowConfirmCopy] = useState(false);

    const hasFetched = useRef(false);
    const { createEvent } = useAppEvents()
    //////////////////////////////////////////


    const handleShareInvitationLink = async () => {
        GenerateRefLink(currentUser?.account_id as number).then(async (res) => {
            await createEvent(currentUser?.account_id as number, 'generate-referral-link', JSON.stringify({ location: location ? location : 'profile_page' }))
            const refLink = res.data.result.referral_link
            setReferralLink(refLink)
            isNativeApp && handleShareInviteNative(refLink)
            !isNativeApp && setOpenPopupShare(true)
        })
    }

    const handleCopyLink = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            setShowConfirmCopy(true)
        }, 500)
    }

    const handleClose = () => {
        setShowConfirmCopy(false);
    };

    const handleShareInviteNative = (shareReferralLink: string) => {

        const payload = {
            title: 'Runsmart Invitation',
            message: 'Join me and train smarter using the RunSmart App. Try 14-days free: ',
            // replace with user's referral link
            url: shareReferralLink,
        }

        const dataToSend = {
            requestType: 'share-invite-native',
            payload: payload,
            timestamp: new Date().getTime(),
        }
        sendDataToReactNative(dataToSend)
    }

    useEffect(() => {
        if (!hasFetched.current) {
            if (currentUser?.account_id) {
                setLoading(true)
                GenerateRefLink(currentUser?.account_id as number).then(res => {
                    const refLink = res?.data.result?.referral_link
                    if (refLink)
                        setReferralLink(refLink)
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                })
            }
            hasFetched.current = true;
        }
    }, [currentUser])

    const renderPopupShare = () => <>
        <RSDrawer bottom maxHeight="70%" maxWidth="60%" open={openPopupShare} onClose={() => setOpenPopupShare(false)}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', paddingBottom: '2rem' }}>
                <div style={{ marginTop: '2rem', marginBottom: '1rem', fontSize: '1.2rem', width: 'auto', fontWeight: 'bold', textAlign: 'center' }}>Invite your Run Buddy to get points!</div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: '1rem', width: '20rem' }}>
                    <EmailShareButton url={referralLink} title={messageHeader1} subject="Runsmart Invitation" body={messageText}>
                        <EmailIcon size={40} round={true} />
                    </EmailShareButton>
                    <FacebookShareButton title={'Runsmart Invitation'} hashtag={messageText + referralLink} url={referralLink}>
                        <FacebookIcon size={40} round={true} />
                    </FacebookShareButton >
                    <TwitterShareButton url={referralLink} title={messageText} hashtags={[messageHeader2]} >
                        <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>
                    <ViberShareButton url={referralLink} title={messageText} >
                        <ViberIcon size={40} round={true} />
                    </ViberShareButton>
                </div>

                {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
                    under construction
                    <img src="https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcTnH30P8_pt24IFQOZ9T6yUMMUTL9zAiGKMJxOODMcpnIu__t_yI6g8KmsQuvpnAWv3xk-h-XaUlae1K8o"
                        alt="share" style={{ width: '20%', height: 'auto' }} />
                </div> */}


            </div>
        </RSDrawer>
    </>


    const renderPopupShareV2 = () => <>
        <RSDrawer fitContent popUpWidth='500px' bottom open={openPopupShare} onClose={() => setOpenPopupShare(false)}>
            <Grid container xs={12} sx={{ p: 4, pb: 2, flexDirection: 'column' }} justifyContent='flex-start' alignItems='center'>
                <SH1 size={4} sx={{ pb: 2, textAlign: 'center' }}>Share and Earn Store Cred</SH1>
                <P1 size={1} sx={{ maxWidth: '320px', textAlign: 'center', lineHeight: '1.5em' }}>{`Earn $${SHARE_REWARD_AMOUNT} towards the RunSmart store for every runner who joins from your link.`}</P1>
                <Grid container xs={12} sx={{ pt: 4 }} justifyContent='center' alignItems='center'>
                    <Grid container xs={isMobileScreen ? 12 : 6} sx={{ pb: 2 }} justifyContent='center' alignItems='center'>
                        <EmailShareButton url={referralLink} title={messageHeader1} subject="Runsmart Invitation" body={messageText}>
                            <RSButton1 sx={{ width: '200px' }}>
                                <i className="fa-solid fa-envelope" aria-hidden="true" style={{ fontSize: '20px', color: 'black', marginRight: '0.4rem' }}></i>
                                Share via Email
                            </RSButton1>
                        </EmailShareButton>
                    </Grid>
                    <Grid container xs={isMobileScreen ? 12 : 6} sx={{ pb: 2 }} justifyContent='center' alignItems='center'>
                        <CopyToClipboard text={referralLink}>
                            <RSButton1 secondary onClick={handleCopyLink} sx={{ width: '200px' }}>
                                <i className="fa-solid fa-copy" aria-hidden="true" style={{ fontSize: '20px', color: 'black', marginRight: '0.4rem' }}></i>
                                Copy Link
                            </RSButton1>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </Grid>
        </RSDrawer>
    </>

    return <>
        {newPage ? <>
            <Box sx={{ width: 'calc(100% - 90px)' }}>
                <RSButton2 secondary sx={{ p: 1, mt: 1, justifyContent: 'flex-start' }} onClick={async () => await handleShareInvitationLink()}>
                    <Box sx={{ width: '28px', mx: '10px' }}>
                        <i className='fa-sharp fa-light fa-paper-plane' style={{ fontSize: '25px' }} />
                    </Box>
                    <Box sx={{ width: 'calc(100% - 45px)', px: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', py: "5px" }}>
                        <SH4 sx={{ mt: 0.4 }} title>Invite a Friend</SH4>
                        {/* <P2 subtitle>Invite a Friend</P2> */}
                    </Box>
                </RSButton2>
            </Box>
            <CopyToClipboard text={referralLink}>
                <RSButton2 secondary sx={{ width: '80px', height: 'auto', mt: 1, flexDirection: 'column' }} onClick={handleCopyLink}>
                    {loading ? <RouletteSpinner size={25} color='#9FCD2B' /> :
                        <Grid container xs={12} sx={{ position: 'absolute', p: '5px' }}>
                            <Box sx={{ width: '100%' }}>
                                <i className='fa-light fa-copy' style={{ fontSize: '24px', marginBottom: '5px' }} />
                            </Box>
                            {/* <P2 sx={{ width: '100%', textAlign: 'center' }}>
                                Copy Link
                            </P2> */}
                        </Grid>
                    }
                </RSButton2>
            </CopyToClipboard>
        </> : <RSButton onClick={async () => await handleShareInvitationLink()} secondary card addBorder>
            <Box sx={{ px: 1, display: 'flex' }}>
                <i className="fa-duotone fa-solid fa-share-nodes duo-tone" style={{ fontSize: '30px' }} />
            </Box>
            <Box sx={{ pr: 1 }}>
                Refer a friend
            </Box>

        </RSButton>}

        {renderPopupShareV2()}

        {/* <Grid container xs={12} sx={{ position: 'relative' }} alignItems='center' justifyContent='flex-start'>
        <Box onClick={handleShareInvitationLink} sx={{ p: 1, zIndex: 1, display: 'flex', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', borderRadius: '50%', height: '60px', width: '60px', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '4px solid #9fcd2b' }}>
            <i className="fa-duotone fa-solid fa-share-nodes duo-tone" style={{ fontSize: '30px', marginLeft: '-4px' }} />
        </Box>
        <RSButton onClick={handleShareInvitationLink} sx={{ width: 'calc(100% - 45px)', height: '50px', borderLeft: 'none !important', borderRadius: '0px 5px 5px 0px', ml: '45px', textAlign: 'left', justifyContent: 'center' }}>
         Refer a friend
        </RSButton>

        </Grid> */}

        <Snackbar
            open={showConfirmCopy}
            autoHideDuration={2000}
            onClose={handleClose}
            message={`Link copied to clipboard`}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        // action={action}
        />
    </>
}


export default ShareInvite
