/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReplayRounded } from '@mui/icons-material'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import useSWR from 'swr'
import { Program } from '../../api/v2/programs/programs.types'
import Loader from '../../components/animation/Loader'
import RSButton from '../../components/buttons/RSButton'
import SelectPill from '../../components/input/SelectPill'
import { postReq } from '../../modules/apiConsume'

const unit = 'miles'

const RunningStyle = [{
    title: 'Heel strike',
    rotate: -20
}, {
    title: 'Midfoot Strike',
    rotate: 0
}, {
    title: 'Toe Strike',
    rotate: 20
}, {
    title: 'Unsure'
}]

const GoalsSelect = [
    'Improve my Nutrition',
    'Start a Running Routine',
    'Train for a race',
    'Get Stronger',
    'Run pain-free',
    'Improve run form',
    'Recover from Race'
]

const RunningActivities = [
    `Less than 10 ${unit}`,
    `10 to 20 ${unit}`,
    `21 to 30 ${unit}`,
    `31 to 40 ${unit}`,
    `41 to 50 ${unit}`,
    `More than 50 ${unit}
    `]

const PhysicalActivities = [
    {
        title: 'Little to No Exercise',
        desc: ''
    }, {
        title: 'Slightly Active',
        desc: 'Light, 1 - 3 times per week'
    }, {
        title: 'Moderately Active',
        desc: 'Moderate intensity, 4 - 5 times per week'
    }, {
        title: 'Very Active',
        desc: 'Daily exercise or training for half marathon'
    }, {
        title: 'Extra Active',
        desc: 'Exercise more than 1x/day training for >= 26.2'
    }]

const ProblemAreasSelect = [
    'Feet / Ankles',
    'Achilles / Calves',
    'Knees',
    'Hamstrings',
    'Quads',
    'Low Back',
]

const PILL_OPTIONS = ["Survey", "Generate"]

const EMBEDDINGS_VARIABLES = ['Programs', 'Exercises']

const Embeddings = () => {
    const [textQuery, setTextQuery] = useState('')
    const [formText, setFormText] = useState('')
    const [goals, setGoals] = useState<string[]>([])
    const [runningActivity, setRunningActivity] = useState(-1)
    const [physicalActitity, setPhysicalActitity] = useState(-1)
    const [isLoadingGenerate, setIsLoadingGenerate] = useState(false)
    const [problemAreas, setProblemAreas] = useState<string[]>([])
    const [allPrograms, setAllPrograms] = useState<Program[]>([])
    const [runningStyle, setRunningStyle] = useState(-1)
    const [dietaryPreferences, setDietaryPreferences] = useState('')
    const [textToGenerate, setTextToGenerate] = useState<string | undefined>(undefined)
    const [selectedProgram, setSelectedProgram] = useState<number | undefined>(undefined)
    const [selectedPill, setSelectedPill] = useState('Survey')
    const [selectedEmbeddingVariable, setSelectedEmbeddingVariable] = useState('Programs')
    const [relatedEmbeddings, setRelatedEmbeddings] = useState<Array<{
        exercise: any, similarity: number, program: Program
    }>>([])

    const { data: programs, isLoading, error } = useSWR(textQuery && selectedEmbeddingVariable == 'Programs' ? `/program-embeddings/search?searchQuery=${textQuery}` : undefined, { revalidateOnFocus: false, revalidateOnReconnect: false })

    const { data: allProgramsData, isLoading: isLoadingAllPrograms, error: errorAllPrograms } = useSWR(`/programs`, { revalidateOnFocus: false, revalidateOnReconnect: false })

    const { data: exerciseEmbeddings, isLoading: isLoadingExercises, error: errorExercises } = useSWR(textQuery && selectedEmbeddingVariable == 'Exercises' ? `/exercise-embeddings/search?searchQuery=${textQuery}` : undefined, { revalidateOnFocus: false, revalidateOnReconnect: false })



    const onPillChange = (value: any) => {
        setSelectedPill(value)
    }

    const handleGenerateEmbeddings = async () => {
        setIsLoadingGenerate(true)
        await postReq('/v2/program-embeddings/generate', {
            program_id: selectedProgram,
            description: textToGenerate
        })
            .then(() => {
                setIsLoadingGenerate(false)
                setSelectedProgram(undefined)
                setTextToGenerate('')
            })
            .catch(() => setIsLoadingGenerate(false))
    }

    const handleGenerateAllEmbeddingsExercises = async () => {
        setIsLoadingGenerate(true)
        await postReq('/v2/exercise-embeddings/all')
            .then((res) => {
                console.log('>>> generating all embeddings: ', res)
                setIsLoadingGenerate(false)
                setSelectedProgram(undefined)
                setTextToGenerate('')
            })
            .catch(() => setIsLoadingGenerate(false))
    }

    const handleChangeEmbeddingVariable = (event: any) => {
        setRelatedEmbeddings([])
        setSelectedEmbeddingVariable(event.target.value)
    }

    const handleChange = (event: any, type: string) => {

        const {
            target: { value },
        } = event;

        if (type === 'goals')
            setGoals(typeof value === 'string' ? value.split(',') : value)
        else if (type === 'runningActivity')
            setRunningActivity(event.target.value);
        else if (type === 'physicalActivity')
            setPhysicalActitity(event.target.value);
        else if (type === 'problemAreas') {
            setProblemAreas(typeof value === 'string' ? value.split(',') : value,
            );
        }
        else if (type === 'runningStyle')
            setRunningStyle(event.target.value);

        else if (type === 'selected-program') {
            setSelectedProgram(value)
        }
    };

    const handleReset = () => {
        setFormText('')
        setTextQuery('')
        setGoals([])
        setRunningActivity(-1)
        setPhysicalActitity(-1)
        setProblemAreas([])
        setRunningStyle(-1)
        setDietaryPreferences('')
    }

    useEffect(() => {
        if (programs?.data?.result && programs?.data?.result.length > 0 && !isLoading && !error)
            setRelatedEmbeddings(programs?.data?.result)
    }, [programs, isLoading, error])

    useEffect(() => {
        if (exerciseEmbeddings?.data?.result && exerciseEmbeddings?.data?.result.length > 0 && isLoadingExercises && !errorExercises)
            console.log('>>> exercise embeddings: ', exerciseEmbeddings?.data?.result)
        setRelatedEmbeddings(exerciseEmbeddings?.data?.result)
    }, [exerciseEmbeddings, isLoadingExercises, errorExercises])

    useEffect(() => {
        if (allProgramsData?.data?.result && allProgramsData?.data?.result.length > 0 && !isLoadingAllPrograms && !errorAllPrograms)
            setAllPrograms(allProgramsData?.data?.result)
    }, [allProgramsData, isLoadingAllPrograms, errorAllPrograms])

    useEffect(() => {
        const runningStyleParse = runningStyle >= 0 ? `I run with a ${RunningStyle[runningStyle].title} running style.` : '';
        const runningGoalParse = goals.length > 0 ? `My running goal is to ${goals.map((x, index) => " " + GoalsSelect[index])}.` : '';
        const runningActivityParse = runningActivity >= 0 ? `In the last four weeks, my average milage is ${RunningActivities[runningActivity]}.` : '';
        const physicalActivityParse = physicalActitity >= 0 ? `I am ${PhysicalActivities[physicalActitity].title}.` : '';
        const problemAreasParse = problemAreas.length > 0 ? `I have been experiencing pain in my ${problemAreas.map((x, index) => ProblemAreasSelect[index])}.` : '';
        const dietaryPreferencesParse = dietaryPreferences ? `My dietary preferences is/are ${dietaryPreferences}.` : '';

        setFormText(`${runningGoalParse} ${runningActivityParse} ${physicalActivityParse} ${problemAreasParse} ${runningStyleParse} ${dietaryPreferencesParse}`.trim().replace(/\s+/g, " "))
    }, [goals, runningActivity, physicalActitity, problemAreas, runningStyle, dietaryPreferences])

    return (
        <>
            <Grid container sm={12} md={12} sx={{ mb: 30 }}>
                <Grid xs={12} sm={12} md={2} lg={2}>
                    <div className='half-md-card w-100 card mx-sm-n1 p-3 mt-0 mb-1'>
                        <div className='w-100 mx-n3 mb-3'>
                            {/* <SelectPill
                                index={0}
                                key={0}
                                options={PILL_OPTIONS}
                                onChange={onPillChange}
                            /> */}
                        </div>

                        <div style={{ borderBottom: '2px solid #9FE0ED' }}>
                            <FormControl sx={{ minWidth: 120, mb: 2, width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label">Embeddings</InputLabel>
                                <Select
                                    value={selectedEmbeddingVariable}
                                    label="Variable"
                                    onChange={(e) => handleChangeEmbeddingVariable(e)}
                                    sx={{ width: '100%', backgroundColor: 'white', marginRight: '1rem' }}
                                >
                                    {EMBEDDINGS_VARIABLES?.map((eVar, index) => (<MenuItem key={index} value={eVar} style={{ border: 'none' }}>{eVar}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </div>

                        {selectedEmbeddingVariable === 'Programs' &&
                            <>
                                <InputLabel className='mt-4' id="demo-multiple-name-label">Running Goal</InputLabel>
                                <Select multiple value={goals} onChange={(e) => handleChange(e, 'goals')} >
                                    {GoalsSelect.map((goalValue, index) => (<MenuItem key={index} value={index}>{goalValue}</MenuItem>))}
                                </Select>

                                <InputLabel className='mt-2' id="demo-multiple-name-label">Running Activity</InputLabel>
                                <Select value={runningActivity} onChange={(e) => handleChange(e, 'runningActivity')} >
                                    {RunningActivities.map((act, index) => (<MenuItem key={index} value={index}>{act}</MenuItem>))}
                                </Select>

                                <InputLabel className='mt-2' id="demo-multiple-name-label">Physical Activity</InputLabel>
                                <Select value={physicalActitity} onChange={(e) => handleChange(e, 'physicalActivity')} >
                                    {PhysicalActivities.map((pa, index) => (<MenuItem key={index} value={index}>{pa.title}</MenuItem>))}
                                </Select>

                                <InputLabel className='mt-2' id="demo-multiple-name-label">Problem Areas</InputLabel>
                                <Select multiple value={problemAreas} onChange={(e) => handleChange(e, 'problemAreas')} >
                                    {ProblemAreasSelect.map((pa, index) => (<MenuItem key={index} value={index}>{pa}</MenuItem>))}
                                </Select>


                                <InputLabel className='mt-2' id="demo-multiple-name-label">Running Style</InputLabel>
                                <Select value={runningStyle} onChange={(e) => handleChange(e, 'runningStyle')} >
                                    {RunningStyle.map((rs, index) => (<MenuItem key={index} value={index}>{rs.title}</MenuItem>))}
                                </Select>

                                <InputLabel className='mt-2' id="demo-multiple-name-label">My dietary preferences is/are </InputLabel>

                                <Form.Control
                                    value={dietaryPreferences}
                                    type="text"
                                    as="textarea"
                                    rows={2}
                                    onChange={(e) => setDietaryPreferences(e.target.value)}
                                    // onBlur={() => handleUpdateSale("subtext")}
                                    disabled={isLoading}
                                />
                            </>}

                        {selectedPill === 'Generate' && allPrograms?.length > 0 &&
                            <div>
                                <InputLabel className='mt-2' id="demo-multiple-name-label">Select Program</InputLabel>
                                <Select className='w-100' placeholder='Select Program' value={selectedProgram} onChange={(e) => handleChange(e, 'selected-program')} >
                                    {allPrograms.map((program, index) => (<MenuItem key={index} value={program?.id}>{program.program_title}</MenuItem>))}
                                </Select>

                                <InputLabel className='mt-2' id="demo-multiple-name-label">Descriptions</InputLabel>
                                <Form.Control
                                    value={textToGenerate}
                                    type="text"
                                    as="textarea"
                                    rows={5}
                                    onChange={(e) => setTextToGenerate(e.target.value as string)}
                                    disabled={isLoadingAllPrograms}
                                />
                                <RSButton disabled={isLoadingAllPrograms || !textToGenerate?.length || !selectedProgram} className='mt-2' onClick={() => selectedProgram && textToGenerate?.length ? handleGenerateEmbeddings() : undefined}> Generate</RSButton>
                                <RSButton disabled={isLoadingAllPrograms} className='mt-4' sx={{ backgroundColor: 'red', color: 'white' }} onClick={() => handleGenerateAllEmbeddingsExercises()}> Generate Exercise Embeddings</RSButton>


                            </div>}
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={10} lg={10}>
                    <div className='half-md-card w-100 card mx-auto p-3 mt-0'>

                        <Grid container sm={12} md={12} sx={{ mb: 1 }}>
                            <Grid xs={12} sm={12} md={8}>
                                <Form.Label className='mt-3'>
                                    <h4 style={{ color: '#777' }}>{selectedEmbeddingVariable} Text Query</h4>
                                </Form.Label>
                            </Grid>
                            <Grid xs={12} sm={12} md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
                                <RSButton disabled={isLoading || isLoadingExercises || !formText} className='mt-2' sx={{ backgroundColor: '#39CFED', border: 'none', width: '4rem', mr: '0.4rem' }} onClick={handleReset}> <ReplayRounded /> </RSButton>
                                <RSButton disabled={isLoading || isLoadingExercises || !formText} className='mt-2' onClick={() => formText ? setTextQuery(formText) : undefined}> Search</RSButton>
                            </Grid>
                        </Grid>

                        <Form.Control
                            value={formText}
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder=""
                            onChange={(e) => setFormText(e.target.value)}
                            // onBlur={() => handleUpdateSale("subtext")}
                            disabled={isLoading || isLoadingExercises}
                        />
                    </div>
                    <div className='half-md-card w-100 card mx-auto p-3 mt-2'>
                        <Table responsive striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>{selectedEmbeddingVariable}</th>
                                    <th>Relevance %</th>
                                </tr>
                            </thead>
                            <tbody>
                                {relatedEmbeddings?.map((data, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td style={{ minWidth: '160px' }}>{selectedEmbeddingVariable == 'Programs' ? data.program.program_title : selectedEmbeddingVariable == 'Exercises' ? data.exercise.exercise_title : ''}</td>
                                        <td style={{ width: '120px' }}><b>{(Number(data.similarity.toPrecision(3) || 0) * 100).toFixed(1)}%</b></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Grid>
            </Grid>
            <Loader active={isLoading || isLoadingAllPrograms || isLoadingGenerate || isLoadingExercises} />
        </>
    )
}

export default Embeddings
