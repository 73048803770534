/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import is_numeric from 'locutus/php/var/is_numeric'
import confetti from 'canvas-confetti'

import { motion } from "framer-motion";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew'

import raceSelection from '../../pages/training-plan/build/RaceSelection.json'
import { titleCase } from '../../modules/miscUtils'
import { UpdateEventName } from '../../modules/trainingPlanActions';
import useTrainingPlanUtils from '../../hooks/useTrainingPlanUtils';
import useIsMobileScreen from '../../hooks/useIsMobileScreen'
import { UserRaceResults } from '../../api/v2/types'
import useStore from '../../store/useStore';
import { ITrainingPlanStore } from '../../store/createTrainingPlanStore';
import RSButton from '../../components/buttons/RSButton'
import RSDrawer from '../../components/layout/RSDrawer'
import RSHeaderCont from '../../components/layout/RSHeaderCont'
import {H2, SH3, P1, P5} from '../../components/text/RSTypography'
import RSInput from '../../components/input/RSInput'
import RSInputAdornment2 from '../../components/input/RSInputAdornment2';
import {RSButton1} from '../../components/buttons';
import RSToggleButton from '../../components/buttons/RSToggleButton';
import Loader from '../../components/animation/Loader'

const CompletePlan = (props: any) => {
	const { userInfo, handleSubmitCompletePlan, selectedRace, updateRaceResult, setUpdateRaceResult, raceResults, isFromWatch, raceTime, defaultComment, gptPlanId } = props
	const marathonType = userInfo?.type === 4 && userInfo?.raceResults ? 7 : userInfo?.type
	const event = raceSelection?.find((rs: any) => rs?.raceValue == marathonType)
	const eventName = userInfo?.event_name || event?.event_name || ''
	const [raceName, setRaceName] = useState<any>(eventName)
	const [rrTime, setRrTime] = useState<any>({ hr: '', min: '', sec: '' })
	const [isPR, setIsPR] = useState(false);
	const [isBQ, setIsBQ] = useState(false);
	const [newComment, setNewComment] = useState('')
	const [isDNF, setIsDNF] = useState(false);
	const [loadingFromWatch, setLoadingFromWatch] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const { ConvertFullTo5k, ConvertFullToHalf, ConvertM2H2, ConvertFullto10k, ConvertS2TimeObject } = useTrainingPlanUtils()
	const { userVdotAdjustments } = useStore((state: ITrainingPlanStore) => state)
	const isMobile = useIsMobileScreen();
	const initRef = useRef<boolean>(false);

	const confettiFire = (particleRatio: any, opts: any) => {
		confetti({
			...opts,
			origin: { y: 0.8 },
			zIndex: 10000,
			particleCount: Math.floor(200 * particleRatio)
		});
	}

	const showConfetti = () => {

    confettiFire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    confettiFire(0.2, {
      spread: 60,
    });
    confettiFire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    confettiFire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    confettiFire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
	}
	useEffect(() => {
		!raceResults?.id && updateRaceResult && showConfetti()
		if (raceResults?.id && (updateRaceResult || isFromWatch)) {
			setRaceName(raceResults?.race_name ? raceResults.race_name : '')
			setIsPR(raceResults?.is_best_pr ? true : false)
			setIsBQ(raceResults?.is_bq ? true : false)
			setNewComment(raceResults?.notes ? raceResults.notes : '')

			const fTime = raceResults?.finished_time_in_sec
			if (fTime) {
				const { hr, min, sec } = ConvertS2TimeObject(fTime)
				setRrTime({ hr: hr ? hr : '', min: min ? min : '', sec: sec ? sec : '' })
			}
			setLoadingFromWatch(false)
		} else {
			isPR && setIsPR(false)
			isBQ && setIsBQ(false)
			newComment && setNewComment('')
		}
	}, [raceResults, updateRaceResult])

	useEffect(() => {
		//console.log('eventName', userInfo, raceResults, eventName)
		setRaceName(eventName)
	},[eventName])

	const isUpdated = () => {
		if (raceResults?.id) {
			const rPR = raceResults?.is_best_pr ? true : false
			const rBQ = raceResults?.is_bq ? true : false
			const hr = rrTime?.hr ? rrTime.hr * 3600 : 0
			const min = rrTime?.min ? rrTime.min * 60 : 0
			const sec = rrTime?.sec ? rrTime.sec : 0
			const totalTime = hr + min + sec
			return (raceResults?.race_name !== raceName || raceResults?.finished_time_in_sec !== totalTime || rPR !== isPR || rBQ !== isBQ || raceResults?.notes !== newComment) ? false : true
		} else {
			return (rrTime.sec || rrTime.min || rrTime.hr) && raceName ? false : true
		}
	}

	const getRG = () => {
		const targetTime = userVdotAdjustments?.length > 0 ? (userVdotAdjustments[userVdotAdjustments?.length - 1]?.targetm) : userInfo?.targetm;
		const type = userInfo?.type;

		switch (type) {
			case 0:
				return ConvertFullToHalf(targetTime)
			case 1:
				return ConvertM2H2(targetTime)
			case 2:
				return ConvertFullTo5k(targetTime)
			case 3:
				return ConvertFullto10k(targetTime)
			case 4:
				return ConvertM2H2(targetTime)
			default:
				return ''
		}
	}

	const handleSubmit = async (isPRFromWatch?: any, valueFromWatch?: any) => {
		setIsSubmitting(true)
		isFromWatch && setLoadingFromWatch(true)
		const { hr, min, sec } = rrTime
		const fTimeInSec = (hr ? hr * 3600 : 0) + (min ? min * 60 : 0) + (sec ? sec * 1 : 0)

		const timeStr = getRG()?.split(' ')
		const dHR = timeStr?.find((t: string) => t.includes('h'))
		const dMIN = timeStr?.find((t: string) => t.includes('m'))
		const dSEC = timeStr?.find((t: string) => t.includes('s'))
		const finalDefaultTime = (dHR ? Number(dHR.replace('h', '')) * 3600 : 0) + (dMIN ? Number(dMIN.replace('m', '')) * 60 : 0) + (dSEC ? Number(dSEC.replace('s', '')) : 0)


		let is_pr = isPR
		let is_bq = isBQ

		if (isPRFromWatch !== undefined) {
			if (isPRFromWatch)
				is_pr = valueFromWatch
			else
				is_bq = valueFromWatch
		}

		const body: UserRaceResults = {
			user_id: userInfo?.internal_id,
			training_plan_id: userInfo?.uid,
			race_type: (selectedRace?.value == 0 || selectedRace?.value == 1) ? `${titleCase(selectedRace?.title)}${titleCase(selectedRace?.subTitle)}` : selectedRace?.title,
			race_name: raceName?.trim(),
			race_date: moment(userInfo?.race_date).day() == 1 ? moment(userInfo?.race_date).subtract(1, 'day').format('YYYY-MM-DD') : moment(userInfo?.race_date).format('YYYY-MM-DD'),
			finished_time_in_sec: isFromWatch ? (raceTime || 0) : (isDNF ? finalDefaultTime : fTimeInSec),
			is_best_pr: is_pr && !isDNF ? 1 : 0,
			is_bq: is_bq && !isDNF ? 1 : 0,
			is_dnf: isDNF ? 1 : 0,
			notes: (isFromWatch && defaultComment) || newComment,
			is_km: userInfo?.km == "Y" ? 1 : 0,
			is_monday_start: userInfo?.monday_start || 0,
			distance: userInfo?.distance || 20,
		}
		const id = gptPlanId ? Number(gptPlanId) : 0
		if (id && raceName?.trim() !== eventName?.trim)
			await UpdateEventName(id - 50000, raceName.trim())
		await handleSubmitCompletePlan?.(body, isFromWatch)
		!isFromWatch && handleExitRaceResult()
		setIsSubmitting(false)
	}

	const handleReset = () => {
		setRrTime({ hr: '', min: '', sec: '' })
		setIsPR(false);
		setIsBQ(false);
		setNewComment('')
		setIsDNF(false)
	}

	const handleExitRaceResult = () => {
		handleReset()
		setUpdateRaceResult?.(false)
	}

	const handleRrTimeChange = (e: any, t: string) => {
		if (!((t === 'min' || t === 'sec') && Number(e) > 59)) {
			const newRrTime = { ...rrTime, [`${t}`]: e }
			setRrTime(newRrTime)
		}
	}
	const handleDNFClick = (dnf: boolean) => {
		handleReset()
		setIsDNF(dnf)
	}


	const achievementList = [{
		label: 'Did you PR?',
		value: isPR,
		set: setIsPR,
		pr: true
	}, {
		label: userInfo?.type === 1 ? 'Is this BQ?' : '',
		value: isBQ,
		set: setIsBQ
	}]

	const renderDNF = () => {
		return <RSDrawer
			open={isDNF}
			onClose={() => handleDNFClick(false)}
		>
			<Box className='bg-main' sx={{minHeight: window.innerHeight }}>
				<RSHeaderCont drawer>
					<Grid container sx={{ position: 'relative' }}>
						<Grid container xs={12} justifyContent="space-between" alignItems="center">
							<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<ArrowBack onClick={() => handleDNFClick(false)} />
								<Box sx={{ ml: '10px', fontFamily: 'Poppins', fontSize: '18px', letterSpacing: 'normal' }}>
									Training Plan
								</Box>
							</Box>
						</Grid>
					</Grid>
				</RSHeaderCont>
				<Grid sx={{ p: 2, pr: '10px', pt: 3}} container>
					<Grid container xs={12} sx={{ fontSize: '18px', fontFamily: 'Poppins-medium', px: 2 }} justifyContent='center' alignItems='center'>
						{`I didn't finish the race.`}
					</Grid>
					<Grid container xs={12} sx={{ px: 2 }} justifyContent='center' alignItems='center'>
						<Grid container xs={10} sx={{ fontSize: '16px', fontFamily: 'Poppins-Light', textAlign: 'center', lineHeight: '1em' }}>
							{`Are you sure you don't have results to enter?`}
						</Grid>
					</Grid>

					<Grid container xs={12} sx={{ pt: '30px', pb: '10px' }} justifyContent="center" alignItems="center">
						<Grid container xs={10}>
							<input value={raceName} onChange={(e) => setRaceName(e.target.value)} placeholder="Enter Race Name" className='enter-time' />
						</Grid>
					</Grid>

					<Grid container xs={12} sx={{ px: 2, lineHeight: '1em' }} justifyContent='center' alignItems='center'>
						<Grid container xs={11}>
							<Grid container xs={12} sx={{ mt: '5px', pb: '5px' }} justifyContent="flex-start" alignItems="center">
								<SH3 size={1} sx={{ width: '100%', my: 1, display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
									Notes
									<P5 sx={{ ml: '2px' }}>(Optional)</P5>
								</SH3>
							</Grid>
							<Grid container xs={12} justifyContent='center' alignItems="center">
								<textarea className='text-area-complete h-100' placeholder='Tell us how it went!' value={newComment} onChange={(e) => setNewComment(e.target.value)} />
							</Grid>
						</Grid>
					</Grid>



					<Grid container xs={12} sx={{ mt: '40px', px: 2 }} justifyContent='center' alignItems='center'>
						<Grid container xs={8}>
							<Button onClick={handleSubmit} disabled={raceName?.trim() ? false : true} variant='contained' sx={{ boxShadow: 'none', width: '100%', fontFamily: 'Poppins', color: '#010101', border: `1px solid ${raceName?.trim() ? '#9FCD2B !important' : 'transparent'}`, p: '8px 0px', borderRadius: '5px', fontSize: '14px', backgroundColor: raceName?.trim() ? '#9FCD2B  !important' : 'default', textTransform: 'none' }}>
								Confirm
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</RSDrawer>
	}


	const handleChangeAchievement = (e: any, v: any, pr: boolean) => {
		if (isFromWatch)
			handleSubmit(pr, e)
		else
			v?.(e)
	}

	const renderAchievementList = () => {
		return <>
			{achievementList.map((v: any, iv: number) => {
				return v.label && <RSToggleButton loading={loadingFromWatch} style2={isFromWatch} key={iv + 'acv'} label={v.label} active={v.value} setActive={(e: any)=>handleChangeAchievement(e, v.set, v.pr || false)}/>
			})}
		</>
	}



	useEffect(() => {
		if (isFromWatch) {
			const differentTime = raceTime && raceResults?.finished_time_in_sec !== raceTime ? true : false
			const differentNotes = raceResults?.notes !== defaultComment ? true : false
			if (differentTime || differentNotes)
				handleSubmit()
		}
	},[isFromWatch, defaultComment, raceTime])

	return isFromWatch ? renderAchievementList() : <>
		<RSDrawer
			bottom={!isMobile}
			open={updateRaceResult && !isDNF}
			onClose={handleExitRaceResult}
		>
			<Box>
				<Loader active={isSubmitting} />
				{isMobile && <RSHeaderCont drawer backClick={handleExitRaceResult} title='Enter Results'/>}
				<Box sx={{ width: '100%', p: 2 }}>
					<H2 size={4} card sx={{py: 1}}>{`Congrats on finishing ${event?.complete_plan || 'the race'}!`}</H2>
					<P1 size={2} card >{`Let's finalize your results.`}</P1>

					<Grid container sx={{my: 2}}>
						<SH3 size={1} sx={{ width: '100%' }}>
							Race Name
						</SH3>
						<RSInput value={raceName} setValue={setRaceName} title='Enter Race Name' medium />
					</Grid>

					<Grid container sx={{mb: 2, mt: 2}}>
						<SH3 size={1} sx={{ width: '100%', my: 1}}>
							Finish Time
						</SH3>
						<Grid container>
							<Box sx={{ mr: 1 }}>
								<RSInputAdornment2 wholeNumber column value={rrTime.hr} setValue={(e: any) => handleRrTimeChange(e, 'hr')} label={'Hours'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
							</Box>
							<Box sx={{ mr: 1 }}>
								<RSInputAdornment2 wholeNumber column max={59} value={rrTime.min} setValue={(e: any) => handleRrTimeChange(e, 'min')} label={'Min'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
							</Box>
							<Box>
								<RSInputAdornment2 wholeNumber column max={59} value={rrTime.sec} setValue={(e: any) => handleRrTimeChange(e, 'sec')} label={'Sec'} sx={{ width: '60px', height: '50px', fontSize: '16px' }} />
							</Box>
						</Grid>
					</Grid>

					<Grid container sx={{mb: 2, mt: 1}}>
						<SH3 size={1} sx={{ width: '100%', my: 1}}>
							Achievements
						</SH3>
						{renderAchievementList()}
					</Grid>


					<Grid container sx={{mb: 2, mt: 1}}>
						<SH3 size={1} sx={{ width: '100%', my: 1, display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
							Notes
							<P5 sx={{ ml: '2px' }}>(Optional)</P5>
						</SH3>
						<Grid container xs={12} justifyContent='center' alignItems="center">
							<textarea className='text-area-complete h-100' placeholder='Tell us how it went!' value={newComment} onChange={(e) => setNewComment(e.target.value)} />
						</Grid>
					</Grid>
					<Grid container xs={12} sx={{ mt: 4 }} >
						<RSButton1 onClick={handleSubmit} disabled={isUpdated()} >Submit Results</RSButton1>
					</Grid>

					{!raceResults?.id && <Grid container xs={12} sx={{ pt: 2, pb: 3 }} justifyContent="center" alignItems="center">
						<RSButton1 tertiary onClick={() => handleDNFClick(true)}>{`I didn't finish the race`}</RSButton1>
					</Grid>}
				</Box>
			</Box>
		</RSDrawer>
		{renderDNF()}
	</>

}

export default CompletePlan;
